import React ,{ useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import { connect } from 'react-redux'
import { setExhibitorList, setCategoryOptions } from '../../../actions/main'
import { setLeftPage } from '../../../actions/ui'
import { setOpenLogin, setOpenBoothList, setOpenBoothDrawer } from '../../../actions/ui'
import { setLoading } from '../../../actions/main'
import axiosInstance from '../../../utils/axiosInstance.js'
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import { getTokenCookie, getTokenPlanner, getUrlToken } from '../../../utils/Common'
import { fetchCategoryOptions } from '../../../services/service.js'
import { setSearchOptions } from '../../../actions/searchbox'
import {
  useLocation
} from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { ESHOW_URL } from '../../../config.js'
import SearchBox from '../SearchBox.js';
import IconButton from '@mui/material/IconButton';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Index(props) {
  const { open } = props;
  const [boothList, setBoothList] = useState([]);
  let query = useQuery();

  let keySelect = props.searchbox?.searchKeySelect
  let pulldownValue = props.searchbox?.searchPulldownValue
  let searchInputValue = props.searchbox?.searchInputValue
  var selectedAdvancedFilterOptions = props.searchbox.selectedAdvancedFilterOptions

  const useStyles = makeStyles((theme) => ({
    navItem: {
      color: props.ui?.customStyles?.['left-drawer-icon-color']?(props.ui?.customStyles['left-drawer-icon-color']+"!important"):'rgba(0, 0, 0, 0.54)',
      "&:hover": {
        color: props.ui?.customStyles?.['left-drawer-icon-color']?(props.ui?.customStyles['left-drawer-icon-color']+"!important"):'rgba(0, 0, 0, 0.54)',
      },
      "&.active": {
        color: props.ui?.customStyles?.['left-drawer-icon-color']?(props.ui?.customStyles['left-drawer-icon-color']+"!important"):'rgba(0, 0, 0, 0.54)',
      },
    },
    searchBtn:{
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    }
  }));

  const classes = useStyles();
  
  useEffect(() => {
    if(pulldownValue || searchInputValue || selectedAdvancedFilterOptions?.length){
      if(props.ui.openBoothList && props.ui.leftPage != 'searched'){
        props.setLeftPage('searched')
      }
    }
  }, [keySelect, pulldownValue, searchInputValue, selectedAdvancedFilterOptions])  
  

  const getBoothList = ()=>{
    var active_booth_file = props.main.active_booth_file
    var token = getTokenCookie();   
    var map_key;   
    if(props.main.authInfo?.role != 'admin') {
      try{     
          var _token = getTokenPlanner();
          if(_token){
            token = _token
          }
      }
      catch(e){
        console.log("token parse", e)
      }
    }
    else{
    }
    map_key = active_booth_file?.KEY_ID
    if(query.get("map")){
      map_key = query.get("map");
    }
    map_key=false
    var url = `${ESHOW_URL()}?method=getExhibitorList&token=${token}${map_key?('&map_key='+map_key):''}`
    const options = {
        headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
            props.setSearchOptions(result.SEARCH) 
            props.setExhibitorList(result)
            if(result.EXHIBITORS){
              var boothList = [];
              for(var i = 0; i< result.EXHIBITORS.length; i++){
                var item = result.EXHIBITORS[i]
                if(!item?.SETUP?.LIST_PENDING_EXHIBITORS && item.STATUS == 2)
                  continue;
                boothList.push(
                  {
                    booth: item['BOOTHS']?item['BOOTHS']:"",
                    BOOTHS: item['BOOTHS']?item['BOOTHS']:"",
                    name:item['BOOTHS']?item['BOOTHS']:"",
                    priced:false,
                    id:"",
                    EXHIBITOR_KEY:item['EXHIBITOR_KEY']?item['EXHIBITOR_KEY']:"",
                    company:item['COMPANY_NAME']?item['COMPANY_NAME']:"",
                    bookmark:item['BOOKMARKED']?item['BOOKMARKED']:"",
                  }
                )
              }            
              boothList.sort(function(a, b) {
                const nameA = a.company+" "+a.name; // ignore upper and lowercase
                const nameB = b.company+" "+a.name; // ignore upper and lowercase
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }              
                return 0;
              });
              boothList.sort(function(a, b) {
                return a.company - b.company;
              });         
              setBoothList(boothList)
            }
        }
        else{
        }
    }).catch(error => {
    })  
  }

  useEffect(() => {
    if(props.open){
      props.setOpenBoothDrawer(true)
    }
  }, [props.open])

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var urlToken = getUrlToken()   
  useEffect(() => {
    if(props.ui.openBoothList && props.ui.leftPage == "home"){
      getCategoryOptions();
      getBoothList();
    }
  }, [props.main[`planner-${urlToken}`]])
  
  useEffect(() => {
    if(props.ui.openBoothList && props.ui.leftPage == "home"){
      getCategoryOptions();
      getBoothList();
    }
  }, [query])

  
  const getCategoryOptions =async ()=>{
      const categories = await fetchCategoryOptions();
      props.setCategoryOptions(categories)
  }
  
  const threeCount = ()=>{
    var count= 0;
    if(boothList && boothList.length > 0){
      var newBoothList = boothList.filter((booth)=>{
        return (booth.company_tag_3dbooth)
      })
  
      return newBoothList.length
    }
    return 0;
  }

  var cartList = props.cart?.carts;  
  var sales_shopping_cart_show = false
  var sales_booth_list_show = true
  var sales_3d_booths_show = true
  var sales_featured_exhibitors_show = true
  var sales_bookmarks_show = true
  var category_show = true
  var cart_show = false
  if(props.main.authInfo?.SHOPPING_CART  == 'show' || props.main.authInfo?.SALES?.SHOPPING_CART == 'show'){
      if(props.main.cartList?.length)
          cart_show = true
  }
    
  if(props.main.authInfo){
    if(props.main.authInfo.tokenInfo?.SHOPPING_CART == 'hide'){
      sales_shopping_cart_show = false
    }
    else if(props.main.authInfo.tokenInfo?.SHOPPING_CART == 'show'){
      sales_shopping_cart_show = true
    }
    if(props.main.authInfo.SALES?.BOOTH_LIST == 'hide'){
      sales_booth_list_show = false
    }
    if(props.main.authInfo.SALES?.['3D_BOOTHS'] == 'hide'){
      sales_3d_booths_show = false
    }
    if(props.main.authInfo.SALES?.FEATURED_EXHIBITORS == 'hide'){
      sales_featured_exhibitors_show = false
    }
    if(props.main.showSetup?.PUBLIC?.FEATURED_EXHIBITORS == 'hide'){
      sales_featured_exhibitors_show = false
    }
    if(props.main.showSetup?.PUBLIC?.BOOKMARKS == 'hide'){
      sales_bookmarks_show = false
    }
    if(props.main.authInfo.SALES?.CATEGORY == 'hide'){
      category_show = false
    }
    if(props.main.showSetup?.PUBLIC?.PRODUCT_CATEGORIES == 'hide'){
      category_show = false
    }
  }
  var categoryCnt = 0;
  if(props.main.categoryOptions && props.main.categoryOptions.length > 0){
    // categoryData.SUBCAT = listToTree(props.main.categoryOptions)
    console.log("categoryOptions", props.main.categoryOptions)
    for(var i = 0; i < props.main.categoryOptions.length; i++){
      let subCat = props.main.categoryOptions[i]['SUBCAT'];
      if(subCat?.length){
        categoryCnt+= subCat.length
      }
      else{
        categoryCnt++;
      }
    }
  }

  return (
    <Box pt={0} className="side-home xhbt-side-home" style={{paddingLeft:10}}>
      {/* <Box display='flex' alignItems={'center'} justifyContent={'space-between'} sx={{width:'100%'}}>
        <Box sx={{width:'calc(100% - 40px)'}}>
          <SearchBox leftBar = {true}/>
        </Box>        
        <IconButton style={{marginRight:'4px'}} size="small" aria-label="close" color="inherit" onClick={()=>{props.setOpenBoothList(false); }}>
            <KeyboardDoubleArrowLeftOutlinedIcon />
        </IconButton>
      </Box> */}
      <List className='xhbt-MuiList-root'>
        {props.main.active_booth_file?.event_home && props.main.active_booth_file?.event_home!="" &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            href={props.main.active_booth_file? `${(props.main.active_booth_file?.event_home?.includes("http")?"":"https://")+props.main.active_booth_file.event_home}`:"#"} target="_blank"
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem}  primary={'Home'} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        {sales_booth_list_show && props.role =='admin' &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>{props.setLeftPage('booths')}}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={'Booth List'} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        {sales_bookmarks_show && props.bookmarkCnt>0 && getTokenPlanner() &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>props.setLeftPage('bookmarks')}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>Bookmarks <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({props.bookmarkCnt?props.bookmarkCnt:0})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        
        {props.role != 'admin' && cart_show &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>{
              props.setLeftPage('sponsor_cart') 
            }}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>Shopping cart <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({Math.max(props.main.cartList?.length-2,0)})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        {props.role != 'admin' && sales_shopping_cart_show &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>{
              const _token = getTokenPlanner();
              if(!_token){
                props.setOpenLogin(true)
                return false;
              }
              props.setLeftPage('cart')
            }}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>Shopping cart <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({cartList?cartList.length:0})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        {sales_3d_booths_show &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>props.setLeftPage('three')}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>3D Booths <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({threeCount()})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        {sales_featured_exhibitors_show &&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>props.setLeftPage('features')}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>Featured Exhibitors <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({props.exhibitorFeaturedCnt?props.exhibitorFeaturedCnt:0})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        }
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>props.setLeftPage('all')}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>List All Exhibitors <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({props.exhibitorCnt?props.exhibitorCnt:0})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>
        {category_show&&
        <ListItem className='xhbt-MuiListItem-root' disablePadding sx={{ display: 'block', border:'none!important'}}>
          <ListItemButton
            onClick={()=>props.setLeftPage('categorylist')}
            sx={{
              minHeight: 48,
              height: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 0.5,
            }}
          >
            <ListItemText className={classes.navItem} primary={<>Categories <span style={{color:'rgb(160, 160, 160)'}}>&nbsp;({categoryCnt})</span></>} sx={{ opacity: open ? 1 : 0, fontWeight:'bold' }} />
          </ListItemButton>
        </ListItem>   
        }     
      </List>
      
    </Box>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
      cart: state.cart,
      searchbox: state.searchbox,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
        setLeftPage: value=>dispatch(setLeftPage(value)) ,
        setLoading:  data=>dispatch(setLoading(data)),
        setOpenBoothList: value=>dispatch(setOpenBoothList(value)) ,
        setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
        setOpenBoothDrawer: value=>dispatch(setOpenBoothDrawer(value)) ,
        setExhibitorList:  data=>dispatch(setExhibitorList(data)),
        setCategoryOptions:  data=>dispatch(setCategoryOptions(data)),
        setSearchOptions:  data=>dispatch(setSearchOptions(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Index);
import React ,{ useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import './App.css';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import ToolBox from './viewer/ToolBox.js';
import { setAuthInfo, setLoading} from './actions/main.js'
import { setOpenCompanyProfile, setCustomStyles, setSnackMsg, setOpenSnack } from './actions/ui.js'
import { parseToken, getTokenCookie, setCookie, getTokenPlanner } from './utils/Common.js'
import ReCAPTCHA from "react-google-recaptcha";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import {
  useLocation
} from "react-router-dom";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import qs from 'qs'
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import LeftProfile from  './viewer/component/LeftProfile.js';
import { useParams } from 'react-router-dom'
import { ESHOW_URL } from './config.js'
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Footer from './viewer/component/Footer.js';

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function Index(props) {
  const { id1, id2, id3, id4, id5 } = useParams();
  let query = useQuery();
  const [data, setData] = useState([]);
  const [setup, setSetup] = useState(false);
  const [openThanksModal, setOpenThanksModal] = useState(false);
  
  const [dataFetched, setDataFetched] = useState(false);
  const [page, setPage] = React.useState(1);
  const [keySelect, setKeySelect] = useState('')
  const [filteredItems, setFilteredItems] = useState([])
  const [searchOptions, setSearchOptions] = useState([]);
  const [pulldownValue, setPulldownValue] = useState(false);
  const [formValue, setFormValue] = React.useState({});
  const page_size = 24;
  const keywordInput = useRef(null);
  const selectRef = useRef(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  
  const useStyles = makeStyles((theme) => ({
    searchBtn:{
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    },
    bubble:{
      color:props.ui?.customStyles?.['bubble-label-color']?(props.ui?.customStyles['bubble-label-color']+"!important"):'white',
      backgroundColor:props.ui?.customStyles?.['bubble-bg-color']?(props.ui?.customStyles['bubble-bg-color']+"!important"):'#1565c0',
    }
  }));
  const classes = useStyles();
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    console.log('Captcha value:', value);
  };

  const onChangeValue = (e)=>{
    setFormValue({...formValue, [e.target.name]: e.target.value});
  }
  
  const processAuth = async ()=>{  
    var tokenInfo = await parseToken(query)     
    await fetchCustomStyles();           
    var show_key = tokenInfo.show_key?tokenInfo.show_key:'';
    var user_key = tokenInfo.user_key?tokenInfo.user_key:'';
    var BOOTH_PRICING = tokenInfo.BOOTH_PRICING?tokenInfo.BOOTH_PRICING:'';
    var SHOPPING_CART = tokenInfo.SHOPPING_CART?tokenInfo.SHOPPING_CART:'';
    var SALES = tokenInfo.SALES
    props.setAuthInfo({show_key, user_key, SALES,BOOTH_PRICING,SHOPPING_CART, authenticated: (tokenInfo.show_key && tokenInfo.user_key?3:2), tokenInfo})
    if(!(tokenInfo.show_key && tokenInfo.user_key)){ 
        return
    }
  }

  const fetchCustomStyles = async ()=>{
    if(query.get('sales_map')) {                        
      props.setCustomStyles({})
      return;
    }
    console.log("fetchCustomStyles")
    var url  = `${ESHOW_URL()}?method=getCSS&token=${getTokenCookie()}`
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    }  
    const response = await axios.get(url, options)
    if(response.status == 200){
        var data = response.data
        if(data.SUCCESS == true){
            if(data.CSS){
                console.log("result.CSS", data.CSS)                    
                props.setCustomStyles(data.CSS)
            }
        }
    }
    else{
        console.log("")
    }
  }

  const openCompanyProfile=(exhibitor)=>{   
    var booth = {};
    booth.exhibitors = [{EXHIBITOR_KEY: exhibitor.EXHIBITOR_ID}]
    booth.booth_number = exhibitor?.BOOTHS

    props.setOpenCompanyProfile({booth:booth, open:true})
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fafafa',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display:'block'
  }));

  const fetchData = ()=>{    
    if(props.urlToken){
      var public_token = `/${id1}`
        if(id2){
          public_token = `${public_token}/${id2}`
        }
        if(id3){
          public_token = `${public_token}/${id3}`
        }
        if(id4){
          public_token = `${public_token}/${id4}`
        }
        if(id5){
          public_token = `${public_token}/${id5}`
        }
        setCookie('V3PUBLICMAP', public_token)
    }
    props.setLoading(true)
    var url = `${ESHOW_URL()}?method=getShowContacts` 
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    } 
    axios.get(url, options).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
          if(result.CONTACTS){
            setData(result.CONTACTS)            
            setSetup(result.SETUP)
            if(result.SEARCH && result.SEARCH?.length){
              setKeySelect(result.SEARCH[0].LABEL)
            }
          }
        }
        else{
          setData([])
        }
        setDataFetched(true)
        props.setLoading(false)
    }).catch(error => {
      props.setLoading(false)
    })  
  }

  useEffect(() => {
    // if(props.urlToken){
    //     var public_token = `/${id1}/${id2}/${id3}`
    //     setCookie('V3PUBLICMAP', public_token+(id4?`/${id4}`:''))
    // }
    // processAuth()
    document.getElementsByTagName("body")[0].style.overflowY='scroll'
    props.setOpenCompanyProfile(false)
    fetchData()
  }, [id5, id4, id3, id2, id1])

  const sendEmail = ()=>{
    // var email = setup?.MESSAGE_EMAIL
    // if(email){
      // var body = '';   
      // body = `Name: ${formValue.name}<br>Subject: ${formValue.subject}<br>Message: ${formValue.message}`

      var _token = getTokenPlanner();
      if(!_token){
        _token = getTokenCookie()
      }
      var postData =
      {
        SETUP_KEY: setup?.SETUP_KEY,
        YOUR_EMAIL:  `${formValue.email??''}`,
        YOUR_NAME: `${formValue.name??''}`,
        SUBJECT:'Request for information',
        MESSAGE: `${formValue.message??''}`,
        EMAIL_TYPE: "contact_us"
      };

      
      const options = {
        headers: {
          'body': '[{"company:","test"}]', 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${_token}`
        }
      }  
      var url = `${ESHOW_URL()}?method=putEmail`
      axios.post(url, JSON.stringify(postData), options).then(async res=>{
        const eshowResponse = res.data;
        if(eshowResponse.SUCCESS == true){
          setOpenThanksModal(true); 
        }
        else{
          props.setOpenSnack(true);
          props.setSnackMsg("Something went wrong");
        }
      }).catch(error => {
          console.log("error", error);
      })      
    // }
  }

  const handleSubmit = (e)=>{
    e.preventDefault();
    if(!captchaValue){
      props.setOpenSnack(true);
      props.setSnackMsg("Captcha verification failed");
      return;
    }
    if(!formValue.name){
      props.setOpenSnack(true);
      props.setSnackMsg("Name is required");
      return;
    }
    if(!formValue.email){
      props.setOpenSnack(true);
      props.setSnackMsg("Email is required");
      return;
    }
    if(!formValue.subject){
      props.setOpenSnack(true);
      props.setSnackMsg("Subject is required");
      return;
    }
    if(!formValue.message){
      props.setOpenSnack(true);
      props.setSnackMsg("Message is required");
      return;
    }
    var formdata = 
    {
      captchaValue :captchaValue?captchaValue:''
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {"content-type": 'application/x-www-form-urlencoded'}
    }
  
    axios.post('verify_captcha', data, options).then(async res=>{
        const eshowResponse = res.data;
        if(eshowResponse.SUCCESS == true){
          sendEmail()
        }
        else{
          props.setOpenSnack(true);
          props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Captcha verification failed");
        }
        
    }).catch(error => {
      props.setOpenSnack(true);
      props.setSnackMsg("Captcha verification failed");
    })
  }

  console.log("render client app")
  console.log("data", data);
  var pageData = paginate(filteredItems, page_size, page);
  var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
  if(banner == "undefined") banner = null

  return (
    <Box id="xhbt-main-container" className="App Client">
      <ToolBox {...props}/>
      <Box className="xhbt-data-box" style={{maxWidth: 1200, margin:'64px auto 128px', paddingLeft: 10, paddingRight:10, marginTop:(banner && banner != ''?(150+64):64)}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box textAlign={'left'}>
              {setup?.HEADER_TEXT &&
              <div dangerouslySetInnerHTML={{ __html: setup?.HEADER_TEXT }}></div>
              }
            </Box>
            <Box>
              {
                data?.map((contact, index)=>{
                  return(
                    <Box key={index} textAlign={'left'}>
                      <Box>
                        <h2>{contact.HEADER_LABEL}</h2>
                      </Box>
                      <Box display='flex'>
                        <img width='80px' height='80px' src={contact.PHOTO}/>
                        <Box ml={2} className='contact-list'>
                          {contact?.NAME &&
                          <p>{contact?.NAME}</p>
                          }
                          {contact?.TITLE &&
                          <p>{contact?.TITLE}</p>
                          }
                          {contact?.PHONE &&
                          <p>{contact?.PHONE}</p>
                          }
                          {contact?.EMAIL &&
                          <p>{contact?.EMAIL}</p>
                          }
                          {contact?.DESCRIPTION &&
                          <p>{contact?.DESCRIPTION}</p>
                          }
                        </Box>
                      </Box>
                    </Box>
                  )
                })
              }
            </Box>
            <Box textAlign={'left'}>
              <h2>OUR MAILING ADDRESS</h2>
              {setup?.MAILING_ADDRESS &&
              <div>{setup?.MAILING_ADDRESS}</div>
              }
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box textAlign={'left'}>
              <h2>SEND A MESSAGE NOW.</h2>
              <form onSubmit={handleSubmit} style={{padding:10}}>
                <TextField
                  name="name"
                  margin="dense"
                  label="Your Name..."
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValue?.name??''}
                  onChange={onChangeValue}
                />
                <TextField
                  name="email"
                  margin="dense"
                  label="Your Email..."
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValue?.email??''}
                  onChange={onChangeValue}
                />
                <TextField
                  name="subject"
                  margin="dense"
                  label="Subject"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValue?.subject??''}
                  onChange={onChangeValue}
                />
                <TextField
                  name="message"
                  margin="dense"
                  label="Your Message"
                  type="text"
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={5}
                  value={formValue?.message??''}
                  onChange={onChangeValue}
                />
                <Box>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                    onChange={handleCaptchaChange}
                  />
                </Box>
                <Box mt={2} textAlign='left' >
                  <Button variant="contained" color="primary" size="large" type="submit">
                    SEND
                  </Button>
                  {/* {processing &&
                  <CircularProgress 
                    sx={{
                    marginLeft:'10px'
                    }}
                    size={25}
                    thickness={4}
                  />
                  } */}
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer/>
      
      <LeftProfile groupOptions = {[]}/>
      {props.ui?.customStyles?.['loaded'] &&
      <LoadingOverlay
            active={props.main.loadingFlag}
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
        }
      <Dialog
        fullWidth={true}
        maxWidth={"xs"} 
        open={openThanksModal}>
        <DialogContent>
          <DialogContentText className='thanks-msg' sx={{textAlign:'center',marginTop:'20px', color:'black'}}>          
              {setup?.CONFIRM_MESSAGE &&
              <div dangerouslySetInnerHTML={{ __html: setup?.CONFIRM_MESSAGE }}>{}</div>
              }
              <Box mt={2}>
                <MarkEmailReadIcon  sx={{ color: '#ee5d10', fontSize: 32 }}/>
              </Box>
          </DialogContentText>         

          <Box style={{textAlign:'center',marginTop:20}}>
            <Button onClick={()=>{setFormValue({}); setOpenThanksModal(false)}} variant="contained" color="primary" size="large" type="submit">
              OK
            </Button>
          </Box>
          
        </DialogContent>
      </Dialog>
    </Box>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
      setCustomStyles: data=>dispatch(setCustomStyles(data)),
      setAuthInfo:  data=>dispatch(setAuthInfo(data)), 
      setLoading:  data=>dispatch(setLoading(data)),
      setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
      setSnackMsg: data=>dispatch(setSnackMsg(data)),
      setOpenSnack: data=>dispatch(setOpenSnack(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);

import React ,{ useEffect, useRef, useState } from 'react';
import { fabric } from "fabric";
import axios from 'axios';
import axiosInstance from '../utils/axiosInstance.js'
import qs, { parse } from 'qs'
import jQuery, { map } from "jquery";
import { SVG, extend as SVGextend, Element as SVGElement } from '@svgdotjs/svg.js'
import { connect } from 'react-redux'
import './View.css';
import AppLeftPanel from './component/AppLeftPanel';
import LeftProfile from  './component/LeftProfile';
import AppUnassignedPanel from './component/AppUnassignedPanel';
import ElementBox from './component/ElementBox';
import SetAisleModal from './component/SetAisleModal'
import SetDrawModal from './component/SetDrawModal'
import SetSponsorshipsModal from './component/SetSponsorshipsModal'
import SetSymbolModal from './component/SetSymbolModal'
import Notfound from './component/Notfound'
import LZString from 'lz-string';
import Grid from '../utils/grid/Grid';
import { Point } from '../utils/geometry/Point';
import { v4 as uuidv4 } from 'uuid';
import Carousel from 'react-material-ui-carousel'
import IconButton from '@mui/material/IconButton';
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, redrawobjectborder, adjustobjectborder, checkifrotate, checkifrotatepoints, checkiftable, rotatePathObject, rotateObject, rotatePoints, rotatePoints2 , checkifpathrotate, checkifbooth, compressJson, toggleDrawMode, unlockMap, lockMap, addText, centerFocus, redrawRulers, changeBorderColor, getGeometry, setObjectBG, getBGColor, checkIfSelectable, composeLoadObjects, initCanvas, afterCanvasLoad, px2unit, unit2Px, renderCoordinate, removeCoordinate, moveCoordinate, setObjectBorder, sendOriginalToTopGrid,getDuplicatedBooths, scaleTextsCenterFocus, transformVertex, transformRotateVertex, getStrokeWidth, setBackgroundImage} from '../utils/CanvasTool'
import ToolBoxAdmin from './ToolBoxAdmin';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Draggable from 'react-draggable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CloseIcon from '@mui/icons-material/Close';
import ShowMore from 'react-show-more';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {mainsvg} from '../dummy/mainsvg'
import {parsedwg} from '../dummy/parsedwg'
import FeaturedLabel from './component/FeaturedLabel';
import ReactPlayer from 'react-player'
import  area  from 'area-polygon'

import { API_URL, ESHOW_URL, main_widow_offset_x, main_widow_offset_y  } from '../config.js'
import { setDwgImported, setMainCanvas, setgGrid, setBgCanvas, setBgImgCanvas, setMainSvg, setImportLayers, setMainLayers, setBoothList, setBoothFileList, setCanvasRefresh, setLoading, setActiveBoothFile, setMenuControl, setPricingOptions, setPremiumOptions, setDiscountOptions, setGroupOptions, setSponsorshipsOptions, setExhibitorList, setCategoryOptions, setFeeGroupOptions, setFeeCategoryOptions,setMechantAccount, setAuthInfo,  to2D, setMapRemoveObjects, setFirstMapContent, setFirstBgContent, setTopRuler, setLeftRuler, setShowSetup, resetMain, setCartList } from '../actions/main'
import { setLog, addLog, addLogs } from '../actions/log'
import { setBoothHistory } from '../actions/booth_history'
import { setOpenCompanyProfile, setOpenSponsorshipProfile, setOpenUnassignedProfile, setOpenFilenameModal,setOpenSetBoothModal, setOpenSetTableModal, setLockFlag, setSnackMsg, setOpenSnack,resetUI,setOpenImportmodal, setOpenCompanyModal, setEditCompany,setMouseHoverEnable } from '../actions/ui'
import JWT from 'cf-jwt';
import { Buffer } from "buffer";
import {
    BrowserRouter as Router,
    Link,
    useLocation
} from "react-router-dom";
import { saveLog } from '../services/service.js'
import { parseToken, buildObjectProperty, convertRgbHex, getDefaultGroup, setCookie, getCookie, convertRgb,getTokenCookie,assetUrl, getCartCookie } from '../utils/Common'
import { uuid4 } from './component/sketch_main/utils';
import { act } from '@testing-library/react';
import Flatten from "@flatten-js/core"

const  turf = require("@turf/turf");
var g_mouseoverObject;

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


Buffer.from("anything", "base64");
window.Buffer = window.Buffer || require("buffer").Buffer;

// // var jwt = require('jsonwebtoken');
// const jwt = require('jsonwebtoken').default;
var leftRuler, topRuler
var clickedObj = null
var gLayerSelect = {booth:true, booth_outline:true, booth_number:true, annotation:true, sponsorships:true, original:false};
var g_lockFlag = false;
var g_panFlag = false
var g_objectMoving = false
var g_zoomTimeout =false
var g_zoomTimeoutFlag =false
var g_fileSaved =false
var g_mouseUpFlag = false
var g_isDragging = false

var table6Object = null;
var table8Object = null;
var table10Object = null;
var g_grid
var gridCanvas;
var bgCanvas
var bgImgCanvas
var g_mainCanvas
var g_groupOptions
var g_startTime = 0
var g_coordinate = false
var g_mouseOutTimeout = false
var linklistBlocks = false
var g_mouseOverTimeout = false
var axiosController
var g_mouseEvent;
var mouseHoverEnable;
var topRuler;
var leftRuler;
let timeoutWheel;

function MainViewer(props) {
    const mainCanvasRef = useRef(null);
    const overviewCanvasRef = useRef(null);
    const mainCanvasDiv = useRef(null);
    const mouseModalRef = useRef(null);
    const loadingImagRef = useRef(null);
    const [openSetAisleModal, setOpenSetAisleModal] = useState(false);
    const [openSetDrawModal, setOpenSetDrawModal] = useState(false);
    const [openSetSponsorshipsModal, setOpenSetSponsorshipsModal] = useState(false);
    const [openSetSymbolModal, setOpenSetSymbolModal] = useState(false);
    const [selectedAisle, setSelectedAisle] = useState(null);
    const [selectedDrawObject, setSelectedDrawObject] = useState(null);
    const [selectedSponsorshipsObject, setSelectedSponsorshipsObject] = useState(null);
    const [selectedSymbolObject, setSelectedSymbolObject] = useState(null);
    const [maincanvas_width, setMaincanvasWidth] = useState(false)
    const [maincanvas_height, setMaincanvasHeight] = useState(false)
    const [mouseoverObject, setMouseOverObject] = useState(false)
    const [mouseOverExibitorInfo, setMouseOverExibitorInfo] = useState(false)
    const [mouseOverBoothInfo, setMouseOverBoothInfo] = useState(false)    
    const [mouseOverExibitorKeyList, setMouseOverExibitorKeyList] = useState([])
    
    
    const [openLegend, setOpenLegend] = useState(false);
    // const [authenticated , setAuthenticated] = React.useState(1); //1:loading, 2:fail, 3:success
    let query = useQuery();

    const createToken = async ()=>{
        var test_data = {
            show_name : "test",
            show_key: "test_key",
            user_key: "test_user",
            show_url: "http://test_url/",
            tpage : "http://test_tpage/",
            uuid : "12345648"
        }
        const jwt = await JWT.sign(test_data, 'jwt', {
            algorithm: 'HS256',
            expiresAt: new Date(Date.now() + 600000000), // 60 seconds
        });
        // const jwt = await JWT.sign({
        //     hello: 'world!',
        //     some: 'lovely data',
        //     that: 'i can sign!',
        // }, 'what-a-lovely-secret', {
        //     algorithm: 'HS256',
        //     expiresAt: new Date(Date.now() + 60000), // 60 seconds
        // });

        //console.log("test_token", jwt)
    }
    var mainOffsetX = 50;
    var mainOffsetY = 50;
    var imageOffsetX, imageOffsetY;
    var view_file = props.main.view_file;
    var mainCanvas = props.main.mainCanvas;
    var mainLayers = props.main.mainLayers;
    var mainSvg = props.main.mainSvg
    var boothFileList = props.main.boothFileList;    
    var active_booth_file = props.main.active_booth_file
    var pricingOptions = props.main.pricingOptions
    var premiumOptions = props.main.premiumOptions
    var discountOptions = props.main.discountOptions
    var groupOptions = props.main.groupOptions
    var authInfo = props.main.authInfo
    mouseHoverEnable = props.ui.mouseHoverEnable
    // console.log("boothFileList", boothFileList)
    const handleCloseSetAisleModal = ()=>{
        setOpenSetAisleModal(false)
    }
    const handleCloseSetDrawModal = ()=>{
        setOpenSetDrawModal(false)
    }
    const handleCloseSetSponsorshipsModal = ()=>{
        setOpenSetSponsorshipsModal(false)
    }
    const handleCloseSetSymbolModal = ()=>{
        setOpenSetSymbolModal(false)
    }
    
    const handleConfirmSetAisleModal = (name)=>{
        setOpenSetAisleModal(false)
        var textObjs= selectedAisle.getObjects('text');
        if(textObjs && textObjs.length>0){
            textObjs[0].set('text', name)
            mainCanvas.requestRenderAll();
        }
    }

    const handleConfirmSetDrawModal = ()=>{
        handleCloseSetDrawModal()
    }

    const handleConfirmSetSymbolodal = ()=>{
        handleCloseSetSymbolModal()
    }

    const handleConfirmSetSponsorshipsModal = ()=>{
        handleCloseSetSponsorshipsModal()
    }
    
    const StartSetDrawModal = (aisle) =>{     
        setSelectedDrawObject(aisle)   
        setOpenSetDrawModal(true)
    }

    const StartSetSponsorshipsModal = (aisle) =>{     
        setSelectedSponsorshipsObject(aisle)   
        setOpenSetSponsorshipsModal(true)
    }

    const StartSetSymbolModal = (aisle) =>{     
        setSelectedSymbolObject(aisle)   
        setOpenSetSymbolModal(true)
    }

    const StartSetAisleModal = (aisle) =>{     
        setSelectedAisle(aisle)   
        setOpenSetAisleModal(true)
    }

    const setCanvas = (canvas)=>{
        var activeFile = active_booth_file;
        
        if(activeFile){
            canvas.set({
                convertRateX:activeFile['convertRateX'], 
                convertRateY:activeFile['convertRateY'], 
                convertOffsetX:activeFile['convertOffsetX'], 
                convertOffsetY:activeFile['convertOffsetY'], 
                startX:activeFile['startX'], 
                startY:activeFile['startY'], 
                endX:activeFile['endX'], 
                endY:activeFile['endY'],
                mapWidth:activeFile['mapWidth'],
                mapHeight:activeFile['mapHeight'],
                convertUnit:activeFile['convertUnit'],
                mapUnit:activeFile['mapUnit'],
                sales_form:activeFile['sales_form'],
                mapLock:activeFile['mapLock'],
                gridSize:activeFile['gridSize'],
                strokeWidth:activeFile['strokeWidth'],
                showGrid:activeFile['showGrid'],
                gridAngle:activeFile['gridAngle'],
                gridOffsetX:activeFile['gridOffsetX'], 
                gridOffsetY:activeFile['gridOffsetY'], 
                mapOffsetX:activeFile['mapOffsetX'], 
                mapOffsetY:activeFile['mapOffsetY'], 
                header_banner:activeFile['header_banner'], 
                header_color:activeFile['header_color'], 
                LIST_PENDING_EXHIBITORS: activeFile.LIST_PENDING_EXHIBITORS,
                LIST_TBD_EXHIBITORS: activeFile.LIST_TBD_EXHIBITORS ,
            })
                  
            canvas.clipPath = new fabric.Rect({
                width: Math.abs(activeFile.endX - activeFile.startX)+5,
                height: Math.abs(activeFile.endY - activeFile.startY)+5,
                top: 45,
                left: 45
              });      
              
            canvas.renderAll()
        }
    }

    const fetchShowSetup =async ()=>{
        var url = `${ESHOW_URL()}?method=getShowSetup&token=${getTokenCookie()}`
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getShowSetup&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        const response = await axiosInstance.get(url)
        var result = false;
        if(response.status == 200){
            var data = response.data
            if(data.SUCCESS == true){
                result = data
                props.setShowSetup(result)
            }
        }
        else{
            console.log("")
        }
        return result;
    }

    const fetchSetup =async (map_key)=>{
        var url = `${ESHOW_URL()}?method=getSetup&map_key=${map_key}&token=${getTokenCookie()}`
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getSetup&map_key=${map_key}&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        const response = await axiosInstance.get(url)
        var result = false;
        if(response.status == 200){
            var data = response.data
            result = data
            if(data.SUCCESS == true){
                result = data
            }
            else{
                props.setOpenSnack(true);
                props.setSnackMsg((data.MESSAGE?(" "+data.MESSAGE):""));
            }
        }
        else{
            console.log("")
        }
        return result;
    }

    const fetchGroupList =async ()=>{
        var url = `${ESHOW_URL()}?method=getBoothGroups&token=${getTokenCookie()}`
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getBoothGroups&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            var result = res.data;
            if(result.length > 0){
                for(var i = 0; i < result.length; i++){
                    result[i].TITLE = result[i].TITLE.trim()
                }
            }
            
            result.sort(function (a, b) {
                return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
            });
            props.setGroupOptions(result)
            g_groupOptions = result
        }).catch(error => {
        })  
    }

    const fetchCartList =async ()=>{
        var active_booth_file = props.main.active_booth_file
        
        var  map_key = active_booth_file.KEY_ID;
        var  form_key;
        if(props.main.authInfo){
            form_key = active_booth_file.form_key
        }
        if(!form_key) return
        var query = `${ESHOW_URL()}?method=getCart&form_key=${form_key}&token=${getTokenCookie()}` 
        var parent_key = props.main.authInfo.SALES?.PARENT_KEY
        if(parent_key && parent_key!= ''){
            query = `${query}&parent_key=${parent_key}`
        }    
        else{
            parent_key = getCartCookie();
            query = `${query}&parent_key=${parent_key}`
        }
        var url = query;

        axiosInstance.get(url).then(res=>{
            var result = res.data;
            var data =result.CART 
            props.setCartList(data)
        }).catch(error => {
        })  
    }

    const fetchSponsorshipsList =async ()=>{
        var url = `${ESHOW_URL()}?method=getSponsorshipList&token=${getTokenCookie()}`
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getSponsorshipList&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            var result = res.data;
            if(result.SUCCESS){  
                if(result.SPONSORSHIPS){     
                    var data =result.SPONSORSHIPS 
                    props.setSponsorshipsOptions(data)
                }
            }
            else{
            }
        }).catch(error => {
        })  
    }


    const fetchExhibitorList =async ()=>{
        var url = `${ESHOW_URL()}?method=getExhibitorList&token=${getTokenCookie()}`;
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getExhibitorList&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            var result = res.data;            
            props.setExhibitorList(result)
        }).catch(error => {
        })  
    }

    const fetchAuditLog =async ()=>{
        var url = `${ESHOW_URL()}?method=getAuditLog&token=${getTokenCookie()}`
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getAuditLog&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            var items = res.data;   
            var logs = [];
            for(var i = 0; i < items?.length; i++)         {
                var item = {...items[i]};
                var logContent = JSON.parse(item['LOG_TEXT']);
                logContent = {...logContent, KEY_ID: item.KEY_ID, BOOTH_KEY: item.BOOTH_KEY, old: 1, user:item['USER_NAME'] }
                logs.push(logContent)
            }
            props.setLog(logs)
            // props.setGroupOptions(result)
        }).catch(error => {
        })  
    }

    

    

    const fetchPricingOptions =async ()=>{
        var url = `${ESHOW_URL()}?method=getSales&product_type=sales&token=${getTokenCookie()}` 
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getSales&product_type=sales&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            const result = res.data;
            if(result.SUCCESS){  
                if(result.SALES){     
                    var data =result.SALES 
                    data.sort(function (a, b) {
                        return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
                    });
                    props.setPricingOptions(data)
                }
            }
            else{
            }
        }).catch(error => {
        })  
    }
    const fetchPremiumOptions =async ()=>{
        var url = `${ESHOW_URL()}?method=getSales&product_type=Premiums&token=${getTokenCookie()}`
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getSales&product_type=Premiums&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            const result = res.data;
            if(result.SUCCESS){  
                if(result.PREMIUMS){     
                    data = result.PREMIUMS
                    data.sort(function (a, b) {
                        return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
                    });
                    props.setPremiumOptions(data)
                }
            }
            else{
            }
        }).catch(error => {
        })  
    }
    
    const fetchDiscountOptions =async ()=>{
        var url = `${ESHOW_URL()}?method=getSales&product_type=discounts&token=${getTokenCookie()}`
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getSales&product_type=discounts&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            const result = res.data;
            if(result.SUCCESS){  
                if(result.DISCOUNTS){     
                    data = result.DISCOUNTS
                    data.sort(function (a, b) {
                        return (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1 - (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1;
                    });
                    props.setDiscountOptions(data)
                }
            }
            else{
            }
        }).catch(error => {
        })  
    }

    const fetchMechnantAccount =async ()=>{
        {
          axiosInstance.get(`/getmechantaccount?boothId=''&show_key=${authInfo.show_key}`).then(res=>{
            // console.log("getmechantaccount", res.data)     
            if(res.data){
              props.setMechantAccount(res.data)
            }     
          }).catch(error => {
              //console.log("error", error);
          })
        }
    }
    

    const handleResize=()=>{
        // if(g_mainCanvas){
        //     var winW = window.innerWidth,
        //     winH = window.innerHeight,
        //     curW = g_mainCanvas.getWidth(),
        //     curH = g_mainCanvas.getHeight(),
        //     canW = winW - 75,
        //     canH = winH - 60;
    
        //     g_mainCanvas.setWidth(canW);
        //     g_mainCanvas.setHeight(canH);
        //     g_mainCanvas.renderAll();
        //     centerFocus(g_mainCanvas, bgCanvas, g_grid, topRuler, leftRuler);
        // }
    }
    useEffect(()=>{
        g_mouseoverObject = mouseoverObject
    },[mouseoverObject])

    useEffect(() => {
        g_lockFlag = props.ui.lockFlag
    }, [props.ui.lockFlag])

    useEffect(() => {
        setMaincanvasWidth(window.innerWidth-50)
        setMaincanvasHeight(window.innerHeight-50-51)
        document.getElementsByTagName("body")[0].style.overflowY='hidden'
    }, [])
    
    const processAuth = async ()=>{        
        var tokenInfo = await parseToken(query)
        var show_key = tokenInfo.show_key?tokenInfo.show_key:'';
        var user_key = tokenInfo.user_key?tokenInfo.user_key:'';
        var user_name = tokenInfo.user_key?tokenInfo.user_name:'';
        var show_three_icon = tokenInfo.user_key?tokenInfo['3D_ICON']:'';
        var SHOPPING_CART = tokenInfo.SHOPPING_CART?tokenInfo.SHOPPING_CART:'';
        var SALES = tokenInfo.SALES
        var BOOTH_PRICING = tokenInfo.BOOTH_PRICING?tokenInfo.BOOTH_PRICING:'';
        props.setAuthInfo({show_key, user_key, user_name, SALES ,BOOTH_PRICING,SHOPPING_CART, show_three_icon, authenticated: (tokenInfo.show_key && tokenInfo.user_key?3:2), tokenInfo, role:'admin'})
        if(!(tokenInfo.show_key && tokenInfo.user_key)){ 
            props.setLoading(false)  
            return
        }
    }

    useEffect(async () => {  
        processAuth()
    }, [])
    useEffect(async () => {     
        // if(props.main.mainCanvas){
        //     props.main.mainCanvas.dispose()
        //     setMainCanvas(null)
        // }
        if(authInfo.authenticated == 3  && maincanvas_width)  { 
            window.addEventListener('resize', handleResize)
            window.addEventListener("contextmenu", e => e.preventDefault());
            topRuler = new fabric.StaticCanvas('top-ruler');
            leftRuler = new fabric.StaticCanvas('left-ruler');
            props.setTopRuler(topRuler)
            props.setLeftRuler(topRuler)
            if(mainCanvasRef.current){
                if(0) {
                    fabric.StaticCanvas.prototype._toObjectMethod = (function(toObjectMethod) {
                        return function(propertiesToInclude) {
                        return fabric.util.object.extend(toObjectMethod.call(this, "toDatalessObject", propertiesToInclude), {
                            convertRateX: this.convertRateX,
                            convertRateY: this.convertRateY,
                            convertOffsetX: this.convertOffsetX,
                            convertOffsetY: this.convertOffsetY, 
                            startX:this.startX, 
                            startY:this.startY, 
                            endX:this.endX, 
                            endY:this.endY,
                            mapWidth: this.mapWidth,
                            mapHeight: this.mapHeight,
                            mapUnit: this.mapUnit,
                            convertUnit: this.convertUnit, 
                            mapLock: this.mapLock,
                            gridSize: this.gridSize, 
                            strokeWidth: this.strokeWidth, 
                            showGrid: this.showGrid, 
                            snapToGrid: this.snapToGrid,
                            gridAngle: this.gridAngle
                        });
                        };
                    })(fabric.StaticCanvas.prototype._toObjectMethod);
                }

                fabric.Object.prototype.toDatalessObject = (function (toDatalessObject) {
                    return function () {
                        return fabric.util.object.extend(toDatalessObject.call(this), buildObjectProperty(this));
                    };
                })(fabric.Object.prototype.toDatalessObject);


                fabric.Object.NUM_FRACTION_DIGITS  = 15;

                fabric.Polygon.prototype.toDatalessObject = (function (toDatalessObject) {
                    return function () {
                    
                        return fabric.util.object.extend(toDatalessObject.call(this), {
                            id: this.id,
                            pathOffset: this.pathOffset,
                            scaleX: this.scaleX,
                            scaleY: this.scaleY,
                            left: this.left,
                            top: this.top,
                        });
                    };
                })(fabric.Polygon.prototype.toDatalessObject);

                fabric.Object.prototype.objectCaching = false //true
                fabric.Object.prototype.noScaleCache = false
                
                // fabric.perfLimitSizeTotal = 225000000;
                // fabric.maxCacheSideLimit = 11000;
                const originalRender = fabric.Group.prototype.render;

                fabric.Group.prototype.render = function(ctx, noTransform) {
                    if (this.isOnScreen()) {
                        return originalRender.call(this, ctx, noTransform);
                    }
                    return originalRender.call(this, ctx, noTransform);
                    return;
                };
                
                let canvas = new fabric.Canvas("canvas");
                g_mainCanvas = canvas
                // fabric.util.enlivenObjects([{}, {}, {}], (objs) => {
                //     objs.forEach((item) => {
                //         canvas.add(item);
                //     });
                //     canvas.renderAll(); // Make sure to call once we're ready!
                // });
                
                canvas.set({preserveObjectStacking: true, altActionKey: 'ctrlKey', altSelectionKey: 'ctrlKey'})
                canvas.set({
                    convertRateX:1, 
                    convertRateY:1, 
                    convertOffsetX:0, 
                    convertOffsetY:0, 
                    startX:0, 
                    startY:0, 
                    endX:(window.innerWidth - main_widow_offset_x - 50-100)/12, 
                    endY:(window.innerHeight - main_widow_offset_y - 50-100)/12, 
                    mapWidth:(window.innerWidth - main_widow_offset_x - 50-100)/12, 
                    mapHeight:(window.innerHeight - main_widow_offset_y - 50-100)/12,
                    convertUnit:'feet', 
                    mapUnit:'feet',
                    gridSize:10, 
                    showGrid:true,
                    snapToGrid:true,
                    gridAngle: 0,
                })
                canvas.defaultCursor = 'default';
                canvas.hoverCursor= 'pointer';
                // var width = (window.innerWidth > 0)? window.innerWidth : 1000;
                // var height = (window.innerHeight > 0)? window.innerHeight : 1000;
                // width = 1900;
                // height = 1500;
                // canvas.setHeight(height);
                // canvas.setWidth(width);

               
                var bgImgCanvasEle = cloneCanvas(canvas);
                bgImgCanvasEle.setAttribute('id', 'bg-img-canvas');
                bgImgCanvas = new fabric.StaticCanvas("bg-img-canvas");
                props.setBgImgCanvas(bgImgCanvas)   
                
                
                var bgCanvasEle = cloneCanvas(canvas);
                bgCanvasEle.setAttribute('id', 'bg-canvas');
                bgCanvas = new fabric.StaticCanvas("bg-canvas");
                props.setBgCanvas(bgCanvas)
                
                var content = props.booth_history.content
                if(content){
                    props.setLoading(true)  
                    mainCanvasDiv.current.className = 'hidden';
                    canvas.loadFromJSON(content, ()=>{
                        var groups = canvas.getObjects('group');
                        for(var i = 0; i< groups.length; i++){
                            groups[i].set({
                                borderColor: '#ff7777',
                                cornerColor: 'red',
                                cornerSize: 6,
                                transparentCorners: false
                            });
                            groups[i].set({
                                borderColor: '#ff7777',
                                cornerColor: 'red',
                                cornerSize: 6,
                                transparentCorners: false,
                                lockScalingX: true,
                                lockScalingY: true,
                            });
                            var layer = groups[i].get('layer'); 
                            if(layer == "original"){
                                groups[i].set({
                                    visible:false,
                                    lockMovementX: true,
                                    lockMovementY: true,
                                    lockRotation: true,
                                    lockScalingX: true,
                                    lockScalingY: true,
                                    selection:false,
                                    hasControls:false,
                                    hasBorders:false,
                                    evented:false,
                                    selectable: false ,
                                })     
                            }
                        }
                        setCanvas(canvas);
                        centerFocus(canvas, bgCanvas, g_grid, topRuler, leftRuler, bgImgCanvas)
                        redrawGrid(g_grid, canvas, (canvas.showGrid &&canvas.gridSize),canvas.gridSize, canvas.gridAngle, canvas.gridOffsetX, canvas.gridOffsetY);   
                        redrawRulers(canvas, topRuler, leftRuler);
                        canvas.setBackgroundColor('transparent', canvas.renderAll.bind(canvas))
                        // bgCanvas.setBackgroundColor('transparent', bgCanvas.renderAll.bind(bgCanvas))
                        props.setMainCanvas(canvas);
                        props.setLoading(false)
                        // mainCanvasDiv.current.className = 'visible';
                        var bgContent = props.main.firstBgContent
                        toggleDrawMode(canvas, "general")
                        if(bgContent){
                            bgImgCanvas.loadFromJSON(bgContent, ()=>{                                
                                centerFocus(canvas, bgCanvas, g_grid, topRuler, leftRuler, bgImgCanvas)
                            })
                        }
                        else{
                        }

                    })
                    props.setCanvasRefresh(true)
                    
                    
                    canvas.requestRenderAll();
                    // removeGrid(canvas)
                }
                else{
                    props.setMainCanvas(canvas);
                }
                
                fabric.util.removeListener(document.getElementsByClassName('upper-canvas main-canvas')[0], 'contextmenu' , onContextMenu);
                fabric.util.addListener(document.getElementsByClassName('upper-canvas main-canvas')[0], 'contextmenu', (e)=>onContextMenu(e, canvas, props.main));


                canvas.off('object:moving')
                redrawRulers(canvas, topRuler, leftRuler);
                canvas.selectionBorderColor = '#e01010';
                canvas.selectionLineWidth = 2

                gridCanvas = cloneCanvas(canvas);
                gridCanvas.setAttribute('id', 'indoors-grid-canvas');
                var opt = {}
                var center = new Point();
                opt = {
                    center
                }
                g_grid = new Grid(gridCanvas, opt);
                props.setgGrid(g_grid)


            }
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [authInfo.authenticated, maincanvas_width])

    const cloneCanvas = (canvas)=> {
        const clone = document.createElement('canvas');
        clone.width = canvas.width;
        clone.height = canvas.height;
        canvas.wrapperEl.appendChild(clone);
        return clone;
    }

    useEffect(() => {
        if(view_file && view_file.dwgPath){
            var formdata = 
            {
                "action":"svg_creation",
                "converter":"AutoXchange AX2020",
                "version":"V1.00",
                "contentType":"file",
                "contentLocation":`${view_file?.dwgPath}`,
                "contentFormat":"DWG",
                "contentUsername":"",
                "contentPassword":"",
                "userLabel":"fromCADViewerJS",
                "contentResponse":"stream",
                "leaveStreamOnServer":0,
                "parameters":[
                    {"paramName":"f",
                    "paramValue":"svg"},
                    // {"paramName":"RL",
                    // "paramValue":"RM_"},
                    // {"paramName":"TL","paramValue":"RM_TXT"},
                    // {"paramName":"LA","paramValue":""},
                    // {"paramName":"last","paramValue":""},
                    {"paramName":"model","paramValue":""},
                    // {"paramName":"extents","paramValue":""},
                    {"paramName":"fpath","paramValue":"/converters/ax2022/linux/fonts/"},
                    {"paramName":"WIDTH","paramValue":"10000"},
                    {"paramName":"HEIGHT","paramValue":"10000"},
                    {"paramName":"ShowAllLayers","paramValue":""},                     
                ]
            }
            var data = qs.stringify({
                request: JSON.stringify(formdata)
              });
            // var bodyFormData = new FormData();
            // bodyFormData.append('request', JSON.stringify(formdata));
            const options = {
                headers: {"content-type": 'application/x-www-form-urlencoded'}
            }
            axiosInstance.post('/callapiconversion', data, options).then(res=>{
                const result = res.data;
                // initBoothFileList();
                getConvertedSvg(result);
            }).catch(error => {
                props.setLoading(false) ;
                props.setOpenSnack(true);
                props.setSnackMsg("Failed to convert the dwg.");
                return;
                ////console.log("error", error);
            })
        }
        else{
        }          
    }, [view_file])

    const loadBooth=async (canvasParam = null)=>{
        var curActiveFile = props.main.active_booth_file
        var activeFile;
        for(var i = 0; i <boothFileList.length; i++){
            if(boothFileList[i]['active']){
                activeFile = boothFileList[i];
                break;
            }
        }
        if(!activeFile && boothFileList.length){
            activeFile = boothFileList[0]
        }
        if(g_fileSaved && 0){
            props.setLoading(false)
            g_fileSaved = false; 
            try{
                var mapJS = activeFile.MAPJS;
                setCookie("last_map", activeFile.KEY_ID, 7);
                if(mapJS != ""){
                    mapJS = mapJS.replaceAll('\r\n', ' ')
                    mapJS = JSON.parse(mapJS)
                }
                else{
                    mapJS = {convertRateX: 1}
                }
                if(!mapJS) mapJS = {}
                var settingData = await fetchSetup(activeFile.KEY_ID);
                // console.log("setupData", settingData)
                var convertRateX= Math.abs(mapJS.convertRateX)
                var mapWidthPx = unit2Px(settingData?.CANVAS?.SIZE_X?settingData?.CANVAS?.SIZE_X:window.innerWidth, convertRateX, mapJS.mapUnit)
                var mapHeightPx = unit2Px(settingData?.CANVAS?.SIZE_Y?settingData?.CANVAS?.SIZE_Y:window.innerHeight, convertRateX, mapJS.mapUnit)
                var tmp = {
                    mapWidth:settingData?.CANVAS?.SIZE_X?settingData?.CANVAS?.SIZE_X:window.innerWidth, 
                    mapHeight:settingData?.CANVAS?.SIZE_Y?settingData?.CANVAS?.SIZE_Y:window.innerHeight,
                    mapUnit:settingData?.GENERAL?.UNIT?settingData?.GENERAL?.UNIT:'feet', 
                    convertUnit:settingData?.GENERAL?.CONVERT_UNIT?settingData?.GENERAL?.CONVERT_UNIT:'feet', 
                    sales_form:settingData?.GENERAL?.FORM_KEY?settingData?.GENERAL?.FORM_KEY:'', 
                    gridSize:settingData?.CANVAS?.GRID_SIZE?settingData?.CANVAS?.GRID_SIZE:10, 
                    strokeWidth:settingData?.CANVAS?.STROKE_WIDTH?settingData?.CANVAS?.STROKE_WIDTH:false, 
                    showGrid:settingData?.CANVAS?.GRID_ON, 
                    snapToGrid: settingData?.CANVAS?.GRID_SNAP, 
                    mapOffsetX: settingData?.MAP_OFFSET?.OFFSET_X, 
                    mapOffsetY: settingData?.MAP_OFFSET?.OFFSET_Y, 
                    gridAngle: settingData?.CANVAS?.GRID_ANGLE,
                    boothfile_brand: settingData?.GENERAL?.BRAND_LOGO,
                    event_home: settingData?.GENERAL?.HOME_LINK,
                    header_banner: settingData?.GENERAL?.HEADER_BANNER,
                    header_color: settingData?.GENERAL?.HEADER_COLOR,
                    form_key: settingData?.FORMS?.[0]?.KEY_ID,
                    form_title: settingData?.FORMS?.[0]?.TITLE,
                    convertOffsetX: settingData?.DWG?.COVERT_OFFSET_X,
                    convertOffsetY: settingData?.DWG?.COVERT_OFFSET_Y,
                    svgToWorldUnits: settingData?.DWG?.SVG_TO_WORLD_UNITES,
                    version: settingData?.DWG?.VERSION,
                    dwgPath: settingData?.DWG?.DWG_PATH,
                    dwgName: settingData?.DWG?.DWG_NAME,
                    LIST_PENDING_EXHIBITORS: settingData?.SETUP?.LIST_PENDING_EXHIBITORS,
                    LIST_TBD_EXHIBITORS: settingData?.SETUP?.LIST_TBD_EXHIBITORS ,
                    endX:mapWidthPx,
                    endY:mapHeightPx,
                    startX:0,
                    startY:0,
                }
                mapJS = {...mapJS, ...tmp}
                props.setActiveBoothFile({...activeFile, ...mapJS})      
                props.setMouseHoverEnable(settingData?.CANVAS?.HOVER_ENABLE)          
                  
                props.setLoading(false)
            }
            catch (e) {
                ////console.log("error", error);
                props.setLoading(false)
            }
            return;
        }
        if(!activeFile || !activeFile?.KEY_ID){
            props.setLoading(false)
            return;
        }
        if(activeFile &&activeFile.KEY_ID  && activeFile.KEY_ID != curActiveFile.KEY_ID){ 
            var mapJS = activeFile.MAPJS;     
            var content = {}      
            if(mapJS != ""){
                mapJS = mapJS.replaceAll('\r\n', ' ')
                mapJS = JSON.parse(mapJS)
            }
            if(!mapJS || mapJS == "") mapJS = {}
            var settingData = await fetchSetup(activeFile.KEY_ID);
            // console.log("settingData ", Math.floor(Date.now() / 100))
            // console.log("settingData  total", Math.floor(Date.now() / 100) - g_startTime)
            // console.log("setupData", settingData)
            var convertRateX= Math.abs(mapJS.convertRateX)
            var mapWidthPx = unit2Px(settingData?.CANVAS?.SIZE_X?settingData?.CANVAS?.SIZE_X:window.innerWidth, convertRateX, settingData?.GENERAL?.UNIT?settingData?.GENERAL?.UNIT:'feet')
            var mapHeightPx = unit2Px(settingData?.CANVAS?.SIZE_Y?settingData?.CANVAS?.SIZE_Y:window.innerHeight, convertRateX, settingData?.GENERAL?.UNIT?settingData?.GENERAL?.UNIT:'feet')
            var tmp = {
                mapWidth:settingData?.CANVAS?.SIZE_X?settingData?.CANVAS?.SIZE_X:window.innerWidth, 
                mapHeight:settingData?.CANVAS?.SIZE_Y?settingData?.CANVAS?.SIZE_Y:window.innerHeight,
                mapUnit:settingData?.GENERAL?.UNIT?settingData?.GENERAL?.UNIT:'feet', 
                convertUnit:settingData?.GENERAL?.CONVERT_UNIT?settingData?.GENERAL?.CONVERT_UNIT:'feet', 
                sales_form:settingData?.GENERAL?.FORM_KEY?settingData?.GENERAL?.FORM_KEY:'', 
                gridSize:settingData?.CANVAS?.GRID_SIZE?settingData?.CANVAS?.GRID_SIZE:10, 
                strokeWidth:settingData?.CANVAS?.STROKE_WIDTH?settingData?.CANVAS?.STROKE_WIDTH:false, 
                showGrid:settingData?.CANVAS?.GRID_ON, 
                snapToGrid: settingData?.CANVAS?.GRID_SNAP, 
                mapOffsetX: settingData?.MAP_OFFSET?.OFFSET_X, 
                mapOffsetY: settingData?.MAP_OFFSET?.OFFSET_Y, 
                gridAngle: settingData?.CANVAS?.GRID_ANGLE,
                boothfile_brand: settingData?.GENERAL?.BRAND_LOGO,
                event_home: settingData?.GENERAL?.HOME_LINK,
                header_banner: settingData?.GENERAL?.HEADER_BANNER,
                header_color: settingData?.GENERAL?.HEADER_COLOR,
                form_key: settingData?.FORMS?.[0]?.KEY_ID,
                form_title: settingData?.FORMS?.[0]?.TITLE,
                convertOffsetX: settingData?.DWG?.COVERT_OFFSET_X,
                convertOffsetY: settingData?.DWG?.COVERT_OFFSET_Y,
                svgToWorldUnits: settingData?.DWG?.SVG_TO_WORLD_UNITES,
                version: settingData?.DWG?.VERSION,
                dwgPath: settingData?.DWG?.DWG_PATH,
                dwgName: settingData?.DWG?.DWG_NAME,
                endX:mapWidthPx,
                endY:mapHeightPx,
                startX:0,
                startY:0,
                LIST_PENDING_EXHIBITORS: settingData?.SETUP?.LIST_PENDING_EXHIBITORS,
                LIST_TBD_EXHIBITORS: settingData?.SETUP?.LIST_TBD_EXHIBITORS ,
            }
            mapJS = {...mapJS, ...tmp}
            if(mapJS.mainContent && mapJS.mainContent != ""){
                content = JSON.parse(mapJS.mainContent)
            }
            
            document.getElementsByClassName("canvas-container")[0].style.display = 'block';
            mainCanvas.clear();  
            bgCanvas.clear();  g_grid.clear(); bgImgCanvas.clear()   
            mainCanvas.viewportTransform[0] = 1;
            mainCanvas.viewportTransform[3] = 1;
            mainCanvas.viewportTransform[4] = 0;
            mainCanvas.viewportTransform[5] = 0;

            bgCanvas.viewportTransform[0] = 1;
            bgCanvas.viewportTransform[3] = 1;
            bgCanvas.viewportTransform[4] = 0;
            bgCanvas.viewportTransform[5] = 0;

            bgImgCanvas.viewportTransform[0] = 1;
            bgImgCanvas.viewportTransform[3] = 1;
            bgImgCanvas.viewportTransform[4] = 0;
            bgImgCanvas.viewportTransform[5] = 0;

            

            topRuler.viewportTransform[0] = 1;
            topRuler.viewportTransform[3] = 1;
            topRuler.viewportTransform[4] = 0;
            topRuler.viewportTransform[5] = 0;

            leftRuler.viewportTransform[0] = 1;
            leftRuler.viewportTransform[3] = 1;
            leftRuler.viewportTransform[4] = 0;
            leftRuler.viewportTransform[5] = 0;
            // console.log("init done", Math.floor(Date.now() / 100))
            // console.log("init done total", Math.floor(Date.now() / 100) - g_startTime)

            
            mapJS.role='admin'
            initCanvas(mainCanvas, mapJS, bgCanvas, bgImgCanvas)
            centerFocus(mainCanvas, bgCanvas, g_grid, topRuler, leftRuler, bgImgCanvas, false)
            
            props.setLoading(true)
            // console.log("getMapData Start", Math.floor(Date.now() / 100))
            var g_startTime = Math.floor(Date.now() / 100)
            // var url = `https://dev.goeshow.com/webservices/eshow/floor_space.cfc?method=getMapData&map_key=${activeFile.KEY_ID}&token=${getTokenCookie()}`  
            var url = `${ESHOW_URL()}?method=getMapData&map_key=${activeFile.KEY_ID}&token=${getTokenCookie()}`                      
            // axiosInstance.get(`/getMapData?id=${activeFile.KEY_ID}&show_key=${authInfo.show_key}&user_key=${authInfo.user_key}&token=${getTokenCookie()}`).then(async res=>{
            axiosInstance.get(url).then(async res=>{
                // console.log("getMapData Done", Math.floor(Date.now() / 100))
                // console.log("getMapData total", Math.floor(Date.now() / 100) - g_startTime)
                try{
                    setCookie("last_map", activeFile.KEY_ID, 7);
                    
                    // mainCanvas.renderAll()
                    var mapRemoveObjects = [];
                    // console.log("composeLoadObjects start", Math.floor(Date.now() / 100))
                    // console.log("composeLoadObjects  start total", Math.floor(Date.now() / 100) - g_startTime)
                    var objects = composeLoadObjects(res.data, mapRemoveObjects, mapJS, bgImgCanvas, props.setFirstBgContent)
                    // console.log("composeLoadObjects done", Math.floor(Date.now() / 100))
                    // console.log("composeLoadObjects  done total", Math.floor(Date.now() / 100) - g_startTime) 
                                        
                    content.objects = objects            

                } catch (e) {
                    props.setActiveBoothFile({...activeFile})
                     
                    mainCanvas.clear();  
                    bgCanvas.clear();  g_grid.clear() ; bgImgCanvas.clear()   
                    mainCanvas.viewportTransform[0] = 1;
                    mainCanvas.viewportTransform[3] = 1;
                    mainCanvas.viewportTransform[4] = 0;
                    mainCanvas.viewportTransform[5] = 0;

                    bgCanvas.viewportTransform[0] = 1;
                    bgCanvas.viewportTransform[3] = 1;
                    bgCanvas.viewportTransform[4] = 0;
                    bgCanvas.viewportTransform[5] = 0;

                    bgImgCanvas.viewportTransform[0] = 1;
                    bgImgCanvas.viewportTransform[3] = 1;
                    bgImgCanvas.viewportTransform[4] = 0;
                    bgImgCanvas.viewportTransform[5] = 0;

                    topRuler.viewportTransform[0] = 1;
                    topRuler.viewportTransform[3] = 1;
                    topRuler.viewportTransform[4] = 0;
                    topRuler.viewportTransform[5] = 0;

                    leftRuler.viewportTransform[0] = 1;
                    leftRuler.viewportTransform[3] = 1;
                    leftRuler.viewportTransform[4] = 0;
                    leftRuler.viewportTransform[5] = 0;
                    console.log("Map JSON Error", e)
                    props.setLoading(false)
                    return;
				}

                // console.log("getMapData2 Done", Math.floor(Date.now() / 100))
                // console.log("getMapData2 total", Math.floor(Date.now() / 100) - g_startTime)
                if(content){
                    // content = JSON.parse(content)
                    if(content && content.backgroundImage && content.backgroundImage.src){
                        content.backgroundImage.src = assetUrl(content.backgroundImage.src)
                    }
                    content.backgroundImage = null
                    var canvas = mainCanvas||canvasParam;
                   

                    if(canvas){
                        fabric.Object.prototype.objectCaching = false
                        canvas.renderOnAddRemove = false
                        var objects = content.objects;
                        // console.log("loadFromJSON Start", Math.floor(Date.now() / 100))
                        var startTime = Math.floor(Date.now() / 100)
                        canvas.loadFromJSON(content, ()=>{
                        // fabric.util.enlivenObjects(objects, (objs) => {
                            // objs.forEach((item) => {
                            //     canvas.add(item);
                            // });
                            var objects = canvas.getObjects('group');
                            // objects.forEach(function(object) {
                            //     canvas.moveTo(object, object.zIndex)
                            //     object.moveTo(object.zIndex)
                            // });
                            canvas.renderAll()
                            // console.log("loadFromJSON Done", Math.floor(Date.now() / 100))
                            // console.log("load total", Math.floor(Date.now() / 100) - startTime)
                            mapJS.role='admin'
                            initCanvas(canvas, mapJS, bgCanvas, bgImgCanvas)
                            centerFocus(mainCanvas, bgCanvas, g_grid, topRuler, leftRuler, bgImgCanvas, false)
                            // canvas.renderAll();
                            // console.log("initCanvas Done", Math.floor(Date.now() / 100))
                            // console.log("initCanvas total", Math.floor(Date.now() / 100) - startTime)
                            afterCanvasLoad(canvas, g_groupOptions, false)
                            centerFocus(canvas, bgCanvas, g_grid, topRuler, leftRuler, bgImgCanvas, false)
                            var duplicates = getDuplicatedBooths(canvas)
                            if(duplicates?.length){
                                props.setOpenSnack(true);
                                props.setSnackMsg("There are duplicated booths");
                            }
                            
                            canvas.renderAll()
                            // console.log("loadFromJSON End", Math.floor(Date.now() / 100))
                            // console.log("load End total", Math.floor(Date.now() / 100) - startTime)

                            bgCanvas.renderAll()
                            setTimeout(()=>{
                                var objects = canvas.getObjects('group');
                                // for(var i = 0; i < objects.length; i++){
                                //     var object = objects[i]
                                //     if(object.class == "booth"){
                                //         var polygons = object.getObjects('polygon');
                                //         if(polygons?.length){
                                //             object.clipPath = polygons[0]
                                //         }
                                //     }
                                // }
                                // canvas.renderAll()
                                fabric.Object.prototype.objectCaching = false //true
                                canvas.renderOnAddRemove = true
                                props.onClearHistory();
                                props.setBoothHistory({content: canvas.toDatalessObject(), action:{type:'load'}})
                                props.setFirstMapContent(canvas.toDatalessObject())
                                redrawRulers(canvas, topRuler, leftRuler)
                                // setTimeout(function(){
                                    redrawGrid(g_grid, canvas, (canvas.showGrid &&canvas.gridSize),canvas.gridSize, canvas.gridAngle, canvas.gridOffsetX, canvas.gridOffsetY);
                                    renderBorder(canvas);
                                // }, 300)
                                
                                canvas.setBackgroundColor('transparent', mainCanvas.renderAll.bind(mainCanvas))
                                bgCanvas.setBackgroundColor('transparent', bgCanvas.renderAll.bind(bgCanvas))
                                props.setCanvasRefresh(true)  
                                props.setLoading(false)
                                props.setMapRemoveObjects(mapRemoveObjects?mapRemoveObjects:[])
                                toggleDrawMode(canvas, "general")
                                // if(canvas.mapLock)
                                {
                                    lockMap(canvas)
                                    props.setLockFlag(true)
                                }
                                
                                props.setActiveBoothFile({...activeFile, ...mapJS})
                                props.setMouseHoverEnable(settingData?.CANVAS?.HOVER_ENABLE)   
                                 
                            }, 100)

                            
                            // else{
                            //     unlockMap(canvas)
                            //     props.setLockFlag(false)
                            // }
                        });                            
                    }
                }
            }).catch(error => {
                ////console.log("error", error);
                props.setLoading(false)
            })
        }
        else{          
            try{
                var mapJS = activeFile.MAPJS;
                setCookie("last_map", activeFile.KEY_ID, 7);
                mapJS = mapJS.replaceAll('\r\n', ' ')
                mapJS = JSON.parse(mapJS)
                if(!mapJS) mapJS = {}
                var settingData = await fetchSetup(activeFile.KEY_ID);
                // console.log("setupData", settingData)
                var convertRateX= Math.abs(mapJS.convertRateX)
                var mapWidthPx = unit2Px(settingData?.CANVAS?.SIZE_X?settingData?.CANVAS?.SIZE_X:window.innerWidth, convertRateX, mapJS.mapUnit)
                var mapHeightPx = unit2Px(settingData?.CANVAS?.SIZE_Y?settingData?.CANVAS?.SIZE_Y:window.innerHeight, convertRateX, mapJS.mapUnit)
                var tmp = {
                    mapWidth:settingData?.CANVAS?.SIZE_X?settingData?.CANVAS?.SIZE_X:window.innerWidth, 
                    mapHeight:settingData?.CANVAS?.SIZE_Y?settingData?.CANVAS?.SIZE_Y:window.innerHeight,
                    mapUnit:settingData?.GENERAL?.UNIT?settingData?.GENERAL?.UNIT:'feet', 
                    convertUnit:settingData?.GENERAL?.CONVERT_UNIT?settingData?.GENERAL?.CONVERT_UNIT:'feet', 
                    sales_form:settingData?.GENERAL?.FORM_KEY?settingData?.GENERAL?.FORM_KEY:'', 
                    gridSize:settingData?.CANVAS?.GRID_SIZE?settingData?.CANVAS?.GRID_SIZE:10, 
                    strokeWidth:settingData?.CANVAS?.STROKE_WIDTH?settingData?.CANVAS?.STROKE_WIDTH:false, 
                    showGrid:settingData?.CANVAS?.GRID_ON, 
                    snapToGrid: settingData?.CANVAS?.GRID_SNAP, 
                    mapOffsetX: settingData?.MAP_OFFSET?.OFFSET_X, 
                    mapOffsetY: settingData?.MAP_OFFSET?.OFFSET_Y, 
                    gridAngle: settingData?.CANVAS?.GRID_ANGLE,
                    boothfile_brand: settingData?.GENERAL?.BRAND_LOGO,
                    event_home: settingData?.GENERAL?.HOME_LINK,
                    header_banner: settingData?.GENERAL?.HEADER_BANNER,
                    header_color: settingData?.GENERAL?.HEADER_COLOR,
                    form_key: settingData?.FORMS?.[0]?.KEY_ID,
                    form_title: settingData?.FORMS?.[0]?.TITLE,
                    convertOffsetX: settingData?.DWG?.COVERT_OFFSET_X,
                    convertOffsetY: settingData?.DWG?.COVERT_OFFSET_Y,
                    svgToWorldUnits: settingData?.DWG?.SVG_TO_WORLD_UNITES,
                    version: settingData?.DWG?.VERSION,
                    dwgPath: settingData?.DWG?.DWG_PATH,
                    dwgName: settingData?.DWG?.DWG_NAME,
                    endX:mapWidthPx,
                    endY:mapHeightPx,
                    startX:0,
                    startY:0,
                    LIST_PENDING_EXHIBITORS: settingData?.SETUP?.LIST_PENDING_EXHIBITORS,
                    LIST_TBD_EXHIBITORS: settingData?.SETUP?.LIST_TBD_EXHIBITORS ,
                }
                mapJS = {...mapJS, ...tmp}
                props.setActiveBoothFile({...activeFile, ...mapJS})  
                props.setMouseHoverEnable(settingData?.CANVAS?.HOVER_ENABLE)     
                 
                props.setLoading(false)
            }
            catch (e) {
                ////console.log("error", error);
                props.setLoading(false)
            }
        }
    }

    const loadOverview=async (canvasParam = null)=>{
        console.log("loadOverview")
        var curActiveFile = props.main.active_booth_file
        var activeFile;
        for(var i = 0; i <boothFileList.length; i++){
            if(boothFileList[i]['active']){
                activeFile = boothFileList[i];
                break;
            }
        }
        if(!activeFile && boothFileList.length){
            activeFile = boothFileList[0]
        }
        if(!activeFile || !activeFile?.KEY_ID){
            props.setLoading(false)
            return;
        }
        if(activeFile &&activeFile.KEY_ID  && activeFile.KEY_ID != curActiveFile.KEY_ID){ 
            var mapJS = activeFile.MAPJS;     
            var content = {}      
            if(mapJS != ""){
                mapJS = mapJS.replaceAll('\r\n', ' ')
                mapJS = JSON.parse(mapJS)
            }
            if(!mapJS || mapJS == "") mapJS = {}
            let tmp = {}
            mapJS = {...mapJS, ...tmp}
            props.setActiveBoothFile({...activeFile, ...mapJS})   
            
            // props.setLoading(true)
            
        }
    }

    const getMeta = (url, cb) => {
        const img = new Image();
        img.onload = () => cb(null, img);
        img.onerror = (err) => cb(err);
        img.src = url;
    };

    const loadFirstEshow=async (canvasParam = null)=>{
        if(g_fileSaved){
            props.setLoading(false)
            g_fileSaved = false; return;
        }
        var curActiveFile = props.main.active_booth_file
        var activeFile;
        for(var i = 0; i <boothFileList.length; i++){
            if(boothFileList[i]['active']){
                activeFile = boothFileList[i];
                break;
            }
        }
        if(!activeFile && boothFileList.length){
            activeFile = boothFileList[0]
        }
        if(activeFile && activeFile.KEY_ID != curActiveFile.KEY_ID){
            
            props.setLoading(true)

            mainCanvas.clear();  
            bgCanvas.clear();  g_grid.clear(); bgImgCanvas.clear()    
            mainCanvas.viewportTransform[0] = 1;
            mainCanvas.viewportTransform[3] = 1;
            mainCanvas.viewportTransform[4] = 0;
            mainCanvas.viewportTransform[5] = 0;

            bgCanvas.viewportTransform[0] = 1;
            bgCanvas.viewportTransform[3] = 1;
            bgCanvas.viewportTransform[4] = 0;
            bgCanvas.viewportTransform[5] = 0;

            bgImgCanvas.viewportTransform[0] = 1;
            bgImgCanvas.viewportTransform[3] = 1;
            bgImgCanvas.viewportTransform[4] = 0;
            bgImgCanvas.viewportTransform[5] = 0;

            topRuler.viewportTransform[0] = 1;
            topRuler.viewportTransform[3] = 1;
            topRuler.viewportTransform[4] = 0;
            topRuler.viewportTransform[5] = 0;

            leftRuler.viewportTransform[0] = 1;
            leftRuler.viewportTransform[3] = 1;
            leftRuler.viewportTransform[4] = 0;
            leftRuler.viewportTransform[5] = 0;
            
            var url = `${ESHOW_URL()}?method=getMapData&map_key=${activeFile.KEY_ID}&token=${getTokenCookie()}`     
            // var url = `https://dev.goeshow.com/webservices/eshow/floor_space.cfc?method=getMapData&map_key=${activeFile.KEY_ID}&token=${getTokenCookie()}`     
            // axiosInstance.get(`/getMapData?id=${activeFile.KEY_ID}&show_key=${authInfo.show_key}&user_key=${authInfo.user_key}&token=${getTokenCookie()}`).then(async res=>{
            axiosInstance.get(url).then(async res=>{
                var mapJS = activeFile.MAPJS;
                var content = {}
                try{
                    if(mapJS && mapJS != ""){
                        mapJS = mapJS.replaceAll('\r\n', ' ')
                        mapJS = JSON.parse(mapJS)
                    }
                    else mapJS = {}
                    var settingData = await fetchSetup(activeFile.KEY_ID);
                    // console.log("setupData", settingData)
                    var convertRateX= Math.abs(mapJS.convertRateX)
                    if(!convertRateX) convertRateX = 1
                    var mapWidthPx = unit2Px(settingData?.CANVAS?.SIZE_X?settingData?.CANVAS?.SIZE_X:window.innerWidth, convertRateX, mapJS.mapUnit)
                    var mapHeightPx = unit2Px(settingData?.CANVAS?.SIZE_Y?settingData?.CANVAS?.SIZE_Y:window.innerHeight, convertRateX, mapJS.mapUnit)
                    var tmp = {
                        mapWidth:settingData?.CANVAS?.SIZE_X?settingData?.CANVAS?.SIZE_X:window.innerWidth, 
                        mapHeight:settingData?.CANVAS?.SIZE_Y?settingData?.CANVAS?.SIZE_Y:window.innerHeight,
                        mapUnit:settingData?.GENERAL?.UNIT, 
                        convertUnit:settingData?.GENERAL?.CONVERT_UNIT, 
                        sales_form:settingData?.GENERAL?.FORM_KEY?settingData?.GENERAL?.FORM_KEY:'', 
                        gridSize:settingData?.CANVAS?.GRID_SIZE, 
                        strokeWidth:settingData?.CANVAS?.STROKE_WIDTH, 
                        showGrid:settingData?.CANVAS?.GRID_ON, 
                        snapToGrid: settingData?.CANVAS?.GRID_SNAP, 
                        mapOffsetX: settingData?.MAP_OFFSET?.OFFSET_X, 
                        mapOffsetY: settingData?.MAP_OFFSET?.OFFSET_Y, 
                        gridAngle: settingData?.CANVAS?.GRID_ANGLE,
                        event_home: settingData?.GENERAL?.HOME_LINK,
                        header_banner: settingData?.GENERAL?.HEADER_BANNER,
                        header_color: settingData?.GENERAL?.HEADER_COLOR,
                        endX:mapWidthPx,
                        endY:mapHeightPx,
                        convertRateX:convertRateX,
                        convertRateY:convertRateX,
                        startX: 0,
                        startY:0,
                        saveFileName:activeFile.TITLE,
                        convertOffsetX: settingData?.DWG?.COVERT_OFFSET_X,
                        convertOffsetY: settingData?.DWG?.COVERT_OFFSET_Y,
                        svgToWorldUnits: settingData?.DWG?.SVG_TO_WORLD_UNITES,
                        version: settingData?.DWG?.VERSION,
                        dwgPath: settingData?.DWG?.DWG_PATH,
                        dwgName: settingData?.DWG?.DWG_NAME,
                        LIST_PENDING_EXHIBITORS: settingData?.SETUP?.LIST_PENDING_EXHIBITORS,
                        LIST_TBD_EXHIBITORS: settingData?.SETUP?.LIST_TBD_EXHIBITORS ,
                    }
                    mapJS = {...mapJS, ...tmp}
                    if(mapJS.mainContent && mapJS.mainContent != ""){
                        content = JSON.parse(mapJS.mainContent)
                    }
                    var objects = []
                    var mapRemoveObjects = [];
                    var booths= res.data.BOOTHS
                    var tables= res.data.TABLES
                    var annotations= res.data.ANNOTATIONS
                    var sponsorships= res.data.SPONSORSHIPS
                    var backgrounds= res.data.BACKGROUND
                    var deletes = res.data.DELETED
                    if(booths && booths.length > 0){
                        for(var i = 0; i < booths.length; i++){
                            var fabricJS = booths[i].FABRICJS
                            var objectJS = booths[i].OBJECTJS
                            var bgColor = "#dadada"
                            var booth_width = unit2Px(booths[i].SIZE_X, convertRateX, mainCanvas.mapUnit)
                            var booth_height = unit2Px(booths[i].SIZE_Y, convertRateX, mainCanvas.mapUnit)
                            var INSERT_POINT = booths[i].INSERT_POINT
                            var exhibitors = booths[i].EXHIBITORS
                            var left = 0;
                            var top = 0;
                            if(INSERT_POINT){
                                var tmp = INSERT_POINT.split(",")
                                if(tmp.length >=2){
                                    left = unit2Px(tmp[0], convertRateX, mainCanvas.mapUnit) + 50
                                    top = unit2Px(tmp[1], convertRateX, mainCanvas.mapUnit) + 50
                                }
                            }
                            if(booths[i].BOOTH_NO){
                                var boothNumberFinal = `${booths[i].BOOTH_NO}`;
                                var element = new fabric.Polygon(
                                    [
                                        {x: 0, y:0},
                                        {x: booth_width, y:0},
                                        {x: booth_width, y:booth_height},
                                        {x: 0, y:booth_height},
                                        {x: 0, y:0}
                                    ], {
                                    class:"outline",
                                    left: left,
                                    top: top,
                                    fill: bgColor, 
                                    stroke: 'black',
                                    strokeWidth: 3/convertRateX,
                                    borderColor: 'black',
                                    lockRotation: true,
                                    lockScalingX: true,
                                    lockScalingY: true,
                                });

                                var group = new fabric.Group([element]).set({
                                    width: booth_width,
                                    height: booth_height,
                                    id: 'booth-'+boothNumberFinal,
                                    class: 'booth',
                                    boothGroup: getDefaultGroup(g_groupOptions),
                                    class1 : 'rect-booth',
                                    lockRotation: true,
                                    lockScalingX: true,
                                    lockScalingY: true,
                                    KEY_ID: booths[i].KEY_ID
                                });	
                                group.set({
                                    borderColor: '#ff7777',
                                    cornerColor: 'red',
                                    cornerSize: 6,
                                    hasBorders: false,
                                    transparentCorners: false,
                                    angle: 0,
                                    exhibitors: exhibitors?exhibitors:[],
                                    priceCheck: booths[i].PRICE?booths[i].PRICE:[],
                                    sold: exhibitors && exhibitors.length>0?1:0,
                                    uniq_id:Date.now() + parseInt(Math.random()*10000000000)
                                });
                                
                                // element.clone(function(cloned) {
                                //     group.clipPath = cloned                                    
                                // });

                                element.set({
                                    borderColor: '#ff7777',
                                    cornerColor: 'red',
                                    cornerSize: 6,
                                    transparentCorners: false
                                });
                                
                                
                                addText(mainCanvas, group, boothNumberFinal)

                                group.set({booth_number:boothNumberFinal})
                                mainCanvas.add(group)
                                objects.push(group.toDatalessObject())
                                
                            }
                        }
                    }
                    if(deletes && deletes.length > 0){
                        for(var i = 0; i < deletes.length; i++){
                            var fabricJS = deletes[i].FABRICJS
                            var objectJS = deletes[i].OBJECTJS
                            if(fabricJS && fabricJS != ""){
                                var object = JSON.parse(fabricJS)
                                object.KEY_ID = deletes[i].KEY_ID
                                if(objectJS && objectJS != ""){
                                    objectJS = JSON.parse(objectJS)
                                    object = {...object, ...objectJS}
                                }
                                mapRemoveObjects.push(object)
                            }
                        }
                    }
                    if(tables && tables.length > 0){
                        // for(var i = 0; i < tables.length; i++){
                        //     var fabricJS = tables[i].FABRICJS
                        //     var objectJS = tables[i].OBJECTJS
                        //     if(fabricJS && fabricJS != ""){
                        //         var object = JSON.parse(fabricJS)
                        //         object.KEY_ID = tables[i].KEY_ID
                        //         if(objectJS && objectJS != ""){
                        //             objectJS = JSON.parse(objectJS)
                        //             object = {...object, ...objectJS}
                        //         }
                        //         objects.push(object)
                        //     }
                        // }
                    }
                    if(annotations && annotations.length > 0){
                        // for(var i = 0; i < annotations.length; i++){
                        //     var fabricJS = annotations[i].FABRICJS
                        //     var objectJS = annotations[i].OBJECTJS
                        //     if(fabricJS && fabricJS != ""){
                        //         var object = JSON.parse(fabricJS)
                        //         object.KEY_ID = annotations[i].KEY_ID
                        //         if(objectJS && objectJS != ""){
                        //             objectJS = JSON.parse(objectJS)
                        //             object = {...object, ...objectJS}
                        //         }
                        //         objects.push(object)
                        //     }
                        // }
                    }
                    if(backgrounds && backgrounds.length > 0){
                        for(var i = 0; i < backgrounds.length; i++){
                            var url = backgrounds[i].URL
                            var offsetX = 0;
                            var offsetY = 0;
                            var sizeX = backgrounds[i].SIZE_X;
                            var sizeY = backgrounds[i].SIZE_Y;
                            var KEY_ID = backgrounds[i].KEY_ID
                            var offset = backgrounds[i].INSERT_POINT;
                            if(offset && offset != ""){
                                var tmp = offset.split(",")
                                if(tmp && tmp.length > 1){
                                    offsetX = unit2Px(tmp[0], convertRateX, mainCanvas.mapUnit)
                                    offsetY = unit2Px(tmp[1], convertRateX, mainCanvas.mapUnit)
                                }
                            }
                            if(url && url != ""){
                                getMeta(assetUrl(url), (err, img) => {
                                    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
                                    var mapOffsetX = unit2Px(props.main.mainCanvas.mapOffsetX?props.main.mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
                                    var mapOffsetY = unit2Px(props.main.mainCanvas.mapOffsetY?props.main.mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
                                    fabric.Image.fromURL(assetUrl(url), (img) => {
                                        bgImgCanvas.setBackgroundImage(img, ()=>{bgImgCanvas.renderAll(); props.setFirstBgContent(bgImgCanvas.toDatalessObject())}, {
                                            // opacity: 0,
                                            angle: 0,
                                            left: offsetX*1 + 50 + mapOffsetX,
                                            top: offsetY*1 + 50 + mapOffsetY,
                                            originX: 'left',
                                            originY: 'top',
                                            width: img.naturalWidth,
                                            height: img.naturalHeight,
                                            scaleX:sizeX/img.naturalWidth,
                                            scaleY: sizeY/img.naturalHeight,
                                            crossOrigin: 'anonymous',
                                            KEY_ID: KEY_ID
                                        })
                                    })
                                });

                            }
                        }
                    }
                    content.objects = objects
                    // content = LZString.decompressFromBase64(content)
                    //console.log(res.data)
                    props.setActiveBoothFile({...activeFile, ...mapJS})
                     
                } catch (e) {
                    props.setActiveBoothFile({...activeFile})
                     
                    mainCanvas.clear();  
                    bgCanvas.clear();  g_grid.clear(); bgImgCanvas.clear()    
                    mainCanvas.viewportTransform[0] = 1;
                    mainCanvas.viewportTransform[3] = 1;
                    mainCanvas.viewportTransform[4] = 0;
                    mainCanvas.viewportTransform[5] = 0;

                    bgCanvas.viewportTransform[0] = 1;
                    bgCanvas.viewportTransform[3] = 1;
                    bgCanvas.viewportTransform[4] = 0;
                    bgCanvas.viewportTransform[5] = 0;

                    bgImgCanvas.viewportTransform[0] = 1;
                    bgImgCanvas.viewportTransform[3] = 1;
                    bgImgCanvas.viewportTransform[4] = 0;
                    bgImgCanvas.viewportTransform[5] = 0;


                    topRuler.viewportTransform[0] = 1;
                    topRuler.viewportTransform[3] = 1;
                    topRuler.viewportTransform[4] = 0;
                    topRuler.viewportTransform[5] = 0;

                    leftRuler.viewportTransform[0] = 1;
                    leftRuler.viewportTransform[3] = 1;
                    leftRuler.viewportTransform[4] = 0;
                    leftRuler.viewportTransform[5] = 0;
                    console.log("Map JSON Error", e)
                    props.setLoading(false)
                    return;
				}

                if(content){
                    // content = JSON.parse(content)
                    if(content && content.backgroundImage && content.backgroundImage.src){
                        content.backgroundImage.src = assetUrl(content.backgroundImage.src)
                    }
                    var canvas = mainCanvas||canvasParam;
                   

                    if(canvas){
                        {
                            canvas.set({
                                convertRateX:mapJS['convertRateX'], 
                                convertRateY:mapJS['convertRateY'], 
                                convertOffsetX:mapJS['convertOffsetX'],
                                convertOffsetY:mapJS['convertOffsetY'], 
                                startX:mapJS['startX'], 
                                startY:mapJS['startY'], 
                                endX:mapJS['endX'], 
                                endY:mapJS['endY'], 
                                mapWidth:mapJS['mapWidth'], 
                                mapHeight:mapJS['mapHeight'],
                                mapUnit:mapJS['mapUnit'], 
                                sales_form:mapJS['sales_form'], 
                                convertUnit:mapJS['convertUnit'], 
                                mapLock:mapJS['mapLock']?mapJS['mapLock']:false,
                                gridSize:mapJS['gridSize'], 
                                strokeWidth:mapJS['STROKE_WIDTH'], 
                                showGrid:mapJS['showGrid'], 
                                snapToGrid:mapJS['snapToGrid'],
                                gridAngle:mapJS['gridAngle'], 
                                gridOffsetX:mapJS['gridOffsetX'], 
                                gridOffsetY:mapJS['gridOffsetY'], 
                                gridOffsetXDefault:mapJS['gridOffsetXDefault'], 
                                gridOffsetYDefault:mapJS['gridOffsetYDefault'],
                                mapOffsetX:mapJS['mapOffsetX'], 
                                mapOffsetY:mapJS['mapOffsetY'],
                                strokeWidthUnscaled:mapJS['strokeWidthUnscaled'],
                                boothfile_brand:mapJS['boothfile_brand'],
                                event_home:mapJS['event_home'],
                            })              
                            canvas.clipPath = new fabric.Rect({
                                width: Math.abs(mapJS.endX - mapJS.startX)+5,
                                height: Math.abs(mapJS.endY - mapJS.startY)+5,
                                top: 45,
                                left: 45
                              });      
                            bgCanvas.clipPath = new fabric.Rect({
                                width: Math.abs(mapJS.endX - mapJS.startX)+5,
                                height: Math.abs(mapJS.endY - mapJS.startY)+5,
                                top: 45,
                                left: 45
                            });  
                            bgImgCanvas.clipPath = new fabric.Rect({
                                width: Math.abs(mapJS.endX - mapJS.startX)+5,
                                height: Math.abs(mapJS.endY - mapJS.startY)+5,
                                top: 45,
                                left: 45
                            });      
                            canvas.renderAll();
                            bgCanvas.renderAll();
                            bgImgCanvas.renderAll();
                            
                            var convertRateX= Math.abs(canvas.convertRateX)
                            var mapOffsetX = unit2Px(canvas.mapOffsetX?canvas.mapOffsetX:0, convertRateX, canvas.mapUnit)
                            var mapOffsetY = unit2Px(canvas.mapOffsetY?canvas.mapOffsetY:0, convertRateX, canvas.mapUnit)

                            props.onClearHistory();
                            var groups = canvas.getObjects();
                            for(var i = 0; i< groups.length; i++){
                                groups[i].set({
                                    borderColor: '#ff7777',
                                    cornerColor: 'red',
                                    cornerSize: 6,
                                    transparentCorners: false,
                                    lockScalingX: true,
                                    lockScalingY: true,
                                    objectCaching: false, //true
                                    noScaleCache: true,
                                    left: groups[i].left+mapOffsetX,
                                    top: groups[i].top+mapOffsetY
                                });
                                var layer = groups[i].get('layer'); 
                                if(layer == "original"){
                                    groups[i].set({
                                        visible:false,
                                        lockMovementX: true,
                                        lockMovementY: true,
                                        lockRotation: true,
                                        lockScalingX: true,
                                        lockScalingY: true,
                                        selection:false,
                                        hasControls:false,
                                        hasBorders:false,
                                        evented:false,
                                        selectable: false ,
                                    })
                                }
                                if(groups[i].class == 'table'){
                                    var sub_objects = groups[i].getObjects('group');
                                    if(sub_objects && sub_objects.length > 0){
                                        // if(sub_objects[0].class == "path-group"){
                                            sub_objects[0].set({
                                                left:NaN,
                                                top: NaN,
                                            })
                                            .setCoords();
                                        // }
                                    }
                                }
                                if(groups[i].booth_number == "553"){
                                    // console.log("553", groups[i])
                                }
                                if(groups[i].class == "booth"){
                                    changeBorderColor(groups[i], 'black')
                                    var groupOptions = g_groupOptions
                                    var boothGroup = groups[i].boothGroup;
                                    var exhibitors = groups[i].exhibitors
                                    if(groups[i].holdBooth){
                                        var bgColor = "#dadada"
                                        for(var k = 0; k < groupOptions.length; k++){
                                            if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                                                var groupOption = groupOptions[k]
                                                    bgColor = convertRgbHex(groupOption['RESERVED']);
                                            }
                                        }
                                        setObjectBG(mainCanvas, groups[i], bgColor)
                                    }
                                    else if(groups[i].sold){
                                        var bgColor = "#dadada"
                                        for(var k = 0; k < groupOptions.length; k++){
                                            if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                                                var groupOption = groupOptions[k]
                                                    bgColor = convertRgbHex(groupOption[exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD']);
                                            }
                                        }
                                        setObjectBG(mainCanvas, groups[i], bgColor)
                                    }
                                    else if(groups[i].priceCheck && groups[i].priceCheck.length > 0){
                                        var bgColor = "#dadada"
                                        for(var k = 0; k < groupOptions.length; k++){
                                            if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                                                var groupOption = groupOptions[k]
                                                    bgColor = convertRgbHex(groupOption['AVAILABLE']);
                                            }
                                        }
                                        setObjectBG(mainCanvas, groups[i], bgColor)
                                    }
                                }
                            }
                            canvas.renderAll();
                            props.onClearHistory();
                            props.setBoothHistory({content: canvas.toDatalessObject(), action:{type:'load'}})
                            props.setFirstMapContent(canvas.toDatalessObject())
                            
                            redrawRulers(canvas, topRuler, leftRuler)
                            // setTimeout(function(){
                                redrawGrid(g_grid, canvas, (canvas.showGrid &&canvas.gridSize),canvas.gridSize, canvas.gridAngle, canvas.gridOffsetX, canvas.gridOffsetY);
                                renderBorder(canvas);
                            // }, 300)
                            canvas.renderAll();
                            toggleDrawMode(canvas, "general")
                            
                            canvas.setBackgroundColor('transparent', mainCanvas.renderAll.bind(mainCanvas))
                            bgCanvas.setBackgroundColor('transparent', bgCanvas.renderAll.bind(bgCanvas))
                            props.setCanvasRefresh(true)  
                            props.setLoading(false)
                            props.setMapRemoveObjects(mapRemoveObjects?mapRemoveObjects:[])
                            centerFocus(mainCanvas, bgCanvas, g_grid, topRuler, leftRuler, bgImgCanvas)
                            // if(canvas.mapLock)
                            {
                                lockMap(canvas)
                                props.setLockFlag(true)
                            }
                            // else{
                            //     unlockMap(canvas)
                            //     props.setLockFlag(false)
                            // }
                        }                            
                    }
                }
            }).catch(error => {
                ////console.log("error", error);
                props.setLoading(false)
            })
        }
        else{            
            props.setLoading(false)
        }
    }
    
    useEffect(()=>{
        if(props.main.boothFileList && props.main.mainCanvas){
            
            if(props.main.to2dFlag){ 
                props.to2D(false)
            }   
            else{
                var activeFile;
                

                for(var i = 0; i <boothFileList.length; i++){
                    if(boothFileList[i]['active']){
                        activeFile = boothFileList[i];
                        break;
                    }
                }
                if(!activeFile && boothFileList.length){
                    activeFile = boothFileList[0]
                }
                if(activeFile){
                    if(activeFile?.MIGRATE === true){
                        console.log("load json from eshow")
                        loadFirstEshow(); 
                    }
                    else{
                        console.log("load json from migrated")
                        let mapJs = activeFile?.['MAPJS'];
                        let overview = false
                        if(mapJs && mapJs != ''){
                            try{
                                mapJs = JSON.parse(mapJs);
                            }
                            catch(e){
                            
                            }
                            if(mapJs?.overview){
                                overview = true
                            }
                            else{
                            }
                        }
                        loadBooth()
                    }
                }
            }
        }
    }, [props.main.boothFileList, props.main.mainCanvas])

    useEffect(() => {
        if(authInfo.authenticated == 3 && (!boothFileList || (boothFileList && boothFileList.length==0))){
            //console.log("authenticated", authInfo)
            
            fetchShowSetup()
            fetchGroupList()
            fetchmaplist()
            fetchSponsorshipsList()
            //fetchExhibitorList();
            // fetchFeeGroupList()
            // fetchFeeCategoryList()
            //fetchPricingOptions()
            //fetchPremiumOptions()
            //fetchDiscountOptions()    
            //fetchCategoryOptions()     
            // fetchMechnantAccount()  
            fetchAuditLog()
        }
    }, [authInfo])

    useEffect(()=>{
        if(props.main.mainCanvas){            
              
        }
    }, [props.main.mainCanvas])

    const saveOriginalLayer = (mapKEY_ID = false)=>{
        // mapKEY_ID = active_booth_file.KEY_ID
        var convertRateX= 1
        if(props.main.mainCanvas.convertRateX) {
            convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
        }
        convertRateX= Math.abs(mainCanvas.convertRateX)
        
        const boothJson = (props.main.mainCanvas.toDatalessObject());
        var booths = [];
        

        var objects = boothJson['objects']
        for(var i = 0; i < objects.length; i++){
            var objectContent = objects[i];
            if(objectContent.class=="booth"){
                if(objectContent.booth_number){
                    booths.push({
                        BOOTH_NO: objectContent.booth_number,
                        FABRICJS:JSON.stringify(objectContent),
                        OBJECTJS:"",
                        ACTIVE: 1,
                        STATUS: 1,
                        SIZE_X: getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                        SIZE_Y: getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                        KEY_ID:objectContent.KEY_ID?objectContent.KEY_ID:uuidv4(),
                        OBJECT_TYPE:'rect_booth',
                        SUB_TYPE:1,
                        TYPE:619,
                        EXHIBITORS:[],
                        INSERT_POINT:`${getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                    })                    
                }
            }
        }
        const options = {
            headers: {
                'body': '[{"company:","test"}]', 
                'Content-Type': 'application/json'
              }
        }
        var param = `?method=putOriginalLayout&map_key=${mapKEY_ID}&token=${getTokenCookie()}`
        var url = 
        `${ESHOW_URL()}${param}`
        var postData = {
            LAYOUT: booths,
            SUCCESS: true,
        }
        axiosInstance.post(url, JSON.stringify(postData), options).then(res=>{
        }).catch(error => {
        })  
    }
    const handleOriginalLayer = async ()=>{
        var layerSelect = props.main.layerSelect
        if(layerSelect['original']){
            var show_key = authInfo.show_key?authInfo.show_key:'';
            var user_key = authInfo.user_key?authInfo.user_key:'';
            var activeFile = active_booth_file;
            if(activeFile && activeFile['KEY_ID']) {
                props.setLoading(true)
                var map_key = activeFile.KEY_ID
                var url = `${ESHOW_URL()}?method=getOriginalLayout&map_key=${map_key}&token=${getTokenCookie()}`
               
                var res = await axiosInstance.get(url);
                var result = res.data
                if(result.SUCCESS){
                    var booths = result.LAYOUT
                    var objects = [];
                    if(booths && booths.length > 0){
                        for(var i = 0; i < booths.length; i++){
                            var fabricJS = booths[i].FABRICJS
                            if(fabricJS && fabricJS != ""){
                                try{
                                    var object = JSON.parse(fabricJS)                                
                                    objects.push(object)
                                }
                                catch(e){
                                    console.log("booth error", e)
                                    continue
                                }
                            }
                        }
                    }
                    if(objects && objects.length){
                        var convertRateX= 1
                        if(props.main.mainCanvas.convertRateX) {
                            convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
                        }
                        convertRateX= Math.abs(mainCanvas.convertRateX)
                        var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
                        var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
                        if(objects && objects.length > 0){
                            fabric.util.enlivenObjects(objects, function(objects) {
                                // var origRenderOnAddRemove = mainCanvas.renderOnAddRemove;
                                // mainCanvas.renderOnAddRemove = false;
                            
                                objects.forEach(function(o) {
                                    o.set({
                                        visible: 1,
                                        lockMovementX: true,
                                        lockMovementY: true,
                                        lockRotation: true,
                                        lockScalingX: true,
                                        lockScalingY: true,
                                        selection:false,
                                        hasControls:false,
                                        hasBorders:false,
                                        evented:false,
                                        selectable: false ,
                                        layer:"original",
                                        left: o.left+(mapOffsetX?mapOffsetX:0),
                                        top: o.top+(mapOffsetY?mapOffsetY:0)
                                    })
                                    
                                    setObjectBG(mainCanvas, o, '#ffd3ff')
                                    setObjectBorder(mainCanvas, o, '#800080')
                                    // mainCanvas.sendToBack(o);
                                    // o.sendBackwards();
                                    // mainCanvas.add(o).sendToBack(o);
                                    // o.sendBackwards();
                                    mainCanvas.add(o);
                                });
                            
                                // mainCanvas.renderOnAddRemove = origRenderOnAddRemove;
                                sendOriginalToTopGrid(mainCanvas)
                                mainCanvas.renderAll();                            
                            });
                        }
                    }
                }
                props.setLoading(false)
            }
        }
        else{
            var objects = mainCanvas.getObjects();
            for(var i = 0; i < objects.length; i++){
                var object = objects[i];
                if(object.layer == "original") {
                    mainCanvas.remove(object)
                }
            }
        }
    }

    const handleDeletedLayer  = async ()=>{
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
        var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
        var layerSelect = props.main.layerSelect
        if(layerSelect['deleted']){    
            var objects = [...props.main.mapRemoveObjects] 
            if(objects && objects.length > 0){
                fabric.util.enlivenObjects(objects, function(objects) {
                    // var origRenderOnAddRemove = mainCanvas.renderOnAddRemove;
                    // mainCanvas.renderOnAddRemove = false;
                    
                    objects.forEach(function(o) {
                        o.set({
                            visible: 1,
                            lockMovementX: true,
                            lockMovementY: true,
                            lockRotation: true,
                            lockScalingX: true,
                            lockScalingY: true,
                            selection:false,
                            hasControls:false,
                            hasBorders:false,
                            evented:false,
                            selectable: false ,
                            layer: 'deleted',
                            left: o.left+(o.old_deleted?mapOffsetX:0),
                            top: o.top+(o.old_deleted?mapOffsetY:0)
                        })
                        mainCanvas.add(o);
                    });
                    
                    // mainCanvas.renderOnAddRemove = origRenderOnAddRemove;
                    mainCanvas.renderAll();                            
                });
            }
        }
        else{
            var objects = mainCanvas.getObjects();
            for(var i = 0; i < objects.length; i++){
                var object = objects[i];
                if(object.layer == "deleted") {
                    object.visible = false
                }
            }
            mainCanvas.renderAll();                            
        }
    }

    const handleVenueLayer = async ()=>{
        if(props.main.layerSelect.venue){
            bgCanvas.lowerCanvasEl.style.display = 'block'
        }
        else{
            bgCanvas.lowerCanvasEl.style.display = 'none'
        }
    }
    
    useEffect(()=>{
        if(mainCanvas){
            handleOriginalLayer()
        }
    }, [props.main.layerSelect.original])

    useEffect(()=>{
        if(mainCanvas){
            handleDeletedLayer()
        }
    }, [props.main.layerSelect.deleted])

    
    useEffect(()=>{
        if(mainCanvas){
            handleVenueLayer()
        }
    }, [props.main.layerSelect.venue])


    const handleLayer = (object)=>{
        if(object.layer == "original" || object.layer == "deleted" || object.get('class') == 'line'|| object.get('class') == 'wall'|| object.get('class') == 'table') return;
        var layerSelect = props.main.layerSelect
        if(object.get('type') == 'group'){
            var layer = object.get('layer'); 
            if(layer){
                object.set({visible: layerSelect[layer]})
            }
            var sub_objects = object.getObjects();
            if(object.get('class') == 'booth'){
                if(mainCanvas.drawMode == "draw") return;
                for(var j = 0; j < sub_objects.length; j++){
                    var sub_object = sub_objects[j];
                    if(sub_object.get('type') == 'polygon' || sub_object.get('type') == 'rect'){
                        sub_object.set({visible: layerSelect['booth_outline']})
                        object.set({  
                            selectable:layerSelect['booth_outline'],   
                            hasControls:layerSelect['booth_outline'],
                            hasBorders:layerSelect['booth_outline'],
                            evented:layerSelect['booth_outline'],
                            lockMovementX: !layerSelect['booth_outline'],
                            lockMovementY: !layerSelect['booth_outline']
                        })
                    }
                    if(sub_object.get('type') == 'text' && sub_object.get('class1') != 'hidden'){
                        sub_object.set({visible: layerSelect['booth_number']})
                    }

                    var layer = sub_object.get('layer'); 
                    if(layer){
                        sub_object.set({visible: layerSelect[layer]})
                    }
                }  
            }
            else{
                if(layer) {
                    object.set({  
                        selectable:layerSelect[layer],   
                        hasControls:layerSelect[layer],
                        hasBorders:layerSelect[layer],
                        evented:layerSelect[layer],
                        lockMovementX: !layerSelect[layer],
                        lockMovementY: !layerSelect[layer]
                    })
                }
            }
        }
        else{
            var layer = object.get('layer'); 
            if(layer){
                object.set({visible: layerSelect[layer]})
                {
                    object.set({
                        selectable:layerSelect[layer],   
                        hasControls:layerSelect[layer],
                        hasBorders:layerSelect[layer],
                        evented:layerSelect[layer],
                        lockMovementX: !layerSelect[layer],
                        lockMovementY: !layerSelect[layer]
                    })
                }
            }
        }
    }
    useEffect(async ()=>{
        if(mainCanvas){
            gLayerSelect = props.main.layerSelect
            var objects = mainCanvas.getObjects();
            for(var i = 0; i < objects.length; i++){
                var object = objects[i];
                if(object.get('groupped')){
                    var subgroups = object.getObjects()
                    for(var subgroupInd = 0; subgroupInd< subgroups.length; subgroupInd++){
                        handleLayer(subgroups[subgroupInd])  
                    }
                }      
                else{
                    handleLayer(object)     
                }           
            }  
            mainCanvas.renderAll();
        }
        
    }, [props.main.layerSelect])

    const getConvertedSvg=(convertedSvgInfo) => {
        if(convertedSvgInfo){
            axiosInstance.get(convertedSvgInfo.contentStreamData, { headers: {  } }).then(res=>{
                const result = res.data;
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(result, "application/xml");
                // var svgDoc = doc.contentDocument;
                var svgStr = svgDoc.getElementsByTagName('svg')[0].outerHTML;
                if(mainCanvas){
                    props.setMainSvg(svgStr); 
                    if(svgStr){
                        var layers = parseLayer(svgStr)                        
                        props.setImportLayers(layers)
                    }
                }
            }).catch(error => {
                ////console.log("error", error);
            })
                
        }
    }

    const saveSetup = async ()=>{   
        var mainCanvas = props.main.mainCanvas;
        var bgImgCanvas = props.main.bgImgCanvas;
        var show_key = authInfo.show_key;
        var user_key = authInfo.user_key;
        const view_file = props.main.view_file
        const boothJson = (props.main.mainCanvas.toDatalessObject());
        var groups = mainCanvas.getObjects('group');
        var gridOffsetX
        var gridOffsetY
        for(var i = 0; i < groups.length; i++){
            var group = groups[i]
            if(group.get('class') == 'booth' || group.get('class') == 'booth-group'){
                if(gridOffsetX == undefined || gridOffsetY == undefined){
                    gridOffsetX = group.left;  gridOffsetY = group.top
                }
                if(group.top < gridOffsetY && group.left < gridOffsetX){
                    gridOffsetX = group.left
                    gridOffsetY = group.top
                }
                if(group.left < gridOffsetX){
                    gridOffsetX = group.left
                    gridOffsetY = group.top
                }
            }
        }

        
        var objects = props.main.mainCanvas.getObjects('group');
        var convertRateX= 1
        if(props.main.mainCanvas.convertRateX) {
            convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
        }
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        
        var tmp_totalOrgQty = 0;
        var tmp_totalOrgSqft = 0;
        for(var i = 0; i< objects.length; i++){
            var object = objects[i]
            var row = {}                      
            if(object.get('class')=="original-booth"){
            var booth_width = px2unit(object.width, convertRateX, mainCanvas.mapUnit).toFixed(0)
            var booth_height = px2unit(object.height, convertRateX, mainCanvas.mapUnit).toFixed(0)
            // temp disable
            // if(active_booth_file.version == '2'){
            //     booth_width = object.dwg_width
            //     booth_height = object.dwg_height
            // }
            var sqrt = 0;
            if(object.class1 == "custom-booth"){
                var objects = object.getObjects('polyline');
                if(objects && objects.length > 0 ){
                    var points = objects[0].points;
                    var polyline = [];
                    for(var i = 0; i < points.length; i++){
                        polyline.push([px2unit(points[i].x, convertRateX, props.main.mainCanvas.mapUnit), px2unit(points[i].y, convertRateX, props.main.mainCanvas.mapUnit)])
                    }
                    sqrt = area(polyline)
                }
            }
            else{
                sqrt = (booth_width*booth_height)
            }
            tmp_totalOrgQty++;
            tmp_totalOrgSqft += (sqrt*1).toFixed(0)*1;
            }
        }
                        
        // props.setFirstMapContent(boothJson)     
        // props.setFirstBgContent(bgImgCanvas.toDatalessObject()) 
        // props.setLoading(true)
        var save_id = parseInt(Math.random()*10000000)
        var mainContent = {...boothJson}
        mainContent.objects = [];
        var MAPJS = 
        {
            mainContent: JSON.stringify(mainContent),
            filename:props.main.saveFileName,
            content:[],
            boothList:[],
            save_id:save_id,
            show_key: show_key,
            user_key: user_key,
            convertRateX: mainCanvas.convertRateX,
            convertRateY: mainCanvas.convertRateY,
            convertOffsetX: mainCanvas.convertOffsetX,
            convertOffsetY: mainCanvas.convertOffsetY, 
            startX:mainCanvas.startX, 
            startY:mainCanvas.startY, 
            endX:mainCanvas.endX, 
            endY:mainCanvas.endY,
            mapWidth: mainCanvas.mapWidth,
            mapHeight: mainCanvas.mapHeight,
            mapUnit: mainCanvas.mapUnit,
            sales_form: mainCanvas.sales_form,
            convertUnit: mainCanvas.convertUnit, 
            mapLock: mainCanvas.mapLock,
            gridSize: mainCanvas.gridSize, 
            strokeWidth: mainCanvas.strokeWidth, 
            showGrid:true, 
            snapToGrid: true,
            gridAngle: mainCanvas.gridAngle,
            gridOffsetX: gridOffsetX,
            gridOffsetY: gridOffsetY,
            gridOffsetXDefault: gridOffsetX,
            gridOffsetYDefault: gridOffsetY,
            mapOffsetX: mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0,          
            mapOffsetY: mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0,
            org_booth_cnt: tmp_totalOrgQty,
            org_booth_sqrt: tmp_totalOrgSqft ,
            MIGRATE:""
        }
        var mapKEY_ID = uuidv4();
        var postData = [{
            DISPLAY_ORDER: 0,
            STATUS: "",
            TITLE: props.main.saveFileName,
            MAPJS: JSON.stringify(MAPJS),
            KEY_ID: mapKEY_ID,
            MIGRATE: ""
        }]
        var formdata = {
            query:`${ESHOW_URL()}?method=putMaps&token=${getTokenCookie()}`,
            postData: postData,
            returnResponse: true
        }
        const options = {
            headers: {
                'body': '[{"company:","test"}]', 
                'Content-Type': 'application/json'
              }
        }
        var url = `${ESHOW_URL()}?method=putMaps&token=${getTokenCookie()}`
        var response = await axiosInstance.post(url, JSON.stringify(postData), options)
        if(response.status == 200){
            var result = response.data
            if(result.SUCCESS == true){

                var settingData = {
                    CANVAS:{
                        MAP_KEY: mapKEY_ID,
                        SIZE_X:mainCanvas.mapWidth,
                        SIZE_Y:mainCanvas.mapHeight,
                        GRID_SIZE:mainCanvas.gridSize,
                        STROKE_WIDTH:mainCanvas.strokeWidth,
                        GRID_ON:true,
                        GRID_SNAP:true,
                        GRID_ANGLE:0,
                    },
                    MAP_OFFSET:{
                        MAP_KEY:mapKEY_ID,
                        OFFSET_X:0,
                        OFFSET_Y:0,
                    },
                    GENERAL:{
                        MAP_KEY:mapKEY_ID,
                        UNIT:mainCanvas.mapUnit,
                        CONVERT_UNIT:mainCanvas.convertUnit,
                        HOME_LINK:'',
                        BRAND_LOGO:''
                    },
                    DWG:{
                        MAP_KEY:mapKEY_ID,
                        COVERT_OFFSET_X:mainCanvas.convertOffsetX,
                        COVERT_OFFSET_Y:mainCanvas.convertOffsetY,
                        SVG_TO_WORLD_UNITES:mainCanvas.svgToWorldUnits,
                        VERSION:2,
                        DWG_PATH:view_file.dwgPath?view_file.dwgPath:"",
                        DWG_NAME:view_file.dwgName?view_file.dwgName:"",
                    }
                }
                var postData = {
                    SUCCESS: true,
                    ...settingData
                }
                var formdata = {
                    query:`${ESHOW_URL()}?method=putSetup&map_key=${mapKEY_ID}&token=${getTokenCookie()}`,
                    postData: postData,
                    returnResponse: true
                }
                var data = qs.stringify({
                    request: JSON.stringify(formdata)
                });
                const options = {
                    headers: {
                        'body': '[{"company:","test"}]', 
                        'Content-Type': 'application/json'
                      }
                }
                var url = `${ESHOW_URL()}?method=putSetup&map_key=${mapKEY_ID}&token=${getTokenCookie()}`
                var response = await axiosInstance.post(url, JSON.stringify(postData), options)
                if(response.status == 200){
                    var result = response.data
                    if(result.SUCCESS == true){
                        return mapKEY_ID
                    }
                    else{                    
                        props.setOpenSnack(true);
                        props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Something went wrong");
                        props.setLoading(false)
                    }
                }
            }
            else{                    
                props.setOpenSnack(true);
                props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Something went wrong");
                props.setLoading(false)
            }
        }  
        props.setOpenSnack(true);
        props.setSnackMsg("Something went wrong");
        props.setLoading(false)
        return false;
    }

      
    const confirmSaveFile = async ()=>{   
        var mainCanvas = props.main.mainCanvas;
        var bgImgCanvas = props.main.bgImgCanvas;
        centerFocus(mainCanvas, bgCanvas, g_grid, topRuler, leftRuler, bgImgCanvas)
        // console.log("end import", Math.floor(Date.now() / 1000))
        //test code start
        // let tmpObjs = mainCanvas.getObjects('group');
        // let tmp = [];
        // for(let i = 0; i < tmpObjs?.length; i++){
        //     if(tmpObjs[i].class == 'venue'){
        //         tmp.push(tmpObjs[i].Handle)
        //     }
        // }
        // // console.log("venue", tmp);
        // g_fileSaved = true       
        // document.getElementsByClassName("canvas-container")[0].style.display = 'block';
        // props.setLoading(false)
        // return;         
        //test code end
        // props.setLoading(false)
        if(!props.main.saveFileName) {
            alert("Enter file name first.")
            return false;
        }   
        props.setOpenFilenameModal(false);   
        if(bgImgCanvas){
            bgImgCanvas.renderAll();
            bgImgCanvas.requestRenderAll()
        }
        var mapKEY_ID = await saveSetup();
        if(mapKEY_ID){      
            var objects = props.main.mainCanvas.getObjects('group');
            var convertRateX= 1
            if(props.main.mainCanvas.convertRateX) {
                convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
            }
            var convertRateX= Math.abs(mainCanvas.convertRateX)
            var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
            var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
            
            const boothJson = (props.main.mainCanvas.toDatalessObject());
            const bgImgJson = (props.main.bgImgCanvas.toDatalessObject());
            const columnImgJson = (props.main.bgCanvas.toDatalessObject());
                            
            //console.log("boothJson", boothJson)
            var booths = [];
            var tables = [];
            var annotations = [];
            var columns = [];
            var backgrounds = [];   
            var bgImgContent = {...bgImgJson}
            var columnImgContent = {...columnImgJson}
            bgImgContent.objects = [];
            
            if(bgImgContent?.backgroundImage){                                
                bgImgContent.backgroundImage.modified = false;   
                bgImgContent.backgroundImage.left -=mapOffsetX;
                bgImgContent.backgroundImage.top -=mapOffsetY;        
                backgrounds.push({
                    FABRICJS:(bgImgContent.backgroundImage?JSON.stringify(bgImgContent.backgroundImage):""),
                    OBJECTJS:"",
                    ACTIVE: 1,
                    STATUS: 1,
                    SIZE_X: bgImgContent?.backgroundImage?.width * bgImgContent?.backgroundImage?.scaleX,
                    SIZE_Y: bgImgContent?.backgroundImage?.height * bgImgContent?.backgroundImage?.scaleY,
                    KEY_ID:bgImgContent.backgroundImage.KEY_ID,
                    OBJECT_TYPE:'background',
                    SUB_TYPE:10,
                    TYPE:619,
                    EXHIBITORS:[],
                    URL:(bgImgContent.backgroundImage&&bgImgContent.backgroundImage.src?bgImgContent.backgroundImage.src:""),
                    INSERT_POINT:`${getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).left},${getGeometry(mainCanvas, bgImgContent.backgroundImage, convertRateX, 0, 0).top}`
                })
            }

            if(columnImgContent?.backgroundImage){                                
                columnImgContent.backgroundImage.modified = false;   
                columnImgContent.backgroundImage.left -=mapOffsetX;
                columnImgContent.backgroundImage.top -=mapOffsetY;        
                columns.push({
                    BOOTH_NO:"1",
                    FABRICJS:(columnImgContent.backgroundImage?JSON.stringify(columnImgContent.backgroundImage):""),
                    OBJECTJS:JSON.stringify({bg:1, URL:(columnImgContent.backgroundImage&&columnImgContent.backgroundImage.src?columnImgContent.backgroundImage.src:"")}),
                    ACTIVE: 1,
                    STATUS: 1,
                    SIZE_X: columnImgContent?.backgroundImage?.width * columnImgContent?.backgroundImage?.scaleX,
                    SIZE_Y: columnImgContent?.backgroundImage?.height * columnImgContent?.backgroundImage?.scaleY,
                    KEY_ID:columnImgContent.backgroundImage.KEY_ID,
                    // OBJECT_TYPE:'column-img',
                    // SUB_TYPE:10,
                    // TYPE:619,
                    OBJECT_TYPE:'bg',
                    SUB_TYPE:17,
                    TYPE:619,
                    EXHIBITORS:[],
                    URL:(columnImgContent.backgroundImage&&columnImgContent.backgroundImage.src?columnImgContent.backgroundImage.src:""),
                    INSERT_POINT:`${getGeometry(mainCanvas, columnImgContent.backgroundImage, convertRateX, 0, 0).left},${getGeometry(mainCanvas, columnImgContent.backgroundImage, convertRateX, 0, 0).top}`
                })
            }

            var objects = boothJson['objects']
            for(var i = 0; i < objects.length; i++){
                var objectContent = objects[i];
                if(objectContent.class=="booth"){
                    if(objectContent.booth_number){
                        booths.push({
                            BOOTH_NO: objectContent.booth_number,
                            FABRICJS:JSON.stringify(objectContent),
                            OBJECTJS:"",
                            ACTIVE: 1,
                            STATUS: 1,
                            SIZE_X: getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                            SIZE_Y: getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                            KEY_ID:objectContent.KEY_ID?objectContent.KEY_ID:uuidv4(),
                            OBJECT_TYPE:'rect_booth',
                            SUB_TYPE:1,
                            TYPE:619,
                            EXHIBITORS:[],
                            INSERT_POINT:`${getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                        })
                        
                        // console.log("objectContent.booth_number", objectContent.booth_number)
                        // break;
                    }
                }
                if(objectContent.class=="venue"){
                    if(objectContent.booth_number){
                        columns.push({
                            BOOTH_NO: objectContent.booth_number,
                            FABRICJS:JSON.stringify(objectContent),
                            OBJECTJS:"",
                            ACTIVE: 1,
                            STATUS: 1,
                            SIZE_X: getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                            SIZE_Y: getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                            KEY_ID:objectContent.KEY_ID?objectContent.KEY_ID:uuidv4(),
                            OBJECT_TYPE:'rect_booth',
                            SUB_TYPE:17,
                            TYPE:619,
                            EXHIBITORS:[],
                            INSERT_POINT:`${getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                        })
                    }
                }
                else if(objectContent.class=="table"){
                    if(objectContent.booth_number){
                        var sub_type = 0;
                        if(objectContent.class1 == "table_8"){
                            sub_type = 1;
                        }
                        else if(objectContent.class1 == "table_10"){
                            sub_type = 2;
                        }
                        else if(objectContent.class1 == "table_6"){
                            sub_type = 3;
                        }
                        tables.push({
                            TABLE_NO: objectContent.booth_number,
                            FABRICJS:JSON.stringify(objectContent),
                            OBJECTJS:"",
                            ACTIVE: 1,
                            STATUS: 1,
                            SIZE_X: getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).width,
                            SIZE_Y: getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).height,
                            KEY_ID:objectContent.KEY_ID?objectContent.KEY_ID:uuidv4(),
                            OBJECT_TYPE:(objectContent.class1?objectContent.class1:'table'),
                            SUB_TYPE:sub_type,
                            TYPE:186,
                            EXHIBITORS:[],
                            INSERT_POINT:`${getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).left},${getGeometry(mainCanvas, objectContent, convertRateX, mainCanvas.mapOffsetX, mainCanvas.mapOffsetY).top}`
                        })
                        
                        // console.log("objectContent.booth_number", objectContent.booth_number)
                        // break;
                    }
                }
            }
            var postData = {
                BOOTHS: booths,
                BACKGROUND: backgrounds,
                TABLES: tables,
                ANNOTATIONS: annotations,
                COLUMNS:columns,
                SUCCESS: true,
            }
            // var formdata = {
            //     query:`${ESHOW_URL()}?method=putMapData&map_key=${mapKEY_ID}&token=${getTokenCookie()}`,
            //     postData: postData,
            //     returnResponse: true
            // }
            // console.log("postData", postData)
            // // return
            // var data = qs.stringify({
            //     request: JSON.stringify(formdata)
            // });
            // const options = {
            //     headers: {"content-type": 'application/x-www-form-urlencoded'}
            // }
            var param = `?method=putMapData&map_key=${mapKEY_ID}&token=${getTokenCookie()}`
            // var url = 
            // `https://dev.goeshow.com/webservices/eshow/floor_space.cfc?${param}`
            var url = 
            `${ESHOW_URL()}${param}`
            const options = {
                headers: {
                    'body': '[{"company:","test"}]', 
                    'Content-Type': 'application/json'
                }
            }
            axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
                // const result = res.data;
                // // props.setLoading(false)
                // if(result.status == "success")
                // {  
                    var newLogs = [];
                    var logKey = uuidv4()
                    newLogs.push({
                        BOOTH_KEY: mapKEY_ID,
                        KEY_ID: logKey,
                        ACTIVE: 1,
                        TYPE: 2012,
                        LOG_TEXT: {
                            BOOTH_KEY:mapKEY_ID,
                            KEY_ID: logKey,
                            action:"import dwg",
                            date: new Date().toLocaleString(),
                            description: "imported map "+(props.main.saveFileName??''),
                            user: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
                        },
                        USER_NAME: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
                    })
                    await saveLog(newLogs);
                                    
                    props.resetUI();
                    props.resetMain();

                    var eshowResponse = res.data;
                    if(eshowResponse.SUCCESS == true){
                        g_fileSaved = true 
                        props.setFirstMapContent(props.main.mainCanvas.toDatalessObject())
                        props.setFirstBgContent(props.main.bgImgCanvas.toDatalessObject())
                        fetchmaplist(mapKEY_ID)
                        props.setLoading(false)
                        saveOriginalLayer(mapKEY_ID)
                        fetchAuditLog()
                    }
                    else{
                        props.setOpenSnack(true);
                        props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Failed to update");
                        props.setLoading(false)
                    }
                // }
                // else{
                //     props.setLoading(false)
                // }
            }).catch(error => {
                //console.log("error", error);
            })  
        }
        else{
            props.setLoading(false)
        }
    }

    const createNewMap = async ()=>{
        props.setLoading(true)
        var mainCanvas = props.main.mainCanvas;
        var show_key = authInfo.show_key;
        var user_key = authInfo.user_key;
        mainCanvas.clear();  
        bgCanvas.clear();  g_grid.clear(); bgImgCanvas.clear()   
        mainCanvas.viewportTransform[0] = 1;
        mainCanvas.viewportTransform[3] = 1;
        mainCanvas.viewportTransform[4] = 0;
        mainCanvas.viewportTransform[5] = 0;

        bgCanvas.viewportTransform[0] = 1;
        bgCanvas.viewportTransform[3] = 1;
        bgCanvas.viewportTransform[4] = 0;
        bgCanvas.viewportTransform[5] = 0;

        bgImgCanvas.viewportTransform[0] = 1;
        bgImgCanvas.viewportTransform[3] = 1;
        bgImgCanvas.viewportTransform[4] = 0;
        bgImgCanvas.viewportTransform[5] = 0;

        

        topRuler.viewportTransform[0] = 1;
        topRuler.viewportTransform[3] = 1;
        topRuler.viewportTransform[4] = 0;
        topRuler.viewportTransform[5] = 0;

        leftRuler.viewportTransform[0] = 1;
        leftRuler.viewportTransform[3] = 1;
        leftRuler.viewportTransform[4] = 0;
        leftRuler.viewportTransform[5] = 0;
        
        mainCanvas.set({
            convertRateX:1, 
            convertRateY:1, 
            convertOffsetX:0, 
            convertOffsetY:0, 
            startX:0, 
            startY:0, 
            endX:800*12, 
            endY:500*12, 
            mapWidth:800, 
            mapHeight:500,
            convertUnit:'feet', 
            mapUnit:'feet',
            gridSize:10, 
            showGrid:true,
            snapToGrid:true,
            gridAngle: 0,
        })
        mainCanvas.defaultCursor = 'default';
        mainCanvas.hoverCursor= 'pointer';

        // props.setLoading(true)
        var save_id = parseInt(Math.random()*10000000)
        const boothJson = (props.main.mainCanvas.toDatalessObject());
        var mainContent = {...boothJson}
        mainContent.objects = [];
        var MAPJS = 
        {
            mainContent: JSON.stringify(mainContent),
            filename:props.main.saveFileName,
            content:[],
            boothList:[],
            save_id:save_id,
            show_key: show_key,
            user_key: user_key,
            convertRateX: mainCanvas.convertRateX,
            convertRateY: mainCanvas.convertRateY,
            convertOffsetX: mainCanvas.convertOffsetX,
            convertOffsetY: mainCanvas.convertOffsetY, 
            startX:mainCanvas.startX, 
            startY:mainCanvas.startY, 
            endX:mainCanvas.endX, 
            endY:mainCanvas.endY,
            mapWidth: mainCanvas.mapWidth,
            mapHeight: mainCanvas.mapHeight,
            mapUnit: mainCanvas.mapUnit,
            convertUnit: mainCanvas.convertUnit, 
            mapLock: mainCanvas.mapLock,
            gridSize: mainCanvas.gridSize, 
            strokeWidth: mainCanvas.strokeWidth, 
            showGrid:true, 
            snapToGrid: true,
            gridAngle: mainCanvas.gridAngle,
            gridOffsetX: 50,
            gridOffsetY: 50,
            gridOffsetXDefault: 0,
            gridOffsetYDefault: 0,
            mapOffsetX: mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0,          
            mapOffsetY: mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0,
            org_booth_cnt: 0,
            org_booth_sqrt: 0 ,
            MIGRATE:""         
        }
        var mapKEY_ID = uuidv4();
        var postData = [{
            DISPLAY_ORDER: 0,
            STATUS: "",
            TITLE: props.main.saveFileName,
            MAPJS: JSON.stringify(MAPJS),
            KEY_ID: mapKEY_ID,
            MIGRATE:""
        }]
        var formdata = {
            query:`${ESHOW_URL()}?method=putMaps&token=${getTokenCookie()}`,
            postData: postData,
            returnResponse: true
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {
                'body': '[{"company:","test"}]', 
                'Content-Type': 'application/json'
              }
        }
        // console.log("data", data)
        var url = `${ESHOW_URL()}?method=putMaps&token=${getTokenCookie()}`
        var response = await axiosInstance.post(url, JSON.stringify(postData), options)
        if(response.status == 200){
            var eshowResponse = response.data
            if(eshowResponse.SUCCESS == true){

                var settingData = {
                    CANVAS:{
                        MAP_KEY: mapKEY_ID,
                        SIZE_X:mainCanvas.mapWidth,
                        SIZE_Y:mainCanvas.mapHeight,
                        GRID_SIZE:mainCanvas.gridSize,
                        STROKE_WIDTH:mainCanvas.strokeWidth,
                        GRID_ON:true,
                        GRID_SNAP:true,
                        GRID_ANGLE:0,
                    },
                    MAP_OFFSET:{
                        MAP_KEY:mapKEY_ID,
                        OFFSET_X:0,
                        OFFSET_Y:0,
                    },
                    GENERAL:{
                        MAP_KEY:mapKEY_ID,
                        UNIT:mainCanvas.mapUnit,
                        CONVERT_UNIT:mainCanvas.convertUnit,
                        HOME_LINK:'',
                        BRAND_LOGO:''
                    }
                }
                var postData = {
                    SUCCESS: true,
                    ...settingData
                }
                var formdata = {
                    query:`${ESHOW_URL()}?method=putSetup&map_key=${mapKEY_ID}&token=${getTokenCookie()}`,
                    postData: postData,
                    returnResponse: true
                }
                var data = qs.stringify({
                    request: JSON.stringify(formdata)
                });
                const options = {
                    headers: {
                        'body': '[{"company:","test"}]', 
                        'Content-Type': 'application/json'
                      }
                }
                var url = `${ESHOW_URL()}?method=putSetup&map_key=${mapKEY_ID}&token=${getTokenCookie()}`
                var response = await axiosInstance.post(url, JSON.stringify(postData), options)
                if(response.status == 200){
                    var eshowResponse = response.data
                    if(eshowResponse.SUCCESS == true){
                        props.setFirstMapContent(props.main.mainCanvas.toDatalessObject())
                        props.setFirstBgContent(props.main.bgImgCanvas.toDatalessObject())
                        fetchmaplist(mapKEY_ID)
                        props.setLoading(false)
                    }
                    else{                    
                        props.setOpenSnack(true);
                        props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Something went wrong");
                        props.setLoading(false)
                    }
                }
            }
            else{                    
                props.setOpenSnack(true);
                props.setSnackMsg(eshowResponse.MESSAGE?eshowResponse.MESSAGE:"Something went wrong");
                props.setLoading(false)
            }
        }  
    }


    const fetchmaplist=async (KEY_ID=false)=>{ 
        var url  = `${ESHOW_URL()}?method=getMaps&token=${getTokenCookie()}`
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getMaps&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        axiosInstance.get(url).then(res=>{
            // console.log(res.data)
            if(!res.data || (res.data && res.data.length == 0)){
                props.setLoading(false);
            }
            var tmp = [...res.data];
            var lastFile = null
            if(tmp.length > 0){
                tmp.sort(function (a, b) {
                    let fa = (a.DISPLAY_ORDER?a.DISPLAY_ORDER:0)*1,
                    fb = (b.DISPLAY_ORDER?b.DISPLAY_ORDER:0)*1
                
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    // fa = (a.UPDATED?a.updatedAt:0)
                    // fb = (b.updatedAt?b.updatedAt:0)
                    // if (fa > fb) {
                    //   return -1;
                    // }
                    // if (fa < fb) {
                    //     return 1;
                    // }
                    return 0;
                  });
                lastFile = tmp[0]
            }

            var lastFileSaved  = getCookie('last_map')            
            var flag = false;
            
            for(var i = 0; i <tmp.length; i++){
                var mapJS = tmp[i]['MAPJS']
                if(!mapJS || mapJS == ""){
                    tmp[i]['MAPJS'] = JSON.stringify({convertRateX: 1})
                }
            }

            for(var i = 0; i <tmp.length; i++){
                var mapJS = tmp[i].MAPJS;
                try {
                    mapJS = mapJS.replaceAll('\r\n', ' ')
                    mapJS = JSON.parse(mapJS)
                    if((""+tmp[i]['KEY_ID']).toLowerCase() == (""+KEY_ID).toLowerCase() && KEY_ID){
                        tmp[i] = {...tmp[i], ...mapJS, active:true};
                        flag = 1
                    }
                    else{
                        tmp[i] = {...tmp[i], ...mapJS, active:false};
                    }
                }
                catch(e){
                    continue;
                }
            }
            if(!flag){
                for(var i = 0; i <tmp.length; i++){
                    var mapJS = tmp[i].MAPJS;
                    try {
                        mapJS = mapJS.replaceAll('\r\n', ' ')
                        mapJS = JSON.parse(mapJS)
                        if((""+tmp[i]['KEY_ID']).toLowerCase() == (""+lastFileSaved).toLowerCase() && lastFileSaved){
                            tmp[i] = {...tmp[i], ...mapJS, active:true};
                            flag = 1
                        }
                        else{
                            tmp[i] = {...tmp[i], ...mapJS, active:false};
                        }
                    }
                    catch(e){
                        continue;
                    }
                }
            }
            if(!flag){
                for(var i = 0; i <tmp.length; i++){
                    var mapJS = tmp[i].MAPJS;
                    try {
                        mapJS = mapJS.replaceAll('\r\n', ' ')
                        mapJS = JSON.parse(mapJS)
                        {
                            if((""+tmp[i]['KEY_ID']).toLowerCase() == (""+lastFile['KEY_ID']).toLowerCase()){
                                tmp[i] = {...tmp[i], ...mapJS, active:true};
                            }
                            else{
                                tmp[i] = {...tmp[i], ...mapJS, active:false};
                            }
                        }
                    }
                    catch(e){
                        continue;
                    }
                }
            }


            props.setBoothFileList(tmp)
        }).catch(error => {
            props.setLoading(false)
        })       

    }

    useEffect(async () => {     
        if(mainCanvas && mainSvg && mainLayers && mainLayers.length > 0)  {  
            console.log("mainLayers", mainLayers)
            var parseLayers = [];
            for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
                if(mainLayers[layerInd].number_selected || mainLayers[layerInd].outline_selected || mainLayers[layerInd].venue_selected){
                    parseLayers.push(mainLayers[layerInd].name);
                }
            }
            
            var formdata = 
            {
                // "action":"svg_creation",
                "action":"data_extraction",
                "converter":"AutoXchange AX2020",
                "version":"V1.00",
                "contentType":"file",
                "contentLocation":`${view_file?.dwgPath}`,
                "contentFormat":"DWG",
                "contentUsername":"",
                "contentPassword":"",
                "userLabel":"fromCADViewerJS",
                "contentResponse":"stream",
                "leaveStreamOnServer":0,
                "parameters":[
                    {"paramName":"json",
                    "paramValue":""},
                    {"paramName":"entities",
                    "paramValue":""},
                    {"paramName":"prec",
                    "paramValue":"15"},
                    // {"paramName":"lon",
                    // "paramValue":parseLayers.join(";")},
                    // {"paramName":"explodeMText",
                    // "paramValue":""},
                    // {"paramName":"prec",
                    // "paramValue":"15"},
                ],
                parseLayers:parseLayers
            }
            var data = qs.stringify({
                request: JSON.stringify(formdata)
              });
            const options = {
                headers: {"content-type": 'application/x-www-form-urlencoded'}
            }
            var result = false
            var response = false;
            try{
                response = await axiosInstance.post('/callapiconversion', data, options);
                var parseEntities = [];
                if(response.status == 200){
                    var convertedSvgInfo = response.data
                    var formdata = 
                    {
                        parseLayers:parseLayers
                    }
                    var data = qs.stringify({
                        request: JSON.stringify(formdata)
                    });
                    var response = await axiosInstance.post(convertedSvgInfo.contentStreamData, data, { headers: {  } })
                    if(response.status == 200){
                        parseEntities = response.data.parseEntities
                        linklistBlocks = response.data.linklistBlocks
                        // console.log("linklistData", linklistData)
                        // var blocks  = linklistData?.Drawing?.Blocks;
                        // for(var blockInd = 0; blockInd< blocks?.length; blockInd++){
                        //     var entities = blocks[blockInd]?.Entities;
                        //     for(var entityInd = 0; entityInd < entities?.length; entityInd++){
                        //         var entity = entities[entityInd];
                        //         if(entity.Layer && entity.Layer !='' && parseLayers.includes(entity.Layer)){
                        //             parseEntities.push(entity);
                        //         }
                        //     }
                        // }
                    }

                }
                else{
                    props.setLoading(false) ;
                    props.setOpenSnack(true);
                    props.setSnackMsg("Failed to convert the dwg.");
                    return;
                }
            }
            catch(e){                
                props.setLoading(false) ;
                props.setOpenSnack(true);
                props.setSnackMsg("Failed to convert the dwg.");
                return;
            }
            // console.log("parseEntities", parseEntities);
            // console.log("linklistBlocks", linklistBlocks);

            var mapUnit = mainCanvas.mapUnit
            var group_objects = mainCanvas.getObjects('group');
            for(var j = 0; j < group_objects.length; j++){
                var group_object = group_objects[j]
                mainCanvas.remove(group_object);
            }    


            mainCanvas?.clear();  
            bgCanvas?.clear();  g_grid?.clear() ; bgImgCanvas?.clear()   
            
            const active_booth_file = props.main.active_booth_file
            
            mainCanvas.setBackgroundColor('transparent', mainCanvas.renderAll.bind(mainCanvas))
            if(bgCanvas)
                bgCanvas.setBackgroundColor('transparent', bgCanvas.renderAll.bind(bgCanvas))
            mainCanvas.viewportTransform[0] = 1;
            mainCanvas.viewportTransform[3] = 1;
            mainCanvas.viewportTransform[4] = 0;
            mainCanvas.viewportTransform[5] = 0;
            if(bgCanvas){
                bgCanvas.viewportTransform[0] = 1;
                bgCanvas.viewportTransform[3] = 1;
                bgCanvas.viewportTransform[4] = 0;
                bgCanvas.viewportTransform[5] = 0;
            }


            
            bgImgCanvas.viewportTransform[0] = 1;
            bgImgCanvas.viewportTransform[3] = 1;
            bgImgCanvas.viewportTransform[4] = 0;
            bgImgCanvas.viewportTransform[5] = 0;

            topRuler.viewportTransform[0] = 1;
            topRuler.viewportTransform[3] = 1;
            topRuler.viewportTransform[4] = 0;
            topRuler.viewportTransform[5] = 0;

            leftRuler.viewportTransform[0] = 1;
            leftRuler.viewportTransform[3] = 1;
            leftRuler.viewportTransform[4] = 0;
            leftRuler.viewportTransform[5] = 0;
            
            if(1){                
                mainCanvas.mapUnit = mapUnit
                // console.log("mainSvg", mainSvg)
                // console.log("parseEntities", parseEntities)
                await renderEntities(mainSvg, parseEntities);    
                // await parseSvgPathChilds(mainSvg); 
            }

            
        }
    }, [mainLayers])
    
    
    useEffect(() => {  
        if(mainCanvas)  {       
            setCanvasEvent()
        }
    }, [mainCanvas])

    useEffect(() => {  
       g_panFlag = props.ui.panFlag
    }, [props.ui.panFlag])

    
    useEffect(() => {  
        fetchCartList()
     }, [active_booth_file])

    useEffect(() => {  
        if(mainCanvas && props.main.canvasRefresh)  {
            setCanvasEvent()
            props.setCanvasRefresh(false)
        }
    }, [props.main.canvasRefresh])


    const onContextMenu=(event, mainCanvas, mainprops)=> { 
        if(mainCanvas && g_lockFlag) return;
        var pointer = mainCanvas.getPointer(event);//get translation (transform) position reference
        var posX = pointer.x;
        var posY = pointer.y;

        if(!props.main.menuControl.open){
            if (mainCanvas.getActiveObject() && mainCanvas.getActiveObject().type == 'activeSelection') {
                mainCanvas.setActiveObject(mainCanvas.getActiveObject());
                //Display menu
                props.setMenuControl({open:true, left: event.clientX+5, top:event.clientY+5, offsetX:posX, offsetY:posY, target:mainCanvas.getActiveObject()})
                ////console.log("right1")
            }
            else{
                var objects = mainCanvas.getObjects();
                var found = false;
                for (var i = objects.length-1; i >= 0; i--) {
                    var object = objects[i];
                    //Determine whether the object is at the mouse click
                    if (object.containsPoint(new fabric.Point(event.offsetX, event.offsetY))) {
                        //Select the object
                        if(object.lockforever) continue;
                        if(object.get('layer') == 'original') continue;
                        if(object.get('layer') == 'venue') continue;
                        if(object.get('layer') == 'annotation') {
                            if(mainCanvas.drawMode == "general")
                                continue;
                        }
                        if(!object.get('visible')) continue;
                        if((object.get('class') == 'booth' || object.get('class') == 'booth-group' || object.get('groupped'))){
                            if(gLayerSelect['booth_outline'] != true){
                                continue;
                            }
                        }
                        


                        {
                            mainCanvas.setActiveObject(object);
                            ////console.log("right", object)
                            //Display menu
                            found = true
                            props.setMenuControl({open:true, left: event.clientX+5, top:event.clientY+5, offsetX:posX, offsetY:posY,  target:object})
                        }
                        break;
                    }
                }
                // if(!found){
                //     props.setMenuControl({open:true, left: event.clientX+5, top:event.clientY+5, offsetX:posX, offsetY:posY, target:null})
                // }
            }
        }
    
    //Block the system right-click menu
        event.preventDefault();
        return false;

        // if(opt.target && (opt.target.get('class') == 'booth' || opt.target.get('class') == 'booth-group')){
        //     props.setMenuControl({open:true, left: opt.e.clientX+15, top:opt.e.clientY+15})
        // }
    }

    const onMouseDown = (opt, that)=>{        
        var evt = opt.e;
        if(opt.target){
            g_coordinate = renderCoordinate(mainCanvas, opt.target.left, opt.target.top, opt.target.dwg_left, opt.target.dwg_top )
        }
        if(opt.target && (opt.target.get('class') == 'booth' || opt.target.get('class') == 'booth-group') && opt.target.get('sold') == '1'){
            // mouseModalRef.current.style.display = 'none'
            mouseModalRef.current.classList.remove("active");
            setMouseOverObject(false)
        }
        if(opt.target){
            if (g_panFlag || g_lockFlag) {
                if(g_panFlag)
                    g_isDragging = true;
                that.selection = false;
                that.lastPosX = evt.clientX;
                that.lastPosY = evt.clientY;
                if(!g_lockFlag){
                    if(opt.target){
                        clickedObj = opt.target;
                        opt.target.set({
                            selection:false,
                            hasControls:false,
                            hasBorders:false,
                            evented:false,
                            evented:false,
                            lockMovementX: true,
                            lockMovementY: true,
                            lockRotation: true,
                            lockScalingX: true,
                            lockScalingY: true,
                        })
                    }
                }
            }
            else{
                return;
            }
        }
        else{
            // if (evt.ctrlKey === true || g_panFlag || g_lockFlag) {
            if (evt.ctrlKey !== true) {
                g_isDragging = true;
                that.selection = false;
                that.lastPosX = evt.clientX;
                that.lastPosY = evt.clientY;
                if(!g_lockFlag){
                    if(opt.target){
                        clickedObj = opt.target;
                        opt.target.set({
                            selection:false,
                            hasControls:false,
                            hasBorders:false,
                            evented:false,
                            evented:false,
                            lockMovementX: true,
                            lockMovementY: true,
                            lockRotation: true,
                            lockScalingX: true,
                            lockScalingY: true,
                        })
                    }
                }
            }
        }

    }

    

    function searchTree(element, KEY_ID){
        if(element.KEY_ID == KEY_ID){
             return element;
        }else if (element.SUBCAT != null){
             var i;
             var result = null;
             for(i=0; result == null && i < element.SUBCAT.length; i++){
                  result = searchTree(element.SUBCAT[i], KEY_ID);
             }
             return result;
        }
        return null;
    }

   
    const debounce = (func, wait)=>{
        return function (...args) {
          clearTimeout(timeoutWheel);
          timeoutWheel = setTimeout(() => func.apply(this, args), wait);
        };
    }
      
    const onWheelEnd = debounce(() => {
        var zoom = mainCanvas.getZoom()
        console.log("onWheelEnd", zoom)
        if(zoom < 50)
        {
            scaleTextsCenterFocus(mainCanvas, zoom)
            mainCanvas.renderAll()
        }

        // Perform any expensive operations or UI updates here
      }, 300);

    const setCanvasEvent = ()=>{

        mainCanvas.isDrawingMode = false;
        mainCanvas.selection = true;
        mainCanvas.forEachObject((object) => {
            var layer = object.get('layer'); 
            if((layer == 'annotation') && mainCanvas.drawMode=="general" || layer == 'venue'){ 
                return true;
            }
            if(layer != "original" && object.get('class') != 'line' && object.get('lockforever') != true){                
                object.selectable = object.evented = true;
            }
        });

        mainCanvas.off('object:moving');
        mainCanvas.on('object:moving', onObjectMoving);

        mainCanvas.off('mouse:down');
        mainCanvas.on('mouse:down', function(opt) {
            // return
            onMouseDown(opt, this)            
        });

        mainCanvas.off('mouse:up');
        mainCanvas.on('mouse:up', function(opt) {
            var vpt = this.viewportTransform;
            var position = fabric.util.qrDecompose(vpt);
            g_mouseUpFlag = true
            var that  = this
            if(g_coordinate){
                removeCoordinate(mainCanvas, g_coordinate)
            }
            
            this.setViewportTransform(this.viewportTransform);
            if(g_isDragging){
                g_isDragging = false;
                this.selection = true;
                return;
            }
            if(g_objectMoving){
                g_objectMoving = false
                return;
            }

            setTimeout(()=>{
                if(g_mouseUpFlag){
                    // on mouse up we want to recalculate new interaction
                    // for all objects, so we call setViewportTransform
                    if(opt.target && (opt.target.get('class') == 'booth' || opt.target.get('class') == 'booth-group' || opt.target.get('layer') == 'sponsorships')){                            
                        var priced = false
                        if(opt.target.get('sold')){
                            priced = true
                            let exhibitors = opt.target.exhibitors;
                            if(exhibitors){
                                // let LIST_PENDING_EXHIBITORS = active_booth_file.LIST_PENDING_EXHIBITORS
                                // let LIST_TBD_EXHIBITORS = active_booth_file.LIST_TBD_EXHIBITORS 
                                // if((!LIST_PENDING_EXHIBITORS && exhibitors[0]?.STATUS == 2) || (!LIST_TBD_EXHIBITORS && opt.target.booth_number == 'TBD')){
                                //     return;
                                // }
                            }
                        }
                        
                        if(opt.target.get('layer') == 'sponsorships'){
                            props.setOpenUnassignedProfile(false)
                            props.setOpenCompanyProfile(false)
                            props.setOpenSponsorshipProfile({booth:opt.target, open:true})
                        }
                        else{
                            if(priced){
                                props.setOpenUnassignedProfile(false)
                                props.setOpenCompanyProfile({booth:opt.target, open:true})
                            }
                            else{
                                props.setOpenCompanyProfile(false)
                                props.setOpenUnassignedProfile({booth:opt.target, open:true})
                            }
                        }
                    }
                    if(clickedObj){
                        setTimeout(function(){
                            clickedObj.set({
                                selection:true,
                                hasControls:true,
                                hasBorders:true,
                                evented:true,
                                lockMovementX: false,
                                lockMovementY: false,
                                lockRotation: false,
                                // lockScalingX: false,
                                // lockScalingY: false,
                            })
                            clickedObj = null
                        }, 200)
                    }
                }
            }, 300)

        });
        

        mainCanvas.off('mouse:dblclick')
        mainCanvas.on('mouse:dblclick', function(opt) {
            if(g_lockFlag) return;
            g_mouseUpFlag = false;
            if(mainCanvas.getActiveObject() && mainCanvas.getActiveObject().type =="activeSelection"){
                if(g_lockFlag) return;
                var objects = mainCanvas.getActiveObject().getObjects();
                var boothFlag = false;
                var tableFlag = false;
                if(objects.length > 0){
                    boothFlag = 1; tableFlag = 1;
                }
                for(var i = 0; i < objects.length; i++){
                    if(objects[i].class != "booth"){
                        boothFlag = 0; 
                    }
                    if(objects[i].class != "booth"){
                        tableFlag = 0;
                    }
                }
                if(boothFlag){
                    props.setOpenSetBoothModal(true)
                }
                else if(tableFlag){
                    props.setOpenSetTableModal(true)
                }
            }
            else{
                if(opt.target){
                    
                        if((opt.target.get('class') == 'booth' || opt.target.get('class') == 'booth-group')){                            
                            var id = opt.target.id;
                            props.setOpenSetBoothModal(true)
                        }                
                        else if((opt.target.get('layer') == 'annotation')){  
                            
                            if((opt.target.get('class') == 'symbol')){  
                                StartSetSymbolModal(opt.target)
                            }
                            else{
                                StartSetDrawModal(opt.target)
                            }
                        }    
                        else if((opt.target.get('layer') == 'sponsorships')){  
                            StartSetSponsorshipsModal(opt.target)
                        }
                        else if((opt.target.get('class') == 'table')){     
                            props.setOpenSetTableModal(true)
                        }
                        else if((opt.target.get('class') == 'table')){     
                            props.setOpenSetTableModal(true)
                        }            
                        // if(opt.target && (opt.target.get('class') == 'aisle')){                            
                        //     var id = opt.target.id;
                        //     ////console.log("clicked id", id);
                        //     StartSetAisleModal(opt.target)
                        // }
                }
            }
        });  
        
        mainCanvas.off('object:modified')            
        mainCanvas.on('object:modified', function(opt) {
            // if(opt.e.ctrlKey !== true)
            {
                opt.target.modified = true
                var boothNames = "";
                var logs = [];
                if(opt.target.type === 'activeSelection'){
                    var objects = opt.target.getObjects();
                    for(var i = 0; i < objects.length; i++){
                        var object = objects[i];
                        object.modified = true
                        if(object.class == "booth"){
                            if(boothNames != "")
                                boothNames +=", ";   
                            boothNames +=object.booth_number;  
                            logs.push({
                                BOOTH_KEY:object.KEY_ID,
                                action: 'move',
                                date: new Date().toLocaleString(),
                                description: "moved booth: "+object.booth_number,
                                user: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
                            })                       
                        }
                    }
                }
                props.setBoothHistory({content: (props.main.mainCanvas.toDatalessObject()), action:{type:'modified', transform:opt.transform, target:opt.target}})
                if(opt.target.class == "booth"){
                    boothNames = opt.target.booth_number
                    logs.push({
                        BOOTH_KEY:opt.target.KEY_ID,
                        action: 'move',
                        date: new Date().toLocaleString(),
                        description: "moved booth: "+opt.target.booth_number,
                        user: (props.main.authInfo?.user_name?props.main.authInfo?.user_name:"Admin")
                    })   
                }
                if(logs.length){
                    props.addLogs(logs)
                }
            }
        });  

        mainCanvas.off('mouse:wheel')   
        mainCanvas.on('mouse:wheel', function(opt) {
            var pointer = mainCanvas.getPointer(opt.e);//get translation (transform) position reference
            var posX = pointer.x;
            var posY = pointer.y;
            var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
            var mapWidth = Math.abs((mainCanvas.endX?mainCanvas.endX:0) - (mainCanvas.startX?mainCanvas.startX:0));
            var mapHeight = Math.abs((mainCanvas.endY?mainCanvas.endY:0) - (mainCanvas.startY?mainCanvas.startY:0));
            var insideFlag = false;
            if(posX >= 0 && posX <=mapWidth && posY >= 0 && posY <=mapHeight){
                insideFlag = true
            }
            if(!insideFlag){
                return
            }
            
            if(g_zoomTimeout){
                clearTimeout(g_zoomTimeout);
                g_zoomTimeout = false
            }
            var vpt = this.viewportTransform;
            // console.log("vpt1", vpt)
            var delta = opt.e.deltaY;
            var zoom = mainCanvas.getZoom();
            zoom *= 0.999 ** delta;
            var dir = opt.e.wheelDelta;
            if (zoom > 1000) {
                zoom = 1000;
            }
            else{
                if(dir < 0){
                    var mapWidth = window.innerWidth;
                    var mapHeight = window.innerHeight;
                    if(mainCanvas.endX){
                        var convertRateX= Math.abs(mainCanvas.convertRateX)
                        mapWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
                        mapHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);
                        if(mainCanvas.mapWidth && mainCanvas.mapWidth*1 && mainCanvas.mapHeight && mainCanvas.mapHeight*1){
                            mapWidth = unit2Px(mainCanvas.mapWidth?mainCanvas.mapWidth:0, convertRateX, mainCanvas.mapUnit)
                            mapHeight = unit2Px(mainCanvas.mapHeight?mainCanvas.mapHeight:0, convertRateX, mainCanvas.mapUnit)
                        }
                    }
                    
                    var zoomX = (window.innerWidth- 50 - 0)/(mapWidth)
                    var zoomY = (window.innerHeight - 50 - 51)/(mapHeight)
                    var minZoom = Math.min(zoomX, zoomY)*0.5

                    if (zoom < minZoom) {
                        zoom = minZoom;
                        return;
                    }
                }
                // if (dir > 0){
                //     zoom = zoom * 1.05;		    
                
                // } else {
                //     zoom = zoom * .95;
                // }

            }
            mainCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            bgCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            bgImgCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
            opt.e.preventDefault();
            opt.e.stopPropagation();
            // return;
            var objects = mainCanvas.getObjects();
            var convertRateX= 1
            if(mainCanvas.convertRateX) {
                var convertRateX= Math.abs(mainCanvas.convertRateX)
            }
            // if(zoom < 50)
            // {
            //     scaleTextsCenterFocus(mainCanvas, zoom)
            // }
            
            // console.log("mouse:wheel")
            onWheelEnd();
            redrawRulers(this, topRuler, leftRuler);
            // redrawGrid(g_grid, mainCanvas, (mainCanvas.showGrid &&mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
            // this.renderAll()
        });

        mainCanvas.off('mouse:move')    
        mainCanvas.on('mouse:move', function(opt) {
            var evt = opt.e;
            if (g_isDragging) {
              var e = opt.e;
              var vpt = this.viewportTransform;
              var deltaX = e.clientX - this.lastPosX;
              var deltaY = e.clientY - this.lastPosY;
              vpt[4] += deltaX;
              vpt[5] += deltaY;
              this.requestRenderAll();

              bgCanvas.setViewportTransform(vpt);
              bgImgCanvas.setViewportTransform(vpt);
                // var delta = new fabric.Point(deltaX, 0);
                // topRuler.relativePan(delta);
                // delta = new fabric.Point(0, deltaY);
                // leftRuler.relativePan(delta);
                redrawRulers(this, topRuler, leftRuler)
                // redrawGrid(g_grid, mainCanvas, (mainCanvas.showGrid &&mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
                // var position = fabric.util.qrDecompose(vpt);
                // ////console.log(opt)
                // var j = 0;
                // var mapWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
                // var mapHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);                
                // console.log("move position", position)
                // g_grid.update2({
                //     x: -((position.translateX)/position.scaleX+50),
                //     y: -((position.translateY)/position.scaleX+50),
                //     zoom: position.scaleX,
                //     mapWidth: mapWidth*position.scaleX,
                //     mapHeight: mapHeight*position.scaleX,              
                //     align_offset_x: mainCanvas.gridOffsetX*position.scaleX,             
                //     align_offset_y: mainCanvas.gridOffsetY*position.scaleX,
                // });
                // g_grid.render()
              this.lastPosX = e.clientX;
              this.lastPosY = e.clientY;
            }
            
        });

        mainCanvas.off('mouse:over')    
        mainCanvas.on('mouse:over', async function(opt) {       
            if(!mouseHoverEnable) return;
            setMouseOverObject(false)
            if(g_mouseOverTimeout){
                clearTimeout(g_mouseOverTimeout)
                console.log("mouse over start", g_mouseOverTimeout)
            }

            var x =  opt.e.clientX;
            var y =  opt.e.clientY;
            if(x>=  mouseModalRef.current.offsetLeft && x <= mouseModalRef.current.offsetLeft+mouseModalRef.current.offsetWidth &&
                y>=  mouseModalRef.current.offsetTop && y <= mouseModalRef.current.offsetTop+mouseModalRef.current.offsetHeight
            ){
                // console.log("inside")
            }
            else{
                // console.log("outside")
                mouseModalRef.current.classList.remove("active");
                setMouseOverObject(false)
                mouseModalRef.current.style.left = -1000
                mouseModalRef.current.style.top = -1000
            }


            var activeObject = opt.target
            // if(activeObject.booth_number == 945) return;
            if(!activeObject) return;
            console.log("activeObject",activeObject)
            if(!checkIfSelectable(activeObject) || activeObject.layer == 'venue') return;
            setMouseOverObject(activeObject)   
            mouseModalRef.current.style.minWidth='420px';
            mouseModalRef.current.style.width='max-content';
            if(activeObject && (activeObject.get('layer') == 'annotation' || activeObject.get('layer') == 'venue')){
                if(activeObject.description || activeObject.get('layer') == 'venue'){
                    var body = activeObject.description
                    // mouseModalRef.current.style.display = 'block'
                    // mouseModalRef.current.innerHTML = body;
                    if(opt.e.clientX + mouseModalRef.current.offsetWidth > window.innerWidth){
                        mouseModalRef.current.style.left = 'unset'
                        mouseModalRef.current.style.right = window.innerWidth - opt.e.clientX+0+'px'
                    }
                    else{
                        mouseModalRef.current.style.right = 'unset'
                        mouseModalRef.current.style.left = opt.e.clientX+0+'px'
                    }
                    if(opt.e.clientY + mouseModalRef.current.offsetHeight > window.innerHeight){
                        mouseModalRef.current.style.top = 'unset'
                        mouseModalRef.current.style.bottom = window.innerHeight - opt.e.clientY+0+'px'
                    }
                    else{
                        mouseModalRef.current.style.bottom = 'unset'
                        mouseModalRef.current.style.top = opt.e.clientY+0+'px'
                    }
                    mouseModalRef.current.classList.add("active");
                }
                setMouseOverObject(activeObject)   
                // setLogoLoaded(false)              
                setMouseOverExibitorInfo(false)
                setMouseOverBoothInfo(false)
                setMouseOverExibitorKeyList([])
            }            
            if(activeObject && (activeObject.get('class') == 'booth') || activeObject.get('layer') == 'sponsorships'){
                
                var pointer = mainCanvas.getPointer(opt.e);
                var objectClientX = opt.e.clientX - (pointer.x - activeObject.left - activeObject.width/2 - activeObject.width*0.1 - unit2Px(1, mainCanvas.convertRateX))*mainCanvas.getZoom()
                var objectClientY = opt.e.clientY - (pointer.y - activeObject.top - activeObject.height/2 - activeObject.height*0.1 - unit2Px(1, mainCanvas.convertRateX))*mainCanvas.getZoom()
                // console.log("pointer", objectClientX, objectClientY)
                activeObject.orgBg = getBGColor(activeObject);
                activeObject.hovered = true;
                setObjectBG(mainCanvas, activeObject, '#65AE2D')
                mainCanvas.renderAll()
                // mouseModalRef.current.style.left = objectClientX+'px'
                // mouseModalRef.current.style.top = objectClientY+'px'

               

                var booth_number = (activeObject.booth_number)

                if(axiosController){
                    axiosController.abort()
                    axiosController =false
                }
                axiosController = new AbortController();
                setMouseOverObject(activeObject)
                g_mouseEvent = opt
                var exhibitors = activeObject?.exhibitors
                var company_KEY_ID = false
                var holdBooth = activeObject.holdBooth
                var pending = false
                if(exhibitors && exhibitors.length > 0){
                    company_KEY_ID = exhibitors[0].EXHIBITOR_KEY
                    pending = exhibitors[0].STATUS == '2'
                }
                if(activeObject.get('layer') == 'sponsorships'){
                    if(activeObject.sponsorshipId){
                        setMouseOverExibitorInfo(false)
                        setMouseOverBoothInfo(false)
                        setMouseOverExibitorKeyList([])
                        mouseModalRef.current.classList.add("active");
                    }
                }
                else{
                    if(company_KEY_ID && !holdBooth && !pending && 0){
                        mouseModalRef.current.style.width='420px';
                        getMouseOverExhibitor(company_KEY_ID, true, activeObject)
                        setMouseOverBoothInfo(false)
                    }
                    else{   
                        mouseModalRef.current.style.minWidth='0px';
                        getMouseOverBooth(booth_number, company_KEY_ID)                 
                        setMouseOverExibitorInfo(false)
                        setMouseOverExibitorKeyList([])
                    }
                }
                if(opt.e.clientX + mouseModalRef.current.offsetWidth/2 > window.innerWidth){
                    mouseModalRef.current.style.left = 'unset'
                    mouseModalRef.current.style.right = window.innerWidth-opt.e.clientX+'px'
                    // mouseModalRef.current.style.right = 5+'px'
                }
                else if(opt.e.clientX - mouseModalRef.current.offsetWidth/2 <0){
                    mouseModalRef.current.style.right = 'unset'
                    mouseModalRef.current.style.left = 5+'px'
                }
                else{
                    mouseModalRef.current.style.right = 'unset'
                    if(company_KEY_ID && !holdBooth && !pending && 0){
                        mouseModalRef.current.style.left = opt.e.clientX- mouseModalRef.current.offsetWidth/2+'px'
                    }
                    else{
                        mouseModalRef.current.style.left = opt.e.clientX + 20 +'px'
                    }
                }
                if(opt.e.clientY > window.innerHeight){
                // if(opt.e.clientY + mouseModalRef.current.offsetHeight > window.innerHeight){
                    mouseModalRef.current.style.top = 'unset'
                    // mouseModalRef.current.style.bottom = window.innerHeight-opt.e.clientY+'px'
                    mouseModalRef.current.style.bottom = 5+'px'
                }
                else{
                    mouseModalRef.current.style.bottom = 'unset'
                    if(company_KEY_ID && !holdBooth && !pending &&0){
                        mouseModalRef.current.style.top = opt.e.clientY - (pointer.y - activeObject.top - activeObject.height/2 - activeObject.height*0.1 - unit2Px(1, mainCanvas.convertRateX))*mainCanvas.getZoom()+'px'
                    }
                    else{
                        mouseModalRef.current.style.top = opt.e.clientY + 20 +'px'
                    }
                    // mouseModalRef.current.style.top = opt.e.clientY+'px'
                }
                mainCanvas.renderAll();
            }
        });

        mainCanvas.off('mouse:out')    
        mainCanvas.on('mouse:out', function(opt) {
            var x =  opt.e.clientX;
            var y =  opt.e.clientY;
            
            if(opt.target && (opt.target.get('class') == 'booth' || opt.target.get('layer') == 'sponsorships')){
                if(opt.target.hovered){        
                    setObjectBG(mainCanvas, opt.target, opt.target.orgBg)
                    opt.target.hovered = false
                    mainCanvas.renderAll();
                }
            }
            if(opt.target && (opt.target.get('class') == 'booth' || opt.target.layer == 'annotation' || opt.target.layer == 'sponsorships')){
                // g_mouseOutTimeout = setTimeout(function(){
                //     g_mouseOutTimeout = null
                //     console.log("mouse:out")
                    if(opt.target && (opt.target.get('class') == 'booth' || opt.target.layer == 'sponsorships')){
                        if((x+1)>=  mouseModalRef.current.offsetLeft && x <= mouseModalRef.current.offsetLeft+mouseModalRef.current.offsetWidth &&
                            (y+1)>=  mouseModalRef.current.offsetTop && y <= mouseModalRef.current.offsetTop+mouseModalRef.current.offsetHeight
                        ){
        
                            // console.log("mouse:out2")
                            return;
                        }
                        else{
                            // console.log("mouse:out3",x, y,mouseModalRef.current.offsetLeft, mouseModalRef.current.offsetTop)
                        }
                    }
                    // if(opt.target && (opt.target.get('class') == 'booth' || opt.target.get('class') == 'booth-group')){
                        
                        // mouseModalRef.current.style.display = 'none'
                        mouseModalRef.current.classList.remove("active");
                        setMouseOverObject(false)
                        if(g_mouseOverTimeout){
                            clearTimeout(g_mouseOverTimeout)
                            console.log("mouse out", g_mouseOverTimeout)
                        }
                        g_mouseOverTimeout =false
                        
                        mouseModalRef.current.style.left = -1000
                        mouseModalRef.current.style.top = -1000
                        // if(this.mouseOutTimeoutId){
                        //     clearTimeout(this.mouseOutTimeoutId)
                        // }
                        // this.mouseOutTimeoutId = setTimeout(()=>{
                        //     mouseModalRef.current.style.display = 'none'
                        // },300)
                    // }
                // }, 1000)
            }
            else{
                mouseModalRef.current.classList.remove("active");
                setMouseOverObject(false)
                if(g_mouseOverTimeout){
                    clearTimeout(g_mouseOverTimeout)
                    console.log("mouse out", g_mouseOverTimeout)
                }
                g_mouseOverTimeout =false
                mouseModalRef.current.style.left = -1000
                mouseModalRef.current.style.top = -1000
            }
            
        });
        if(props.ui.panFlag){
            mainCanvas.defaultCursor = 'grab';
            mainCanvas.hoverCursor= 'grab';
            mainCanvas.renderAll();
        }
    }    

    const getExhibitor = async (KEY_ID)=>{
        var result = null
        // KEY_ID = '078ABFD7-38EA-4CED-BC74-5E3C6CB6BEE8'
        var url = `${ESHOW_URL()}?method=getExhibitor&exhibitor_key=${KEY_ID}&token=${getTokenCookie()}`
        var formdata = 
        {  
            query:`${ESHOW_URL()}?method=getExhibitor&exhibitor_key=${KEY_ID}&token=${getTokenCookie()}`                
        }
        var data = qs.stringify({
            request: JSON.stringify(formdata)
        });
        const options = {
            headers: {"content-type": 'application/x-www-form-urlencoded'}
        }
        const response = await axiosInstance.get(url);
        if(response.status == 200){
          result = response.data?.EXHIBITOR;
        }
        return result
    
    }
    const getMouseOverExhibitor = async (KEY_ID, mainFlag = true, activeObject)=>{
        
        setMouseOverExibitorInfo(false)
        if(mainFlag){
            setMouseOverExibitorKeyList([])
        }
        var result = null
        // KEY_ID = '078ABFD7-38EA-4CED-BC74-5E3C6CB6BEE8'
        var url = `${ESHOW_URL()}?method=getExhibitor&exhibitor_key=${KEY_ID}&token=${getTokenCookie()}&booth_key=${activeObject?.KEY_ID}`
        

        axiosInstance.get(url,{signal: axiosController.signal}).then(res=>{
            result = res.data?.EXHIBITOR;
            if(mainFlag){
                var tmp = [];
                tmp.push(result.KEY_ID);
                for(var i = 0; i < result.SUBEXHIBITORS?.length; i++){
                    tmp.push(result.SUBEXHIBITORS[i].EXHIBITOR_KEY)
                    // tmp.push(result.SUBEXHIBITORS[i].EXHIBITOR_KEY)
                    // tmp.push(result.SUBEXHIBITORS[i].EXHIBITOR_KEY)
                }
                setMouseOverExibitorKeyList(tmp)
                if(g_mouseoverObject){
                    setTimeout(()=>{
                        if(mouseModalRef.current.style.top.replace("px","")*1 + mouseModalRef.current.offsetHeight> window.innerHeight){
                            mouseModalRef.current.style.top = 'unset'
                            // mouseModalRef.current.style.bottom = 5+'px'
                            var pointer = mainCanvas.getPointer(g_mouseEvent.e);
                            mouseModalRef.current.style.bottom = (window.innerHeight-g_mouseEvent.e.clientY + (pointer.y - g_mouseoverObject.top - g_mouseoverObject.height*0.1)*mainCanvas.getZoom())+'px'
                        }
                        mouseModalRef.current.classList.add("active");
                    }, 150)
                }
            }
            setMouseOverExibitorInfo(result)

        }).catch(error => {
            console.log("error", error)
        })  
    
    }

    const getMouseOverBooth = async (booth_number, exhibitor_key = null)=>{
        
        setMouseOverBoothInfo(false)
        var result = null
        // KEY_ID = '078ABFD7-38EA-4CED-BC74-5E3C6CB6BEE8'        
        var url = `${ESHOW_URL()}?method=getBoothProfile&map_key=${active_booth_file.KEY_ID}&booth_no=${booth_number}&token=${getTokenCookie()}&map_type=admin`
        
        if(exhibitor_key){
            url = `${url}&exhibitor_key=${exhibitor_key}`
        }

        axiosInstance.get(url,{signal: axiosController.signal}).then(res=>{
            result = res.data;
            setMouseOverBoothInfo(result)
            if(g_mouseoverObject){
                setTimeout(()=>{
                    var pointer = mainCanvas.getPointer(g_mouseEvent.e);
                    if(g_mouseEvent.e.clientX + mouseModalRef.current.offsetWidth> window.innerWidth){
                        mouseModalRef.current.style.left = 'unset'
                        // mouseModalRef.current.style.bottom = 5+'px'
                        // mouseModalRef.current.style.right = (window.innerWidth-g_mouseEvent.e.clientX + (pointer.x - g_mouseoverObject.left - g_mouseoverObject.width*0.1)*mainCanvas.getZoom())+'px'
                        mouseModalRef.current.style.right = window.innerWidth - g_mouseEvent.e.clientX + 20 +'px'
                    }
                    // else{
                    //     mouseModalRef.current.style.right = 'unset'
                    //     mouseModalRef.current.style.left = pointer.x+'px'
                    // }

                    if(g_mouseEvent.e.clientY + mouseModalRef.current.offsetHeight> window.innerHeight){
                        mouseModalRef.current.style.top = 'unset'
                        // mouseModalRef.current.style.bottom = 5+'px'
                        // mouseModalRef.current.style.bottom = (window.innerHeight-g_mouseEvent.e.clientY + (pointer.y - g_mouseoverObject.top - g_mouseoverObject.height*0.1)*mainCanvas.getZoom())+'px'
                        mouseModalRef.current.style.bottom = window.innerHeight - g_mouseEvent.e.clientY + 20 +'px'
                    }
                    // else{
                    //     mouseModalRef.current.style.bottom = 'unset'
                    //     mouseModalRef.current.style.top = pointer.y+'px'
                    // }
                    mouseModalRef.current.classList.add("active");
                }, 150)
            }
        }).catch(error => {
            console.log("error", error)
        })  
    
    }

    var newleft = 0
    var g_edgedetection = 20 //pixels to snap 20 inch

    function findNewPos(distX, distY, target, obj) {
        // See whether to focus on X or Y axis
        if(Math.abs(distX) > Math.abs(distY)) {
            if (distX > 0) {
                target.set({left:(obj.left - target.width)})
            } else {
                target.set({left:(obj.left + obj.width)})
            }
        } else {
            if (distY > 0) {
                target.set({left:(obj.top - target.height)})
            } else {
                target.set({left:(obj.top + obj.height)})
            }
        }
    }

    const onObjectMoving=(options)=>{
        var mainCanvas = props.main.mainCanvas
        var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
        var realMapWidth;
        var realMapHeight;
        var realGrid;
        var realOffsetX;
        var realOffsetY;
        if(mainCanvas.mapUnit == 'meter'){
            g_edgedetection = 0.5 //0.5m
            // if(mainCanvas.convertUnit=='mm'){
            //     g_edgedetection = g_edgedetection*1000
            // }
        }
        var edgedetection = g_edgedetection/Math.sqrt(convertRateX)

        var mapWidth = Math.abs(props.main.mainCanvas.endX - props.main.mainCanvas.startX);
        var mapHeight = Math.abs(props.main.mainCanvas.endY - props.main.mainCanvas.startY);
        if(convertRateX){
            realMapWidth = mapWidth
            realMapHeight = mapHeight
            realGrid = unit2Px(mainCanvas.gridSize, convertRateX, mainCanvas.mapUnit)
            // if(mainCanvas.convertUnit == 'mm'){
            //     realGrid = realGrid*1000
            // }
            realOffsetX = mainCanvas.gridOffsetX?((mainCanvas.gridOffsetX % realGrid )-realGrid):50
            realOffsetY = mainCanvas.gridOffsetY?((mainCanvas.gridOffsetY % realGrid )-realGrid):50
            realOffsetX+= unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
            realOffsetY+= unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
        }
       
        if(g_coordinate){
            moveCoordinate(mainCanvas, options.target.left,  options.target.top, g_coordinate, options.target.dwg_left,  options.target.dwg_top)
        }
        if(mainCanvas.snapToGrid){
            ////console.log('realOffsetX', realOffsetX)
            var angle = options.target.get('angle');
            if(!angle) angle = 0;
            var gridAngle = mainCanvas.gridAngle?mainCanvas.gridAngle:0
            if(gridAngle != angle) return;
            // var canvasCenter = new fabric.Point(mainCanvas.getWidth()/2, mainCanvas.getHeight()/2);
            // var canvasCenter = new fabric.Point(50, 50);
            var canvasCenter = new fabric.Point(mainCanvas.getWidth()/2, mainCanvas.getHeight()/2);
            var objectOrigin = new fabric.Point(options.target.left, options.target.top);
            var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(-1))
            ////console.log('new_loc_x', new_loc.x)
            ////console.log('new_loc_y', new_loc.y)
            ////console.log('realGrid', realGrid)
            var left = Math.round((new_loc.x-realOffsetX) / realGrid) * realGrid+realOffsetX;
            var top = Math.round((new_loc.y-realOffsetY) / realGrid) * realGrid+realOffsetY;
            ////console.log('left', left)
            ////console.log('top', top)
    
            objectOrigin = new fabric.Point(left, top);
            new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(1))
    
            // var angle_grid = grid*Math.cos(2*Math.PI*options.target.get('angle')/360)
            // var angle_offset = grid*Math.sin(2*Math.PI*options.target.get('angle')/360)
            options.target.set({
              left: new_loc.x,
              top: new_loc.y
            });
    
            
            moveCoordinate(mainCanvas, new_loc.x,  new_loc.y, g_coordinate, options.target.dwg_left,  options.target.dwg_top)

            // var objectOrigin = new fabric.Point(Math.round(options.target.left / angle_grid)+angle_offset ,Math.round(options.target.top / grid) * angle_grid)+angle_offset;
            // var val = options.target.get('angle');
            // var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, val*2*Math.PI/360)
            // options.target.set({
            //   left: new_loc.x,
            //   top: new_loc.y
            // });
        }
        else{          
            var e = options
            var obj = e.target;
            obj.setCoords(); //Sets corner position coordinates based on current angle, width and height
            
            var canvasWidth = document.getElementById('canvas').width
            var canvasHeight = document.getElementById('canvas').height;
            // Prevent object from leaving canvas
            // if(obj.left < edgedetection) obj.set({left:0});
            // if(obj.left < edgedetection) obj.set({top:0});
            // if((obj.width + obj.left) > (canvasWidth - edgedetection)) obj.set({left:canvasWidth - obj.width});
            // if((obj.width + obj.left) > (canvasHeight - edgedetection)) obj.set({top:canvasHeight - obj.height});
        
            var objects = props.main.mainCanvas.getObjects();
            var activeObject = props.main.mainCanvas.getActiveObject();
            for(var i = 0; i< objects.length; i++){
                var targ = objects[i]
        
                if(targ === activeObject) continue;
                if(targ.get('class') != 'booth' && targ.get('class') != 'booth-group' && targ.get('class') != 'door' && targ.get('class') != 'wall'){
                    continue
                }
                
                var active_oCoords = {
                    tl:{x: activeObject.left, y: activeObject.top},
                    tr:{x: activeObject.left+activeObject.width, y: activeObject.top},
                    bl:{x: activeObject.left, y: activeObject.top+activeObject.height},
                    br:{x: activeObject.left+activeObject.width, y: activeObject.top+activeObject.height},
                }
                var targ_oCoords = {
                    tl:{x: targ.left, y: targ.top},
                    tr:{x: targ.left+targ.width, y: targ.top},
                    bl:{x: targ.left, y: targ.top+targ.height},
                    br:{x: targ.left+targ.width, y: targ.top+targ.height},
                }
                // var active_oCoords = activeObject.aCoords
                // var targ_oCoords = targ.aCoords


                // if (activeObject.isContainedWithinObject(targ) || activeObject.intersectsWithObject(targ) || targ.isContainedWithinObject(activeObject)) {

                //     var distX = ((targ.left + targ.width) / 2) - ((activeObject.left + activeObject.width) / 2);
                //     var distY = ((targ.top + targ.height) / 2) - ((activeObject.top + activeObject.height) / 2);
                //     if(targ_oCoords.tl.x - active_oCoords.tr.x>0){              //left           
                //         activeObject.set({left:(obj.left - (targ_oCoords.tl.x - active_oCoords.tr.x))})    
                //     }
                //     else if(active_oCoords.tr.x - targ_oCoords.tr.x >0){ //rgith
                //         activeObject.set({left:(obj.left + (active_oCoords.tr.x - targ_oCoords.tr.x))})    
                //     }
                    
                //     else if(active_oCoords.bl.y - targ_oCoords.tr.y>0){ //top
                //         activeObject.set({top:(obj.top - (active_oCoords.bl.y - targ_oCoords.tr.y))})   
                        
                //     }
                    
                //     else if(targ_oCoords.bl.y - active_oCoords.tr.y>0){ //bottom
                //         activeObject.set({top:(obj.top + (targ_oCoords.bl.y - active_oCoords.tr.y>1))})   
                //     }
        
                //     // Set new position
                //     // findNewPos(distX, distY, activeObject, targ);
                // }

                // Standard snapping when within range
                if(targ.get('class') == 'wall'){
                    if(Math.abs(active_oCoords.tl.x - targ_oCoords.tr.x) < edgedetection) {
                        ////console.log("left", targ)
                        activeObject.left = targ.left + targ.width;
                        break;
                    }
                    else if(Math.abs(active_oCoords.tr.x - targ_oCoords.tl.x) < edgedetection) {
                        ////console.log("right", targ)
                        activeObject.left = targ.left - activeObject.width;
                        break;
                    }                
                    else if(Math.abs(active_oCoords.br.y - targ_oCoords.tr.y) < edgedetection) {
                        ////console.log("bottom", targ)
                        activeObject.top = targ.top - activeObject.height;
                        break;
                    }
                    else if(Math.abs(targ_oCoords.br.y - active_oCoords.tr.y) < edgedetection) {
                        ////console.log("top", targ)
                        activeObject.top = targ.top + targ.height;
                        break;
                    }
                }
                else{
                    if(Math.abs(active_oCoords.tl.x - targ_oCoords.tr.x) < edgedetection && Math.abs(targ_oCoords.tr.y - active_oCoords.tl.y) < edgedetection) { //target right 1,0
                        ////console.log("left", targ)
                        activeObject.left = targ_oCoords.tr.x //targ.left + targ.width;
                        // if(Math.abs(activeObject.top - targ.top) < edgedetection) {
                            activeObject.top = targ_oCoords.tr.y;
                        // }
                        break;
                    }
                    else if(Math.abs(active_oCoords.tl.x - targ_oCoords.bl.x) < edgedetection && Math.abs(targ_oCoords.bl.y - active_oCoords.tl.y) < edgedetection) { //target bottom 0,1
                        ////console.log("top", targ)
                        activeObject.top = targ_oCoords.bl.y//targ.top + targ.height;
                        // if(Math.abs(activeObject.left - targ.left) < edgedetection) {
                            activeObject.left = targ_oCoords.bl.x;
                        // }
                        break;
                    }
                    else if(Math.abs(active_oCoords.bl.x - targ_oCoords.tl.x) < edgedetection && Math.abs(active_oCoords.bl.y - targ_oCoords.tl.y) < edgedetection) { //target top 0,0
                        ////console.log("bottom", targ)
                        activeObject.top = targ.top +(active_oCoords.tl.y - active_oCoords.bl.y);
                        // if(Math.abs(activeObject.left - targ.left) < edgedetection) {
                            activeObject.left = targ.left + (active_oCoords.tl.x - active_oCoords.bl.x);
                        // }
                        break;
                    }
                    else if(Math.abs(active_oCoords.tr.x - targ_oCoords.tl.x) < edgedetection && Math.abs(active_oCoords.tr.y - targ_oCoords.tl.y) < edgedetection) {//target left 0,0
                        ////console.log("right", targ)
                        activeObject.left = targ.left - (active_oCoords.tr.x - active_oCoords.tl.x);
                        // if(Math.abs(activeObject.top - targ.top) < edgedetection) {
                            activeObject.top = targ.top + (active_oCoords.tl.y - active_oCoords.tr.y);
                        // }
                        break;
                    }                
                }
        
                // Snap top/left together when moving up/down or side to side if within range
                // if(Math.abs(activeObject.top - targ.top) < edgedetection && Math.abs(targ_oCoords.tr.x - active_oCoords.tl.x) < edgedetection) {
                //     activeObject.top = targ.top;
                // }
                // if(Math.abs(activeObject.top - targ.top) < edgedetection && Math.abs(targ_oCoords.tl.x - active_oCoords.tr.x) < edgedetection) {
                //     activeObject.top = targ.top;
                // }
                // if(Math.abs(activeObject.left - targ.left) < edgedetection && Math.abs(targ_oCoords.bl.y - active_oCoords.tl.y) < edgedetection) {
                //     activeObject.left = targ.left;
                // }
                // if(Math.abs(activeObject.left - targ.left) < edgedetection && Math.abs(targ_oCoords.tl.y - active_oCoords.bl.y) < edgedetection) {
                //     activeObject.left = targ.left;
                // }
        
                // if(activeObject.intersectsWithObject(targ) && targ.intersectsWithObject(activeObject)) {
                //     targ.set({ strokeWidth: 2, stroke: 'red' });
                // } else {
                //     targ.set({ strokeWidth: 0, stroke: false });
                // }
        
                // if(!activeObject.intersectsWithObject(targ)) {
                //     activeObject.set({ strokeWidth: 0, stroke: false });
                // }
            }
        }
        if(options.target.lockforever != true)
        {
            var mainCanvas = props.main.mainCanvas
            var zoom = props.main.mainCanvas.getZoom();
            var ponter = options.pointer;
            var left = options.e.clientX - (ponter.x - options.target.left)*zoom
            var top = options.e.clientY - (ponter.y - options.target.top)*zoom
            var right = options.e.clientX - (ponter.x - options.target.left)*zoom + options.target.width*zoom
            var bottom = options.e.clientY - (ponter.y - options.target.top)*zoom + options.target.height*zoom


            // console.log("options.e.clientX", options.e.clientX)
            // console.log("ponter.x", ponter.x)
            // console.log("options.target.left", options.target.left)
            // console.log("(ponter.x - options.target.left)*zoom", (ponter.x - options.target.left)*zoom)
            // console.log("left", left)
            //console.log("bottom", bottom)
            //console.log("window.height", window.innerHeight)
            //console.log("calcOffset()",props.main.mainCanvas.calcOffset())
            if(left <= 50){
                if(props.main.mainCanvas.vptCoords.tl.x > 0){
                    var deltaX = (50 - (left))/Math.max(zoom, 1) ;
                    var deltaY = 0;
                    var delta = new fabric.Point(deltaX, 0);
                    mainCanvas.relativePan(delta);
                    bgCanvas.relativePan(delta);
                    bgImgCanvas.relativePan(delta);
                    redrawRulers(mainCanvas, topRuler, leftRuler)
                    // redrawGrid(g_grid, mainCanvas, (mainCanvas.showGrid &&mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
                }
            }

            if(options.target.left <= 50){
                options.target.left = 50
            }
            else{
            }
            if(top <= 100){
                if(props.main.mainCanvas.vptCoords.tl.y > 0){
                    var deltaY = (100 - (top))/Math.max(zoom, 1);
                    var delta = new fabric.Point(0, deltaY);
                    mainCanvas.relativePan(delta);
                    bgCanvas.relativePan(delta);
                    bgImgCanvas.relativePan(delta);
                    redrawRulers(mainCanvas, topRuler, leftRuler)                    
                    // redrawGrid(g_grid, mainCanvas, (mainCanvas.showGrid &&mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
                }
            }
            if(options.target.top <= 50){
                options.target.top = 50
            }
            else
            {
            }
            
            if(right > window.window.innerWidth){
                if(props.main.mainCanvas.vptCoords.br.x  < (realMapWidth+90)){
                    var deltaX = ((window.innerWidth) - (right))/Math.max(zoom, 1);
                    var deltaY = 0;
                    var delta = new fabric.Point(deltaX, 0);
                    mainCanvas.relativePan(delta);
                    bgCanvas.relativePan(delta);
                    bgImgCanvas.relativePan(delta);
                    redrawRulers(mainCanvas, topRuler, leftRuler)
                    // redrawGrid(g_grid, mainCanvas, (mainCanvas.showGrid &&mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
                }
            }
            if(options.target.left+options.target.width > realMapWidth+45){
                options.target.left = realMapWidth+45-options.target.width
            }
            else{
            }

            if(bottom > window.innerHeight){
                if(props.main.mainCanvas.vptCoords.br.y  < (realMapHeight+90) ){
                    var deltaY = ((window.innerHeight) - (bottom))/Math.max(zoom, 1);
                    var delta = new fabric.Point(0, deltaY);
                    mainCanvas.relativePan(delta);
                    bgCanvas.relativePan(delta);
                    bgImgCanvas.relativePan(delta);
                    redrawRulers(mainCanvas, topRuler, leftRuler)
                    // redrawGrid(g_grid, mainCanvas, (mainCanvas.showGrid &&mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
                }
            }
            if(options.target.top+options.target.height > realMapHeight+45){
                options.target.top = realMapHeight+45-options.target.height
            }
            else{
            }

            

            // if(options.target.left <= 50){
            //     var deltaX = (options.target.left) - (50);
            //     var deltaY = 0;
            //     var delta = new fabric.Point(deltaX, 0);
            //     mainCanvas.relativePan(delta);
            //     topRuler.relativePan(delta);
            //     leftRuler.relativePan(delta);
            //     options.target.left = 50
            // }
            // if(options.target.top <= 50){
            //     var deltaY = (options.target.top) - (50);
            //     var delta = new fabric.Point(0, deltaY);
            //     mainCanvas.relativePan(delta);
            //     topRuler.relativePan(delta);
            //     leftRuler.relativePan(delta);
            //     options.target.top = 50
            // }
            
            // if(options.target.left+options.target.width > realMapWidth+45){
            //     var deltaX = (options.target.left+options.target.width) - (realMapWidth+45);
            //     var deltaY = 0;
            //     var delta = new fabric.Point(deltaX, 0);
            //     mainCanvas.relativePan(delta);
            //     topRuler.relativePan(delta);
            //     leftRuler.relativePan(delta);
            //     options.target.left = realMapWidth+45-options.target.width
            // }
            // if(options.target.top+options.target.height > realMapHeight+45){
            //     var deltaY = (options.target.top+options.target.height) - (realMapHeight+45);
            //     var delta = new fabric.Point(0, deltaY);
            //     mainCanvas.relativePan(delta);
            //     topRuler.relativePan(delta);
            //     leftRuler.relativePan(delta);
            //     options.target.top = realMapHeight+45-options.target.height
            // }
        }

        // options.target.setCoords();
        // mainCanvas.forEachObject(function(obj) {            
        //     if(!checkIfSelectable(obj)) return;
        //     if (obj === options.target) return;
        //     obj.set('opacity' ,options.target.intersectsWithObject(obj) ? 0.5 : 1);
        // });

        g_objectMoving = true
    } 


    const getSvgInfo=(svgStr)=>{
        var drawermain = SVG(svgStr);
        var dimension_str = drawermain.find("#cadviewer_drawingCoordinates").text();
        var units_str = drawermain.find("#cadviewer_worldUnits").text();
        ////console.log("dimension_str", dimension_str)
        var dimension = null;
        var convertRateX = 1;
        var convertRateY = 1;
        var svgToWorldUnits = 1;
        var convertOffsetXOrg = 0;
        var convertOffsetYOrg = 0;
        var convertOffsetX = 0;
        var convertOffsetY = 0;
        var startX = 0;
        var startY = 0;
        var endX = 0;
        var endY = 0;
        var mapWidth = 0;
        var mapHeight = 0;
        var mapUnit = mainCanvas.mapUnit;
        let convertUnit = ''
        if(units_str && units_str.length>0){
            units_str[0] = units_str[0].replaceAll(". ", "");
            var units = JSON.parse(units_str[0]);
            var globalOrigin = units['globalOrigin']
            if(globalOrigin['units']){
                convertUnit = globalOrigin['units']
            }
            else{
                convertUnit = units['units']
            }
            
            if(globalOrigin['svgToWorldUnits']){
                convertRateX = convertRateY = globalOrigin['svgToWorldUnits']
                svgToWorldUnits = globalOrigin['svgToWorldUnits']
            }
            else{
                convertRateX = convertRateY = units['svgToWorldUnits']
                svgToWorldUnits = units['svgToWorldUnits']
            }
        }
        if(dimension_str && dimension_str.length>0){
            dimension_str[0] = dimension_str[0].replaceAll(". ", "");
            dimension = JSON.parse(dimension_str[0])
            ////console.log("dimension", dimension)
            var dwg_info = dimension.DWG;
            var svg_info = dimension.SVG;
            var width_svg = Math.abs(svg_info.upperRight.x - svg_info.lowerLeft.x)
            var height_svg = Math.abs(svg_info.upperRight.y - svg_info.lowerLeft.y)
            var width_dwg = Math.abs(dwg_info.upperRight.x - dwg_info.lowerLeft.x)
            var height_dwg = Math.abs(dwg_info.upperRight.y - dwg_info.lowerLeft.y)
            var real_width_svg = width_dwg/svgToWorldUnits
            var real_height_svg = height_dwg/svgToWorldUnits
            var offset_svg_x =  svg_info.lowerLeft.x
            var offset_svg_y =  real_height_svg - svg_info.lowerLeft.y

            let cropOffsetY = height_dwg - svg_info.upperRight.y*svgToWorldUnits
            
            // convertOffsetX = offset_svg_x*svgToWorldUnits + dwg_info.lowerLeft.x
            // convertOffsetY = dwg_info.upperRight.y - (height_dwg - (svg_info.lowerLeft.y - svg_info.upperRight.y)*svgToWorldUnits)

            convertOffsetXOrg = dwg_info.lowerLeft.x + svg_info.lowerLeft.x*svgToWorldUnits
            convertOffsetYOrg = dwg_info.upperRight.y - svg_info.lowerLeft.y*svgToWorldUnits - cropOffsetY

            // position_x = entity.Text_Position.x/convertRateX - mainCanvas.dwg_lowerLeft_x/convertRateX - mainCanvas.svg_lowerLeft_x+50;
            //                     position_y = entity.Text_Position.y*(-1)/convertRateX - mainCanvas.dwg_upperRight_y*(-1)/convertRateX - mainCanvas.svg_lowerLeft_y+50;

            // convertRateX = width_dwg/width_svg;
            // convertRateY = height_dwg/height_svg;
            // startX = svg_info.lowerLeft.x;
            // startY = svg_info.lowerLeft.y;
            // endX = svg_info.upperRight.x;
            // endY = svg_info.upperRight.y;
            startX = 0;
            startY = 0;
            endX = width_svg;
            endY = height_svg;
            if(convertUnit == 'mm'){
                convertRateX/=1000
                convertOffsetX=convertOffsetXOrg/1000
                convertOffsetY=convertOffsetYOrg/1000
            }
            else{
                convertOffsetX=convertOffsetXOrg
                convertOffsetY=convertOffsetYOrg
            }
            if(mapUnit){
                mapWidth = px2unit(width_svg, convertRateX, mapUnit)
                mapHeight = px2unit(height_svg, convertRateX, mapUnit)
            }
        }

        // mainCanvas.set({convertRateX, convertRateY, convertOffsetX, convertOffsetY, startX, startY, endX, endY, mapWidth, mapHeight, convertUnit, mapUnit: (convertUnit == 'in'?'feet':'meter')})
        
        mainCanvas.set({convertRateX, convertRateY, svgToWorldUnits, convertOffsetX, convertOffsetY, convertOffsetXOrg, convertOffsetYOrg, startX, startY, endX, endY, mapWidth, mapHeight, convertUnit})
        mainCanvas.set({
            dwg_lowerLeft_x:dwg_info.lowerLeft.x,
            dwg_lowerLeft_y:dwg_info.lowerLeft.y,
            dwg_upperRight_x:dwg_info.upperRight.x,
            dwg_upperRight_y:dwg_info.upperRight.y,
            svg_lowerLeft_x:svg_info.lowerLeft.x,
            svg_lowerLeft_y:svg_info.lowerLeft.y,
            svg_upperRight_x:svg_info.upperRight.x,
            svg_upperRight_y:svg_info.upperRight.y,
        })
        ////console.log(mainCanvas.get('convertRateX'))
        ////console.log(mainCanvas.get('convertRateY'))
        ////console.log(mainCanvas.get('convertOffsetX'))
        ////console.log(mainCanvas.get('convertOffsetY'))
        mainCanvas.renderAll();
        return {
          convertRateX, convertRateY, svgToWorldUnits, convertOffsetX, convertOffsetY, mapWidth, mapHeight, convertUnit
        };
    }

    const getStrokeInfo = (entities)=>{
        var minSize = 120;
        if (mainCanvas.mapUnit == 'meter'){
            minSize = 3 //3meter
            if(mainCanvas.convertUnit == 'mm'){
                minSize = 3000
            }
        }
        for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
            var layer = mainLayers[layerInd];
            if(layer){
                if(layer.outline_selected ){
                    for(var entInd = 0; entInd < entities?.length; entInd++)  
                    {
                        var entity = entities[entInd];
                        if(layer.name == entity.Layer) {
                            var newPoints = [];
                            var dwg_width = 0
                            var dwg_height = 0
                            if(entity.Type == 'BlockReference'){                                
                                var blockDefinition = findBlock(entity.Block_Name)
                                if(blockDefinition){
                                    var blockDefinitionEntity
                                    for(var defInd = 0; defInd< blockDefinition?.Entities?.length; defInd++){
                                        if(blockDefinition?.Entities?.[defInd].Type == "Polyline" || blockDefinition?.Entities?.[defInd].Type == "2dPolyline"){
                                            blockDefinitionEntity = blockDefinition?.Entities?.[defInd]
                                            break;
                                        }
                                    }
                                    if(blockDefinitionEntity){
                                        if(blockDefinitionEntity.Type == "Polyline" || blockDefinitionEntity.Type == "2dPolyline"){
                                           
                                            dwg_width = Math.abs(blockDefinitionEntity.Entity_Extents.Maximum_Point.x-blockDefinitionEntity.Entity_Extents.Minimum_Point.x)
                                            dwg_height = Math.abs(blockDefinitionEntity.Entity_Extents.Maximum_Point.y-blockDefinitionEntity.Entity_Extents.Minimum_Point.y)
                                            
                                        }
                                    }
                                }

                            }
                            if(entity.Type == 'Polyline' || entity.Type == '2dPolyline'){
                                var position = {};

                                dwg_width = Math.abs(entity.Entity_Extents.Maximum_Point.x-entity.Entity_Extents.Minimum_Point.x)
                                dwg_height = Math.abs(entity.Entity_Extents.Maximum_Point.y-entity.Entity_Extents.Minimum_Point.y)
                                
                            }
                            if(dwg_width > 0){
                                minSize = Math.min(minSize, dwg_width)
                            }
                            if(dwg_height > 0){
                                minSize = Math.min(minSize, dwg_height)
                            }
                            
                        }
                    }
                }
            }
        }
        if(mainCanvas.convertUnit == 'mm'){
            minSize /= 1000
        }
        mainCanvas.set({strokeWidth:minSize/40}).renderAll()
        return minSize/40;
    }
    const findBlock = (block_name)=>{
        var result;
        if(!block_name || block_name == ''){
            return false;
        }
        for(var i = 0; i < linklistBlocks?.length; i++){
            if(linklistBlocks[i].Block_Name == block_name){
                result = linklistBlocks[i];
                break;
            }
        }
        return result;
    }
    const renderEntities = async (svgStr, entities)=>{
        console.log("renderEntities active_booth_file", active_booth_file)
        if(svgStr){
            unlockMap(mainCanvas)
            props.setLockFlag(false)
            var {convertRateX, svgToWorldUnits, convertRateY, svgToWorldUnits, convertOffsetX, convertOffsetY} = getSvgInfo(svgStr);
            var svgTopElement = jQuery("svg");
            var drawermain = SVG(svgStr);
            // drawermain.svg(svgStr);
            ////console.log("hi");
            // ////console.log(drawermain.svg())
            mainCanvas.selectionColor = 'rgba(25, 118, 210,0.3)';
            mainCanvas.selectionBorderColor = 'rgba(255,0,0,0.8)';
            mainCanvas.selectionLineWidth = 3;
            // console.log("mainLayers", mainLayers)

            var bgColor = "#dadada"
            fabric.loadSVGFromURL("/table_round_6.svg",function(objects, options) {
                table6Object = fabric.util.groupSVGElements(objects, options);
                table6Object.set({
                    fill: bgColor, 
                    strokeWidth:3/svgToWorldUnits,
                    stroke:'black',
                })
            });
            fabric.loadSVGFromURL("/table_round_8.svg",function(objects, options) {
                table8Object = fabric.util.groupSVGElements(objects, options);
                table8Object.set({
                    fill: bgColor, 
                    strokeWidth:3/svgToWorldUnits,
                    stroke:'black',
                })
            });
            fabric.loadSVGFromURL("/table_round_10.svg",async function(objects, options) {
                table10Object = fabric.util.groupSVGElements(objects, options);
                table10Object.set({
                    fill: bgColor, 
                    strokeWidth:3/svgToWorldUnits,
                    stroke:'black',
                })
            
                fabric.Object.prototype.objectCaching = false
                mainCanvas.renderOnAddRemove = false
                getStrokeInfo(entities);
                for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
                    var layer = mainLayers[layerInd];
                    if(layer){
                        if(layer.outline_selected ){
                            for(var entInd = 0; entInd < entities?.length; entInd++)  
                            {
                                var entity = entities[entInd];
                                if(layer.name == entity.Layer) {
                                    var newPoints = [];
                                    // var angle = entity.Rotation*180/Math.PI;
                                    var angle = 0;
                                    var position = {}
                                    if(entity.Handle == "E5A7"){
                                        console.log("E5A7", entity)
                                    }
                                    var dwg_left = 0
                                    var dwg_top = 0
                                    var dwg_width = 0
                                    var dwg_height = 0
                                    if(entity.Type == 'BlockReference'){
                                        // var position = {};
                                        // var dwg_left = entity.Entity_Extents.Minimum_Point.x
                                        // var dwg_top = entity.Entity_Extents.Maximum_Point.y                                        
                                        // dwg_width = Math.abs(entity.Entity_Extents.Maximum_Point.x-entity.Entity_Extents.Minimum_Point.x)
                                        // dwg_height = Math.abs(entity.Entity_Extents.Maximum_Point.y-entity.Entity_Extents.Minimum_Point.y)
                                        // console.log("BlockReference size", dwg_width, dwg_height)
                                        // if(mainCanvas.mapUnit == 'feet'){
                                        //     dwg_width = Math.abs(entity.Entity_Extents.Maximum_Point.x-entity.Entity_Extents.Minimum_Point.x)/12
                                        //     dwg_height = Math.abs(entity.Entity_Extents.Maximum_Point.y-entity.Entity_Extents.Minimum_Point.y)/12
                                        // }
                                        
                                        // var width = Math.abs(entity.Entity_Extents.Maximum_Point.x-entity.Entity_Extents.Minimum_Point.x)/svgToWorldUnits
                                        // var height = Math.abs(entity.Entity_Extents.Maximum_Point.y-entity.Entity_Extents.Minimum_Point.y)/svgToWorldUnits
                                        
                                        // // dwg_lowerLeft_x:dwg_info.lowerLeft.x,
                                        // // dwg_lowerLeft_y:dwg_info.lowerLeft.y,
                                        // // dwg_upperRight_x:dwg_info.upperRight.x,
                                        // // dwg_upperRight_y:dwg_info.upperRight.y,
                                        // // svg_lowerLeft_x:svg_info.lowerLeft.x,
                                        // // svg_lowerLeft_y:svg_info.lowerLeft.y,
                                        // // svg_upperRight_x:svg_info.upperRight.x,
                                        // // svg_upperRight_y:svg_info.upperRight.y,
                                        // // position.x = position.x/svgToWorldUnits - mainCanvas.dwg_lowerLeft_x/svgToWorldUnits - mainCanvas.svg_lowerLeft_x+50;
                                        // // position.y = position.y*(-1)/svgToWorldUnits - mainCanvas.dwg_upperRight_y*(-1)/svgToWorldUnits - mainCanvas.svg_lowerLeft_y+50;
                                        
                                        // position.x = (dwg_left*1 - mainCanvas.convertOffsetX*1)/svgToWorldUnits+50;
                                        // position.y = (dwg_top*1 - mainCanvas.convertOffsetY*1)/(-svgToWorldUnits)+50;

                                        // newPoints.push({x: position.x, y:position.y})
                                        // newPoints.push({x: position.x+width, y:position.y})
                                        // newPoints.push({x: position.x+width, y:position.y+height})
                                        // newPoints.push({x: position.x, y:position.y+height})
                                        // newPoints.push({x: position.x, y:position.y})
                                        var basicPoint = {}
                                        basicPoint.x = entity.Position.x
                                        basicPoint.y = entity.Position.y
                                        var blockDefinition = findBlock(entity.Block_Name)
                                        if(blockDefinition){
                                            var blockDefinitionEntity
                                            for(var defInd = 0; defInd< blockDefinition?.Entities?.length; defInd++){
                                                if(blockDefinition?.Entities?.[defInd].Type == "Polyline" || blockDefinition?.Entities?.[defInd].Type == "2dPolyline"){
                                                    blockDefinitionEntity = blockDefinition?.Entities?.[defInd]
                                                    break;
                                                }
                                            }
                                            if(blockDefinitionEntity){
                                                if(blockDefinitionEntity.Type == "Polyline" || blockDefinitionEntity.Type == "2dPolyline"){
                                                    var position = {};
                                                    var startPointX = entity.Entity_Extents.Minimum_Point.x*1
                                                    var startPointY = entity.Entity_Extents.Maximum_Point.y*1
                                                    position.x = (startPointX - mainCanvas.convertOffsetXOrg*1)/svgToWorldUnits+50;
                                                    position.y = (startPointY - mainCanvas.convertOffsetYOrg*1)/(-svgToWorldUnits)+50;

                                                    dwg_left = startPointX/12
                                                    dwg_top = startPointY/12
                                                    // dwg_lowerLeft_x:dwg_info.lowerLeft.x,
                                                    // dwg_lowerLeft_y:dwg_info.lowerLeft.y,
                                                    // dwg_upperRight_x:dwg_info.upperRight.x,
                                                    // dwg_upperRight_y:dwg_info.upperRight.y,
                                                    // svg_lowerLeft_x:svg_info.lowerLeft.x,
                                                    // svg_lowerLeft_y:svg_info.lowerLeft.y,
                                                    // svg_upperRight_x:svg_info.upperRight.x,
                                                    // svg_upperRight_y:svg_info.upperRight.y,
                                                    var transformedVertexes = transformRotateVertex(blockDefinitionEntity.Vertices, entity.Rotation, blockDefinitionEntity.Type)
                                                    for(var vertices_index = 0; vertices_index < transformedVertexes?.length; vertices_index++){
                                                        var position_x
                                                        var position_y
                                                        position_x = (startPointX + transformedVertexes[vertices_index].x*1 - mainCanvas.convertOffsetXOrg*1)/svgToWorldUnits+50;
                                                        position_y = (startPointY + transformedVertexes[vertices_index].y*1- mainCanvas.convertOffsetYOrg*1)/(-svgToWorldUnits)+50;
                                                        newPoints.push({x: position_x, y:position_y})
                                                    }
                                                    if(newPoints.length == 4){
                                                        newPoints.push(newPoints[0])
                                                    }
                                                    
                                                    dwg_width = Math.abs(blockDefinitionEntity.Entity_Extents.Maximum_Point.x-blockDefinitionEntity.Entity_Extents.Minimum_Point.x)
                                                    dwg_height = Math.abs(blockDefinitionEntity.Entity_Extents.Maximum_Point.y-blockDefinitionEntity.Entity_Extents.Minimum_Point.y)
                                                    // console.log("Polyline size", dwg_width, dwg_height)
                                                    if(mainCanvas.mapUnit == 'feet'){
                                                        dwg_width = dwg_width/12
                                                        dwg_height = dwg_height/12
                                                    } 
                                                }
                                                // ((position_x - 50)*svgToWorldUnits+convertOffsetXOrg)
                                                // 3645.95
                                                // (1326.3833333333334*12 - 9388.000234397325)/1.815544379974906+50
                                            }
                                        }

                                    }
                                    if(entity.Type == 'Polyline' || entity.Type == '2dPolyline'){
                                        var position = {};

                                        
                                        var startPointX = entity.Entity_Extents.Minimum_Point.x*1
                                        var startPointY = entity.Entity_Extents.Maximum_Point.y*1
                                        
                                        position.x = (startPointX - mainCanvas.convertOffsetXOrg*1)/svgToWorldUnits+50;
                                        position.y = (startPointY - mainCanvas.convertOffsetYOrg*1)/(-svgToWorldUnits)+50;

                                        dwg_left = startPointX/12
                                        dwg_top = startPointY/12

                                        var transformedVertexes = transformVertex(entity.Vertices, {x:startPointX, y:startPointY}, entity.Type)
                                        for(var vertices_index = 0; vertices_index < transformedVertexes?.length; vertices_index++){
                                            var position_x
                                            var position_y
                                            position_x = (startPointX + transformedVertexes[vertices_index].x*1 - mainCanvas.convertOffsetXOrg*1)/svgToWorldUnits+50;
                                            position_y = (startPointY + transformedVertexes[vertices_index].y*1- mainCanvas.convertOffsetYOrg*1)/(-svgToWorldUnits)+50;
                                            newPoints.push({x: position_x, y:position_y})
                                        }
                                        if(newPoints.length == 4){
                                            newPoints.push(newPoints[0])
                                        }                                        
                                        dwg_width = Math.abs(entity.Entity_Extents.Maximum_Point.x-entity.Entity_Extents.Minimum_Point.x)
                                        dwg_height = Math.abs(entity.Entity_Extents.Maximum_Point.y-entity.Entity_Extents.Minimum_Point.y)
                                        // console.log("Polyline size", dwg_width, dwg_height)
                                        if(mainCanvas.mapUnit == 'feet'){
                                            dwg_width = dwg_width/12
                                            dwg_height = dwg_height/12
                                        }

                                    }
                                    ////console.log(paths);
                                    if(newPoints?.length){
                                        var rotateFlag = checkifrotatepoints(newPoints)
                                        if(rotateFlag){
                                            var ratateResult = rotatePoints2(mainCanvas, newPoints)
                                            newPoints = ratateResult.newPoints
                                            angle = ratateResult.angle
                                            var new_centerPoint = ratateResult.new_centerPoint
                                            position.x+= ratateResult.offsetX
                                        }
                                        
                                        fabric.Object.prototype.transparentCorners = false;
                                        var obj = new fabric.Polygon(
                                            newPoints, {
                                            
                                            layer: 'booth_outline',
                                            class:'outline',
                                            left : position.x,
                                            top : position.y,
                                            visible:1,
                                            fill: bgColor, 
                                            // fill: (entity.Type == 'BlockReference'?'red':bgColor),
                                            strokeWidth:getStrokeWidth(mainCanvas),
                                            stroke:'black',
                                            // stroke:(entity.Type == 'BlockReference'?'red':'black'),
                                            objectCaching: false //true
                                        });
                                        var f_group = new fabric.Group([obj]).set({
                                                width : obj.width,
                                                height : obj.height,
                                                id: 'booth-'+entInd,
                                                Handle:  entity.Handle,
                                                Entity_Extents: entity.Entity_Extents,
                                                boothGroup: getDefaultGroup(props.main.groupOptions),
                                                labelPosition: 'bottom-left',
                                                class: 'booth',
                                                layer:'booth',
                                                preserveObjectStacking: true,
                                                lockRotation: true,
                                                lockScalingX: true,
                                                lockScalingY: true,
                                                objectCaching: false, //true
                                                uniq_id: Date.now() + parseInt(Math.random()*10000000000),
                                                KEY_ID: uuidv4(),
                                                dwg_left,
                                                dwg_top,
                                                dwg_width,
                                                dwg_height
                                        });	
                                        f_group.set({
                                            borderColor: '#ff7777',
                                            cornerColor: 'red',
                                            cornerSize: 6,
                                            transparentCorners: false,
                                        });
                                        
                                        
                                        
                                        if(angle > 0){
                                            f_group.set({
                                                angle:angle
                                            })
                                        }
                                        mainCanvas.add(f_group)
                                    }
                                }
                            }
                        }
                        else if(layer.venue_selected){ //draw individual venue
                            let venueHandles = [];
                            for(var entInd = 0; entInd < entities?.length; entInd++)  
                            {
                                var entity = entities[entInd];
                                if(layer.name == entity.Layer) {
                                    var newPoints = [];
                                    // var angle = entity.Rotation*180/Math.PI;
                                    var angle = 0;
                                    var position = {}
                                    var dwg_left = 0
                                    var dwg_top = 0
                                    var dwg_width = 0
                                    var dwg_height = 0
                                    venueHandles.push([entity.Handle,entity.Type])
                                    if(entity.Type == 'BlockReference'){
                                        var basicPoint = {}
                                        basicPoint.x = entity.Position.x
                                        basicPoint.y = entity.Position.y
                                        var blockDefinition = findBlock(entity.Block_Name)
                                        if(blockDefinition){
                                            var blockDefinitionEntity
                                            for(var defInd = 0; defInd< blockDefinition?.Entities?.length; defInd++){
                                                if(blockDefinition?.Entities?.[defInd].Type == "Polyline" || blockDefinition?.Entities?.[defInd].Type == "2dPolyline"){
                                                    blockDefinitionEntity = blockDefinition?.Entities?.[defInd]
                                                    break;
                                                }
                                            }
                                            if(blockDefinitionEntity){
                                                if(blockDefinitionEntity.Type == "Polyline" || blockDefinitionEntity.Type == "2dPolyline"){
                                                    var position = {};
                                                    var startPointX = entity.Entity_Extents.Minimum_Point.x*1
                                                    var startPointY = entity.Entity_Extents.Maximum_Point.y*1
                                                    position.x = (startPointX - mainCanvas.convertOffsetX*1)/svgToWorldUnits+50;
                                                    position.y = (startPointY - mainCanvas.convertOffsetY*1)/(-svgToWorldUnits)+50;

                                                    dwg_left = startPointX/12
                                                    dwg_top = startPointY/12
                                                    var transformedVertexes = transformRotateVertex(blockDefinitionEntity.Vertices, entity.Rotation, blockDefinitionEntity.Type)
                                                    for(var vertices_index = 0; vertices_index < transformedVertexes?.length; vertices_index++){
                                                        var position_x
                                                        var position_y
                                                        position_x = (startPointX + transformedVertexes[vertices_index].x*1 - mainCanvas.convertOffsetX*1)/svgToWorldUnits+50;
                                                        position_y = (startPointY + transformedVertexes[vertices_index].y*1- mainCanvas.convertOffsetY*1)/(-svgToWorldUnits)+50;
                                                        newPoints.push({x: position_x, y:position_y})
                                                    }
                                                    if(newPoints.length == 4){
                                                        newPoints.push(newPoints[0])
                                                    }
                                                    
                                                    dwg_width = Math.abs(blockDefinitionEntity.Entity_Extents.Maximum_Point.x-blockDefinitionEntity.Entity_Extents.Minimum_Point.x)
                                                    dwg_height = Math.abs(blockDefinitionEntity.Entity_Extents.Maximum_Point.y-blockDefinitionEntity.Entity_Extents.Minimum_Point.y)
                                                    // console.log("Polyline size", dwg_width, dwg_height)
                                                    if(mainCanvas.mapUnit == 'feet'){
                                                        dwg_width = dwg_width/12
                                                        dwg_height = dwg_height/12
                                                    } 
                                                }
                                                // ((position_x - 50)*svgToWorldUnits+convertOffsetX)
                                                // 3645.95
                                                // (1326.3833333333334*12 - 9388.000234397325)/1.815544379974906+50
                                            }
                                        }

                                    }
                                    if(entity.Type == 'Polyline' || entity.Type == '2dPolyline'){
                                        var position = {};

                                        
                                        var startPointX = entity.Entity_Extents.Minimum_Point.x*1
                                        var startPointY = entity.Entity_Extents.Maximum_Point.y*1
                                        
                                        position.x = (startPointX - mainCanvas.convertOffsetX*1)/svgToWorldUnits+50;
                                        position.y = (startPointY - mainCanvas.convertOffsetY*1)/(-svgToWorldUnits)+50;

                                        dwg_left = startPointX/12
                                        dwg_top = startPointY/12

                                        var transformedVertexes = transformVertex(entity.Vertices, {x:startPointX, y:startPointY}, entity.Type)
                                        for(var vertices_index = 0; vertices_index < transformedVertexes?.length; vertices_index++){
                                            var position_x
                                            var position_y
                                            position_x = (startPointX + transformedVertexes[vertices_index].x*1 - mainCanvas.convertOffsetX*1)/svgToWorldUnits+50;
                                            position_y = (startPointY + transformedVertexes[vertices_index].y*1- mainCanvas.convertOffsetY*1)/(-svgToWorldUnits)+50;
                                            newPoints.push({x: position_x, y:position_y})
                                        }
                                        if(newPoints.length == 4){
                                            newPoints.push(newPoints[0])
                                        }                                        
                                        dwg_width = Math.abs(entity.Entity_Extents.Maximum_Point.x-entity.Entity_Extents.Minimum_Point.x)
                                        dwg_height = Math.abs(entity.Entity_Extents.Maximum_Point.y-entity.Entity_Extents.Minimum_Point.y)
                                        // console.log("Polyline size", dwg_width, dwg_height)
                                        if(mainCanvas.mapUnit == 'feet'){
                                            dwg_width = dwg_width/12
                                            dwg_height = dwg_height/12
                                        }

                                    }
                                    ////console.log(paths);
                                    if(newPoints?.length >= 4 ){
                                        var rotateFlag = checkifrotatepoints(newPoints)
                                        if(rotateFlag){
                                            var ratateResult = rotatePoints2(mainCanvas, newPoints)
                                            newPoints = ratateResult.newPoints
                                            angle = ratateResult.angle
                                            var new_centerPoint = ratateResult.new_centerPoint
                                            position.x+= ratateResult.offsetX
                                        }
                                        
                                        fabric.Object.prototype.transparentCorners = false;
                                        var obj = new fabric.Polygon(
                                            newPoints, {
                                            
                                            layer: 'venue',
                                            class:'venue',
                                            left : position.x,
                                            top : position.y,
                                            visible:1,
                                            fill: (entInd==412?'red':bgColor), 
                                            // fill: (entity.Type == 'BlockReference'?'red':bgColor),
                                            strokeWidth:getStrokeWidth(mainCanvas),
                                            stroke:'black',
                                            // stroke:(entity.Type == 'BlockReference'?'red':'black'),
                                            objectCaching: false //true
                                        });
                                        var f_group = new fabric.Group([obj]).set({
                                                width : obj.width,
                                                height : obj.height,
                                                booth_number: 'venue-'+entInd,
                                                id: 'venue-'+entInd,
                                                Handle:  entity.Handle,
                                                boothGroup: getDefaultGroup(props.main.groupOptions),
                                                labelPosition: 'bottom-left',
                                                class: 'venue',
                                                layer:'venue',
                                                preserveObjectStacking: true,
                                                objectCaching: false, //true
                                                lockRotation: true,
                                                lockScalingX: true,
                                                lockScalingY: true,
                                                selectable: false,
                                                selection: false,
                                                hasControls: false,
                                                hasBorders: false,
                                                lockMovementX: true,
                                                lockMovementY: true,
                                                uniq_id: Date.now() + parseInt(Math.random()*10000000000),
                                                KEY_ID: uuidv4(),
                                                dwg_left,
                                                dwg_top,
                                                dwg_width,
                                                dwg_height
                                        });	
                                        f_group.set({
                                            borderColor: '#ff7777',
                                            cornerColor: 'red',
                                            cornerSize: 6,
                                            transparentCorners: false,
                                        });
                                        
                                        
                                        
                                        if(angle > 0){
                                            f_group.set({
                                                angle:angle
                                            })
                                        }
                                        mainCanvas.add(f_group)
                                    }
                                    if(entity.Type == 'Line'){
                                        var position = {};

                                        
                                        var startPointX = entity.Start_Point.x*1
                                        var startPointY = entity.Start_Point.y*1

                                        var endPointX = entity.End_Point.x*1
                                        var endPointY = entity.End_Point.y*1
                                        
                                        position.x = (startPointX - mainCanvas.convertOffsetX*1)/svgToWorldUnits+50;
                                        position.y = (startPointY - mainCanvas.convertOffsetY*1)/(-svgToWorldUnits)+50;
                                        var radius = (entity.Radius)/svgToWorldUnits;

                                        dwg_left = startPointX/12
                                        dwg_top = startPointY/12
   
                                        dwg_width = Math.abs(entity.Entity_Extents.Maximum_Point.x-entity.Entity_Extents.Minimum_Point.x)
                                        dwg_height = Math.abs(entity.Entity_Extents.Maximum_Point.y-entity.Entity_Extents.Minimum_Point.y)
                                        // console.log("Polyline size", dwg_width, dwg_height)
                                        if(mainCanvas.mapUnit == 'feet'){
                                            dwg_width = dwg_width/12
                                            dwg_height = dwg_height/12
                                        }
                                        let points = [
                                            {x:(startPointX - mainCanvas.convertOffsetX*1)/svgToWorldUnits+50,y:(startPointY - mainCanvas.convertOffsetY*1)/(-svgToWorldUnits)+50},
                                            {x:(endPointX - mainCanvas.convertOffsetX*1)/(svgToWorldUnits)+50, y:(endPointY - mainCanvas.convertOffsetY*1)/(-svgToWorldUnits)+50}
                                        ]
                                        fabric.Object.prototype.transparentCorners = false;
                                        var obj = new fabric.Polyline(points,
                                            {
                                                fill: bgColor, 
                                                // left: position.x,  // X position
                                                // top: position.y,    // Y position
                                                strokeWidth:getStrokeWidth(mainCanvas),
                                                objectCaching: false, //true
                                                stroke:'black',
                                                visible:1,
                                                layer: 'venue',
                                                class:'venue',
                                                strokeLineCap: 'round'
                                            }
                                        );
                                        var f_group = new fabric.Group([obj]).set({
                                                width : obj.width,
                                                height : obj.height,
                                                booth_number: 'venue-'+entInd,
                                                id: 'venue-'+entInd,
                                                Handle:  entity.Handle,
                                                boothGroup: getDefaultGroup(props.main.groupOptions),
                                                labelPosition: 'bottom-left',
                                                class: 'venue',
                                                layer:'venue',
                                                preserveObjectStacking: true,
                                                objectCaching: false, //true
                                                lockRotation: true,
                                                lockScalingX: true,
                                                lockScalingY: true,
                                                selectable: false,
                                                selection: false,
                                                hasControls: false,
                                                hasBorders: false,
                                                lockMovementX: true,
                                                lockMovementY: true,
                                                uniq_id: Date.now() + parseInt(Math.random()*10000000000),
                                                KEY_ID: uuidv4(),
                                                dwg_left,
                                                dwg_top,
                                                dwg_width,
                                                dwg_height
                                        });	
                                        f_group.set({
                                            borderColor: '#ff7777',
                                            cornerColor: 'red',
                                            cornerSize: 6,
                                            transparentCorners: false,
                                        });
                                        
                                        
                                        
                                        if(angle > 0){
                                            f_group.set({
                                                angle:angle
                                            })
                                        }
                                        mainCanvas.add(f_group)
                                    }
                                    if(entity.Type == 'Circle'){
                                        var position = {};

                                        
                                        var startPointX = entity.Center.x*1
                                        var startPointY = entity.Center.y*1
                                        
                                        position.x = (startPointX - mainCanvas.convertOffsetX*1)/svgToWorldUnits+50;
                                        position.y = (startPointY - mainCanvas.convertOffsetY*1)/(-svgToWorldUnits)+50;
                                        var radius = (entity.Radius)/svgToWorldUnits;

                                        dwg_left = startPointX/12
                                        dwg_top = startPointY/12
   
                                        dwg_width = Math.abs(entity.Entity_Extents.Maximum_Point.x-entity.Entity_Extents.Minimum_Point.x)
                                        dwg_height = Math.abs(entity.Entity_Extents.Maximum_Point.y-entity.Entity_Extents.Minimum_Point.y)
                                        // console.log("Polyline size", dwg_width, dwg_height)
                                        if(mainCanvas.mapUnit == 'feet'){
                                            dwg_width = dwg_width/12
                                            dwg_height = dwg_height/12
                                        }
                                        fabric.Object.prototype.transparentCorners = false;
                                        var obj = new fabric.Circle(
                                            {
                                                radius: radius,  // Radius of the circle
                                                fill: bgColor, 
                                                left: position.x - radius,  // X position
                                                top: position.y - radius,    // Y position
                                                strokeWidth:getStrokeWidth(mainCanvas),
                                                objectCaching: false, //true
                                                stroke:'black',
                                                visible:1,
                                                layer: 'venue',
                                                class:'venue',
                                            }
                                        );
                                        var f_group = new fabric.Group([obj]).set({
                                                width : obj.width,
                                                height : obj.height,
                                                booth_number: 'venue-'+entInd,
                                                id: 'venue-'+entInd,
                                                Handle:  entity.Handle,
                                                boothGroup: getDefaultGroup(props.main.groupOptions),
                                                labelPosition: 'bottom-left',
                                                class: 'venue',
                                                layer:'venue',
                                                preserveObjectStacking: true,
                                                objectCaching: false, //true
                                                lockRotation: true,
                                                lockScalingX: true,
                                                lockScalingY: true,
                                                selectable: false,
                                                selection: false,
                                                hasControls: false,
                                                hasBorders: false,
                                                lockMovementX: true,
                                                lockMovementY: true,
                                                uniq_id: Date.now() + parseInt(Math.random()*10000000000),
                                                KEY_ID: uuidv4(),
                                                dwg_left,
                                                dwg_top,
                                                dwg_width,
                                                dwg_height
                                        });	
                                        f_group.set({
                                            borderColor: '#ff7777',
                                            cornerColor: 'red',
                                            cornerSize: 6,
                                            transparentCorners: false,
                                        });
                                        
                                        
                                        
                                        if(angle > 0){
                                            f_group.set({
                                                angle:angle
                                            })
                                        }
                                        mainCanvas.add(f_group)
                                    }
                                }
                            }
                            // console.log("venueHandles",venueHandles)
                        }
                    }
                }
                if(0)
                for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
                    var layer = mainLayers[layerInd];
                    if(layer){
                        var topgroup = drawermain.find("#"+layer['id']);
                        if(topgroup && topgroup.length > 0){
                            if(layer.banquet_selected){
                                
                                var secondgroups = topgroup[0].children();
                                if(secondgroups.length > 0){
                                    for(var groupInd = 0; groupInd < secondgroups.length; groupInd++){      
                                        var id =   (secondgroups[groupInd]?secondgroups[groupInd].attr('id'):"")+"" 
                                        var groupName =   "Default" 
                                        var tmp = id.split("_");
                                        var table_number = 0;
                                        if(tmp && tmp.length > 0){
                                            table_number = tmp[tmp.length-1];
                                        }
                                        {
                                            var tables =secondgroups[groupInd].find('g');
                                            var table = null;
                                            if(tables && tables.length > 0){
                                                table = tables[0]
                                            }
                                            else{
                                                continue;
                                            }
                                            var paths = table.find('text');
                                            var flag = false;
                                            for(var i = 0; i < paths?.length; i++){
                                                var path = paths[i];
                                                var text = path.text();
                                                if(text && (text.toString().includes("For Demonstration Purposes Only") || text.toString().includes("Tailor Made Software") || text.toString().includes("www.cadviewer.com") || text.toString().includes("www.tailormade.com"))){
                                                    flag = true;  break;
                                                }
                                            }
                                            if(flag){
                                                continue;
                                            }
                                            var pathbox = table.bbox();
                                            var pathtransform = table.transform();
                                            var drawer = SVG();
                                            var group = drawer.group();
                                            group.attr({
                                                fill:'none',
                                                stroke:"black",
                                                preserveObjectStacking: true
                                            })
                                            table.move(0,0)
                                            var svgPath = SVG(table);
                                            group.add(svgPath);
                                        
                                            fabric.Object.prototype.transparentCorners = false;
                                            var tableid = id

                                            var bgColor = "#dadada"

                                            var table_path = "";
                                            var seatsCnt = 0;
                                            if(tableid){
                                                var tmp = tableid.split("_");
                                                var index = tmp.indexOf('ROUND')
                                                if(index >=0) {
                                                    var pathCnt = tmp[index + 1];
                                                    seatsCnt = pathCnt/2
                                                }
                                            }

                                            if(seatsCnt == 6 || seatsCnt == 8 || seatsCnt == 10)
                                            {
                                                var sampleObject;
                                                var tableWidth;
                                                if(seatsCnt == 6){
                                                    sampleObject = table6Object
                                                    if(svgToWorldUnits){
                                                        tableWidth = (108/svgToWorldUnits) //48inch
                                                    }
                                                }
                                                if(seatsCnt == 8){
                                                    sampleObject = table8Object
                                                    if(svgToWorldUnits){
                                                        tableWidth = (120/svgToWorldUnits) //60inch
                                                    }
                                                }
                                                else if(seatsCnt == 10){
                                                    sampleObject = table10Object
                                                    if(svgToWorldUnits){
                                                        tableWidth = (132/svgToWorldUnits) //72inch
                                                    }
                                                }
                                                groupName = seatsCnt+" seat table"
                                                if(sampleObject){                                            
                                                    sampleObject.clone(function(obj) {
                                                        obj.width = sampleObject.width
                                                        obj.height = sampleObject.height
                                                        obj.scaleX = sampleObject.scaleX
                                                        obj.scaleY = sampleObject.scaleY
                                                        if(checkiftable(obj))
                                                        {
                                                            
                                                            obj.scale(tableWidth/obj.width)
                                                            obj.set({
                                                                left: 0-NaN,
                                                                top: 0-NaN,
                                                            })
                                                            .setCoords();
                                                            // var rotateFlag = checkifrotate(obj)
                                                            var angle = 0;
                                                            // obj.set({
                                                            //     left : pathbox.x+mainOffsetX,
                                                            //     top : pathbox.y+mainOffsetY,
                                                            //     objectCaching: false,
                                                            //     dirty: true
                                                            // })
                                                            // .setCoords();
                                                            var f_group = new fabric.Group().set({
                                                                left : pathbox.x+mainOffsetX,
                                                                top : pathbox.y+mainOffsetY,
                                                                width : tableWidth,
                                                                height : tableWidth,
                                                                id: 'table-'+tableid,
                                                                boothGroup: groupName,
                                                                class: 'table',
                                                                class1: "table_"+seatsCnt,
                                                                layer:'banquet',
                                                                preserveObjectStacking: true,
                                                                lockRotation: true,
                                                                lockScalingX: true,
                                                                lockScalingY: true,
                                                                objectCaching: false, //true
                                                                uniq_id: Date.now() + parseInt(Math.random()*10000000000),
                                                                visible:true
                                                            });	
                                                            f_group.set({
                                                                borderColor: '#ff7777',
                                                                cornerColor: 'red',
                                                                cornerSize: 6,
                                                                transparentCorners: false,
                                                            });
                                                            f_group.add(obj)
                                                            var f_text = new fabric.Text(table_number, {
                                                                left: (-(table_number.length*20/Math.max(svgToWorldUnits, 1)/1.7)/2),
                                                                top:  (-(20/Math.max(svgToWorldUnits, 1)*1.05)),
                                                                fontSize: 20/Math.max(svgToWorldUnits, 1), 
                                                                layer: 'table_number',
                                                                class:'tableNumber'
                                                            });
                                                            f_group.add(f_text); 
                                                            f_group.set({booth_number:table_number})
                                                                                                                        
                                                        
                                                            mainCanvas.add(f_group)
                                                            f_text.bringToFront();
                                                            f_text.bringForward(obj);
                                                        }
                                                    });
                                                }
                                                else{
                                                    // console.log("sampleObject empty")
                                                }
                                            }
                                            else {
                                                var fabricpath = fabric.loadSVGFromString(drawer.svg(),function(objects, options) {
                                                    var obj = fabric.util.groupSVGElements(objects, options);
                                                    if(checkiftable(obj))
                                                    {
                                                        // var rotateFlag = checkifrotate(obj)
                                                        var angle = 0;
                                                        obj.set({
                                                            left : pathbox.x+mainOffsetX,
                                                            top : pathbox.y+mainOffsetY,
                                                            objectCaching: false
                                                        })
                                                        .setCoords();
                                                        if(obj.type == 'group'){
                                                            var paths = obj.getObjects();
                                                            for(var i = 0; i < paths.length; i++){
                                                                paths[i].set({
                                                                    fill: bgColor, 
                                                                    strokeWidth:3/Math.max(svgToWorldUnits, 1),
                                                                    stroke:'black',
                                                                })
                                                            }
                                                        }
                                                        if(obj.type != 'group'){
                                                            obj.set({
                                                                fill: bgColor, 
                                                                strokeWidth:3/Math.max(svgToWorldUnits, 1),
                                                                stroke:'black',
                                                            })
                                                        }
                                                        var f_group = new fabric.Group([obj]).set({
                                                            width : obj.width,
                                                            height : obj.height,
                                                            id: 'table-'+tableid,
                                                            boothGroup: groupName,
                                                            class: 'table',
                                                            layer:'banquet',
                                                            preserveObjectStacking: true,
                                                            lockRotation: true,
                                                            lockScalingX: true,
                                                            lockScalingY: true,
                                                            objectCaching: false, //true
                                                            uniq_id: Date.now() + parseInt(Math.random()*10000000000),
                                                            
                                                        });	
                                                        f_group.set({
                                                            borderColor: '#ff7777',
                                                            cornerColor: 'red',
                                                            cornerSize: 6,
                                                            transparentCorners: false,
                                                        });

                                                        var f_text = new fabric.Text(table_number, {
                                                            left: (f_group.width/2-(table_number.length*9.3)/2),
                                                            top:  (f_group.height/2-(table_number.length*8)/2),
                                                            fontSize: 15/Math.max(svgToWorldUnits, 1), 
                                                            layer: 'table_number',
                                                            class:'tableNumber'
                                                        });
                                                        // mainCanvas.add(f_text); 
                                                        f_group.add(f_text); 
                                                        f_group.set({booth_number:table_number})
                                                        
                                                        // obj.clone(function(cloned) {
                                                        //     f_group.clipPath = cloned                                    
                                                        // });

                                                        
                                                        if(angle > 0){
                                                            f_group.set({
                                                                angle:angle
                                                            })
                                                        }
                                                        mainCanvas.add(f_group)
                                                        f_text.bringToFront();
                                                        f_text.bringForward(obj);
                                                    }
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                            else if(layer.venue_selected){
                                var drawer = SVG();          
                                var secondgroups = topgroup[0].find("g");
                                if(secondgroups.length > 0){     
                                    for(var groupInd = 0; groupInd < secondgroups.length; groupInd++){      
                                        var id =   (secondgroups[groupInd]['id']?secondgroups[groupInd]['id']:"")+""  
                                        var paths =secondgroups[groupInd].find('path');
                                        
                                        for(var i = 0; i < paths.length; i++){   
                                            var path = paths[i];
                                            var pathbox = path.bbox();
                                            var pathtransform = path.transform();
                                            var drawer = SVG();
                                            var group = drawer.group();
                                            group.attr({
                                                fill:'none',
                                                stroke:"black",
                                                preserveObjectStacking: true
                                            })
                                            path.move(0,0)
                                            var svgPath = SVG(path);
                                            group.add(svgPath);
                                            const computedStyle = window.getComputedStyle(path.node);
                                            const strokeWidth = computedStyle.getPropertyValue('stroke-width');
                                            var f_group = new fabric.Group().set({
                                                left : pathbox.x+mainOffsetX,
                                                top : pathbox.y+mainOffsetY,
                                                width : pathbox.width,
                                                height : pathbox.height,
                                                lockRotation: true,
                                                lockScalingX: true,
                                                lockScalingY: true,
                                            });	
                                            f_group.set({
                                                borderColor: '#ff7777',
                                                cornerColor: 'red',
                                                cornerSize: 6,
                                                transparentCorners: false,
                                                layer:'venue',
                                                class:'venue',
                                                preserveObjectStacking: true
                                            });
                                            fabric.Object.prototype.transparentCorners = false;
                                            ////console.log("drawer.svg()",drawer.svg())
                                            fabric.loadSVGFromString(drawer.svg(),function(objects, options) {
                                                var obj = fabric.util.groupSVGElements(objects, options);
                                                // obj.scaleToHeight(mainCanvas.height-10)
                                                    // .set({ left: mainCanvas.width/2, top: mainCanvas.height/2 })
                                                obj.set({
                                                    left: 0-pathbox.width/2, 
                                                    top: 0-pathbox.height/2, 
                                                    visible:1,
                                                    layer: 'venue',
                                                    fill:'black',
                                                    strokeWidth:5,//(strokeWidth?strokeWidth:1)*Math.min(2/(svgToWorldUnits?svgToWorldUnits:1),10)
                                                })
                                                .setCoords();
                                                
                                                obj.clone(function(cloned) {
                                                    obj.clipPath = cloned                                    
                                                });
                                                f_group.add(obj)
                                                mainCanvas.add(f_group)
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                mainCanvas.renderAll();
                renderTextEntities(mainSvg, entities)    
                
                var mapWidth = mainCanvas.mapWidth*1
                var default_grid;
                if(mainCanvas.mapUnit == "feet"){
                    var default_grid = 10;
                    
                    // if(mapWidth < 10){
                    //     default_grid = 0.1
                    // }
                    // else if(mapWidth < 100){
                    //     default_grid = 1
                    // }
                    // if(mapWidth > 1000){
                    //     default_grid = 50
                    // }
                    // if(mapWidth > 10000){
                    //     default_grid = 100
                    // }
                }
                else {
                    var default_grid = 3;
                    // if(mapWidth > 1000){
                    //     default_grid = 6
                    // }
                    // if(mapWidth > 10000){
                    //     default_grid = 9
                    // }
                }
                mainCanvas.set({
                    gridSize:default_grid,  
                    snapToGrid:true ,
                    showGrid: true,
                    gridAngle: 0,
                    mapOffsetX: 0,
                    mapOffsetY: 0             
                })    
                var mapWidth = 0;
                var mapHeight = 0;
                if(mainCanvas.mapWidth && mainCanvas.mapWidth*1 && mainCanvas.mapHeight && mainCanvas.mapHeight*1){
                    mapWidth = unit2Px(mainCanvas.mapWidth?mainCanvas.mapWidth:0, svgToWorldUnits, mainCanvas.mapUnit)
                    mapHeight = unit2Px(mainCanvas.mapHeight?mainCanvas.mapHeight:0, svgToWorldUnits, mainCanvas.mapUnit)
                }

                mainCanvas.clipPath = new fabric.Rect({
                    width: mapWidth+5,
                    height: mapHeight+5,
                    top: 45,
                    left: 45
                });    
                bgCanvas.clipPath = new fabric.Rect({
                    width: mapWidth+5,
                    height: mapHeight+5,
                    top: 45,
                    left: 45
                });    
                bgImgCanvas.clipPath = new fabric.Rect({
                    width: mapWidth+5,
                    height: mapHeight+5,
                    top: 45,
                    left: 45
                });    
                
                fabric.Object.prototype.objectCaching = false //true
                mainCanvas.renderOnAddRemove = true
                centerFocus(mainCanvas, bgCanvas, g_grid, topRuler, leftRuler, bgImgCanvas, false)
                try{
                    mainCanvas.renderAll()
                }
                catch(e){
                    // console.log("render imported map error", e);
                }
                bgCanvas.renderAll()
                bgImgCanvas.renderAll()
                redrawRulers(mainCanvas, topRuler, leftRuler)
                redrawGrid(g_grid, mainCanvas, (mainCanvas.showGrid &&mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
                renderBorder(mainCanvas);           
                props.onClearHistory();
                props.setBoothHistory({content: (props.main.mainCanvas.toDatalessObject()), action:{type:'load'}})
                console.log("active_booth_file", active_booth_file)
                if(!props.main.dwgimported){
                    if(active_booth_file.column_url && false){ //draw venue image
                        await setBackgroundImage(bgCanvas, active_booth_file.column_url.replace("http://12.41.127.199", "https://mapbeta.goeshow.com"), {
                            // opacity: 0.5,
                            angle: 0,
                            left: active_booth_file.bg_offset_x*1+50,
                            top: active_booth_file.bg_offset_y*1+50,
                            originX: 'left',
                            originY: 'top',
                            scaleX: active_booth_file.bg_scale,
                            scaleY: active_booth_file.bg_scale,
                            crossOrigin: 'anonymous',
                            KEY_ID: uuidv4()
                        });
                    }

                    if(active_booth_file.bg_url){
                        
                        fabric.Image.fromURL(active_booth_file.bg_url.replace("http://12.41.127.199", "https://mapbeta.goeshow.com"), (img) => {
                            bgImgCanvas.setBackgroundImage(img, confirmSaveFile.bind(mainCanvas), {
                                // opacity: 0.5,
                                angle: 0,
                                left: active_booth_file.bg_offset_x*1+50,
                                top: active_booth_file.bg_offset_y*1+50,
                                originX: 'left',
                                originY: 'top',
                                scaleX: active_booth_file.bg_scale,
                                scaleY: active_booth_file.bg_scale,
                                crossOrigin: 'anonymous',
                                KEY_ID: uuidv4()
                            }).renderAll();
                        })
                        
                    }
                    else{
                        confirmSaveFile() 
                    }                
                    props.setDwgImported(true)
                }
                // props.setLoading(false)

            })
        }
    }
    const renderTextEntities=(svgStr,  entities)=>{
        if(svgStr){
            var drawermain = SVG(svgStr);
            // drawermain.svg(svgStr);
            ////console.log("hi");
            // ////console.log(drawermain.svg())
            // var topgroup = drawermain.find("[id*='layer_ESHOW_NUMBERS_FINAL']");
            
            var svgToWorldUnits= 1
            var convertRateY= 1
            if(mainCanvas.svgToWorldUnits) {
                var svgToWorldUnits= Math.abs(mainCanvas.svgToWorldUnits)
                var convertRateY= Math.abs(mainCanvas.convertRateY)
            }
            for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
                var layer = mainLayers[layerInd];
                if(layer){
                    for(var entInd = 0; entInd < entities?.length; entInd++)   
                    {
                        var entity = entities[entInd];
                        if(layer.name == entity.Layer) {
                            var newPoints = [];
                            var angle = entity.Rotation*180/Math.PI;
                            // var angle = 0;
                            if(entity.Type == 'Text' || entity.Type == 'MText'){
                                var boothName = entity.Text_String;                                                    
                                var svgToWorldUnits = mainCanvas.svgToWorldUnits
                                var position_x
                                var position_y
                                position_x = (entity.Text_Position.x*1 - mainCanvas.convertOffsetXOrg*1)/svgToWorldUnits+50;
                                position_y = (entity.Text_Position.y*1 - mainCanvas.convertOffsetYOrg*1)/(-svgToWorldUnits)+50;
        
                                // if(boothName == "27"){
                                //     console.log("boothName 1001", boothName)
                                // }
                                var fontSize = entity.Text_Height/svgToWorldUnits;
                                if(!fontSize) fontSize = 20/svgToWorldUnits
                                var fontSizeOrg = fontSize
                                // fontSize = 50/svgToWorldUnits
                                if(layer.number_selected){
                                    var group_objects = mainCanvas.getObjects('group');
                                    var f_text_booth = new fabric.Text(boothName?boothName:"", { 
                                        fontSize:fontSize,
                                        class:'boothName',
                                        class1:'boothNumber',
                                        objectCaching: false,
                                        overflow:'hidden',
                                        fill: 'black',
                                        left:position_x,
                                        left:position_y
                                    });
                                    mainCanvas.add(f_text_booth);
                                    
                                    if(boothName*1 == 359){                                    
                                        // console.log("359", position_x, position_y, position_x/12, position_y/12)
                                    }
                                    var foundObject = false
                                    for(var j = 0; j < group_objects.length; j++){
                                        var group_object = group_objects[j]
                                        if(group_object.Handle == "E5A5"){
                                            console.log("group_object", group_object)
                                        }
                                        if(
                                            group_object.get('class')=="booth" && 
                                            group_object.containsPoint(
                                                new fabric.Point(position_x+(entity.Rotation > 0?(fontSizeOrg/4):fontSizeOrg/2), position_y-(entity.Rotation > 0?0:fontSizeOrg/2))
                                                , null, true
                                            )
                                        )
                                        {
                                            foundObject = group_object                                           

                                        }
                                    }
                                    if(!foundObject){
                                        for(var j = 0; j < group_objects.length; j++){
                                            var group_object = group_objects[j]
                                            if(
                                                group_object.get('class')=="booth" && 
                                                group_object.containsPoint(
                                                    new fabric.Point(position_x+(entity.Rotation > 0?(fontSizeOrg/4):fontSizeOrg/2), position_y-(entity.Rotation > 0?0:fontSizeOrg))
                                                    , null, true
                                                )
                                            )
                                            {
                                                foundObject = group_object                                           

                                            }
                                        }
                                    }
                                    if(!foundObject){
                                        for(var j = 0; j < group_objects.length; j++){
                                            var group_object = group_objects[j]
                                            if(group_object.Handle == "E5A5"){
                                                console.log("group_object", group_object)
                                            }
                                            
                                            var Entity_Extents =  group_object.Entity_Extents
                                            if(
                                                group_object.get('class')=="booth" && Entity_Extents &&
                                                (
                                                    entity.Text_Position.x*1 >= Entity_Extents.Minimum_Point.x*1 && 
                                                    entity.Text_Position.x*1 <= Entity_Extents.Maximum_Point.x*1 &&
                                                    entity.Text_Position.y*1 >= Entity_Extents.Minimum_Point.y*1 &&
                                                    entity.Text_Position.y*1 <= Entity_Extents.Maximum_Point.y*1
                                                )
                                            )
                                            {
                                                foundObject = group_object                                           

                                            }
                                        }
                                    }
                                    if(foundObject){
                                        var existingTxt = false;
                                        var txts = foundObject.getObjects('text');
                                        // //console.log("txts", txts)
                                        for(var txtInd = 0; txtInd < txts.length; txtInd++){
                                            if(txts[txtInd].get('class') == 'boothName'){
                                                existingTxt = txts[txtInd]; break;
                                            }
                                        }
                                        // //console.log("existingTxt", JSON.stringify(existingTxt))
                                        if(!existingTxt){
                                            foundObject.set({booth_number:boothName})
                                            addText(mainCanvas, foundObject, boothName)
                                            
                                            foundObject.clone(function(cloned) {
                                                cloned.set({
                                                    layer: 'original',
                                                    class: 'original-booth',
                                                    visible:false,
                                                    lockMovementX: true,
                                                    lockMovementY: true,
                                                    lockRotation: true,
                                                    lockScalingX: true,
                                                    lockScalingY: true,
                                                    selection:false,
                                                    hasControls:false,
                                                    hasBorders:false,
                                                    evented:false,
                                                    selectable: false ,
                                                })
                                                var pathAs = cloned.getObjects('path')
                                                if(pathAs && pathAs[0]){
                                                    pathAs[0].set('stroke', 'red')
                                                }
                                                pathAs = cloned.getObjects('rect')
                                                if(pathAs && pathAs[0]){
                                                    pathAs[0].set('stroke', 'red')
                                                }
                                                mainCanvas.add(cloned)                                                           
                                            });
                                        }
                                        else{
                                            existingTxt.set('text', existingTxt.get('text')+" "+boothName)
                                        }
                                    }
                                }
                                else{
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    const parseSvgPathChilds = async (svgStr)=>{
        if(svgStr){
            unlockMap(mainCanvas)
            props.setLockFlag(false)
            var {convertRateX, convertRateY, convertOffsetX, convertOffsetY} = getSvgInfo(svgStr);
            var svgTopElement = jQuery("svg");
            var drawermain = SVG(svgStr);
            // drawermain.svg(svgStr);
            ////console.log("hi");
            // ////console.log(drawermain.svg())
            mainCanvas.selectionColor = 'rgba(25, 118, 210,0.3)';
            mainCanvas.selectionBorderColor = 'rgba(255,0,0,0.8)';
            mainCanvas.selectionLineWidth = 3;
            // console.log("mainLayers", mainLayers)

            var bgColor = "#dadada"
            fabric.loadSVGFromURL("/table_round_6.svg",function(objects, options) {
                table6Object = fabric.util.groupSVGElements(objects, options);
                table6Object.set({
                    fill: bgColor, 
                    strokeWidth:3/convertRateX,
                    stroke:'black',
                })
            });
            fabric.loadSVGFromURL("/table_round_8.svg",function(objects, options) {
                table8Object = fabric.util.groupSVGElements(objects, options);
                table8Object.set({
                    fill: bgColor, 
                    strokeWidth:3/convertRateX,
                    stroke:'black',
                })
            });
            fabric.loadSVGFromURL("/table_round_10.svg",function(objects, options) {
                table10Object = fabric.util.groupSVGElements(objects, options);
                table10Object.set({
                    fill: bgColor, 
                    strokeWidth:3/convertRateX,
                    stroke:'black',
                })
            
                fabric.Object.prototype.objectCaching = false
                mainCanvas.renderOnAddRemove = false
                for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
                    var layer = mainLayers[layerInd];
                    if(layer){
                        var topgroup = drawermain.find("#"+layer['id']);
                        if(topgroup && topgroup.length > 0){
                            if(layer.outline_selected){
                                var secondgroups = topgroup[0].find("g");
                                if(secondgroups.length > 0){
                                    for(var groupInd = 0; groupInd < secondgroups.length; groupInd++){      
                                        var id =   (secondgroups[groupInd]['id']?secondgroups[groupInd]['id']:"")+""       
                                        //console.log("id", id) 
                                        {
                                            var paths =secondgroups[groupInd].find('path');
                                            ////console.log(paths);
                                            for(var i = 0; i < paths.length; i++){
                                                var path = paths[i];
                                                var pathbox = path.bbox();
                                                var pathtransform = path.transform();
                                                var drawer = SVG();
                                                var group = drawer.group();
                                                group.attr({
                                                    fill:'none',
                                                    stroke:"black",
                                                    preserveObjectStacking: true
                                                })
                                                path.move(0,0)
                                                var svgPath = SVG(path);
                                                group.add(svgPath);
                                                // ////console.log(drawer.svg());
                                                // drawer.css({position:'absolute', left: pathbox.x, top: pathbox.y})
                                                // drawer.addTo('.render-svg').size(pathbox.width, pathbox.height)

                                            
                                                fabric.Object.prototype.transparentCorners = false;
                                                var pathid = path.attr('id')

                                                // var f_text = new fabric.Text((pathbox.x+mainOffsetX).toFixed(2)+", "+ (pathbox.y+mainOffsetY).toFixed(2)+", "+ (pathbox.width).toFixed(2)+", "+ (pathbox.height).toFixed(2), {
                                                //     // left: pathtransform.translateX+mainOffsetX-group_object.left-group_object.width/2+10 -((Math.abs(Math.round(pathtransform.rotate*1)) ==90 || Math.abs(Math.round(pathtransform.rotate*1))==270)?(fontSize-5):0),
                                                //     left: 0,
                                                //     top: 0,
                                                //     // left: ((Math.abs(Math.round(pathtransform.rotate*1)) ==90 || Math.abs(Math.round(pathtransform.rotate*1))==270)?(-fontSize+5):(0-group_object.width/2+5)),
                                                //     // top:  ((Math.abs(Math.round(pathtransform.rotate*1)) ==0 || Math.abs(Math.round(pathtransform.rotate*1))==180)?(-fontSize+5):(0-group_object.height/2+5)),
                                                //     fontSize:16, 
                                                // });


                                                
                                                var fabricpath = fabric.loadSVGFromString(drawer.svg(),function(objects, options) {
                                                    var obj = fabric.util.groupSVGElements(objects, options);
                                                    var bgColor = "#dadada"
                                                    var angle = 0;
                                                    var left = pathbox.x+mainOffsetX;
                                                    var top = pathbox.y+mainOffsetY;
                                                    var path = obj.path
                                                    var polygons = [];
                                                    var polygonPoints = [];
                                                    for(var pathInd = 0; pathInd < path?.length; pathInd++){
                                                        if(path[pathInd][0] == 'M'){
                                                            if(polygonPoints.length){
                                                                polygons.push(polygonPoints)
                                                            }
                                                            polygonPoints = [];
                                                        }
                                                        polygonPoints.push(
                                                            {x: path[pathInd][1], y:path[pathInd][2]}
                                                        )
                                                    }
                                                    if(polygonPoints.length){
                                                        polygons.push(polygonPoints)
                                                    }
                                                    for(var polygonInd = 0; polygonInd < polygons?.length; polygonInd++){
                                                        angle = 0;
                                                        var polygonPoints = polygons[polygonInd]
                                                        var newLeft = left
                                                        var newTop = top
                                                        if(polygonPoints.length >= 5){ //check if booth
                                                            var basePoint = polygonPoints[0];
                                                            for(var pointInd = 0; pointInd<polygonPoints.length; pointInd++ ){
                                                                
                                                                var point = polygonPoints[pointInd];
                                                                if((point.x+point.y)< (basePoint.x + basePoint.y)){
                                                                    basePoint = point
                                                                }
                                                            }
                                                            if(polygonInd > 0 || (basePoint.x != 0 || basePoint.y != 0)){
                                                                newLeft = left + basePoint.x
                                                                newTop = top + basePoint.y
                                                            }
                                                            var newPoints = [];
                                                            for(var pointInd = 0; pointInd<polygonPoints.length; pointInd++ ){
                                                                newPoints.push(
                                                                    {x: polygonPoints[pointInd].x-basePoint.x, y:polygonPoints[pointInd].y-basePoint.y}
                                                                )
                                                            }
                                                            if(i == 2 && polygonInd == 3){
                                                                // console.log("rotated", newPoints)
                                                            }
                                                            var rotateFlag = checkifrotatepoints(newPoints)
                                                            if(rotateFlag){
                                                                var ratateResult = rotatePoints(mainCanvas, newPoints)
                                                                newPoints = ratateResult.newPoints
                                                                angle = ratateResult.angle
                                                                var new_centerPoint = ratateResult.new_centerPoint
                                                                newLeft+= new_centerPoint.x
                                                                newTop+= new_centerPoint.y
                                                            }
                                                            if(newTop == "422.94"){
                                                                // console.log("422.94", newPoints)
                                                            }
                                                            obj = new fabric.Polygon(
                                                                newPoints, {
                                                                
                                                                layer: 'booth_outline',
                                                                class:'outline',
                                                                left : newLeft,
                                                                top : newTop,
                                                                visible:(layer.outline_selected || layer.number_selected),
                                                                fill: bgColor, 
                                                                strokeWidth:3/convertRateX,
                                                                stroke:'black',
                                                                objectCaching: false //true
                                                            });
                                                            var f_group = new fabric.Group([obj]).set({
                                                                //     left : pathbox.x+mainOffsetX,
                                                                //     top : pathbox.y+mainOffsetY,
                                                                    // width : pathbox.width+1,
                                                                    // height : pathbox.height+1,
                                                                    width : obj.width,
                                                                    height : obj.height,
                                                                    id: 'booth-'+pathid+" "+i+" "+polygonInd,
                                                                    boothGroup: getDefaultGroup(props.main.groupOptions),
                                                                    labelPosition: 'bottom-left',
                                                                    class: 'booth',
                                                                    layer:'booth',
                                                                    preserveObjectStacking: true,
                                                                    lockRotation: true,
                                                                    lockScalingX: true,
                                                                    lockScalingY: true,
                                                                    objectCaching: false, //true
                                                                    uniq_id: Date.now() + parseInt(Math.random()*10000000000),
                                                                    KEY_ID: uuidv4()
                                                            });	
                                                            f_group.set({
                                                                borderColor: '#ff7777',
                                                                cornerColor: 'red',
                                                                cornerSize: 6,
                                                                transparentCorners: false,
                                                            });
                                                            
                                                            
                                                            
                                                            if(angle > 0){
                                                                f_group.set({
                                                                    angle:angle
                                                                })
                                                            }
                                                            mainCanvas.add(f_group)
                                                            // console.log("f_group", f_group)
                                                        }
                                                        else{
                                                            // console.log("no polygon", polygonPoints)
                                                        }
                                                    }



                                                    if( 0 && checkifbooth(obj) && 0)
                                                    {
                                                        
                                                        var bgColor = "#dadada"
                                                        var rotateFlag = checkifpathrotate(obj)
                                                        var angle = 0;
                                                        var path = obj.path
                                                        obj.set({
                                                            layer: 'booth_outline',
                                                            class:'outline',
                                                            left : pathbox.x+mainOffsetX,
                                                            top : pathbox.y+mainOffsetY,
                                                            visible:(layer.outline_selected || layer.number_selected), 
                                                            fill: bgColor, 
                                                            strokeWidth:3/convertRateX,
                                                            stroke:'black',
                                                            objectCaching: false, //true
                                                        })
                                                        .setCoords();
                                                        if(rotateFlag){
                                                            angle = rotatePathObject(mainCanvas, obj)
                                                        }
                                                        
                                                        var newPath = obj.path
                                                        var newLeft = obj.left
                                                        var newTop = obj.top
                                                        var polygonPoints = [];
                                                        for(var pathInd = 0; pathInd < newPath?.length; pathInd++){
                                                            polygonPoints.push(
                                                                {x: newPath[pathInd][1], y:newPath[pathInd][2]}
                                                            )
                                                        }
                                                        obj = new fabric.Polygon(
                                                            polygonPoints, {
                                                            
                                                            layer: 'booth_outline',
                                                            class:'outline',
                                                            left : newLeft,
                                                            top : newTop,
                                                            visible:(layer.outline_selected || layer.number_selected),
                                                            fill: bgColor, 
                                                            strokeWidth:3/convertRateX,
                                                            stroke:'black',
                                                            objectCaching: false, //true
                                                        });

                                                        var f_group = new fabric.Group([obj]).set({
                                                        //     left : pathbox.x+mainOffsetX,
                                                        //     top : pathbox.y+mainOffsetY,
                                                            // width : pathbox.width+1,
                                                            // height : pathbox.height+1,
                                                            width : obj.width,
                                                            height : obj.height,
                                                            id: 'booth-'+pathid,
                                                            boothGroup: getDefaultGroup(props.main.groupOptions),
                                                            labelPosition: 'bottom-left',
                                                            class: 'booth',
                                                            layer:'booth',
                                                            preserveObjectStacking: true,
                                                            lockRotation: true,
                                                            lockScalingX: true,
                                                            lockScalingY: true,
                                                            objectCaching: false, //true
                                                            uniq_id: Date.now() + parseInt(Math.random()*10000000000),
                                                            KEY_ID: uuidv4()
                                                        });	
                                                        f_group.set({
                                                            borderColor: '#ff7777',
                                                            cornerColor: 'red',
                                                            cornerSize: 6,
                                                            transparentCorners: false,
                                                        });
                                                        
                                                        
                                                        // obj.clone(function(cloned) {
                                                        //     f_group.clipPath = cloned                                    
                                                        // });
                                                        
                                                        if(angle > 0){
                                                            f_group.set({
                                                                angle:angle
                                                            })
                                                        }
                                                        mainCanvas.add(f_group)
                                                        // mainCanvas.renderAll();   
                                                    }
                                                });
                                                var newBooth = {
                                                    id: path.attr('id')
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            else if(layer.number_selected){

                            }
                            else if(layer.banquet_selected){
                                
                                var secondgroups = topgroup[0].children();
                                if(secondgroups.length > 0){
                                    for(var groupInd = 0; groupInd < secondgroups.length; groupInd++){      
                                        var id =   (secondgroups[groupInd]?secondgroups[groupInd].attr('id'):"")+"" 
                                        var groupName =   "Default" 
                                        var tmp = id.split("_");
                                        var table_number = 0;
                                        if(tmp && tmp.length > 0){
                                            table_number = tmp[tmp.length-1];
                                        }
                                        {
                                            var tables =secondgroups[groupInd].find('g');
                                            var table = null;
                                            if(tables && tables.length > 0){
                                                table = tables[0]
                                            }
                                            else{
                                                continue;
                                            }
                                            var paths = table.find('text');
                                            var flag = false;
                                            for(var i = 0; i < paths?.length; i++){
                                                var path = paths[i];
                                                var text = path.text();
                                                if(text && (text.toString().includes("For Demonstration Purposes Only") || text.toString().includes("Tailor Made Software") || text.toString().includes("www.cadviewer.com") || text.toString().includes("www.tailormade.com"))){
                                                    flag = true;  break;
                                                }
                                            }
                                            if(flag){
                                                continue;
                                            }
                                            var pathbox = table.bbox();
                                            var pathtransform = table.transform();
                                            var drawer = SVG();
                                            var group = drawer.group();
                                            group.attr({
                                                fill:'none',
                                                stroke:"black",
                                                preserveObjectStacking: true
                                            })
                                            table.move(0,0)
                                            var svgPath = SVG(table);
                                            group.add(svgPath);
                                        
                                            fabric.Object.prototype.transparentCorners = false;
                                            var tableid = id

                                            var bgColor = "#dadada"

                                            var table_path = "";
                                            var seatsCnt = 0;
                                            if(tableid){
                                                var tmp = tableid.split("_");
                                                var index = tmp.indexOf('ROUND')
                                                if(index >=0) {
                                                    var pathCnt = tmp[index + 1];
                                                    seatsCnt = pathCnt/2
                                                }
                                            }

                                            if(seatsCnt == 6 || seatsCnt == 8 || seatsCnt == 10)
                                            {
                                                var sampleObject;
                                                var tableWidth;
                                                if(seatsCnt == 6){
                                                    sampleObject = table6Object
                                                    if(convertRateX){
                                                        tableWidth = (108/convertRateX) //48inch
                                                    }
                                                }
                                                if(seatsCnt == 8){
                                                    sampleObject = table8Object
                                                    if(convertRateX){
                                                        tableWidth = (120/convertRateX) //60inch
                                                    }
                                                }
                                                else if(seatsCnt == 10){
                                                    sampleObject = table10Object
                                                    if(convertRateX){
                                                        tableWidth = (132/convertRateX) //72inch
                                                    }
                                                }
                                                groupName = seatsCnt+" seat table"
                                                if(sampleObject){                                            
                                                    sampleObject.clone(function(obj) {
                                                        obj.width = sampleObject.width
                                                        obj.height = sampleObject.height
                                                        obj.scaleX = sampleObject.scaleX
                                                        obj.scaleY = sampleObject.scaleY
                                                        if(checkiftable(obj))
                                                        {
                                                            
                                                            obj.scale(tableWidth/obj.width)
                                                            obj.set({
                                                                left: 0-NaN,
                                                                top: 0-NaN,
                                                            })
                                                            .setCoords();
                                                            // var rotateFlag = checkifrotate(obj)
                                                            var angle = 0;
                                                            // obj.set({
                                                            //     left : pathbox.x+mainOffsetX,
                                                            //     top : pathbox.y+mainOffsetY,
                                                            //     objectCaching: false,
                                                            //     dirty: true
                                                            // })
                                                            // .setCoords();
                                                            var f_group = new fabric.Group().set({
                                                                left : pathbox.x+mainOffsetX,
                                                                top : pathbox.y+mainOffsetY,
                                                                width : tableWidth,
                                                                height : tableWidth,
                                                                id: 'table-'+tableid,
                                                                boothGroup: groupName,
                                                                class: 'table',
                                                                class1: "table_"+seatsCnt,
                                                                layer:'banquet',
                                                                preserveObjectStacking: true,
                                                                lockRotation: true,
                                                                lockScalingX: true,
                                                                lockScalingY: true,
                                                                objectCaching: false, //true
                                                                uniq_id: Date.now() + parseInt(Math.random()*10000000000),
                                                                visible:true
                                                            });	
                                                            f_group.set({
                                                                borderColor: '#ff7777',
                                                                cornerColor: 'red',
                                                                cornerSize: 6,
                                                                transparentCorners: false,
                                                            });
                                                            f_group.add(obj)
                                                            var f_text = new fabric.Text(table_number, {
                                                                left: (-(table_number.length*20/convertRateX/1.7)/2),
                                                                top:  (-(20/convertRateX*1.05)),
                                                                fontSize: 20/convertRateX, 
                                                                layer: 'table_number',
                                                                class:'tableNumber'
                                                            });
                                                            f_group.add(f_text); 
                                                            f_group.set({booth_number:table_number})
                                                                                                                        
                                                        
                                                            mainCanvas.add(f_group)
                                                            f_text.bringToFront();
                                                            f_text.bringForward(obj);
                                                        }
                                                    });
                                                }
                                                else{
                                                    // console.log("sampleObject empty")
                                                }
                                            }
                                            else {
                                                var fabricpath = fabric.loadSVGFromString(drawer.svg(),function(objects, options) {
                                                    var obj = fabric.util.groupSVGElements(objects, options);
                                                    if(checkiftable(obj))
                                                    {
                                                        // var rotateFlag = checkifrotate(obj)
                                                        var angle = 0;
                                                        obj.set({
                                                            left : pathbox.x+mainOffsetX,
                                                            top : pathbox.y+mainOffsetY,
                                                            objectCaching: false
                                                        })
                                                        .setCoords();
                                                        if(obj.type == 'group'){
                                                            var paths = obj.getObjects();
                                                            for(var i = 0; i < paths.length; i++){
                                                                paths[i].set({
                                                                    fill: bgColor, 
                                                                    strokeWidth:3/convertRateX,
                                                                    stroke:'black',
                                                                })
                                                            }
                                                        }
                                                        if(obj.type != 'group'){
                                                            obj.set({
                                                                fill: bgColor, 
                                                                strokeWidth:3/convertRateX,
                                                                stroke:'black',
                                                            })
                                                        }
                                                        var f_group = new fabric.Group([obj]).set({
                                                            width : obj.width,
                                                            height : obj.height,
                                                            id: 'table-'+tableid,
                                                            boothGroup: groupName,
                                                            class: 'table',
                                                            layer:'banquet',
                                                            preserveObjectStacking: true,
                                                            lockRotation: true,
                                                            lockScalingX: true,
                                                            lockScalingY: true,
                                                            objectCaching: false, //true
                                                            uniq_id: Date.now() + parseInt(Math.random()*10000000000),
                                                            
                                                        });	
                                                        f_group.set({
                                                            borderColor: '#ff7777',
                                                            cornerColor: 'red',
                                                            cornerSize: 6,
                                                            transparentCorners: false,
                                                        });

                                                        var f_text = new fabric.Text(table_number, {
                                                            left: (f_group.width/2-(table_number.length*9.3)/2),
                                                            top:  (f_group.height/2-(table_number.length*8)/2),
                                                            fontSize: 15/convertRateX, 
                                                            layer: 'table_number',
                                                            class:'tableNumber'
                                                        });
                                                        // mainCanvas.add(f_text); 
                                                        f_group.add(f_text); 
                                                        f_group.set({booth_number:table_number})
                                                        
                                                        // obj.clone(function(cloned) {
                                                        //     f_group.clipPath = cloned                                    
                                                        // });

                                                        
                                                        if(angle > 0){
                                                            f_group.set({
                                                                angle:angle
                                                            })
                                                        }
                                                        mainCanvas.add(f_group)
                                                        f_text.bringToFront();
                                                        f_text.bringForward(obj);
                                                    }
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                            else{
                                // var drawer = SVG();          
                                // var secondgroups = topgroup[0].find("g");
                                // if(secondgroups.length > 0){             
                                //     drawer.add(secondgroups[0]);   
                                //     var pathbox = topgroup[0].bbox();
                                //     secondgroups[0].move(0,0)
                                //     var f_group = new fabric.Group().set({
                                //         left : pathbox.x+mainOffsetX,
                                //         top : pathbox.y+mainOffsetY,
                                //         width : pathbox.width,
                                //         height : pathbox.height,
                                //         lockRotation: true,
                                //         lockScalingX: true,
                                //         lockScalingY: true,
                                //     });	
                                //     f_group.set({
                                //         borderColor: '#ff7777',
                                //         cornerColor: 'red',
                                //         cornerSize: 6,
                                //         transparentCorners: false,
                                //         layer:'booth',
                                //         preserveObjectStacking: true
                                //     });
                                //     fabric.Object.prototype.transparentCorners = false;
                                //     ////console.log("drawer.svg()",drawer.svg())
                                //     fabric.loadSVGFromString(drawer.svg(),function(objects, options) {
                                //         var obj = fabric.util.groupSVGElements(objects, options);
                                //         // obj.scaleToHeight(mainCanvas.height-10)
                                //             // .set({ left: mainCanvas.width/2, top: mainCanvas.height/2 })
                                //         obj.set({
                                //             left: 0-pathbox.width/2, 
                                //             top: 0-pathbox.height/2, 
                                //             visible:1,
                                //             layer: 'booth_outline'
                                //         })
                                //         .setCoords();
                                        
                                //         obj.clone(function(cloned) {
                                //             obj.clipPath = cloned                                    
                                //         });
                                //         f_group.add(obj)
                                //         mainCanvas.add(f_group)
                                //     });
                                // }
                            }
                        }
                    }
                }
                parseSvgTextChilds(mainSvg)    
                
                var mapWidth = mainCanvas.mapWidth*1
                var default_grid;
                if(mainCanvas.mapUnit == "feet"){
                    var default_grid = 10;
                    
                    // if(mapWidth < 10){
                    //     default_grid = 0.1
                    // }
                    // else if(mapWidth < 100){
                    //     default_grid = 1
                    // }
                    // if(mapWidth > 1000){
                    //     default_grid = 50
                    // }
                    // if(mapWidth > 10000){
                    //     default_grid = 100
                    // }
                }
                else {
                    var default_grid = 3;
                    // if(mapWidth > 1000){
                    //     default_grid = 6
                    // }
                    // if(mapWidth > 10000){
                    //     default_grid = 9
                    // }
                }
                mainCanvas.set({
                    gridSize:default_grid,  
                    snapToGrid:true ,
                    showGrid: true,
                    gridAngle: 0,
                    mapOffsetX: 0,
                    mapOffsetY: 0             
                })    
                mainCanvas.clipPath = new fabric.Rect({
                    width: Math.abs(mainCanvas.endX - mainCanvas.startX)+5,
                    height: Math.abs(mainCanvas.endY - mainCanvas.startY)+5,
                    top: 45,
                    left: 45
                });    
                bgCanvas.clipPath = new fabric.Rect({
                    width: Math.abs(mainCanvas.endX - mainCanvas.startX)+5,
                    height: Math.abs(mainCanvas.endY - mainCanvas.startY)+5,
                    top: 45,
                    left: 45
                });    
                bgImgCanvas.clipPath = new fabric.Rect({
                    width: Math.abs(mainCanvas.endX - mainCanvas.startX)+5,
                    height: Math.abs(mainCanvas.endY - mainCanvas.startY)+5,
                    top: 45,
                    left: 45
                });    
                
                fabric.Object.prototype.objectCaching = false //true
                mainCanvas.renderOnAddRemove = true
                centerFocus(mainCanvas, bgCanvas, g_grid, topRuler, leftRuler, bgImgCanvas, false)
                try{
                    mainCanvas.renderAll()
                }
                catch(e){
                    console.log("render imported map error", e);
                }
                bgCanvas.renderAll()
                bgImgCanvas.renderAll()
                redrawRulers(mainCanvas, topRuler, leftRuler)
                redrawGrid(g_grid, mainCanvas, (mainCanvas.showGrid &&mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
                renderBorder(mainCanvas);           
                props.onClearHistory();
                props.setBoothHistory({content: (props.main.mainCanvas.toDatalessObject()), action:{type:'load'}})
                if(!props.main.dwgimported){
                    if(active_booth_file.bg_url){
                        // bg_url:imgUrl,
                        // bg_width:imageWidth,
                        // bg_height:imageHeight,
                        // bg_scale:factor,
                        // bg_offset_x:offsetX,
                        // bg_offset_y:offsetY,
                        // bg_source_type:filetype
                        fabric.Image.fromURL(assetUrl(active_booth_file.bg_url), (img) => {
                            bgImgCanvas.setBackgroundImage(img, confirmSaveFile.bind(mainCanvas), {
                                // opacity: 0.5,
                                angle: 0,
                                left: active_booth_file.bg_offset_x*1+50,
                                top: active_booth_file.bg_offset_y*1+50,
                                originX: 'left',
                                originY: 'top',
                                scaleX: active_booth_file.bg_scale,
                                scaleY: active_booth_file.bg_scale,
                                crossOrigin: 'anonymous',
                                KEY_ID: uuidv4()
                            }).renderAll();
                        })
                        
                    }
                    else{
                        confirmSaveFile() 
                    }                
                    props.setDwgImported(true)
                }
                // props.setLoading(false)

            })
        }
    }
    const parseSvgTextChilds = (svgStr)=>{
        if(svgStr){
            var drawermain = SVG(svgStr);
            // drawermain.svg(svgStr);
            ////console.log("hi");
            // ////console.log(drawermain.svg())
            // var topgroup = drawermain.find("[id*='layer_ESHOW_NUMBERS_FINAL']");
            
            var convertRateX= 1
            var convertRateY= 1
            if(mainCanvas.convertRateX) {
                var convertRateX= Math.abs(mainCanvas.convertRateX)
                var convertRateY= Math.abs(mainCanvas.convertRateY)
            }
            for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
                var layer = mainLayers[layerInd];
                if(layer){
                    var topgroup = drawermain.find("#"+layer['id']);
                    if(topgroup && topgroup.length > 0){
                        if(layer['id'].toLowerCase().includes("boothnumber") || 1){
                            var secondgroups = topgroup[0].find("g");
                            if(secondgroups.length > 0){
                                for(var secondGroupInd = 0; secondGroupInd<secondgroups.length; secondGroupInd++){
                                    var paths = secondgroups[secondGroupInd].find('text');
                                    ////console.log(paths);
                                    for(var i = 0; i < paths?.length; i++){
                                        var path = paths[i];
                                        var boothName = path.text();
                                        
                                        var fontSize = path.parent().attr('font-size');
                                        var fontSizeOrg = fontSize
                                        if(!fontSize) fontSize = 20
                                        fontSize = 50/convertRateX
                                        ////console.log("boothname", boothName);
                                        var parentGroup = path.parents(SVG.G);
                                        var pathbox = path.bbox();
                                        var pathtransform = path.transform();
                                        var drawer = SVG();
                                        var group = drawer.group();
                                        group.attr({
                                            fill:'black',
                                            stroke:"none",
                                            'font-size':'26.6497',
                                            "font-family": "arial, helvetica, sans-serif",
                                        })
                                            path.transform({
                                                translate:[0,0]
                                            })
                                        var svgPath = SVG(path);
                                        group.add(svgPath);
                                        ////console.log(drawer.svg());
                                        // drawer.css({position:'absolute', left: pathbox.x, top: pathbox.y})
                                        // drawer.addTo('.render-svg').size(pathbox.width, pathbox.height)
                                        if(boothName && boothName!="" && !boothName.toString().includes("For Demonstration Purposes Only") && !boothName.toString().includes("Tailor Made Software") && !boothName.toString().includes("www.cadviewer.com") && !boothName.toString().includes("www.tailormade.com")){
                                            //console.log("boothName", boothName)
                                            if(layer.number_selected){
                                                var group_objects = mainCanvas.getObjects('group');
                                                for(var j = 0; j < group_objects.length; j++){
                                                    var group_object = group_objects[j]
                                                    var objectPos = {
                                                        xStart: group_object.left,
                                                        xEnd: group_object.left+group_object.width,
                                                        yStart: group_object.top,
                                                        yEnd: group_object.top+group_object.height
                                                    }
                                                    var centerPoint = new fabric.Point(pathtransform.translateX+mainOffsetX, pathtransform.translateY+mainOffsetY);
                                                    var org = new fabric.Point(pathtransform.translateX+mainOffsetX+fontSizeOrg/2, pathtransform.translateY+mainOffsetY-fontSizeOrg/2);
                                                    var new_loc = fabric.util.rotatePoint(org, centerPoint, pathtransform.rotate*2*Math.PI/360)
                                                    
                                                    if(boothName == "2622"){
                                                        console.log("boothName 2622", boothName)
                                                    }
                                                    if(
                                                        group_object.get('class')=="booth" && 
                                                        // objectPos.xStart < pathtransform.translateX+mainOffsetX+fontSizeOrg/2 && 
                                                        // pathtransform.translateX+mainOffsetX+fontSizeOrg/2< objectPos.xEnd && 
                                                        // objectPos.yStart < pathtransform.translateY+mainOffsetY+fontSizeOrg/2 &&
                                                        // pathtransform.translateY+mainOffsetY+fontSizeOrg/2< objectPos.yEnd 
                                                        group_object.containsPoint(
                                                            new fabric.Point(new_loc.x, new_loc.y)
                                                        )
                                                    )
                                                    {
                                                        
                                                        if(boothName == "2622"){
                                                            console.log("boothName 2622", boothName)
                                                        }
                                                        // //console.log("objectPos", objectPos)
                                                        // //console.log("pathtransform", pathtransform)
                                                        // //console.log("pathbox", pathbox)
                                                        // //console.log("pathtransform.translateX+mainOffsetX+fontSizeOrg/2", pathtransform.translateX+mainOffsetX+fontSizeOrg/2)
                                                        // //console.log("pathtransform.translateY+mainOffsetY-fontSizeOrg/2", pathtransform.translateY+mainOffsetY+fontSizeOrg/2)
                                                        var existingTxt = false;
                                                        var txts = group_object.getObjects('text');
                                                        // //console.log("txts", txts)
                                                        for(var txtInd = 0; txtInd < txts.length; txtInd++){
                                                            if(txts[txtInd].get('class') == 'boothName'){
                                                                existingTxt = txts[txtInd]; break;
                                                            }
                                                        }
                                                        // //console.log("existingTxt", JSON.stringify(existingTxt))
                                                        if(!existingTxt){
                                                            // ////console.log("group_object", group_object);
                                                            // ////console.log("pathbox", pathbox);
                                                            // ////console.log("pathtransform", pathtransform);

                                                            // //console.log("boothName", boothName)
                                                            // var f_text = new fabric.Text(boothName+": "+(pathtransform.translateX+mainOffsetX).toFixed(2)+", "+ (pathtransform.translateY+mainOffsetY).toFixed(2), {
                                                            // var f_text = new fabric.Text(boothName, {
                                                            //     // left: pathtransform.translateX+mainOffsetX-group_object.left-group_object.width/2+10 -((Math.abs(Math.round(pathtransform.rotate*1)) ==90 || Math.abs(Math.round(pathtransform.rotate*1))==270)?(fontSize-5):0),
                                                            //     // left: pathtransform.translateX+mainOffsetX,
                                                            //     // top: pathtransform.translateY+mainOffsetY,
                                                            //     // left: ((Math.abs(Math.round(pathtransform.rotate*1)) ==90 || Math.abs(Math.round(pathtransform.rotate*1))==270)?(-fontSize+5):(0-group_object.width/2+5)),
                                                            //     // top:  ((Math.abs(Math.round(pathtransform.rotate*1)) ==0 || Math.abs(Math.round(pathtransform.rotate*1))==180)?(-fontSize+5):(0-group_object.height/2+5)),
                                                            //     left: (0-group_object.width/2+5/convertRateX),
                                                            //     top:  (0-group_object.height/2+5/convertRateX),
                                                            //     fontSize:fontSize, 
                                                            //     visible:(layer.outline_selected || layer.number_selected),
                                                            //     angle: pathtransform.rotate,
                                                            //     layer: (layer.outline_selected?'booth_outline':(layer.number_selected?'booth_number':'')),
                                                            //     class:'boothName'
                                                            // });
                                                            // // mainCanvas.add(f_text); 
                                                            // group_object.add(f_text); 
                                                            addText(mainCanvas, group_object, boothName)
                                                            group_object.set({booth_number:boothName})
                                                            
                                                            group_object.clone(function(cloned) {
                                                                cloned.set({
                                                                    layer: 'original',
                                                                    class: 'original-booth',
                                                                    visible:false,
                                                                    lockMovementX: true,
                                                                    lockMovementY: true,
                                                                    lockRotation: true,
                                                                    lockScalingX: true,
                                                                    lockScalingY: true,
                                                                    selection:false,
                                                                    hasControls:false,
                                                                    hasBorders:false,
                                                                    evented:false,
                                                                    selectable: false ,
                                                                })
                                                                var pathAs = cloned.getObjects('path')
                                                                if(pathAs && pathAs[0]){
                                                                    pathAs[0].set('stroke', 'red')
                                                                }
                                                                pathAs = cloned.getObjects('rect')
                                                                if(pathAs && pathAs[0]){
                                                                    pathAs[0].set('stroke', 'red')
                                                                }
                                                                mainCanvas.add(cloned)                                                           
                                                            });
                                                        }
                                                        else{
                                                            existingTxt.set('text', existingTxt.get('text')+" "+boothName)
                                                        }

                                                    }
                                                }
                                            }
                                            else{
                                                // if(pathtransform.rotate){
                                                //     ////console.log("pathtransform.rotate",pathtransform.rotate)
                                                // }
                                                // var f_text = new fabric.Text(boothName, {
                                                //     left: pathtransform.translateX+mainOffsetX -((Math.abs(Math.round(pathtransform.rotate*1)) ==90 || Math.abs(Math.round(pathtransform.rotate*1))==270)?(fontSize-5):0),
                                                //     top: pathtransform.translateY+mainOffsetY -((Math.abs(Math.round(pathtransform.rotate*1)) ==0 || Math.abs(Math.round(pathtransform.rotate*1))==180)?(fontSize-5):0),
                                                //     fontSize:fontSize, 
                                                //     visible:(layer.outline_selected || layer.number_selected),
                                                //     angle: pathtransform.rotate
                                                // });
                                                // mainCanvas.add(f_text); 
                                            }
                                        }

                                        // var fabricpath = fabric.loadSVGFromString(drawer.svg(),function(objects, options) {
                                        //     var obj = fabric.util.groupSVGElements(objects, options);
                                        //     // obj.scaleToHeight(mainCanvas.height-10)
                                        //         // .set({ left: mainCanvas.width/2, top: mainCanvas.height/2 })
                                        //         obj.set({ left: pathtransform.translateX+mainOffsetX, top: pathtransform.translateY+mainOffsetY })
                                        //         .setCoords();
                                            
                                        //     mainCanvas.add(obj).renderAll();
                                        // });
                                    }                                      
                                }                  
                            }
                        }
                    }
                }
            }
        }
    }

    const parseLayer = (svgStr)=>{
        if(svgStr){
            var drawermain = SVG(svgStr);
            var layerGroups = drawermain.find("[id*='layer_']");
            var layers = [];
            if(layerGroups && layerGroups.length>0){
                for(var loop = 0; loop < layerGroups.length; loop++){
                    var layerGroup = layerGroups[loop];
                    if(layerGroup){
                        var layerid = layerGroup.attr('id');
                        var layername = layerGroup.attr('cvjs:layername')
                        ////console.log("layername", layername)
                        ////console.log("layerid", layerid)
                        layers.push({id:layerid, name:layername, active:true})
                    }
                }
            }
            return layers;
        }
    }

    
    

    const renderMouseOverComponentContent = ()=>{
        var activeObject = mouseoverObject
        if(!activeObject){
            return (
                <></>
            )
        }
        var body = []
        if(activeObject.layer == "annotation"){
            body.push(<div>{activeObject.description}</div>)
        }
        else if(activeObject.layer == "venue"){
            return (
                <></>
            )
        }
        else if(activeObject.layer == "sponsorships"){
            let sponsorshipsItem = false;
            let sponsorshipsOptions = props.main.sponsorshipsOptions
            sponsorshipsItem = sponsorshipsOptions?.find((item)=>item.SPONSORSHIP_ID == activeObject.sponsorshipId)
            if(!sponsorshipsItem){
                body.push(<div></div>)
                return;
            }
            if(sponsorshipsItem.FEATURED){
                body.push(<FeaturedLabel  moreClass='feet-content' style={{backgroundColor:'#EE5D10'}}/>)
            }
            if(sponsorshipsItem.HEADER){
                let headerVideo = false
                for(let i = 0; i < sponsorshipsItem.HEADER?.length; i++){
                    if(sponsorshipsItem.HEADER[i].MEDIA_TYPE == 'Video'){
                        headerVideo = sponsorshipsItem.HEADER[i].URL
                    }
                }
                if(headerVideo){
                    body.push(
                        <div style={{textAlign:'center', marginBottom:'15px'}}>
                            <ReactPlayer width="100%" height="250px" controls muted={true} playing={true} className="video" url={headerVideo} />                                                                     
                        </div>
                    )
                }
            }
            if(sponsorshipsItem.LOGO){
                body.push(
                    <div style={{textAlign:'center'}}>
                        <div className={`loader-${activeObject.sponsorshipId}`} style={{display:'block'}}><ScaleLoader color='#1976d2' radius={2} margin={2} height={30} width={4}/></div>   
                        <img 
                            onLoad={
                                (e)=>{
                                    var ele = document.getElementsByClassName(`loader-${activeObject.sponsorshipId}`)
                                    for (var i = 0; i < ele.length; i++ ) {
                                        ele[i].style.display = "none";
                                    }
                                }
                            } 
                            className='sponsorships-logo' width="100%" height="auto" src={assetUrl(sponsorshipsItem.LOGO)}/>                                                             
                    </div>
                )
            }
            let imagesDiv = [];
            for(let i = 0; i < sponsorshipsItem.PRODUCTS?.length; i++){                
                imagesDiv.push(<img className='sponsorship-image' width={`32%`}  src={sponsorshipsItem.PRODUCTS[i].HEADER_IMAGE}/>)
            }
            if(sponsorshipsItem.PRODUCTS?.length){
                body.push(
                    <div className='sponsorship-images'>{imagesDiv}</div>
                )
            }
            setTimeout(()=>{
                if(mouseModalRef.current.style.top.replace("px","")*1 + mouseModalRef.current.offsetHeight> window.innerHeight){
                    mouseModalRef.current.style.top = 'unset'
                    // mouseModalRef.current.style.bottom = 5+'px'
                    var pointer = mainCanvas.getPointer(g_mouseEvent.e);
                    mouseModalRef.current.style.bottom = (window.innerHeight-g_mouseEvent.e.clientY + (pointer.y - g_mouseoverObject.top - g_mouseoverObject.height*0.1)*mainCanvas.getZoom())+'px'
                }
            }, 150)
        }
        else{
            // if(!mouseOverExibitorInfo && activeObject.sold){
            //     body.push(<div></div>)
            //     return body;
            // }
            var convertRateX= 1
            var convertRateY= 1
            if(mainCanvas.convertRateX) {
                var convertRateX= Math.abs(mainCanvas.convertRateX)
                var convertRateY= Math.abs(mainCanvas.convertRateY)
            }
            var booth_number = (activeObject.booth_number)
            var booth_width = 0
            var booth_height = 0
            if(active_booth_file.version == '2' && 0){ // temp disable
                booth_width = activeObject.dwg_width
                booth_height = activeObject.dwg_height
            }
            else{
                if(px2unit(activeObject.width, convertRateX, mainCanvas.mapUnit) >= 5){      
                    booth_width = px2unit(activeObject.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
                    booth_height = px2unit(activeObject.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
                }
                else{
                    booth_width = px2unit(activeObject.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
                    booth_height = px2unit(activeObject.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
                }
            }

            var booth_group = (activeObject.boothGroup?activeObject.boothGroup:"")
            var premiumCheck = [];
            var priceCheck = [];
            var discountCheck = [];
            var tmp1 = activeObject.get('premiumCheck')
            if(tmp1){
                premiumCheck = (tmp1)
            }
            var tmp2 = activeObject.get('priceCheck')
            if(tmp2){
                priceCheck = (tmp2)
            }
            var tmp3 = activeObject.get('discountCheck')
            if(tmp3){
                discountCheck = (tmp3)
            }
            if(mouseOverExibitorInfo){
                var company = "";//activeObject.company; 
                var company_description = activeObject.get('company_description');
                var company_category = activeObject.get('company_category');
                var company_log_url = activeObject.get('company_log_url');
                var company_tag_featured = false;
                var company_tag_member = false;
                var company_tag_3dbooth = false;
                var company_tag_sponser = false;
                var company_tag_level = false;
                var header_image =false
                var company_key =false
                var exhibitors = activeObject?.exhibitors
                var company_KEY_ID
                if(exhibitors && exhibitors.length > 0){
                    company_KEY_ID = exhibitors[0].EXHIBITOR_KEY
                }
                if(company_KEY_ID){
                    // var exhibitorInfo = await getExhibitor(company_KEY_ID)
                    // company_log_url = exhibitorInfo?.['DIRECTORY']['LOGO'];
                    // company_description = exhibitorInfo?.['DIRECTORY']['DESCRIPTION'];
                    // company_category = exhibitorInfo?.['DIRECTORY']['CATEGORY'];
                }
                if(mouseOverExibitorInfo){
                    company_log_url = mouseOverExibitorInfo?.['DIRECTORY']['LOGO'];
                    company_description = mouseOverExibitorInfo?.['DIRECTORY']['DESCRIPTION'];
                    company_category = mouseOverExibitorInfo?.['DIRECTORY']['CATEGORY'];
                    company = mouseOverExibitorInfo?.['DIRECTORY']['COMPANY_NAME'];
                    header_image = mouseOverExibitorInfo?.['DIRECTORY']['HEADERIMAGE'];
                    company_tag_featured = mouseOverExibitorInfo?.['DIRECTORY']['FEATURED'];
                    company_tag_member = mouseOverExibitorInfo?.['DIRECTORY']['MEMBER'];
                    company_tag_3dbooth = (mouseOverExibitorInfo?.['DIRECTORY']['3DMODEL']&&mouseOverExibitorInfo?.['DIRECTORY']['3DMODEL']!="")?true:false;
                    company_tag_sponser = mouseOverExibitorInfo?.['DIRECTORY']['SPONSOR'];
                    company_tag_level = mouseOverExibitorInfo?.['DIRECTORY']['BOOTHLEVEL'];
                    company_key = mouseOverExibitorInfo?.['DIRECTORY']['KEY_ID'];
                }
                if(company_tag_featured){
                    body.push(<FeaturedLabel  moreClass='feet-content' style={{backgroundColor:'#EE5D10'}}/>)
                }
                if(header_image){
                    body.push(
                        <img style={{width:'calc(100% + 20px)', height:'90px', marginLeft:'-10px', marginRight:'-10px', marginTop:'-10px'}} width="auto" height="90px" src={assetUrl(header_image)}/>
                    )
                } 
                body.push(
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Box>
                            <p>{company}</p>
                            <p>Booth #: {activeObject.booth_number}</p>
                        </Box>
                    </Box>
                )    
                console.log("body push", company_key)
                body.push(
                    <div mt={3}>
                        {company_log_url &&
                        <div style={{textAlign:'center', marginTop:5}}>
                            <div className={`loader-${company_key}`} style={{display:'block'}}><ScaleLoader color='#1976d2' radius={2} margin={2} height={30} width={4}/></div>   
                            <img 
                                onLoad={
                                    (e)=>{
                                        var ele = document.getElementsByClassName(`loader-${company_key}`)
                                        for (var i = 0; i < ele.length; i++ ) {
                                            ele[i].style.display = "none";
                                        }
                                    }
                                } 
                                style={{maxWidth:'100%', height:'auto', maxHeight:'50px'}} width="auto" height="100px" src={assetUrl(company_log_url)}/>                                                             
                        </div>
                        }
                        <div style={{paddingTop:"10px", paddingBottom:'10px'}}>
                            <ShowMore
                                lines={3}
                                more='Read More'
                                less='Read Less'
                                anchorClass='mouse-over-showmore'
                            >
                                {company_description?company_description:""} 
                            </ShowMore>
                        </div>
                    </div>
                )
                if(company_tag_member){
                    body.push(<div className="chip" style={{backgroundColor:'rgb(213 5 48)'}}><span>Member</span></div>)
                }
                if(company_tag_sponser){
                    body.push(<div className="chip" style={{backgroundColor:'rgb(213 5 48)'}}><span>Sponsor</span></div>)
                }
                if(company_tag_level){
                    body.push(<div className="chip" style={{backgroundColor:'rgb(213 5 48)'}}><span>{company_tag_level}</span></div>)
                }
                if(company_tag_3dbooth){
                    body.push(<div className="chip" style={{backgroundColor:'rgb(213 5 48)'}}><span>3D</span></div>)
                }
                if(company_category){
                    for(var i = 0; i < company_category.length; i++){
                        body.push(<div className="chip"><span>{company_category[i].ANSWER_TITLE}</span></div>)
                    }
                }
            }
            else if(mouseOverBoothInfo){
                if(mouseOverBoothInfo){
                    for(var i = 0; i < mouseOverBoothInfo.BOOTH?.length; i++){
                        body.push(<p>{mouseOverBoothInfo.BOOTH[i].LABEL} {mouseOverBoothInfo.BOOTH[i].VALUE}</p>)
                    }
                    if(mouseOverBoothInfo.PRICE?.length)
                        body.push(<h4> Price</h4>)
                    for(var i = 0; i < mouseOverBoothInfo.PRICE?.length; i++){
                        body.push(<p>{mouseOverBoothInfo.PRICE[i].LABEL} {mouseOverBoothInfo.PRICE[i].VALUE}</p>)
                    }
                }
                // mouseModalRef.current.innerHTML = body;

            }
        }
        return body;
    }

    const MouseOverCarouselItem=()=>
    {
        return (
            <div style={{height:'calc(100% - 35px)', overflowY:'auto', padding:'10px', boxSizing:'content-box'}}>
                {renderMouseOverComponentContent()}
            </div>
        )
    }

    const renderMouseOverComponent = ()=>{
        var activeObject = mouseoverObject
        return(
            <>
            {
                (!activeObject.sold || mouseOverExibitorKeyList?.length <= 1)?
                <div style={{padding:'10px', overflowY:'auto', maxHeight:'280px'}}>
                    {renderMouseOverComponentContent()}
                </div>:
                <div>
                    <Carousel
                        indicatorContainerProps={{
                            style: {
                                marginTop: '5px', // 5
                                marginBottom: '5px' // 4
                            }                    
                        }}
                        height={270}
                        autoPlay={false}
                        swipe={false}
                        navButtonsAlwaysVisible={true}
                        navButtonsWrapperProps={{   // Move the buttons to the bottom. Unsetting top here to override default style.
                            style: {
                                height:'33px',
                                bottom: '0px',
                                top: 'unset'
                            }
                        }} 
                        navButtonsProps={{          // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                            style: {
                                padding:'3px',
                                background: 'rgb(67 65 65 / 41%)'
                            }
                        }} 
                        indicatorIconButtonProps={{
                            style:{
                                width: `${Math.min(15, 300/mouseOverExibitorKeyList.length)}px`,
                                height:`${Math.min(15, 300/mouseOverExibitorKeyList.length)}px`,
                            },
                            className:'custom-indicator'
                        }}
                        next={ (next, active) => {console.log("next", next); console.log("active", active); getMouseOverExhibitor(mouseOverExibitorKeyList[next], false,activeObject)} }
                        prev={ (prev, active) => {console.log("prev", prev); console.log("active", active); getMouseOverExhibitor(mouseOverExibitorKeyList[prev], false,activeObject)} }                
                    >
                        {
                            mouseOverExibitorKeyList?.map( (item, i) => <MouseOverCarouselItem key={i} exhibitor={item} /> )
                        }
                    </Carousel> 
                </div>
            }
            </>
        )
    }

    if(groupOptions)
    groupOptions.sort(function (a, b) {
      return (a.order?a.order:0)*1 - (b.order?b.order:0)*1;
    });
    return (
        <>
        {authInfo.authenticated == 2&&
            <Notfound/>
        }
        {authInfo.authenticated == 3&& maincanvas_width && maincanvas_height &&
            <>        
                <ToolBoxAdmin {...props}
                    redrawRulers = {()=>redrawRulers(mainCanvas, topRuler, leftRuler)}
                    centerFocus = {()=>centerFocus(mainCanvas, bgCanvas, g_grid, topRuler, leftRuler, bgImgCanvas)}
                    setOpenLegend = {setOpenLegend}
                    fetchmaplist = {fetchmaplist}
                    createNewMap = {createNewMap}
                    saveOriginalLayer = {saveOriginalLayer}
                    g_grid = {g_grid}
                />
                <div className="main-container" >
                    {active_booth_file && active_booth_file.KEY_ID &&
                    <>
                        <AppLeftPanel role="admin"/>                
                        <LeftProfile role="admin" groupOptions = {groupOptions}/>                
                        <AppUnassignedPanel role="admin" groupOptions = {groupOptions}/>
                        {/* <ElementBox/> */}
                    </>
                    }
                    <div className="canvas-viewer" >
                        {/* {active_booth_file && active_booth_file.KEY_ID &&
                        <div className="controls -ready main-control">
                            <Button type="button" title="Legend" onClick={()=>{setOpenLegend(true)}}><PriorityHighIcon/></Button>
                        </div>
                        } */}
                        <div style ={{paddingLeft:50, height:50, background:props.ui.lockFlag?'inherit':'#ffff0059'}}>
                            <canvas style={{background:'transprent'}} id="top-ruler" width={`${maincanvas_width}px`} height="50"/>
                        </div>
                        <div style={{display:'flex'}}>
                            <div style={{width:50}}>
                                <canvas style={{background:props.ui.lockFlag?'inherit':'#ffff0059'}} id="left-ruler" width="50" height={`${maincanvas_height}px`}/>
                            </div>
                            <div style={{width:'calc(100% - 50px)', borderLeft:'1px solid black', borderTop:'1px solid black'}}>
                                <div ref={mainCanvasDiv}>
                                    <canvas style={{background:"#f5f50f5"}} ref={mainCanvasRef} className="main-canvas" id="canvas" width={`${maincanvas_width}px`} height={`${maincanvas_height}px`}></canvas>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    {openLegend &&
                        <Draggable
                        handle=".handle"
                        defaultPosition={{x: 0, y: 0}}
                        position={null}
                        grid={[2, 2]}
                        scale={1}
                        bounds="body"
                        // onStart={this.handleStart}
                        // onDrag={this.handleDrag}
                        // onStop={this.handleStop}
                        >
                        <div className="legend-option">
                            <Box display="flex" style={{justifyContent:'space-between', marginBottom: 10}}>
                            <DragIndicatorIcon fontSize="small" style={{cursor:'move', width:20}} className="handle"/>
                            <CloseIcon fontSize="small" style={{cursor:'pointer'}} onClick={()=>setOpenLegend(false)}/>
                            </Box>
                            <Box className='legend-content' style={{textAlign:'center'}}>
                                <Box className="draw-buttons" style={{marginBottom: 15}}>
                                    <Table className="color-table"  aria-label="simple table" style={{width:'100%', margin:'auto'}}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell width="34%">Title</TableCell>
                                                <TableCell width="16%">Sold</TableCell>
                                                <TableCell width="16%">Pending</TableCell>
                                                <TableCell width="16%">Available</TableCell>
                                                <TableCell width="16%">Reserved</TableCell>
                                                {/* <TableCell width="15%">Assign<br/>Group</TableCell> */}
                                            </TableRow>
                                        {g_groupOptions.map((row) => (
                                            <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >                                                
                                                <TableCell style={{wordBreak:'break-word'}}>{row.TITLE}</TableCell>
                                                <TableCell><Box  className="legend-color" style={{backgroundColor: convertRgb(row.SOLD)}}></Box></TableCell>
                                                <TableCell><Box  className="legend-color" style={{backgroundColor: convertRgb(row.PENDING)}}></Box></TableCell>
                                                <TableCell><Box  className="legend-color" style={{backgroundColor: convertRgb(row.AVAILABLE)}}></Box></TableCell>
                                                <TableCell><Box  className="legend-color" style={{backgroundColor: convertRgb(row.RESERVED)}}></Box></TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Box>
                        </div>
                        </Draggable>
                        }
                </div>
                
                <div onMouseLeave={()=>{console.log("mouse out"); mouseModalRef.current.classList.remove("active");setMouseOverObject(false)}} style={{width:-1000, height: -10000}} ref={mouseModalRef} className='mouseModal'>

                    {renderMouseOverComponent()}
                    
                </div>

                
                
                {openSetDrawModal&&
                <SetDrawModal
                    open={openSetDrawModal}
                    onClose={handleCloseSetDrawModal}
                    onOk={handleConfirmSetDrawModal}
                    object = {selectedDrawObject}
                />
                }
                {openSetSponsorshipsModal&&
                <SetSponsorshipsModal
                    open={openSetSponsorshipsModal}
                    onClose={handleCloseSetSponsorshipsModal}
                    onOk={handleConfirmSetSponsorshipsModal}
                    object = {selectedSponsorshipsObject}
                />
                }

                {openSetSymbolModal&&
                <SetSymbolModal
                    open={openSetSymbolModal}
                    onClose={handleCloseSetSymbolModal}
                    onOk={handleConfirmSetSymbolodal}
                    object = {selectedSymbolObject}
                />
                }

                
                {selectedAisle&&
                <SetAisleModal
                    open={openSetAisleModal}
                    onClose={handleCloseSetAisleModal}
                    onOk={handleConfirmSetAisleModal}
                    aisle = {selectedAisle}
                />
                }
                
            </>
        }
        
        <LoadingOverlay
            active={props.main.loadingFlag }
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
                
        </>
    );
  }
  
  
const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
      booth_history: state.booth_history.present
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {    
        setMainCanvas: canvas=>dispatch(setMainCanvas(canvas)),
        setgGrid: data=>dispatch(setgGrid(data)),
        setBgCanvas: data=>dispatch(setBgCanvas(data)),
        setBgImgCanvas: data=>dispatch(setBgImgCanvas(data)),
        setTopRuler: data=>dispatch(setTopRuler(data)),
        setLeftRuler: data=>dispatch(setLeftRuler(data)),
        setMainLayers: layers=>dispatch(setMainLayers(layers)),
        setImportLayers: layers=>dispatch(setImportLayers(layers)),
        setMainSvg: svg=>dispatch(setMainSvg(svg)),
        setBoothList: data=>dispatch(setBoothList(data)),
        setBoothFileList: data=>dispatch(setBoothFileList(data)) ,
        setBoothHistory: data=>dispatch(setBoothHistory(data)),
        setCanvasRefresh:  data=>dispatch(setCanvasRefresh(data)),
        setMapRemoveObjects:  data=>dispatch(setMapRemoveObjects(data)),
        setFirstMapContent:  data=>dispatch(setFirstMapContent(data)),
        setFirstBgContent:  data=>dispatch(setFirstBgContent(data)),
        onClearHistory: () => dispatch(UndoActionCreators.clearHistory()),  
        setActiveBoothFile: data=>dispatch(setActiveBoothFile(data)),  
        setLoading:  data=>dispatch(setLoading(data)),
        setOpenFilenameModal:  data=>dispatch(setOpenFilenameModal(data)),
        setMenuControl:  data=>dispatch(setMenuControl(data)),
        setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
        setOpenSponsorshipProfile: data=>dispatch(setOpenSponsorshipProfile(data)),
        setOpenUnassignedProfile:  data=>dispatch(setOpenUnassignedProfile(data)),
        setDwgImported:  data=>dispatch(setDwgImported(data)),
        setOpenSetBoothModal:  data=>dispatch(setOpenSetBoothModal(data)),
        setOpenSetTableModal:  data=>dispatch(setOpenSetTableModal(data)),
        setLog:  data=>dispatch(setLog(data)),
        setPremiumOptions:  data=>dispatch(setPremiumOptions(data)),
        setPricingOptions:  data=>dispatch(setPricingOptions(data)),
        setDiscountOptions:  data=>dispatch(setDiscountOptions(data)),
        setGroupOptions:  data=>dispatch(setGroupOptions(data)),
        setSponsorshipsOptions:  data=>dispatch(setSponsorshipsOptions(data)),
        setExhibitorList:  data=>dispatch(setExhibitorList(data)),
        setShowSetup:  data=>dispatch(setShowSetup(data)),
        setFeeGroupOptions:  data=>dispatch(setFeeGroupOptions(data)),
        setFeeCategoryOptions:  data=>dispatch(setFeeCategoryOptions(data)),
        setCategoryOptions:  data=>dispatch(setCategoryOptions(data)),
        setAuthInfo:  data=>dispatch(setAuthInfo(data)),
        to2D:  data=>dispatch(to2D(data)),
        addLog:  data=>dispatch(addLog(data)),
        addLogs:  data=>dispatch(addLogs(data)),
        setLockFlag:  data=>dispatch(setLockFlag(data)),
        setMechantAccount: data=>dispatch(setMechantAccount(data)),
        setSnackMsg: data=>dispatch(setSnackMsg(data)),
        setOpenSnack: data=>dispatch(setOpenSnack(data)),        
        resetUI:  data=>dispatch(resetUI(data)),
        resetMain:  data=>dispatch(resetMain(data)),
        setOpenImportmodal:  data=>dispatch(setOpenImportmodal(data)),
        setOpenCompanyModal:  data=>dispatch(setOpenCompanyModal(data)),
        setEditCompany: value=>dispatch(setEditCompany(value)),
        setCartList: value=>dispatch(setCartList(value)),
        setMouseHoverEnable:  data=>dispatch(setMouseHoverEnable(data))
    };
  };
  
  export default connect(mapStateToProps, mapDispatchProps)(MainViewer);



  
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import {Select, Popper} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import TuneIcon from '@mui/icons-material/Tune';
import { makeStyles } from '@mui/styles';
import { setSearchKeySelect, setSearchOptions, setSearchPulldownValue, setSearchInputValue, setSelectedAdvanceFilterOptions} from '../../actions/searchbox'
import { objectExistsInArray } from '../../utils/Common'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Draggable from 'react-draggable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CloseIcon from '@mui/icons-material/Close';

function SearchBox(props) {
  const [openFilter, setOpenFilter] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState([])
  const keywordInput = useRef(null);
  const selectRef = useRef(null);

  const useStyles = makeStyles((theme) => ({
    searchBtn:{
      height:'40px!important',
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    },
    bubble:{
      color:props.ui?.customStyles?.['bubble-label-color']?(props.ui?.customStyles['bubble-label-color']+"!important"):'white',
      backgroundColor:props.ui?.customStyles?.['bubble-bg-color']?(props.ui?.customStyles['bubble-bg-color']+"!important"):'#1565c0',
    }
  }));
  const classes = useStyles();  
  let standardSearchOptions = props.searchbox.searchOptions?.STANDARD??[]
  let advancedSearchOptions = props.searchbox.searchOptions?.ADVANCE??[]
  var keySelect = props.searchbox.searchKeySelect
  var pulldownValue = props.searchbox.searchPulldownValue
  var searchInputValue = props.searchbox.searchInputValue
  var selectedAdvancedFilterOptions = props.searchbox.selectedAdvancedFilterOptions

  
  useEffect(() => {   
    if(props.ui.leftPage){
      props.setSearchPulldownValue('')
      props.setSearchInputValue('')
      props.setSelectedAdvanceFilterOptions([])
    }
  }, [props.ui.leftPage])  

  useEffect(() => {
    if(standardSearchOptions && standardSearchOptions.length){
      if(keySelect == '' || !(standardSearchOptions.some(obj => obj.ID === keySelect)))
        props.setSearchKeySelect(standardSearchOptions[0].ID)
    }
  }, [standardSearchOptions, advancedSearchOptions])  

  const resetSearch=()=>{
    if(keywordInput?.current)
      keywordInput.current.value = ''
    if(selectRef?.current)
      selectRef.current.value = ''    
    
    props.setSearchPulldownValue(false)
    props.setSearchInputValue('')
    props.setSelectedAdvanceFilterOptions([])
  }

  
  const getSearchOption = (value)=>{
    if (!value) return false;
    for(var i = 0; i < standardSearchOptions?.length; i++){
      if(standardSearchOptions[i].ID == value){
        return standardSearchOptions[i]
      }
    }
    return false
  }
  
  const onChangeFilter = (filterOption, value)=>{
    let tmp = [...selectedAdvancedFilterOptions]    
    if(value){
      if(!objectExistsInArray(selectedAdvancedFilterOptions, filterOption)){
        tmp.push(filterOption)
      }
    }
    else{
      tmp = selectedAdvancedFilterOptions.filter((item)=>!(item.key==filterOption.key && item.value==filterOption.value));
    }
    props.setSelectedAdvanceFilterOptions(tmp)
  }
  
  const renderAnswerFilter = (selectedFilter)=>{
      return(
        <>
        {
          selectedFilter?.ITEMS?.map((answer, index)=>{
            return(
              <Box style={{overflowX:'auto'}} key={index}><FormControlLabel control={<Checkbox sx={{paddingTop:'3px', paddingBottom:'3px'}} checked={objectExistsInArray(selectedAdvancedFilterOptions, {key:selectedFilter?.LABEL, value:answer.ID})} color="success" size="small"/>} label={<span style={{fontSize:13}}>{`${answer.TITLE}${answer.QTY?(" ("+answer.QTY+")"):""}`}</span>} onChange={(event)=>onChangeFilter({key:selectedFilter?.LABEL, value:answer.ID}, event.target.checked)}/></Box>
            )
          })
        }
        </>
      )
  }

  const getAnswerLabel = (answer)=>{
    for(let i = 0; i< advancedSearchOptions?.length; i++){
      let filterItem = advancedSearchOptions[i];      
      let tmp
      {
        tmp = filterItem?.ITEMS?.filter(item=>item.ID == answer.value)
        if(tmp?.[0]?.TITLE) return (tmp?.[0]?.TITLE??'')
      }
    }
    return ''
  }

  let searchOption = getSearchOption(keySelect)
  let dropdownOptions = [];
  if(searchOption && searchOption.TYPE == 'pulldown'){
    if(searchOption.ID == 'question'){
      for(var i = 0; i < searchOption.ITEMS?.length; i++){
        let title = searchOption.ITEMS[i].answer_title
        if(title === true){
          title = "Yes"
        }
        if(title === false){
          title = "No"
        }
        dropdownOptions.push(
          {
            value:`${searchOption.ITEMS[i].answer_key}`?.trim(),
            label:`${title}`?.trim()+(searchOption.ITEMS[i].qty?(` (${searchOption.ITEMS[i].qty})`):""),
          }
        )
      }
    }
    else if(searchOption.ID == 'product_category'){
      for(var i = 0; i < searchOption.ANSWERS?.length; i++){
        let title = searchOption.ANSWERS[i].answer_title
        dropdownOptions.push(
          {
            value:`${searchOption.ANSWERS[i].answer_key}`?.trim(),
            label:`${title}`?.trim()+(searchOption.ANSWERS[i].qty?(` (${searchOption.ANSWERS[i].qty})`):""),
          }
        )
      }
    }
    else if(searchOption.ID == 'company'){
      for(var i = 0; i < searchOption.COMPANIES?.length; i++){
        let title = ''
        if(searchOption.COMPANIES[i].company_name){
          title = searchOption.COMPANIES[i].company_name
        }
        else if(searchOption.COMPANIES[i].COMPANY_NAME){
          title = searchOption.COMPANIES[i].COMPANY_NAME
        }
        dropdownOptions.push(
          {
            value:`${title}`?.trim(),
            label:`${title}`?.trim()+(searchOption.COMPANIES[i].qty?(` (${searchOption.COMPANIES[i].qty})`):""),
          }
        )
      }
    }
    else if(searchOption.ID == 'date'){
      for(var i = 0; i < searchOption.DATES?.length; i++){
        let title = ''
        if(searchOption.DATES[i].DATE){
          title = searchOption.DATES[i].DATE
        }
        dropdownOptions.push(
          {
            value:`${title}`?.trim(),
            label:`${title}`?.trim()+(searchOption.DATES[i].qty?(` (${searchOption.DATES[i].qty})`):""),
          }
        )
      }
    }
    else{
      var option_key = false;
      if(searchOption.ITEMS?.length){
        option_key = 'ITEMS';
      }
      else if(searchOption.COMPANIES?.length){
        option_key = 'COMPANIES';
      }
      else if(searchOption.BOOTHS?.length){
        option_key = 'BOOTHS';
      }
      else if(searchOption.STATES?.length){
        option_key = 'STATES';
      }
      else if(searchOption.COUNTRIES?.length){
        option_key = 'COUNTRIES';
      }
      if(option_key){
        for(var i = 0; i < searchOption[option_key]?.length; i++){
          var key = false
          var label = false
          if(searchOption[option_key][i].VALUE){
            key = searchOption[option_key][i].VALUE
          }
          else if(searchOption[option_key][i].ID){
            key = searchOption[option_key][i].ID
          }
          else if(searchOption[option_key][i].exhibitor_key){
            key = searchOption[option_key][i].exhibitor_key
          }

          
          if(searchOption[option_key][i].TITLE){
            label = searchOption[option_key][i].TITLE
          }
          else if(searchOption[option_key][i].company_name){
            label = searchOption[option_key][i].company_name
          }

          if(key){
            dropdownOptions.push(
              {
                value:`${key}`?.trim(),
                label:`${label}`?.trim()+(searchOption[option_key][i].QTY?(` (${searchOption[option_key][i].QTY})`):""),
              }
            )
          }
        }
      }
    }
  }
  if(searchOption && searchOption.TYPE == 'checkbox'){
    if(1){
      dropdownOptions.push(
        {
          value:1,
          label:'Yes',
        }
      )
      dropdownOptions.push(
        {
          value:0,
          label:'No',
        }
      )
    }
  }

  return (
    <Box sx={{ maxWidth: '750px', margin:'auto',  position:'relative' }}>
    <Paper
      className='search-bar'
      component="form"
      sx={{ p: '0px 0px', display: 'flex', alignItems: 'center'}}
    >
      <FormControl variant="outlined" style={{width:(props.leftBar?'auto':'auto'), minWidth:'75px', border:'none'}}>
        <Select
          className='key-select'
          style={{border:'none', outline:'none'}}
          // displayEmpty
          value={keySelect?keySelect:'Keyword'}
          onChange={(e)=>{
            props.setSearchKeySelect(e.target.value); props.setSearchPulldownValue(false);
            if(keywordInput?.current)
              keywordInput.current.value = ''
            if(selectRef?.current)
              selectRef.current.value = ''
          }}
        >
          {
            standardSearchOptions.map((searchOption, index)=>{
              return(
              <MenuItem value={searchOption.ID} key={index}>{searchOption.LABEL}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
      
      <Divider sx={{ height: '32px', m: 0.5 }} orientation="vertical" />
      
      {searchOption.TYPE == 'pulldown'  || searchOption.TYPE == 'checkbox' ?
        <Select
          className='key-select'
          sx={{ flex: 1, width:(props.leftBar?'auto':'300px'),border:'none', outline:'none', textAlign:'left'  }}
          // displayEmpty
          ref={selectRef}
          value = {pulldownValue}
          onChange={(e)=>{props.setSearchPulldownValue(e.target.value)}}
        >
          {
            dropdownOptions.map((dropdownOption, index)=>{
              return(
                <MenuItem value={dropdownOption.value} key={index}>{dropdownOption.label}</MenuItem>
              )
            })
          }
        </Select>:
        <InputBase
          sx={{ flex: 1, width:(props.leftBar?'auto':'300px')  }}
          placeholder={`Search by ${keySelect=='exhibitor_name'?'Exhibitor Name':'keyword'}`}
          inputProps={{ 'aria-label': `Search by ${keySelect=='exhibitor_name'?'Exhibitor Name':'keyword'}` }}
          onChange={(e)=>{props.setSearchInputValue(e.target.value)}}
          value={searchInputValue?searchInputValue:''}         
          inputRef={keywordInput}
        />
      }
      {advancedSearchOptions?.length > 0 &&
      <IconButton aria-label="delete" onClick={()=>{setOpenFilter(true)}} style={{padding:0, marginRight:5}}>
        <TuneIcon fontSize="small" sx={{ p: '5px' }} />
      </IconButton>
      }
      <Button onClick={(()=>resetSearch())} className={`${classes.searchBtn} search-attach` } variant="contained" color="primary" sx={{ p: '10px' }} aria-label="directions">
        {/* <SearchIcon sx={{ p: '5px' }} /> */}
        Clear
      </Button>
    </Paper>
    {
      selectedAdvancedFilterOptions?.length > 0 && 
      <Box sx={{ marginTop: '5px', paddingLeft: '10px', paddingRight: '10px', display:'flex', flexWrap:'wrap'}}>
        {
          selectedAdvancedFilterOptions.map((item, index)=>{
            return(
              <Box display='inline-flex' alignItems='center' mr={1} key={index} style={{backgroundColor:'#e5e4e4', padding:'3px 5px', borderRadius:'3px',  marginBottom:3, fontSize:13}}><span>{getAnswerLabel(item)}</span><CloseIcon fontSize="small" style={{cursor:'pointer'}} onClick={()=>{onChangeFilter(item, false)}}/></Box>
            )
          })
        }
      </Box>
    }
    {openFilter &&
        <Draggable
          handle=".handle"
          defaultPosition={{x: 0, y: 0}}
          position={null}
          grid={[2, 2]}
          scale={1}
          bounds="body"
          >
            <Box className="filiter-modal" textAlign={'left'}>              
              <Box display="flex" style={{justifyContent:'space-between', marginBottom: 10}}>
                <Box display="flex" style={{alignItems:'center'}}>
                  <DragIndicatorIcon fontSize="small" style={{cursor:'move', width:20}} className="handle"/>
                  <div>Filters</div>
                </Box>
                <CloseIcon fontSize="small" style={{cursor:'pointer'}} onClick={()=>setOpenFilter(false)}/>
              </Box>
              <Box>
                <Box mb={1}>
                  <Select
                    MenuProps={{
                      disableScrollLock: true, // Prevents body scroll lock
                    }}
                    PopperComponent={(props) => <Popper {...props} disablePortal />}
                    sx={{ flex: 1, width:'100%'  }}
                    size="small"
                    value={selectedFilter?.LABEL??false}
                    onChange={(e)=>{
                      if(!e.target.value){
                        setSelectedFilter(false)
                      }
                      else{
                        let tmp = advancedSearchOptions?.filter(item=>item.LABEL == e.target.value)
                        // props.setSelectedAdvanceFilterOptions([])
                        setSelectedFilter(tmp?.[0])     
                      }
                    }}
                  >
                    {
                      advancedSearchOptions?.map((filter, index)=>{
                        return(
                          <MenuItem key={index} value={filter.LABEL}>{`${filter.LABEL}`}</MenuItem>
                        )
                      })

                    } 
                  </Select>
                </Box>
                {renderAnswerFilter(selectedFilter)}
              </Box>
              <Box mt={1}>
                <Button size="small" onClick={()=>{props.setSelectedAdvanceFilterOptions([])}}>Reset</Button>
                <Button size="small" onClick={()=>{setOpenFilter(false)}}>Close</Button>
              </Box>
            </Box>
        </Draggable>
      }
    </Box>
  )
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
      ui: state.ui,
      searchbox: state.searchbox
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
      setSearchOptions:  data=>dispatch(setSearchOptions(data)),
      setSearchKeySelect:  data=>dispatch(setSearchKeySelect(data)),
      setSearchPulldownValue:  data=>dispatch(setSearchPulldownValue(data)),
      setSearchInputValue:  data=>dispatch(setSearchInputValue(data)),
      setSelectedAdvanceFilterOptions:  data=>dispatch(setSelectedAdvanceFilterOptions(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(SearchBox);
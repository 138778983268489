
import { fabric } from "fabric";
import LZString from 'lz-string';
import { buildObjectProperty, convertRgbHex, uuidRegexExp, getDefaultGroup, isUUID, assetUrl } from "./Common"
import { LOCATION_WIDTH, BREAK_WORD_WIDTH } from "../config"
import { store } from "../store"
import Flatten from "@flatten-js/core"
import BigNumber from "bignumber.js";
import  area  from 'area-polygon'


export const cloneCanvas = (canvas)=> {
    const clone = document.createElement('canvas');
    clone.width = canvas.width;
    clone.height = canvas.height;
    canvas.wrapperEl.appendChild(clone);
    return clone;
}

export function rotateCanvasAndElements(mainCanvas, angleChange) {
    if(mainCanvas && angleChange){
      var objs = mainCanvas.getObjects();
      var canvasCenter = new fabric.Point(mainCanvas.getWidth()/2, mainCanvas.getHeight()/2);
      objs.forEach(function(obj){
        // var canvasCenter = new fabric.Point(obj.width/2, obj.height/2);
        if(obj.get('class') == 'line' && obj.get('class1')!="outline"){
            var objectOrigin = new fabric.Point(obj.left, obj.top);
            var val = angleChange-obj.get('angle');
            var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, val*2*Math.PI/360)
            obj.set('angle', angleChange);
            obj.top = new_loc.y;
            obj.left = new_loc.x;
            obj.dirty = true
        }            
      });
      mainCanvas.renderAll();
    }    
}
export function rotatePathObject(mainCanvas, obj) {
    {
        var angle = 0;
        var path = obj.path
        var newPath = [];
        var beforePoint = path[3];
        var centerPoint = path[0];
        var nextPoint = path[1];
        var centerPointInd = 0;
        var beforePointInd = 3;
        var nextPointInd = 1;
        for(var i = 0; i < path.length-1; i++){           
            var point = path[i];
            if(point[2] < centerPoint[2]){
                centerPoint = point
                centerPointInd = i;
            }
        }

        if(centerPointInd > 0){
            beforePoint = path[centerPointInd - 1]
            nextPoint = path[centerPointInd + 1]            
            beforePointInd = centerPointInd - 1;
            nextPointInd = centerPointInd + 1;
        }

        
        var angleRadians = Math.abs(Math.atan2(beforePoint[2] - centerPoint[2], beforePoint[1] - centerPoint[1]));
        if(angleRadians > Math.PI/2)
            angleRadians = angleRadians - Math.PI/2
        angle = Math.abs((angleRadians * 180 / Math.PI));

        var basePoint = new fabric.Point(centerPoint[1], centerPoint[2]);
        for(var i = 0; i < path.length; i++){
            var objectOrigin = new fabric.Point(path[i][1], path[i][2]);
            var new_loc = fabric.util.rotatePoint(objectOrigin, basePoint, -angleRadians)
            if(i == 0){
                newPath.push(['M', new_loc.x, new_loc.y])
            }
            else{
                newPath.push(['L', new_loc.x, new_loc.y])
            }
        }

        var new_centerPoint = newPath[centerPointInd];
        var new_beforePoint = newPath[beforePointInd];
        var new_nextPoint = newPath[nextPointInd];

        var width = Math.max(Math.abs(new_beforePoint[1] - new_centerPoint[1]), Math.abs(new_nextPoint[1] - new_centerPoint[1]))
        var height = Math.max(Math.abs(new_beforePoint[2] - new_centerPoint[2]), Math.abs(new_nextPoint[2] - new_centerPoint[2]))
        // var minX = 0
        // var minY = 0;
        // var maxX = 0;
        // var maxY = 0;
        // for(var i = 0; i < newPath.length; i++){
        //     var point = newPath[i]
        //     if(point[1] < minX){
        //         minX = point[1]
        //     }
        //     if(point[2] < minY){
        //         minY = point[2]
        //     }
        //     if(point[1] > maxX){
        //         maxX = point[1]
        //     }
        //     if(point[2] > maxY){
        //         maxY = point[2]
        //     }
        // }
    
        // var objectOrigin = new fabric.Point(minX, minY);
        // basePoint = new fabric.Point(path[0][1], path[0][2]);
        // var new_loc = fabric.util.rotatePoint(objectOrigin, basePoint, -angle*2*Math.PI/360)
        obj.left = obj.left+centerPoint[1];
        obj.top = obj.top+centerPoint[2];
        obj.width = width;
        obj.height = height;
        
        obj.path = newPath
        obj.pathOffset={x:0, y:0}
        return angle
    }
    
}

export function rotateObject(mainCanvas, obj) {
    {
        var angle = 0;
        var points = obj.points
        var newPath = [];
        var newPoints = [];
        var beforePoint = points[3];
        var centerPoint = points[0];
        var nextPoint = points[1];
        var centerPointInd = 0;
        var beforePointInd = 3;
        var nextPointInd = 1;
        for(var i = 0; i < points.length-1; i++){           
            var point = points[i];
            if(point['y'] < centerPoint['y']){
                centerPoint = point
                centerPointInd = i;
            }
        }

        if(centerPointInd > 0){
            beforePoint = points[centerPointInd - 1]
            nextPoint = points[centerPointInd + 1]            
            beforePointInd = centerPointInd - 1;
            nextPointInd = centerPointInd + 1;
        }

        
        var angleRadians = Math.abs(Math.atan2(beforePoint['y'] - centerPoint['y'], beforePoint['x'] - centerPoint['x']));
        if(angleRadians > Math.PI/2)
            angleRadians = angleRadians - Math.PI/2
        angle = Math.abs((angleRadians * 180 / Math.PI));

        var basePoint = new fabric.Point(centerPoint['x'], centerPoint['y']);
        for(var i = 0; i < points.length; i++){
            var objectOrigin = new fabric.Point(points[i]['x'], points[i]['y']);
            var new_loc = fabric.util.rotatePoint(objectOrigin, basePoint, -angleRadians)
            newPoints.push({x:new_loc.x, y:new_loc.y})
        }

        var new_centerPoint = newPoints[centerPointInd];
        var new_beforePoint = newPoints[beforePointInd];
        var new_nextPoint = newPoints[nextPointInd];

        var width = Math.max(Math.abs(new_beforePoint['x'] - new_centerPoint['x']), Math.abs(new_nextPoint['x'] - new_centerPoint['x']))
        var height = Math.max(Math.abs(new_beforePoint['y'] - new_centerPoint['y']), Math.abs(new_nextPoint['y'] - new_centerPoint['y']))
        
        obj.left = obj.left+centerPoint['x'];
        obj.top = obj.top+centerPoint['y'];
        obj.width = width;
        obj.height = height;
        
        obj.points = newPoints
        obj.pathOffset={x:0, y:0}
        return angle
    }
    
}

export function rotatePoints(mainCanvas, points) {
    {
        var angle = 0;
        var newPath = [];
        var newPoints = [];
        var beforePoint = points[3];
        var centerPoint = points[0];
        var nextPoint = points[1];
        var centerPointInd = 0;
        var beforePointInd = 3;
        var nextPointInd = 1;
        for(var i = 0; i < points.length-1; i++){           
            var point = points[i];
            if((point['x'] + point['y']) < (centerPoint['x'] + centerPoint['y'])){
                centerPoint = point
                centerPointInd = i;
            }
        }

        if(centerPointInd > 0){
            beforePoint = points[centerPointInd - 1]
            nextPoint = points[centerPointInd + 1]            
            beforePointInd = centerPointInd - 1;
            nextPointInd = centerPointInd + 1;
        }

        
        var angleRadians = Math.abs(Math.atan2(beforePoint['y'] - centerPoint['y'], beforePoint['x'] - centerPoint['x']));
        if(angleRadians > Math.PI/2)
            angleRadians = angleRadians - Math.PI/2
        angle = Math.abs((angleRadians * 180 / Math.PI));
        angle = Math.round(angle)
        angleRadians = angle/180*Math.PI
        

        var basePoint = new fabric.Point(centerPoint['x'], centerPoint['y']);
        for(var i = 0; i < points.length; i++){
            var objectOrigin = new fabric.Point(points[i]['x'], points[i]['y']);
            var new_loc = fabric.util.rotatePoint(objectOrigin, basePoint, -angleRadians)
            newPoints.push({x:new_loc.x, y:new_loc.y})
        }

        var new_centerPoint = newPoints[centerPointInd];
        var new_beforePoint = newPoints[beforePointInd];
        var new_nextPoint = newPoints[nextPointInd];

        var width = Math.max(Math.abs(new_beforePoint['x'] - new_centerPoint['x']), Math.abs(new_nextPoint['x'] - new_centerPoint['x']))
        var height = Math.max(Math.abs(new_beforePoint['y'] - new_centerPoint['y']), Math.abs(new_nextPoint['y'] - new_centerPoint['y']))
        var transformPoints = [];
        for(var pointInd = 0; pointInd<newPoints.length; pointInd++ ){
            transformPoints.push(
                {x: newPoints[pointInd].x-new_centerPoint.x, y:newPoints[pointInd].y-new_centerPoint.y}
            )
        }
        // obj.left = obj.left+centerPoint['x'];
        // obj.top = obj.top+centerPoint['y'];
        // obj.width = width;
        // obj.height = height;
        
        // obj.points = newPoints
        // obj.pathOffset={x:0, y:0}
        return {newPoints: transformPoints, angle, new_centerPoint}
    }
    
}

export function rotatePoints2(mainCanvas, points) {
    {
        var angle = 0;
        var newPath = [];
        var newPoints = [];
        var beforePoint = points[3];
        var centerPoint = points[0];
        var nextPoint = points[1];
        var centerPointInd = 0;
        var beforePointInd = 3;
        var nextPointInd = 1;
        for(var i = 0; i < points.length-1; i++){           
            var point = points[i];
            if(( point['y']) < (centerPoint['y'])){
                centerPoint = point
                centerPointInd = i;
            }
        }

        if(centerPointInd > 0){
            beforePoint = points[centerPointInd - 1]
            nextPoint = points[centerPointInd + 1]            
            beforePointInd = centerPointInd - 1;
            nextPointInd = centerPointInd + 1;
        }

        
        var angleRadians = Math.abs(Math.atan2(beforePoint['y'] - centerPoint['y'], beforePoint['x'] - centerPoint['x']));
        if(angleRadians > Math.PI/2)
            angleRadians = angleRadians - Math.PI/2
        angle = Math.abs((angleRadians * 180 / Math.PI));
        angle = Math.round(angle)
        angleRadians = angle/180*Math.PI
        

        var basePoint = new fabric.Point(centerPoint['x'], centerPoint['y']);
        for(var i = 0; i < points.length; i++){
            var objectOrigin = new fabric.Point(points[i]['x'], points[i]['y']);
            var new_loc = fabric.util.rotatePoint(objectOrigin, basePoint, -angleRadians)
            newPoints.push({x:new_loc.x, y:new_loc.y})
        }

        var new_centerPoint = newPoints[centerPointInd];
        var new_beforePoint = newPoints[beforePointInd];
        var new_nextPoint = newPoints[nextPointInd];

        var width = Math.max(Math.abs(new_beforePoint['x'] - new_centerPoint['x']), Math.abs(new_nextPoint['x'] - new_centerPoint['x']))
        var height = Math.max(Math.abs(new_beforePoint['y'] - new_centerPoint['y']), Math.abs(new_nextPoint['y'] - new_centerPoint['y']))
        var transformPoints = [];
        for(var pointInd = 0; pointInd<newPoints.length; pointInd++ ){
            transformPoints.push(
                {x: newPoints[pointInd].x-new_centerPoint.x, y:newPoints[pointInd].y-new_centerPoint.y}
            )
        }
        
        var topPoint = points[0];
        var leftPoint = points[0];
        for(var pointInd = 0; pointInd<points.length; pointInd++ ){
            
            var tmp = points[pointInd];
            if((tmp.y)< (topPoint.y)){
                topPoint = tmp
            }
            if((tmp.x)< (leftPoint.x)){
                leftPoint = tmp
            }
        }
        var offsetX = topPoint.x - leftPoint.x;
        
        return {newPoints: transformPoints, angle, new_centerPoint, offsetX}
    }
    
}

export function rotatePath(points) {
    {
        var angle = 0;
        var newPath = [];
        var newPoints = [];
        var beforePoint = points[3];
        var centerPoint = points[0];
        var nextPoint = points[1];
        var centerPointInd = 0;
        var beforePointInd = 3;
        var nextPointInd = 1;
        for(var i = 0; i < points.length-1; i++){           
            var point = points[i];
            if(point['x'] < centerPoint['x']){
                centerPoint = point
                centerPointInd = i;
            }
            if(point['y'] < centerPoint['y']){
                centerPoint = point
                centerPointInd = i;
            }
        }

        if(centerPointInd > 0){
            beforePoint = points[centerPointInd - 1]
            nextPoint = points[centerPointInd + 1]            
            beforePointInd = centerPointInd - 1;
            nextPointInd = centerPointInd + 1;
        }

        
        var angleRadians = Math.abs(Math.atan2(beforePoint['y'] - centerPoint['y'], beforePoint['x'] - centerPoint['x']));
        if(angleRadians > Math.PI/2)
            angleRadians = angleRadians - Math.PI/2
        angle = Math.abs((angleRadians * 180 / Math.PI));

        var basePoint = new fabric.Point(centerPoint['x'], centerPoint['y']);
        for(var i = 0; i < points.length; i++){
            var objectOrigin = new fabric.Point(points[i]['x'], points[i]['y']);
            var new_loc = fabric.util.rotatePoint(objectOrigin, basePoint, -angleRadians)
            newPoints.push({x:new_loc.x, y:new_loc.y})
        }

        var new_centerPoint = newPoints[centerPointInd];
        
        return {angle, left:new_centerPoint['x'], top:new_centerPoint['y'], points: newPoints}
    }
    
}


export const renderBorder = (mainCanvas, render = true)=>{
    if(mainCanvas){
        // console.log("render border")
        var objs = mainCanvas.getObjects('group');
        objs.forEach(function(obj){
            var objClass = obj.get('class');
            if(objClass == "wall" && obj.lockforever){
                mainCanvas.remove(obj);
            }
        })
        if(render)
            mainCanvas.renderAll();

        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var realMapWidth;
        var realMapHeight;

        var mapWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
        var mapHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);
        if(convertRateX){
            realMapWidth = mapWidth
            realMapHeight = mapHeight
            if(mainCanvas.mapWidth*1 && mainCanvas.mapHeight*1){
                realMapWidth = unit2Px(mainCanvas.mapWidth, convertRateX, mainCanvas.mapUnit)
                realMapHeight = unit2Px(mainCanvas.mapHeight, convertRateX, mainCanvas.mapUnit)
            }
        }
    }
    if(mainCanvas)
    {

        var width = realMapWidth;
        var height = realMapHeight;

        var polyline = new fabric.Polyline(
            [
                {x: 47.5, y:47.5},
                {x: 47.5, y:height+47.5},
                {x: 47.5, y:height+47.5},
                {x: width+47.5, y:height+47.5},
                {x: width+47.5, y:height+47.5},
                {x: width+47.5, y:47.5},
                {x: width+47.5, y:47.5},
                {x: 47.5, y:47.5},
            ]
            ,{fill:'transparent', stroke:'#a0a0a0', strokeWidth:3/mainCanvas.getZoom()});
        var rect = polyline.getBoundingRect();
        var wallId = "G_"+parseInt(Math.random()*(10000))
        var group = new fabric.Group().set({
          left: rect.left,
          top: rect.top,
          width: rect.width,
          height: rect.height,
          id: 'wall-'+wallId,
          class: 'wall',
          class1: 'main',
          preserveObjectStacking: true,
          lockforever: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          evented: false,
          selectable: false,
          selection: false,
          hasControls: false,
          hasBorders: false,
          lockMovementX: true,
          lockMovementY: true,
        //   excludeFromExport: true
        });	
        polyline.set({
          left:-rect.width/2,
          top:-rect.height/2
        })
    
        group.add(polyline);

        mainCanvas.add(group);        
        mainCanvas.sendToBack(group);
        if(render)
            mainCanvas.renderAll();
    }
}

export const renderGrid = (g_grid, mainCanvas, gridSize, gridOffsetX, gridOffsetY, angle)=>{
    if(mainCanvas){
        removeGrid(mainCanvas);
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var realMapWidth;
        var realMapHeight;
        var realGrid;
        var realOffsetX;
        var realOffsetY;

        var offsetX = 0;
        var offsetY = 0;
        var mapWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
        var mapHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);
        if(convertRateX){
            realMapWidth = mapWidth
            realMapHeight = mapHeight
            realGrid = unit2Px(gridSize, convertRateX, mainCanvas.mapUnit)
            realOffsetX = unit2Px(offsetX, convertRateX, mainCanvas.mapUnit)
            realOffsetY = unit2Px(offsetY, convertRateX, mainCanvas.mapUnit)
            if(mainCanvas.mapWidth*1 && mainCanvas.mapHeight*1){
                realMapWidth = unit2Px(mainCanvas.mapWidth, convertRateX, mainCanvas.mapUnit)
                realMapHeight = unit2Px(mainCanvas.mapHeight, convertRateX, mainCanvas.mapUnit)
            }
        }
        realOffsetX = 10;

    }
    if(mainCanvas && realGrid)
    {
        // if(mainCanvas.convertUnit == 'mm'){
        //     realGrid = realGrid*1000
        // }
        realOffsetX = gridOffsetX?((gridOffsetX % realGrid )-realGrid):50
        realOffsetY = gridOffsetY?((gridOffsetY % realGrid )-realGrid):50
        realOffsetX+= unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
        realOffsetY+= unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
        var width = realMapWidth - unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit);
        var height = realMapHeight - unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit); 
        var zoom = mainCanvas.getZoom()
        var lines = [];
        var strokeWidth = getStrokeWidth(mainCanvas)
        strokeWidth = getStrokeWidth(mainCanvas)
        for (var i = parseInt(-(width / realGrid)); i < (2*width / realGrid); i++) {
            var newLine = new fabric.Line([ i * realGrid+realOffsetX, realOffsetY-height, i * realGrid+realOffsetX, 2*height], { type:'line',  stroke: '#c0c0c0', strokeWidth:strokeWidth, selectable: false, objectCaching: false, noScaleCache: true, class:'line',lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false, evented:false,excludeFromExport: true})
            lines.push(newLine)
        }
        for (var i = parseInt(-height / realGrid); i < (2*height / realGrid); i++) {
            var newLine = new fabric.Line([ realOffsetX-width, i * realGrid+realOffsetY, 2*width, i * realGrid+realOffsetY], { type: 'line', stroke: '#c0c0c0', strokeWidth:strokeWidth, selectable: false, objectCaching: false, noScaleCache: true,  class:'line',lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false, evented:false,excludeFromExport: true})
            lines.push(newLine)
        }
        var group = new fabric.Group(lines).set({
            selectable: false ,
            class:'line',
            lockMovementX: true,
            lockMovementY: true,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            selection:false,
            hasControls:false,
            hasBorders:false, 
            evented:false,
            excludeFromExport: true,
            lockforever: true,
            objectCaching: false, //true
            noScaleCache: true,
        });	
        group.left = group.left - strokeWidth/2
        group.top = group.top - strokeWidth/2
        mainCanvas.add(group)
        sendToBackBooth(group, mainCanvas);
        mainCanvas.renderAll();
        // console.log("group", group)
    }
    rotateCanvasAndElements(mainCanvas, angle);
}

export const sendToBackBooth = (group, mainCanvas)=>{
    var zIndex = 99999;
    var objs = mainCanvas.getObjects('group');
    for(var i = 0; i < objs.length; i++){
        var obj = objs[i]
        var objClass = obj.get('class');
        if(objClass == "booth" || objClass == "venue"){
            zIndex = i;
            break;
        }
    }

    // console.log("zIndex", zIndex)
    mainCanvas.moveTo(group, zIndex)
    group.moveTo(zIndex)
    group.zIndex = zIndex

    for(var i = 0; i < objs.length; i++){
        var obj = objs[i]
        if(obj.get('layer') == 'booth' || obj.get('layer') == 'venue'){
            mainCanvas.moveTo(obj, i+1)
            obj.moveTo(i+2)
            obj.zIndex = i+2
        }
    }
    
    // objs.forEach(function(obj, index){
    //     var objClass = obj.get('class');
    //     if(obj.zIndex > zIndex){
    //         mainCanvas.moveTo(obj, obj.zIndex-1)
    //         obj.moveTo(obj.zIndex-1)
    //         obj.zIndex = obj.zIndex-1
    //     }
    // })
}


export const getZindex =(object, mainCanvas)=>{
    var zIndex = 0;
    zIndex = mainCanvas.getObjects().indexOf(object)
    return zIndex;
}

export const sendToBackGrid = (object, mainCanvas)=>{
    var gridZIndex = 99999;
    var objs = mainCanvas.getObjects('group');

    for(var i = 0; i < objs.length; i++){
        var obj = objs[i]
        var objClass = obj.get('class');
        if(objClass == "booth"){
            gridZIndex = i;
            break;
        }
    }

    for(var i = 0; i < objs.length; i++){
        var obj = objs[i]
        var objClass = obj.get('class');
        if(objClass == "line"){
            gridZIndex = i;
            break;
        }
    }
    var objectZIndex=  getZindex(object, mainCanvas)
    if(objectZIndex >= gridZIndex){
        mainCanvas.moveTo(object, gridZIndex-1)
        object.moveTo(gridZIndex-1)
        object.zIndex = gridZIndex-1
    }
}

export const sendOriginalToTopGrid = (mainCanvas)=>{
    var gridZIndex = 99999;
    var objs = mainCanvas.getObjects('group');
    for(var i = 0; i < objs.length; i++){
        var obj = objs[i]
        var objClass = obj.get('class');
        if(objClass == "booth"){
            gridZIndex = i;
            break;
        }
    }
    
    for(var i = 0; i < objs.length; i++){
        var obj = objs[i]
        var objClass = obj.get('class');
        if(objClass == "line"){
            gridZIndex = i;
            break;
        }
    }
    objs.forEach(function(object){
        if(object.get('layer') == "original"){          
            var objectZIndex=  getZindex(object, mainCanvas)
            if(objectZIndex >= gridZIndex){
                mainCanvas.moveTo(object, gridZIndex-1)
                object.moveTo(gridZIndex+1)
                object.zIndex = gridZIndex+1
            }
        }
    })
}

export const sendAnotationToBackGrid = (mainCanvas)=>{
    var gridZIndex = 99999;
    var objs = mainCanvas.getObjects('group');
    for(var i = 0; i < objs.length; i++){
        var obj = objs[i]
        var objClass = obj.get('class');
        if(objClass == "booth"){
            gridZIndex = i;
            break;
        }
    }
    
    for(var i = 0; i < objs.length; i++){
        var obj = objs[i]
        var objClass = obj.get('class');
        if(objClass == "line"){
            gridZIndex = i;
            break;
        }
    }
    objs.forEach(function(object){
        if(object.get('layer') == "annotation" && !object.forward){          
            var objectZIndex=  getZindex(object, mainCanvas)
            if(objectZIndex >= gridZIndex){
                mainCanvas.moveTo(object, gridZIndex-1)
                object.moveTo(gridZIndex-1)
                object.zIndex = gridZIndex-1
            }
        }
    })
}

export const renderGrid2 = (g_grid, mainCanvas, gridSize, gridOffsetX, gridOffsetY, angle)=>{
    if(mainCanvas){
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var realMapWidth;
        var realMapHeight;
        var realGrid;
        var realOffsetX;
        var realOffsetY;

        var offsetX = 0;
        var offsetY = 0;
        var mapWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
        var mapHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);
        if(convertRateX){
            realMapWidth = mapWidth
            realMapHeight = mapHeight
            realGrid = unit2Px(gridSize, convertRateX, mainCanvas.mapUnit)
            realOffsetX = unit2Px(offsetX, convertRateX, mainCanvas.mapUnit)
            realOffsetY = unit2Px(offsetY, convertRateX, mainCanvas.mapUnit)
            if(mainCanvas.mapWidth*1 && mainCanvas.mapHeight*1){
                realMapWidth = unit2Px(mainCanvas.mapWidth, convertRateX, mainCanvas.mapUnit)
                realMapHeight = unit2Px(mainCanvas.mapHeight, convertRateX, mainCanvas.mapUnit)
            }
        }
        realOffsetX = 10;

    }
    if(mainCanvas && realGrid)
    {
        // if(gridOffsetX == undefined || gridOffsetY == undefined){
        //     var groups = mainCanvas.getObjects('group');
        //     for(var i = 0; i < groups.length; i++){
        //         var group = groups[i]
        //         if(group.get('class') == 'booth' || group.get('class') == 'booth-group'){
        //             if(gridOffsetX == undefined || gridOffsetY == undefined){
        //                 gridOffsetX = group.left;  gridOffsetY = group.top
        //             }
        //             if(group.top < gridOffsetY && group.left < gridOffsetX){
        //                 gridOffsetX = group.left
        //                 gridOffsetY = group.top
        //             }
        //             if(group.left < gridOffsetX){
        //                 gridOffsetX = group.left
        //                 gridOffsetY = group.top
        //             }
        //         }
        //     }
        // }
        realOffsetX = gridOffsetX?((gridOffsetX % realGrid )-realGrid):50
        realOffsetY = gridOffsetY?((gridOffsetY % realGrid )-realGrid):50
        realOffsetX+= unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
        realOffsetY+= unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
        var width = realMapWidth;
        var height = realMapHeight; 
        var zoom = mainCanvas.getZoom()
        for (var i = parseInt(-(width / realGrid)); i < (2*width / realGrid); i++) {
            var newLine = new fabric.Line([ i * realGrid+realOffsetX, realOffsetY-height, i * realGrid+realOffsetX, 2*height], { type:'line',  stroke: '#c0c0c0', strokeWidth:getStrokeWidth(mainCanvas)/zoom, selectable: false , class:'line',lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false, evented:false,excludeFromExport: true})
            mainCanvas.add(newLine);
            mainCanvas.sendToBack(newLine);
        }
        for (var i = parseInt(-height / realGrid); i < (2*height / realGrid); i++) {
            var newLine = new fabric.Line([ realOffsetX-width, i * realGrid+realOffsetY, 2*width, i * realGrid+realOffsetY], { type: 'line', stroke: '#c0c0c0', strokeWidth:getStrokeWidth(mainCanvas)/zoom, selectable: false , class:'line',lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false, evented:false,excludeFromExport: true})
            mainCanvas.add(newLine)
            mainCanvas.sendToBack(newLine);
        }

        // mainCanvas.clipPath = new fabric.Rect({
        //     width: realMapWidth+5,
        //     height: realMapHeight+5,
        //     top: 45,
        //     left: 45
        // });
    }
    mainCanvas.renderAll();
    rotateCanvasAndElements(mainCanvas, angle);
}
 

export const renderGrid1 = (g_grid, mainCanvas, gridSize, gridOffsetX, gridOffsetY, angle)=>{
    if(mainCanvas){
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var realMapWidth;
        var realMapHeight;
        var realGrid;
        var realOffsetX;
        var realOffsetY;

        var offsetX = 0;
        var offsetY = 0;
        var mapWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
        var mapHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);
        if(convertRateX){
            realMapWidth = mapWidth
            realMapHeight = mapHeight
            realGrid = unit2Px(gridSize, convertRateX, mainCanvas.mapUnit)
            realOffsetX = unit2Px(offsetX, convertRateX, mainCanvas.mapUnit)
            realOffsetY = unit2Px(offsetY, convertRateX, mainCanvas.mapUnit)
            if(mainCanvas.mapWidth*1 && mainCanvas.mapHeight*1){
                realMapWidth = unit2Px(mainCanvas.mapWidth, convertRateX, mainCanvas.mapUnit)
                realMapHeight = unit2Px(mainCanvas.mapHeight, convertRateX, mainCanvas.mapUnit)
            }
        }
        realOffsetX = 10;

    }
    if(mainCanvas && realGrid)
    {
        realOffsetX = gridOffsetX?((gridOffsetX *1)):0
        realOffsetY = gridOffsetY?((gridOffsetY *1)):0
        realOffsetX+= unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
        realOffsetY+= unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
        realOffsetX-=50
        realOffsetY-=50
        // realOffsetX = realOffsetX% realGrid 
        // realOffsetY = realOffsetY% realGrid 
        var width = realMapWidth;
        var height = realMapHeight;
        var zoom = mainCanvas.getZoom()

        var vpt = mainCanvas.viewportTransform;
        var position = fabric.util.qrDecompose(vpt);

        var option = {
            x: -((position.translateX)/position.scaleX+50),
            y: -((position.translateY)/position.scaleX+50),
            zoom: position.scaleX,
            mapWidth: mapWidth*position.scaleX,
            mapHeight: mapHeight*position.scaleX,                
            align_offset_x: realOffsetX*position.scaleX,             
            align_offset_y: realOffsetY*position.scaleX,
            gridSize:realGrid,
            angle:-angle,
            lineWidth: getStrokeWidth(mainCanvas)*zoom
            
        }
        g_grid.update2(option);
       
        g_grid.render()

    }
}

export const renderCoordinate=(mainCanvas, x, y, dwg_left, dwg_top)=>{
    var active_booth_file
    if (store){
        const state = store.getState();
        var active_booth_file = state.main.active_booth_file
    }

    var convertRateX= Math.abs(mainCanvas.convertRateX)
    var realMapWidth;
    var realMapHeight;
    var realGrid;
    var realOffsetX;
    var realOffsetY;

    var offsetX = 0;
    var offsetY = 0;
    var mapWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
    var mapHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);
    if(convertRateX){
        realMapWidth = mapWidth
        realMapHeight = mapHeight
        realOffsetX = unit2Px(offsetX, convertRateX, mainCanvas.mapUnit)
        realOffsetY = unit2Px(offsetY, convertRateX, mainCanvas.mapUnit)
        if(mainCanvas.mapWidth*1 && mainCanvas.mapHeight*1){
            realMapWidth = unit2Px(mainCanvas.mapWidth, convertRateX, mainCanvas.mapUnit)
            realMapHeight = unit2Px(mainCanvas.mapHeight, convertRateX, mainCanvas.mapUnit)
        }
    }
    var width = realMapWidth;
    var height = realMapHeight;
    var zoom = mainCanvas.getZoom()
    var coordiante = [];
    let gap = 0;
    if (mainCanvas.mapUnit=='meter'){
        gap = 0.5
        // if(mainCanvas.convertUnit=='mm'){
        //     gap = 1000
        // }
    }
    else{
        gap = 10
    }
    var newLine1 = new fabric.Line([ x-getStrokeWidth(mainCanvas)/2, -height, x-getStrokeWidth(mainCanvas)/2, 2*height], { type:'line',  stroke: '#888888', strokeDashArray:[Math.max(gap/convertRateX/Math.max(zoom,1), 1), Math.max(gap/convertRateX/Math.max(zoom,1), 1)], strokeWidth:getStrokeWidth(mainCanvas), selectable: false , class:'line',lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false, evented:false,excludeFromExport: true})
    mainCanvas.add(newLine1);
    coordiante.push(newLine1);
    var newLine2 = new fabric.Line([ -width, y-getStrokeWidth(mainCanvas)/2, 2*width, y-getStrokeWidth(mainCanvas)/2], { type: 'line', stroke: '#888888', strokeDashArray:[Math.max(gap/convertRateX/Math.max(zoom,1), 1), Math.max(gap/convertRateX/Math.max(zoom,1), 1)], strokeWidth:getStrokeWidth(mainCanvas), selectable: false , class:'line',lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false, evented:false,excludeFromExport: true})
    mainCanvas.add(newLine2)
    coordiante.push(newLine2);

    var fontSize = 50/convertRateX
    let idealSize = 0;
    if(mainCanvas.mapUnit == 'meter'){
        idealSize = 2-0.1;
    }
    else{
        idealSize = 10-1;
    }
    var minFontSize = unit2Px((idealSize)/3, convertRateX, mainCanvas.mapUnit)
    fontSize = Math.min(fontSize, minFontSize)
    var leftTxt = 0
    var topTxt = 0
    if(active_booth_file?.version == 2){
        leftTxt = (dwg_left*1).toFixed(2);
        topTxt = (dwg_top*1).toFixed(2);
    }
    else{
        leftTxt = unit2Px(x, convertRateX, mainCanvas.mapUnit).toFixed(2);
        topTxt = unit2Px(y, convertRateX, mainCanvas.mapUnit).toFixed(2);
    }
    var f_text1 = new fabric.Text(`(${leftTxt}, ${topTxt})`, { 
        fontSize:fontSize,
        class:'boothName',
        objectCaching: false,
        overflow:'hidden',     
        left: x +unit2Px(0.5, convertRateX, mainCanvas.mapUnit), 
        top:y-fontSize*1.5,
        backgroundColor:'white'
    });
    mainCanvas.add(f_text1); 
    coordiante.push(f_text1);


    mainCanvas.renderAll()
    return coordiante;
}

export const removeCoordinate=(mainCanvas, coordiante)=>{
    for(var i = 0; i < coordiante?.length; i++){
        mainCanvas.remove(coordiante[i]);
    }
    mainCanvas.remove();
}

export const moveCoordinate=(mainCanvas, x, y, coordiante, dwg_left, dwg_top)=>{
    var active_booth_file
    if (store){
        const state = store.getState();
        var active_booth_file = state.main.active_booth_file
    }
    var convertRateX= Math.abs(mainCanvas.convertRateX)    
    var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
    var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
    var fontSize = 50/Math.max(convertRateX, 1)
    fontSize = 50/convertRateX
    let idealSize = 0;
    if(mainCanvas.mapUnit == 'meter'){
        idealSize = 2-0.1;
    }
    else{
        idealSize = 10-1;
    }
    var minFontSize = unit2Px((idealSize)/3, convertRateX, mainCanvas.mapUnit)
    fontSize = Math.min(fontSize, minFontSize)

    if(coordiante){
        if(coordiante[0]){
            coordiante[0].left = x-getStrokeWidth(mainCanvas)/2
        }
        if(coordiante[1]){
            coordiante[1].top = y-getStrokeWidth(mainCanvas)/2
        }
        if(coordiante[2]){
            coordiante[2].left = x + unit2Px(0.5, convertRateX, mainCanvas.mapUnit)
            coordiante[2].top = y-fontSize*1.5
            var leftTxt = 0
            var topTxt = 0
            if(active_booth_file?.version == 2){
                leftTxt = (px2unit(x-50-mapOffsetX, convertRateX)+mainCanvas.convertOffsetX/12).toFixed(2);
                topTxt = (mainCanvas.convertOffsetY/12 - px2unit(y-50-mapOffsetY,convertRateX)).toFixed(2);
            }
            else{
                leftTxt = unit2Px(x, convertRateX, mainCanvas.mapUnit).toFixed(2);
                topTxt = unit2Px(y, convertRateX, mainCanvas.mapUnit).toFixed(2);
            }

            coordiante[2].set({
                'text':`(${leftTxt}, ${topTxt})`
            })
        }
    }
    mainCanvas.remove();
}

export const removeGrid = (mainCanvas)=>{
    if(mainCanvas){
      var objs = mainCanvas.getObjects('group');
      objs.forEach(function(obj){
        var objClass = obj.get('class');
        if(objClass == "line"){
          mainCanvas.remove(obj);
        }
      })
      mainCanvas.renderAll();
    }
}

export const scaleGrid = (mainCanvas)=>{
    // if(mainCanvas){
    //   var objs = mainCanvas.getObjects('group');
    //   objs.forEach(function(obj){
    //     var objClass = obj.get('class');
    //     if(objClass == "line"){
    //       var lines = obj.getObjects('line')
    //         lines.forEach(function(line){
    //             var objClass = obj.get('class');
    //             if(objClass == "line"){
    //                 line.
    //             }
    //         })
    //     }
    //   })
    //   mainCanvas.renderAll();
    // }
}

export const removeGrid1 = (g_grid)=>{
    g_grid.clear()
}

export const redrawGrid = (g_grid, mainCanvas, show, gridSize, angle, gridOffsetX, gridOffsetY)=>{
    if(g_grid && mainCanvas){
        if(show){
            // renderBorder(mainCanvas)
            renderGrid(g_grid, mainCanvas, gridSize, gridOffsetX, gridOffsetY, angle);
            // rotateCanvasAndElements(mainCanvas, angle);
        }
        else{
            removeGrid(mainCanvas);
        }
    }
}


export const lockMap = (canvas) =>{
    if(canvas){
        canvas.discardActiveObject();
        canvas.forEachObject(function(object) {
            var layer = object.get('layer'); 
            if(!checkIfSelectable(object)){
                return true;
            }
            // object.evented = false;
            // object.selectable = false; 
            // object.selection=false;
            // object.hasControls=false;
            // object.hasBorders=false;
            object.lockMovementX= true;
            object.lockMovementY= true;
            object.lockRotation= true;
            object.lockScalingX= true;
            object.lockScalingY= true;
        });
        canvas.mapLock = 1;
        canvas.renderAll();
    }
}


export const unlockMap = (canvas) =>{
    if(canvas){
        canvas.discardActiveObject();
        canvas.forEachObject(function(object) {
            var layer = object.get('layer'); 
            if(!checkIfSelectable(object) || layer == "annotation" || layer == "venue"){
                return true;
            }
            object.evented = true;
            object.selectable = true; 
            object.lockMovementX= false;
            object.lockMovementY= false;
            object.lockRotation= false;
            object.lockScalingX= false;
            object.lockScalingY= false;
        });
        canvas.selection = true;
        canvas.mapLock = 0;
        canvas.renderAll();
    }
}

export const checkIfSelectable = (object)=>{
    if(object){
        var layer = object.layer; 
        if(layer == "original" || layer == "deleted" || object.class == 'line' || object.lockforever ){
            return false;
        }
        return true
    }
    return false
}

export const toggleDrawMode=(canvas, mode)=>{ //mode=draw|general
    if(canvas){
        if(mode == "draw"){
            canvas.forEachObject(function(object) {
                if(object.layer == "annotation"){
                    object.evented = true;
                    object.selectable = true; 
                    object.hasControls= true
                    object.hasBorders= true
                    object.lockMovementX= false;
                    object.lockMovementY= false;
                    object.lockRotation= false;
                    object.lockScalingX= false;
                    object.lockScalingY= false;
                    // object.opacity= 1;
                }
                else if(object.layer == "venue"){
                    object.selectable = false; 
                    object.hasControls= false
                    object.hasBorders= false
                    object.lockMovementX= true;
                    object.lockMovementY= true;
                    object.lockRotation= true;
                    object.lockScalingX= true;
                    object.lockScalingY= true;
                    // object.opacity= 1;
                }
                else{
                    if(!checkIfSelectable(object)){
                        return true;
                    }
                    object.evented = false;
                    object.selectable = false; 
                    object.hasControls= false
                    object.hasBorders= false
                    object.lockMovementX= true;
                    object.lockMovementY= true;
                    object.lockRotation= true;
                    object.lockScalingX= true;
                    object.lockScalingY= true;
                    object.opacity= 0.4;
                }
            });
            canvas.drawMode = mode
            canvas.renderAll();
        }
        else if(mode == "general"){
            canvas.forEachObject(function(object) {
                if(object.layer == "annotation"){
                    object.evented = false;
                    object.selectable = false; 
                    object.hasControls= false
                    object.hasBorders= false
                    object.lockMovementX= true;
                    object.lockMovementY= true;
                    object.lockRotation= true;
                    object.lockScalingX= true;
                    object.lockScalingY= true;
                    // object.opacity= 1;
                }
                else if(object.layer == "venue"){
                    object.selectable = false; 
                    object.hasControls= false
                    object.hasBorders= false
                    object.lockMovementX= true;
                    object.lockMovementY= true;
                    object.lockRotation= true;
                    object.lockScalingX= true;
                    object.lockScalingY= true;
                    // object.opacity= 1;
                }
                else{
                    if(!checkIfSelectable(object)){
                        return true;
                    }
                    object.evented = true;
                    object.selectable = true; 
                    object.hasControls= true
                    object.hasBorders= true
                    object.lockMovementX= false;
                    object.lockMovementY= false;
                    object.lockRotation= false;
                    object.lockScalingX= false;
                    object.lockScalingY= false;
                    object.opacity= 1;
                }
            });
            canvas.drawMode = mode
            canvas.renderAll();
        }
        else if(mode == "sponsorships"){
            // canvas.forEachObject(function(object) {
            //     if(object.layer == "sponsorships"){
            //         object.evented = true;
            //         object.selectable = true; 
            //         object.hasControls= true
            //         object.hasBorders= true
            //         object.lockMovementX= false;
            //         object.lockMovementY= false;
            //         object.lockRotation= false;
            //         object.lockScalingX= false;
            //         object.lockScalingY= false;
            //         // object.opacity= 1;
            //     }
            //     else{
            //         if(!checkIfSelectable(object)){
            //             return true;
            //         }
            //         object.evented = false;
            //         object.selectable = false; 
            //         object.hasControls= false
            //         object.hasBorders= false
            //         object.lockMovementX= true;
            //         object.lockMovementY= true;
            //         object.lockRotation= true;
            //         object.lockScalingX= true;
            //         object.lockScalingY= true;
            //         object.opacity= 0.4;
            //     }
            // });
            canvas.drawMode = mode
            canvas.renderAll();
        }
    }
}

export const redrawobjectborder = (mainCanvas, zoom, target)=>{
    if(target.get('groupped')){
        var groups = target.getObjects('group');
        for(var groupInd = 0; groupInd < groups.length; groupInd++){
            var group = groups[groupInd]
            redrawboothborder(mainCanvas, zoom, group)
        }
        target.dirty = true
        // mainCanvas.requestRenderAll()
    }
    else{
        if(target.get('class') == "booth-group"){
            var groups = target.getObjects('group');
            for(var groupInd = 0; groupInd < groups.length; groupInd++){
                var group = groups[groupInd]
                redrawboothborder(mainCanvas, zoom, group)
            }
            target.dirty = true
            // mainCanvas.requestRenderAll()
        }
        else{
            redrawboothborder(mainCanvas, zoom, target)
            target.dirty = true
            // mainCanvas.requestRenderAll()
        }
    }

}

export const redrawboothborder = (mainCanvas, zoom, object)=>{
    var polygons = object.getObjects('polygon');
    for(var j = 0; j<  polygons.length; j++){
        var o = polygons[j];
        if (!o.strokeWidthUnscaled && o.strokeWidth) {
            o.strokeWidthUnscaled = o.strokeWidth;
            o.strokeWidthUnscaled = 1;
        }
        if (!o.leftUnscaled) {
            o.leftUnscaled = o.left;
            o.topUnscaled = o.top;
        }
        if (o.strokeWidthUnscaled) {
            o.strokeWidth = o.strokeWidthUnscaled / zoom;
            o.dirty= true
        }
        
        // object.width = o.width;
        // object.height = o.height;
    }
    object.dirty= true
}

export const adjustobjectborder = (mainCanvas, zoom, target)=>{
    if(target.get('groupped')){
        var groups = target.getObjects('group');
        for(var groupInd = 0; groupInd < groups.length; groupInd++){
            var group = groups[groupInd]
            adjustboothborder(mainCanvas, zoom, group)
        }
        target.dirty = true
        // mainCanvas.requestRenderAll()
    }
    else{
        if(target.get('class') == "booth-group"){
            var groups = target.getObjects('group');
            for(var groupInd = 0; groupInd < groups.length; groupInd++){
                var group = groups[groupInd]
                adjustboothborder(mainCanvas, zoom, group)
            }
            target.dirty = true
            // mainCanvas.requestRenderAll()
        }
        else{
            adjustboothborder(mainCanvas, zoom, target)
            target.dirty = true
            // mainCanvas.requestRenderAll()
        }
    }

}

export const adjustboothborder = (mainCanvas, zoom, object)=>{

    var polygons = object.getObjects('polygon');
    for(var j = 0; j<  polygons.length; j++){
        var o = polygons[j];
        
        o.left = -object.width/2-o.strokeWidth/2
        o.top = -object.hegith/2-o.strokeWidth/2
        o.dirty= true
        // o.left = o.left + offsetX/2
        // o.top = o.top + offsetY/2
        
        // object.width = o.width;
        // object.height = o.height;
    }
    object.dirty= true
}

export const setObjectBorder = (mainCanvas, target, color)=>{

    var polygons = target.getObjects('polygon');
    for(var j = 0; j<  polygons.length; j++){
        var o = polygons[j];
        o.stroke = color
        o.dirty= true
    }
    target.dirty= true
}

export const setObjectBG = (mainCanvas, target, color)=>{
    if(target.get('groupped')){
        var groups = target.getObjects('group');
        for(var groupInd = 0; groupInd < groups.length; groupInd++){
            var group = groups[groupInd]
            changeSingleBG(group, color)
            mainCanvas.bringToFront(group);
            // mainCanvas.requestRenderAll()
        }
    }
    else{
        // if(target.get('class') == "booth-group"){
        //     var groups = target.getObjects('group');
        //     for(var groupInd = 0; groupInd < groups.length; groupInd++){
        //         var group = groups[groupInd]
        //         changeSingleBG(group, color)
        //     }
        //     mainCanvas.bringToFront(target);
        //     // mainCanvas.requestRenderAll()
        // }
        // else
        {
            changeSingleBG(target, color)
            // target.bringForward();
            // mainCanvas.bringToFront(target);
            // mainCanvas.requestRenderAll()
        }
    }
}

export const setObjectFontColor = (mainCanvas, target, color)=>{
    if(target.get('class') == "booth"){ 
        var textObjects = target.getObjects('text');
        for(var k = 0; k< textObjects.length; k++){
            textObjects[k].set({
                fill: color
            })
        }
    }
}

export const getBGColor = (target)=>{   
    
    if(target.get('class') == "booth"){ 
        var objects = target.getObjects('polygon');
        var bgObject = null;
        for(var i = 0; i < objects.length; i++){
            if(objects[i].type == "polygon" && objects[i].class!="mask"){
                bgObject = objects[i]; 
                return bgObject.fill
            }
        }
    }
    else if(target.get('layer') == "sponsorships"){ 
        return target.bgColorCustom
    }
    return "#dadada"
}

export const changeSingleBG = (target, color)=>{   
    
    if(target.get('class') == "booth"){ 
        var objects = target.getObjects('polygon');
        var bgObject = null;
        for(var i = 0; i < objects.length; i++){
            if(objects[i].type == "polygon" && objects[i].class!="mask"){
                bgObject = objects[i]; 
                
                bgObject.set({
                fill: color
                })
            }
        }
    }
    else if(target.get('layer') == "sponsorships"){
        if(target.get('type') == "group"){
            if(target.getObjects('rect')[0]){
              target.getObjects('rect')[0].set({fill: color})
            }
            else if(target.getObjects('polygon')[0]){
              target.getObjects('polygon')[0].set({fill: color})
            }
            else if(target.getObjects('polyline')[0]){
              target.getObjects('polyline')[0].set({fill: color})
            }
            if(target.getObjects('circle')[0]){
              target.getObjects('circle')[0].set({fill: color})
            }
        }
        else{
            target.set({fill: color})
        }
    }
    else{
        if(target.get('type') == "group"){
            if(target.getObjects('rect')[0]){
              target.getObjects('rect')[0].set({fill: color})
            }
            else if(target.getObjects('polygon')[0]){
              target.getObjects('polygon')[0].set({fill: color})
            }
            else if(target.getObjects('polyline')[0]){
              target.getObjects('polyline')[0].set({fill: color})
            }
            if(target.getObjects('circle')[0]){
              target.getObjects('circle')[0].set({fill: color})
            }
        }
        else{
            target.set({fill: color})
        }
    }
}
export const checkiftable = (object)=>{
    return true;
}
export const checkifrotate = (object)=>{
    
    if(object.id == "cv_2637"){
        // console.log("booth-cv_2637", object)
    }
    if(checkifrect(object)){
        if(object.id == "cv_2637"){
            // console.log("booth-cv_2637", object)
        }
        var points = object.points;
        var p1 = points[0]
        var p2 = points[1]
        var p3 = points[2]
        var angleRadians1 = Math.atan2(p2['y'] - p1['y'], p2['x'] - p1['x']);
        var angleRadians2 = Math.atan2(p3['y'] - p2['y'], p3['x'] - p2['x']);
    
        var angleDeg1 = 0;
        var angleDeg2 = 0;
        // angle in degrees
        angleDeg1 = Math.abs(angleRadians1 * 180 / Math.PI);
        angleDeg1 = angleDeg1 % 90
        angleDeg2 = Math.abs(angleRadians2 * 180 / Math.PI);
        angleDeg2 = angleDeg2 % 90
        if((Math.abs(angleDeg1) > 6 && Math.abs(angleDeg1) <84) || (Math.abs(angleDeg2) > 6 && Math.abs(angleDeg2) <84)){
            return true
        }

    }
    else{        
        // console.log("non rect", object)
    }
    return false;
}

export const checkifrotatepoints = (points)=>{
    var p1 = points[0]
    var p2 = points[1]
    var p3 = points[2]
    var angleRadians1 = Math.atan2(p2['y'] - p1['y'], p2['x'] - p1['x']);
    var angleRadians2 = Math.atan2(p3['y'] - p2['y'], p3['x'] - p2['x']);

    var angleDeg1 = 0;
    var angleDeg2 = 0;
    // angle in degrees
    angleDeg1 = Math.abs(angleRadians1 * 180 / Math.PI);
    angleDeg1 = angleDeg1 % 90
    angleDeg2 = Math.abs(angleRadians2 * 180 / Math.PI);
    angleDeg2 = angleDeg2 % 90
    if((Math.abs(angleDeg1) > 6 && Math.abs(angleDeg1) <84) || (Math.abs(angleDeg2) > 6 && Math.abs(angleDeg2) <84)){
        if(isAlmostRectangle({points:points}))
            return true
        return false
    }
    // console.log("less angle", points)
    return false;
}

export const checkifpathrotate = (object)=>{
    
    if(object.id == "cv_2637"){
        // console.log("booth-cv_2637", object)
    }
    if(checkifpathrect(object)){
        if(object.id == "cv_2637"){
            // console.log("booth-cv_2637", object)
        }
        var path = object.path;
        var p1 = path[0]
        var p2 = path[1]
        var p3 = path[2]
        var angleRadians1 = Math.atan2(p2[2] - p1[2], p2[1] - p1[1]);
        var angleRadians2 = Math.atan2(p3[2] - p2[2], p3[1] - p2[1]);
    
        var angleDeg1 = 0;
        var angleDeg2 = 0;
        // angle in degrees
        angleDeg1 = Math.abs(angleRadians1 * 180 / Math.PI);
        angleDeg1 = angleDeg1 % 90
        angleDeg2 = Math.abs(angleRadians2 * 180 / Math.PI);
        angleDeg2 = angleDeg2 % 90
        if((Math.abs(angleDeg1) > 6 && Math.abs(angleDeg1) <84) || (Math.abs(angleDeg2) > 6 && Math.abs(angleDeg2) <84)){
            return true
        }

    }
    else{        
        // console.log("non rect", object)
    }
    return false;
}

export const checkifbooth = (object)=>{
    var path = object.path;
    if(path.length >= 5){
        var point0 = path[0]
        var point1 = path[path.length - 1]
        var d = Math.sqrt((point1[1]-point0[1])*(point1[1]-point0[1]) + (point1[2]-point0[2])*(point1[2]-point0[2]))
        if(Math.round(d) < 0.5) return true;
    }
    return false
}

export const checkifpathrect = (object)=>{
    var path = object.path;
    if(path.length != 5) return false;
    var p0 = path[0]
    var p1 = path[1]
    var p2 = path[2]
    var p3 = path[3]
    var p4 = path[4]
    var angleRadians1 = Math.atan2(p0[2] - p1[2], p0[1] - p1[1]);
    var angleRadians2 = Math.atan2(p2[2] - p1[2], p2[1] - p1[1]);
    if(angleRadians1 < 0) angleRadians1 = Math.PI+angleRadians1
    if(angleRadians2 < 0) angleRadians2 = Math.PI+angleRadians2
    
    // angle in degrees
    var angleDeg1 = Math.abs(angleRadians1 * 180 / Math.PI);
    var angleDeg2 = Math.abs(angleRadians2 * 180 / Math.PI);

    var angle1 = Math.abs(angleDeg2 - angleDeg1);

    angleRadians1 = Math.atan2(p1[2] - p2[2], p1[1] - p2[1]);
    angleRadians2 = Math.atan2(p3[2] - p2[2], p3[1] - p2[1]);
    if(angleRadians1 < 0) angleRadians1 = Math.PI+angleRadians1
    if(angleRadians2 < 0) angleRadians2 = Math.PI+angleRadians2
    
    // angle in degrees
    angleDeg1 = Math.abs(angleRadians1 * 180 / Math.PI);
    angleDeg2 = Math.abs(angleRadians2 * 180 / Math.PI);

    var angle2 = Math.abs(angleDeg2 - angleDeg1);

    angleRadians1 = Math.atan2(p2[2] - p3[2], p2[1] - p3[1]);
    angleRadians2 = Math.atan2(p4[2] - p3[2], p4[1] - p3[1]);
    if(angleRadians1 < 0) angleRadians1 = Math.PI+angleRadians1
    if(angleRadians2 < 0) angleRadians2 = Math.PI+angleRadians2
    
    // angle in degrees
    angleDeg1 = Math.abs(angleRadians1 * 180 / Math.PI);
    angleDeg2 = Math.abs(angleRadians2 * 180 / Math.PI);

    var angle3 = Math.abs(angleDeg2 - angleDeg1);

    

    angleRadians1 = Math.atan2(p3[2] - p4[2], p3[1] - p4[1]);
    angleRadians2 = Math.atan2(p1[2] - p0[2], p1[1] - p0[1]);
    if(angleRadians1 < 0) angleRadians1 = Math.PI+angleRadians1
    if(angleRadians2 < 0) angleRadians2 = Math.PI+angleRadians2
    
    // angle in degrees
    angleDeg1 = Math.abs(angleRadians1 * 180 / Math.PI);
    angleDeg2 = Math.abs(angleRadians2 * 180 / Math.PI);

    var angle4 = Math.abs(angleDeg2 - angleDeg1);
    if(
        (Math.round(angle1)%90 <= 6) && 
        (Math.round(angle2)%90 <= 6) && 
        (Math.round(angle3)%90 <= 6) && 
        (Math.round(angle4)%90 <= 6)
    )
        return true;
    return false
}

export const checkifrect = (object)=>{
    var points = object.points;
    if(points.length != 5) return false;
    var p0 = points[0]
    var p1 = points[1]
    var p2 = points[2]
    var p3 = points[3]
    var p4 = points[4]
    var angleRadians1 = Math.atan2(p0['y'] - p1['y'], p0['x'] - p1['x']);
    var angleRadians2 = Math.atan2(p2['y'] - p1['y'], p2['x'] - p1['x']);
    if(angleRadians1 < 0) angleRadians1 = Math.PI+angleRadians1
    if(angleRadians2 < 0) angleRadians2 = Math.PI+angleRadians2
    
    // angle in degrees
    var angleDeg1 = Math.abs(angleRadians1 * 180 / Math.PI);
    var angleDeg2 = Math.abs(angleRadians2 * 180 / Math.PI);

    var angle1 = Math.abs(angleDeg2 - angleDeg1);

    angleRadians1 = Math.atan2(p1['y'] - p2['y'], p1['x'] - p2['x']);
    angleRadians2 = Math.atan2(p3['y'] - p2['y'], p3['x'] - p2['x']);
    if(angleRadians1 < 0) angleRadians1 = Math.PI+angleRadians1
    if(angleRadians2 < 0) angleRadians2 = Math.PI+angleRadians2
    
    // angle in degrees
    angleDeg1 = Math.abs(angleRadians1 * 180 / Math.PI);
    angleDeg2 = Math.abs(angleRadians2 * 180 / Math.PI);

    var angle2 = Math.abs(angleDeg2 - angleDeg1);

    angleRadians1 = Math.atan2(p2['y'] - p3['y'], p2['x'] - p3['x']);
    angleRadians2 = Math.atan2(p4['y'] - p3['y'], p4['x'] - p3['x']);
    if(angleRadians1 < 0) angleRadians1 = Math.PI+angleRadians1
    if(angleRadians2 < 0) angleRadians2 = Math.PI+angleRadians2
    
    // angle in degrees
    angleDeg1 = Math.abs(angleRadians1 * 180 / Math.PI);
    angleDeg2 = Math.abs(angleRadians2 * 180 / Math.PI);

    var angle3 = Math.abs(angleDeg2 - angleDeg1);

    

    angleRadians1 = Math.atan2(p3['y'] - p4['y'], p3['x'] - p4['x']);
    angleRadians2 = Math.atan2(p1['y'] - p0['y'], p1['x'] - p0['x']);
    if(angleRadians1 < 0) angleRadians1 = Math.PI+angleRadians1
    if(angleRadians2 < 0) angleRadians2 = Math.PI+angleRadians2
    
    // angle in degrees
    angleDeg1 = Math.abs(angleRadians1 * 180 / Math.PI);
    angleDeg2 = Math.abs(angleRadians2 * 180 / Math.PI);

    var angle4 = Math.abs(angleDeg2 - angleDeg1);
    if(
        (Math.round(angle1)%90 <= 6) && 
        (Math.round(angle2)%90 <= 6) && 
        (Math.round(angle3)%90 <= 6) && 
        (Math.round(angle4)%90 <= 6)
    )
        return true;
    return false
}

export const resizeBooth = (mainCanvas, booth, width, height)=>{
    var convertRateX= 1
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
    }
    
    var bgColor = "#dadada"
    var element = new fabric.Polygon(
        [
            {x: 0, y:0},
            {x: width, y:0},
            {x: width, y:height},
            {x: 0, y:height},
            {x: 0, y:0}
        ], {
          class:"outline",
          left: booth.left,
          top: booth.top,
          // left: 0-(boxUnitSize)/2,
          // top: 0-(boxUnitSize)/2,
          fill: '', 
          stroke: 'black',
        //   strokeWidth: 3/Math.max(convertRateX, 1),
          strokeWidth: getStrokeWidth(mainCanvas),
          borderColor: 'black',
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          fill: bgColor, 
    });


    var group = new fabric.Group([element]).set({
        // width: boxUnitSize+1,
        // height: boxUnitSize+1,
        width: width,
        height: height,
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
    });	
    group.set(buildObjectProperty(booth))
    group.set({        
        borderColor: '#ff7777',
        cornerColor: 'red',
        cornerSize: 6,
        transparentCorners: false,
        uniq_id: booth.uniq_id,
        modified: true,
    });
    // element.clone(function(cloned) {
    //     cloned.set({
    //         borderColor: '#ff7777',
    //         cornerColor: 'red',
    //         cornerSize: 6,
    //         transparentCorners: false,
    //         fill: bgColor, 
    //         stroke: 'transparent',
    //         borderColor: 'red',
    //         opacity:1,
    //         class:'color',
    //         layer: 'color'
    //     })
    //     group.add(cloned);   
    //     cloned.sendToBack();                                                 
    // });

    addText(mainCanvas, group, (group.company?((group.company+"").trim()+"\n"):"")+""+group.booth_number)

      
    // element.clone(function(cloned) {
    //     group.clipPath = cloned                                    
    // });
    mainCanvas.remove(booth);
    mainCanvas.add(group);
    return group;
}

export const checkifrectbooth = (object)=>{
    if(object.class == 'booth'){
        var polygons = object.getObjects('polygon')   
        if(polygons?.length){
            let polygon = polygons[0]
            return polygon.points.length === 5
        }
    }
    return false
}

var g_edgedetection = 20 //pixels to snap

export const getSnapPosition=(mainCanvas, position)=>{
    var convertRateX= Math.abs(mainCanvas.convertRateX)
    var realMapWidth;
    var realMapHeight;
    var realGrid;
    var realOffsetX;
    var realOffsetY;
    var edgedetection = g_edgedetection/Math.sqrt(convertRateX)

    var offsetX = 0;
    var offsetY = 0;
    var mapWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
    var mapHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);
    if(convertRateX){
        realMapWidth = mapWidth
        realMapHeight = mapHeight
        realGrid = unit2Px(mainCanvas.gridSize, convertRateX, mainCanvas.mapUnit)
        realOffsetX = mainCanvas.gridOffsetX?((mainCanvas.gridOffsetX % realGrid )-realGrid):50
        realOffsetY = mainCanvas.gridOffsetY?((mainCanvas.gridOffsetY % realGrid )-realGrid):50
        realOffsetX+= unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
        realOffsetY+= unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
    }
    if(mainCanvas.snapToGrid){
        var gridAngle = mainCanvas.gridAngle?mainCanvas.gridAngle:0
        var canvasCenter = new fabric.Point(mainCanvas.getWidth()/2, mainCanvas.getHeight()/2);
        var objectOrigin = new fabric.Point(position.x, position.y);
        var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, gridAngle*2*Math.PI/360*(-1))
        
        var left = Math.round((new_loc.x-realOffsetX) / realGrid) * realGrid+realOffsetX;
        var top = Math.round((new_loc.y-realOffsetY) / realGrid) * realGrid+realOffsetY;
        objectOrigin = new fabric.Point(left, top);
        new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, gridAngle*2*Math.PI/360*(1))
        return new_loc; //{x: x, y: y}
    }
    return false
}

export const addText = (mainCanvas, group, name)=>{
    try{
        var fontColor = "black";
        var exhibitorStatus = false;
        var exhibitors = group?.exhibitors
        if(exhibitors && exhibitors.length > 0){
            exhibitorStatus = exhibitors[0].STATUS
        }
        if(group.layer == 'venue') return
        var LIST_PENDING_EXHIBITORS = true
        if (store){
            const state = store.getState();
            // console.log("state", state)
            var groupOptions = state.main.groupOptions
            var boothGroup = group.boothGroup;
            for(var k = 0; k < groupOptions.length; k++){
                if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                    var groupOption = groupOptions[k]
                    if(groupOption['FONT_COLOR'] && groupOption['FONT_COLOR']!=""){
                        fontColor = convertRgbHex(groupOption['FONT_COLOR']);
                    }
                    else{
                        fontColor = "black"
                    }
                }
            }
            if(exhibitorStatus == 2 && mainCanvas.role!='admin' && state.main.exhibitorList?.SETUP && !state.main.exhibitorList?.SETUP?.LIST_PENDING_EXHIBITORS){
                LIST_PENDING_EXHIBITORS = false;
            }
        }
        
        var booth_number_full = group.booth_number?group.booth_number:"";
        // if(booth_number_full*1 == 523){
        //     group.company = "Test-Exhibitor"
        // }
        
        var company_name_full = group.company?`${group.company}`.trim():"";
        var companyArray = company_name_full.replaceAll("-","- ").split(" ")
        var companyWrap = companyArray.join('\n')

        var convertRateX = 1;
        if(mainCanvas.convertRateX){
            convertRateX = mainCanvas.convertRateX
        }
        var zoom = mainCanvas.getZoom();
        var fontSize = 50/Math.max(convertRateX, 1)
        fontSize = 50/convertRateX
        var minFontSize = unit2Px((10-1)/2, convertRateX, mainCanvas.mapUnit)
        fontSize = Math.min(fontSize, minFontSize)
        var f_text_company = null
        if(company_name_full && company_name_full!="" && LIST_PENDING_EXHIBITORS){
            f_text_company = new fabric.Text(company_name_full?company_name_full:"", { 
                fontSize:fontSize,
                class:'boothName',
                class1:'company',
                objectCaching: false,
                width: group.width,
                height: group.height,
                overflow:'hidden',
                fill: fontColor
            });
        }
        if(!LIST_PENDING_EXHIBITORS){
            company_name_full = ""
        }
        var f_text_booth = new fabric.Text(booth_number_full?booth_number_full:"", { 
            fontSize:fontSize,
            class:'boothName',
            class1:'boothNumber',
            objectCaching: false,
            width: group.width,
            height: group.height,
            overflow:'hidden',
            fill: fontColor
        });
        var f_text_hidden = new fabric.Text(booth_number_full?booth_number_full:"", { 
            fontSize:fontSize,
            class:'boothName',
            class1:'hidden',
            objectCaching: false,
            width: group.width,
            height: group.height,
            overflow:'hidden',
            visible: false
        });
        if(f_text_company || f_text_booth){
            var f_text = f_text_company?f_text_company:f_text_booth;
            if(maxCompanyLength(company_name_full) < booth_number_full.length){
                f_text = f_text_booth;
            }
            if(!f_text){
                f_text = f_text_booth
            }
            
            var newScale = 1;
            var newScaleBooth = 1;
            var marginX = 5/Math.max(zoom, 1)/Math.max(convertRateX, 1) + 2/Math.max(convertRateX, 1)
            var marginX =  getStrokeWidth(mainCanvas)*2;//5/Math.max(zoom, 1)/convertRateX + 2/convertRateX
            var v_width = f_text_company?f_text_company.width*1.2:1
            var v_hegiht = f_text_company?f_text_company.height*1.2:1                        
            
            var v_width_booth = f_text_hidden?f_text_hidden.width*1.2:1
            var v_height_booth = f_text_hidden?f_text_hidden.height*1.2:1

            if(f_text.fontSize*zoom > 30)
            {
                var newScaleY = Math.min(30/(f_text.fontSize*zoom), (group.height-f_text_booth.height*newScale-2*marginX)/v_hegiht)
                var newScaleX = Math.min(30/(f_text.fontSize*zoom), (group.width-2*marginX)/v_width)
                newScale =  newScale = Math.max(Math.min(newScaleX, newScaleY),0)
                
                newScaleBooth = Math.max(Math.min(30/(f_text.fontSize*zoom), (group.width-2*marginX)/v_width_booth, (group.height-2*marginX)/v_height_booth),0)
            }
            else{
                var newScaleY = Math.min(1, (group.height-f_text_booth.height*newScale-2*marginX)/v_hegiht)
                var newScaleX = Math.min(1, (group.width-2*marginX)/v_width)
                newScale = Math.max(Math.min(newScaleX, newScaleY),0)
                // newScale = group.height/v_hegiht
                
                newScaleBooth = Math.max(Math.min(1, (group.width-2*marginX)/v_width_booth, (group.height-2*marginX)/v_height_booth),0)
            }     

            var v_rate_width =Math.min((group.width-2*marginX)/(newScaleBooth*v_width_booth), 1)
            v_rate_width = Math.round(v_rate_width*1000)/1000
            // var v_rate_height =Math.min((group.height-2*marginX)/(newScale*v_hegiht), 1)

            var booth_length = booth_number_full.length
            var companyString = ""
            if(group.company){
                companyString = subCompanyString(company_name_full, 0)
            }
            var booth_number = ""
            if(group.booth_number){
                var length = Math.floor(booth_length*v_rate_width)
                if(length < group.booth_number.length){
                    length--;
                }
                booth_number = booth_number_full.substring(0, length)
            }
            var newTextBooth = booth_number?booth_number:""
            if(newTextBooth != f_text_booth.text){
                f_text_booth.set({      
                    text: newTextBooth
                })
            }
            var newTextCompany = companyString?companyString:""
            if(f_text_company && newTextBooth != f_text_company.text){
                f_text_company?.set({      
                    text: newTextCompany
                })
            }

            
            var textPadding = 0.25*newScaleBooth
            var textWidthBooth = f_text_booth.width*newScaleBooth+textPadding;
            var textHeightBooth = f_text_booth.height*newScaleBooth+textPadding;
            var textWidthCompany = 0
            var textHeightCompany = 0
            if(f_text_company){
                textWidthCompany = f_text_company.width*newScale+textPadding;
                textHeightCompany = f_text_company.height*newScale+textPadding;
            }
            
            
            var newLeft = 0 - group.width/2 + marginX
            var newTop = 0 - group.height/2 + marginX

            //booth number scale
            if(group.labelPosition == 'center'){
                newLeft = 0 - textWidthBooth/2
                newTop = 0 + group.height/2 - textHeightBooth
            }
            else if(group.labelPosition == 'top-center'){
                newLeft = 0 - textWidthBooth/2
                newTop = marginX*1 - group.height/2
            }
            else if(group.labelPosition == 'bottom-right'){
                newLeft = 0 + group.width/2 - marginX*3 - textWidthBooth
                newTop = 0 + group.height/2 - marginX*1 - textHeightBooth
            }
            else if(group.labelPosition == 'top-right'){
                newLeft = 0 + group.width/2 - marginX*3 - textWidthBooth
                newTop = marginX*1 - group.height/2
            }
            else if(group.labelPosition == 'top-left'){
                newLeft = 0 - group.width/2 + marginX
                newTop = marginX*1 - group.height/2
            }
            else{
                newLeft = 0 - group.width/2 + marginX
                newTop = 0 + group.height/2 - marginX*1 - textHeightBooth
            }
            
            f_text_booth.set({             
                left: newLeft, 
                top:newTop,
                scaleX: newScaleBooth,
                scaleY: newScaleBooth,
            })
            
            
            //company scale
            var newLeftCompany =  0 - textWidthCompany/2
            var newTopCompany =  - group.height/2 + (group.height - f_text_booth.height*newScaleBooth)/2 +marginX - textHeightCompany/2 //0 - textHeightCompany/2 - textHeightBooth/2
            if(group.labelPosition?.includes('top')){
                newTopCompany =   (f_text_booth.height*newScaleBooth)/2 +marginX - textHeightCompany/2 
            }
            var limitTop = newTop - textHeightCompany
            // newTopCompany = Math.min(newTopCompany, limitTop)
            f_text_company?.set({
                textAlign:'center'
            })        
            f_text_company?.set({             
                left: newLeftCompany, 
                top:newTopCompany,
                scaleX: newScale,
                scaleY: newScale,
            })
        }
        if(f_text_company)
            group.add(f_text_company); 
        if(f_text_booth)
            group.add(f_text_booth); 
        if(f_text_hidden)
            group.add(f_text_hidden); 
    }
    catch(e){
        console.log("addtext", e)
    }
}

export const addTableText = (mainCanvas, group, name)=>{
    var convertRateX = 1;
    if(mainCanvas.convertRateX){
        convertRateX = mainCanvas.convertRateX
    }
    var zoom = mainCanvas.getZoom();
    var fontSize = 20/Math.max(convertRateX, 1)
    fontSize = 20/convertRateX
    var f_text = new fabric.Text(name, { 
        fontSize:fontSize,
        class:'tableNumber'
    });
    var objects = mainCanvas.getObjects('group');
    var sampleText = null;
    var sampleObject = null;
    for(var i = 0; i< objects.length; i++){
        if(objects[i].class == "table"){
            var textObjects = objects[i].getObjects('text');
            if(textObjects[0]){
                sampleObject = objects[i];
                sampleText = textObjects[0];
                break;
            }
        }
    }

    
    if(sampleText){
        var offsetX = sampleObject.width/2 + sampleText.left 
        var offsetY = sampleObject.height/2 + sampleText.top 
        f_text.set({             
            left: offsetX - group.width/2, 
            top:offsetY  - group.height/2,
            scaleX: sampleText.scaleX,
            scaleY: sampleText.scaleY,
        })
    }
    else
    {
        if(f_text.fontSize*zoom > 30)
        {   
            // var newLeft = (-(name.length*20/Math.max(convertRateX, 1)/1.7)/2)
            var newLeft = (-(name.length*20/convertRateX/1.7)/2)
            // var newTop = (-(20/Math.max(convertRateX, 1)*1.05))
            var newTop = (-(20/convertRateX*1.05))
            // var newLeft = 0 - group.width/2+5/zoom/convertRateX + 3/Math.max(convertRateX, 1)
            // var newTop = 0 - group.height/2+5/zoom/convertRateX + 3/Math.max(convertRateX, 1)
            var newScale = 30/(fontSize*zoom)
            f_text.set({             
                left: newLeft, 
                top:newTop,
                scaleX: newScale,
                scaleY: newScale,
            })
        }
        else{
            // var newLeft = (-(name.length*20/Math.max(convertRateX, 1)/1.7)/2)
            // var newTop = (-(20/Math.max(convertRateX, 1)*1.05))
            
            var newLeft = (-(name.length*20/convertRateX/1.7)/2)
            var newTop = (-(20/convertRateX*1.05))
        //   var newLeft = 0 - group.width/2+5/zoom/convertRateX+ 3/Math.max(convertRateX, 1)
        //   var newTop = 0 - group.height/2+5/zoom/convertRateX+ 3/Math.max(convertRateX, 1)
            f_text.set({left: newLeft, top:newTop})
        }
    }
    
    f_text.set({left: newLeft, top:newTop})
    group.add(f_text); 
}

export const meter2feet = (meter)=>{
    return (meter * 3.28084 )
}

export const feet2meter = (feet)=>{
    return (feet * 0.3048)
}

export const in2feet = (inch)=>{
    return (inch /12)
}


export const px2unit=(px, convertRateX, unit = 'feet')=>{
    var unitRate = 12;
    if(unit == "feet")
        unitRate = 12; //1feet = 12inch
    else 
        unitRate = 1; //1m = 39.3701inch
    var real = px*convertRateX/unitRate
    var int_val = Math.floor(real)
    // if(int_val >= 5){
    //     // if(Math.abs(real - int_val) < 0.2){
    //     //     return Math.floor(real*10)/10
    //     // }
    //     // else if(Math.abs(real - int_val) > 0.8){
    //     //     return Math.ceil(real*10)/10
    //     // }
    //     if(Math.abs(real - int_val) < 0.2){
    //         return Math.floor(real)
    //     }
    //     else if(Math.abs(real - int_val) > 0.8){
    //         return Math.ceil(real)
    //     }
    // }
    return real

}

export const px2unitBig=(px, convertRateX, unit = 'feet')=>{
    var unitRate = 12;
    if(unit == "feet")
        unitRate = 12; //1feet = 12inch
    else 
        unitRate = 1; //1m = 39.3701inch
    var real = new BigNumber(px).multipliedBy(convertRateX).dividedBy(unitRate)
    return real

}

export const unit2Px=(feet, convertRateX, unit = 'feet')=>{
    var unitRate = 12;
    if(unit == "feet")
        unitRate = 12; //1feet = 12inch
    else 
        unitRate = 1; //1m = 39.3701inch
    return feet*unitRate/convertRateX
//3000cm:150px : 20
//3m:150px: 0.02
}
export const unit2PxBig=(feet, convertRateX, unit = 'feet')=>{
    var unitRate = 12;
    if(unit == "feet")
        unitRate = 12; //1feet = 12inch
    else 
        unitRate = 1; //1m = 39.3701inch
    return feet.multipliedBy(unitRate).dividedBy(convertRateX)

}

export const compressJson = (data)=>{
    return LZString.compressToBase64(JSON.stringify(trimJson(data)))
}

export const trimJson = (data)=>{
    let copy = {...data, objects:[]};

    (data.objects || []).map(object => {
        let objectCopy = {};
        for (let key in object) {
            var val = object[key];
            if (val !== null && val !== ""){
                objectCopy[key] = object[key]
            } else {
                // console.log('skipped', key, object[key])
            }
        }
        copy.objects.push(objectCopy);
    })
    return copy
}

export const  rotate = (cx, cy, x, y, angle) =>{
    var radians = (Math.PI / 180) * angle,
        cos = Math.cos(radians),
        sin = Math.sin(radians),
        nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
        ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    return [nx, ny];
}

export const checkBoothSequential = (booths)=>{
    var arry = [];
    if(booths){
        for(var i = 0; i < booths.length; i++){
            var booth_number = booths[i].booth_number*1;
            arry.push(booth_number);
        }
    }
    arry.sort()
    if(arry.length > 0){
        for(var i=1;i<arry.length;i++)
        {
            if(arry[i]!=arry[i-1]+1)
            {
               return false
            }
        }
    }
    return true
}

export const checkBoothCompanyEqual = (booths)=>{
    var arry = [];
    if(booths){
        for(var i = 0; i < booths.length; i++){
            var company = booths[i].company;
            arry.push(company);
        }
    }
    return arry.every( (val, i, arr) => val === arr[0] )
}

export const checkCombinable = (booths)=>{
    var result = true;
    if(booths){
        var hasSold = false
        for(var i = 0; i < booths.length;i++){
            if(booths[i].sold){
                hasSold = true;
            }
        }
        if(hasSold) {
            var sequential = 1;//checkBoothSequential(booths)
            var hasSameCompany = checkBoothCompanyEqual(booths)
            result = sequential & hasSameCompany
        }
    }
    return result;
}

export const checkContainSold = (booths)=>{
    var result = false;
    if(booths){
        for(var i = 0; i < booths.length;i++){
            if(booths[i].sold){
                return true;
            }
        }
    }
    return result;
}

export const checkSelectionAssignable = (objects)=>{
    var result = true;
    if(objects){
        for(var i = 0; i < objects.length;i++){
            if(objects[i].sold){
                return false;
            }
            if(objects[i].class!= 'booth' && objects[i].get('layer') != "sponsorships"){
                return false;
            }
            
            var premiumCheck = objects[i].get('premiumCheck')
            var priceCheck = objects[i].get('priceCheck')
            var discountCheck = objects[i].get('discountCheck')
            if(!(priceCheck && priceCheck.length > 0) || (premiumCheck && premiumCheck.length > 0) || (discountCheck && discountCheck.length > 0)){
                return false
            }
        }
    }
    return result;
}

export const checkSelectionUnAssignable = (objects)=>{
    var result = true;
    if(objects){
        for(var i = 0; i < objects.length;i++){
            if(!objects[i].sold){
                return false;
            }
            if(objects[i].class!= 'booth' && objects[i].get('layer') != "sponsorships"){
                return false;
            }
        }
    }
    
    var hasSameCompany = checkBoothCompanyEqual(objects)
    return result && hasSameCompany;
}

export const redrawRulers=(canvas, topRuler, leftRuler)=> {
    if(canvas){
        var zoomLevel = canvas.getZoom();
        topRuler.clear();
        leftRuler.clear();
        topRuler.setBackgroundColor('transparent');
        leftRuler.setBackgroundColor('transparent');
        var convertRateX= 1
        if(canvas.convertRateX) {
            var convertRateX= Math.abs(canvas.convertRateX)
        }
        var mapWidth = window.innerWidth;
        var mapHeight = window.innerHeight;
        // if(canvas.endX && canvas.startX && canvas.endY && canvas.startY){
        //     mapWidth = Math.abs(canvas.endX - canvas.startX)*zoomLevel;
        //     mapHeight = Math.abs(canvas.endY - canvas.startY)*zoomLevel;
        // }

        var rulerSize = 203.05;
        var step;
        if(convertRateX){
            if(canvas.mapUnit == "feet"){
                step = 10
                rulerSize = unit2Px(10, convertRateX, canvas.mapUnit)
            }
            else {
                step = 1
                rulerSize = unit2Px(1, convertRateX, canvas.mapUnit)
            }
        }
        // var stepRate = (mapWidth/rulerSize/zoomLevel/25)
        // ////console.log('zoomLevel', zoomLevel)
        // ////console.log('stepRate', stepRate)
        // ////console.log('step', stepRate)
        var vpt = canvas.viewportTransform;
        var opt = fabric.util.qrDecompose(vpt);
        ////console.log(opt)
        var j = 0;
        // if(canvas.convertUnit == 'mm'){
        //     rulerSize = rulerSize*1000
        // }
        for (var i = opt.translateX+50*zoomLevel ; i < mapWidth; i += ((rulerSize) * zoomLevel), j++) {
            if((rulerSize * zoomLevel < 5 && j%40  == 0) || (rulerSize * zoomLevel >= 5 && rulerSize * zoomLevel < 10 && j%20  == 0) || (rulerSize * zoomLevel >= 10 && rulerSize * zoomLevel < 20 && j%10  == 0) || (rulerSize * zoomLevel >= 20 && rulerSize * zoomLevel < 40 && j%5  == 0) || rulerSize * zoomLevel >= 40){
                var strokeWidth = 1;
                var strokeHeight = 35;
                var topLine = new fabric.Line([i, strokeHeight, i, 50], {
                    stroke: 'black',
                    strokeWidth: strokeWidth,
                    selectable: false ,lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false
                });
                topRuler.add(topLine);
            }
        }
        j = 0;
        for (var i = opt.translateX+50*zoomLevel ; i >=-mapWidth; i -= ((rulerSize) * zoomLevel), j++) {
            if(j == 0) continue;
            if((rulerSize * zoomLevel < 5 && j%40  == 0) || (rulerSize * zoomLevel >= 5 && rulerSize * zoomLevel < 10 && j%20  == 0) || (rulerSize * zoomLevel >= 10 && rulerSize * zoomLevel < 20 && j%10  == 0) || (rulerSize * zoomLevel >= 20 && rulerSize * zoomLevel < 40 && j%5  == 0) || rulerSize * zoomLevel >= 40){
                var strokeWidth = 1;
                var strokeHeight = 35;
                var topLine = new fabric.Line([i, strokeHeight, i, 50], {
                    stroke: 'black',
                    strokeWidth: strokeWidth,
                    selectable: false ,lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false
                });
                topRuler.add(topLine);
            }
        }

        // var topBorderLine = new fabric.Line([0, 48.5, mapWidth, 48.5], {
        //     stroke: 'black',
        //     strokeWidth: 1.5,
        //     selectable: false ,lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false
        // });
        // topRuler.add(topBorderLine);

        j = 0;
        for (var i = opt.translateY+50*zoomLevel; i < mapHeight; i += ((rulerSize) * zoomLevel), j++) {
            if((rulerSize * zoomLevel < 5 && j%40  == 0) || (rulerSize * zoomLevel >= 5 && rulerSize * zoomLevel < 10 && j%20  == 0) || (rulerSize * zoomLevel >= 10 && rulerSize * zoomLevel < 20 && j%10  == 0) || (rulerSize * zoomLevel >= 20 && rulerSize * zoomLevel < 40 && j%5  == 0) || rulerSize * zoomLevel >= 40){
                var strokeWidth = 1;
                var strokeHeight = 35;
                var leftLine = new fabric.Line([strokeHeight, i, 50, i], {
                stroke: 'black',
                strokeWidth: strokeWidth,
                selectable: false ,lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false
                ,objectCaching: true
                });
                leftRuler.add(leftLine);
            }
        }
        j = 0;
        for (var i = opt.translateY+50*zoomLevel; i >=0; i -= ((rulerSize) * zoomLevel), j++) {
            if(j == 0) continue;
            if((rulerSize * zoomLevel < 5 && j%40  == 0) || (rulerSize * zoomLevel >= 5 && rulerSize * zoomLevel < 10 && j%20  == 0) || (rulerSize * zoomLevel >= 10 && rulerSize * zoomLevel < 20 && j%10  == 0) || (rulerSize * zoomLevel >= 20 && rulerSize * zoomLevel < 40 && j%5  == 0) || rulerSize * zoomLevel >= 40){
                var strokeWidth = 1;
                var strokeHeight = 35;
                var leftLine = new fabric.Line([strokeHeight, i, 50, i], {
                stroke: 'black',
                strokeWidth: strokeWidth,
                selectable: false ,lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false
                ,objectCaching: true
                });
                leftRuler.add(leftLine);
            }
        }
        // var leftBorderLine = new fabric.Line([48.5, 0, 48.5, mapHeight], {
        //     stroke: 'black',
        //     strokeWidth: 1.5,
        //     selectable: false ,lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false
        // });
        // leftRuler.add(leftBorderLine);
        
        // Numbers
        j = 0;
        for (i = opt.translateX+50*zoomLevel; i < mapWidth;  i += (rulerSize * zoomLevel), j+=step) {
            if((rulerSize * zoomLevel < 5 && j%400  == 0) || (rulerSize * zoomLevel >= 5 && rulerSize * zoomLevel < 10 && j%200  == 0) || (rulerSize * zoomLevel >= 10 && rulerSize * zoomLevel < 20 && j%100  == 0) || (rulerSize * zoomLevel >= 20 && rulerSize * zoomLevel < 40 && j%50  == 0) || (rulerSize * zoomLevel >= 40 && j%50  == 0)){
                    var text = new fabric.Text((j).toString(), {
                    left: i-(j.toString().length*3/2),
                    top: 10,
                    fontSize: 11.5,
                    selectable: false ,lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false
                    ,objectCaching: true
                });
                topRuler.add(text);
            }
        }
        j = 0;
        for (i = opt.translateX+50*zoomLevel; i >=0;  i -= (rulerSize * zoomLevel), j-=step) {
            if(j == 0) continue;
            if((rulerSize * zoomLevel < 5 && j%400  == 0) || (rulerSize * zoomLevel >= 5 && rulerSize * zoomLevel < 10 && j%200  == 0) || (rulerSize * zoomLevel >= 10 && rulerSize * zoomLevel < 20 && j%100  == 0) || (rulerSize * zoomLevel >= 20 && rulerSize * zoomLevel < 40 && j%50  == 0) || (rulerSize * zoomLevel >= 40 && j%50  == 0)){
                var text = new fabric.Text((j).toString(), {
                    left: i-(j.toString().length*3/2),
                    top: 10,
                    fontSize: 11.5,
                    selectable: false ,lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false
                    ,objectCaching: true
                });
                topRuler.add(text);
            }
        }

        j = 0;
        for (i = opt.translateY+50*zoomLevel; i < mapHeight;  i += (rulerSize * zoomLevel), j+=step) {
            if((rulerSize * zoomLevel < 5 && j%400  == 0) || (rulerSize * zoomLevel >= 5 && rulerSize * zoomLevel < 10 && j%200  == 0) || (rulerSize * zoomLevel >= 10 && rulerSize * zoomLevel < 20 && j%100  == 0) || (rulerSize * zoomLevel >= 20 && rulerSize * zoomLevel < 40 && j%50  == 0) || (rulerSize * zoomLevel >= 40 && j%50  == 0)){
                var text = new fabric.Text((j).toString(), {
                    left: 8,
                    top: i-5,
                    fontSize: 11.5,
                    selectable: false ,lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false
                    ,objectCaching: true
                });
                leftRuler.add(text);
            }
        }
        j = 0;
        for (i = opt.translateY+50*zoomLevel; i >=0;  i -= (rulerSize * zoomLevel), j-=step) {
            if(j == 0) continue;
            if((rulerSize * zoomLevel < 5 && j%400  == 0) || (rulerSize * zoomLevel >= 5 && rulerSize * zoomLevel < 10 && j%200  == 0) || (rulerSize * zoomLevel >= 10 && rulerSize * zoomLevel < 20 && j%100  == 0) || (rulerSize * zoomLevel >= 20 && rulerSize * zoomLevel < 40 && j%50  == 0) || (rulerSize * zoomLevel >= 40 && j%50  == 0)){
                var text = new fabric.Text((j).toString(), {
                    left: 8,
                    top: i-5,
                    fontSize: 11.5,
                    selectable: false ,lockMovementX: true,lockMovementY: true,lockRotation: true,lockScalingX: true,lockScalingY: true,selection:false,hasControls:false,hasBorders:false
                    ,objectCaching: true
                });
                leftRuler.add(text);
            }
        }
    }
}
export const getFirstZoom = (mainCanvas)=>{
    var zoom = 1;
    var mapWidth = window.innerWidth;
    var mapHeight = window.innerHeight;
    if(mainCanvas.endX){
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        mapWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
        mapHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);
        if(mainCanvas.mapWidth && mainCanvas.mapWidth*1 && mainCanvas.mapHeight && mainCanvas.mapHeight*1){
            mapWidth = unit2Px(mainCanvas.mapWidth?mainCanvas.mapWidth:0, convertRateX, mainCanvas.mapUnit)
            mapHeight = unit2Px(mainCanvas.mapHeight?mainCanvas.mapHeight:0, convertRateX, mainCanvas.mapUnit)

        }
    }
    if(mapWidth && mapHeight){
        zoom = (window.innerWidth- 50 - 0)/(mapWidth)
    }
    return zoom
}
export const centerFocus=(mainCanvas, bgCanvas, g_grid, topRuler, leftRuler, bgImgCanvas, render=true)=>{
    // var mainCanvas = props.main.mainCanvas
    var mapWidth = window.innerWidth;
    var mapHeight = window.innerHeight;
    if(mainCanvas.endX){
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        mapWidth = Math.abs(mainCanvas.endX - mainCanvas.startX);
        mapHeight = Math.abs(mainCanvas.endY - mainCanvas.startY);
        if(mainCanvas.mapWidth && mainCanvas.mapWidth*1 && mainCanvas.mapHeight && mainCanvas.mapHeight*1){
            mapWidth = unit2Px(mainCanvas.mapWidth?mainCanvas.mapWidth:0, convertRateX, mainCanvas.mapUnit)
            mapHeight = unit2Px(mainCanvas.mapHeight?mainCanvas.mapHeight:0, convertRateX, mainCanvas.mapUnit)
        }
    }
    if(mapWidth && mapHeight){
        var zoomX = (window.innerWidth- 50 - 0)/(mapWidth)
        var zoomY = (window.innerHeight - 50 - 51)/(mapHeight)
        var zoom = Math.min(zoomX, zoomY)*0.97
        mainCanvas.viewportTransform[0] = Math.min(zoomX, zoomY)*0.97;
        mainCanvas.viewportTransform[3] = Math.min(zoomX, zoomY)*0.97;
        mainCanvas.viewportTransform[4] = 0;
        mainCanvas.viewportTransform[5] = 0;
        if(bgCanvas){
            bgCanvas.viewportTransform[0] = Math.min(zoomX, zoomY)*0.97;
            bgCanvas.viewportTransform[3] = Math.min(zoomX, zoomY)*0.97;
            bgCanvas.viewportTransform[4] = 0;
            bgCanvas.viewportTransform[5] = 0;
        }

        if(bgImgCanvas){
            bgImgCanvas.viewportTransform[0] = Math.min(zoomX, zoomY)*0.97;
            bgImgCanvas.viewportTransform[3] = Math.min(zoomX, zoomY)*0.97;
            bgImgCanvas.viewportTransform[4] = 0;
            bgImgCanvas.viewportTransform[5] = 0;
        }

        window.scrollTo(0, 0)
        if(render){            
            mainCanvas.requestRenderAll();
            bgCanvas.requestRenderAll();
            bgImgCanvas.requestRenderAll();
        }
        if(Math.min(zoomX, zoomY) > 0){
            var deltaX = ((window.innerWidth- 50 - 0) - mapWidth*Math.min(zoomX, zoomY)*0.97)/2-50*zoom;
            var deltaY = ((window.innerHeight- 50 - 51) - mapHeight*Math.min(zoomX, zoomY)*0.97)/2-50*zoom;
            var delta = new fabric.Point(deltaX, deltaY);
            mainCanvas.relativePan(delta);
            bgCanvas.relativePan(delta);
            bgImgCanvas.relativePan(delta);
            redrawRulers(mainCanvas, topRuler, leftRuler, render)
            mainCanvas.renderAll()
            redrawGrid(g_grid, mainCanvas, (mainCanvas.showGrid &&mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
        }

        scaleTextsCenterFocus(mainCanvas, zoom)
        locationScaling(mainCanvas,false)

    }
    renderBorder(mainCanvas, render);

    
}

var maxCompanyLength = (company_name_full)=>{
    var companyArray = company_name_full.replaceAll("-","- ").split(" ")
    if(companyArray.length > 1){
        // console.log("catch")
    }
    var max = 0;
    for(var i = 0; i < companyArray?.length; i++){
        var lineString = companyArray[i].trim();
        if(lineString.length > max){
            max = lineString.length;
        }
    }
    return max;
}

var subCompanyString = (company_name_full, length)=>{
    // var companyArray = company_name_full.replaceAll("-","- ").split(" ")
    // var max = 0;
    // var output = "";
    // for(var i = 0; i < companyArray?.length; i++){
    //     var lineString = companyArray[i].trim();
    //     output+=(lineString.substring(0, length))
    //     if(i < companyArray?.length - 1)
    //         output+="\n"
    // }
    // return output;
    var companyArray = company_name_full.replaceAll("-","- ").split(" ")
    var max = 0;
    var output = "";
    for(var i = 0; i < companyArray?.length; i++){
        var lineString = companyArray[i].trim();
        output+=lineString
        if(i < companyArray?.length - 1)
            output+="\n"
    }
    return output;
}

  
export const scaleTextsCenterFocus=(mainCanvas, zoom)=>{
    var objects = mainCanvas.getObjects();
    var convertRateX= 1
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
    }
    // if(zoom/convertRateX>1)
    {
        for(var i = 0; i< objects.length; i++){
            try{
                var group = objects[i]
                if(objects[i].get('class')=="booth" || objects[i].get('class') == 'booth-group' || objects[i].get('class') == 'original-booth')
                {               
                    var booth_number_full = group.booth_number?group.booth_number:"";                 
                    if(booth_number_full*1 == 18054){
                        console.log("booth_number_full", booth_number_full)
                    }
                    var group = objects[i];
                    var company_name_full = group.company?`${group.company}`.trim():"";
                    var breakedCompany = company_name_full
                    var companyArray = company_name_full.replaceAll("-","- ").split(" ")
                    var companyWrap = companyArray.join('\n')
                    var textobjects = objects[i].getObjects('text');
                    var f_text_company = false;
                    var f_text_booth = false;
                    var f_text_hidden = false;
                    let boothViewWidth = group.width * zoom;
                    breakedCompany = breakSentenceByWidth(breakedCompany, boothViewWidth)
                    if(booth_number_full*1 == 503){
                        // console.log("boothViewWidth", boothViewWidth, breakedCompany)
                    }
                    for(var j = 0; j<  textobjects.length; j++){
                        if(textobjects[j].class == "boothName"){
                            if(textobjects[j].class1 == "hidden"){
                                f_text_hidden = textobjects[j];
                            }
                            else if(textobjects[j].class1 == "company"){
                                f_text_company = textobjects[j];
                            }
                            else if(textobjects[j].class1 == "boothNumber"){
                                f_text_booth = textobjects[j];
                            }
                        }
                    }
                    
                    var polygons = group.getObjects('polygon')
                    var polyObject = false
                    var polygonPoints = [];
                    if(polygons?.length){
                        polyObject = polygons[0];
                        polygonPoints = polygons[0].points
                    }
                    var f_measure_company = false;
                    if(f_text_company){
                        f_measure_company = new fabric.Text(breakedCompany?breakedCompany:"", { 
                            fontSize:f_text_company.fontSize
                        });
                    }
                    
                    if(f_text_company || f_text_booth){
                        var f_text = f_text_company?f_text_company:f_text_booth;
                        if(maxCompanyLength(company_name_full) < booth_number_full.length){
                            f_text = f_text_booth;
                        }
                        if(!f_text){
                            f_text = f_text_booth
                        }
                        
                        if(!f_text){
                            continue;
                        }

                        var newScaleCompany = 1;
                        var newScaleBooth = 1;
                        // var marginX = 5/Math.max(zoom, 1)/Math.max(convertRateX, 1) + 2/Math.max(convertRateX, 1)
                        var marginX = getStrokeWidth(mainCanvas)*2;// 5/Math.max(zoom, 1)/convertRateX + 2/convertRateX
                        // var v_width = f_text_company?f_text_company.width*1.2:1
                        // var v_hegiht = f_text_company?f_text_company.height*1.2:1      
                        
                        var v_width = f_measure_company?f_measure_company.width*1.2:1
                        var v_hegiht = f_measure_company?f_measure_company.height*1.2:1                                          
                        
                        var v_width_booth = f_text_hidden?f_text_hidden.width*1.2:1
                        var v_height_booth = f_text_hidden?f_text_hidden.height*1.2:1
                                  

                        if(f_text.fontSize*zoom > 30)
                        {
                            newScaleBooth = Math.max(Math.min(30/(f_text.fontSize*zoom), (group.width-2*marginX)/v_width_booth, (group.height-2*marginX)/v_height_booth),0)
                            var newScaleY = Math.min(30/(f_text.fontSize*zoom), (group.height-f_text_booth.height*newScaleBooth-2*marginX)/v_hegiht)
                            var newScaleX = Math.min(30/(f_text.fontSize*zoom), (group.width-2*marginX)/v_width)
                            newScaleCompany = Math.max(Math.min(newScaleX, newScaleY),0)                            
                        }
                        else{
                            newScaleBooth = Math.max(Math.min(1, (group.width-2*marginX)/v_width_booth, (group.height-2*marginX)/v_height_booth),0)
                            var newScaleY = Math.min(1, (group.height-f_text_booth.height*newScaleBooth-2*marginX)/v_hegiht)
                            var newScaleX = Math.min(1, (group.width-2*marginX)/v_width)
                            newScaleCompany = Math.max(Math.min(newScaleX, newScaleY),0)                            
                        }     

                        var v_rate_width =Math.min((group.width-2*marginX)/(newScaleBooth*v_width_booth), 1)
                        v_rate_width = Math.round(v_rate_width*1000)/1000
                        var booth_length = booth_number_full.length
                        var companyString = ""
                        // if(group.company){
                        //     companyString = subCompanyString(company_name_full, 0)
                        // }
                        if(breakedCompany){
                            companyString = breakedCompany
                        }
                        var booth_number = ""
                        if(group.booth_number){
                            var length = Math.floor(booth_length*v_rate_width)
                            if(length < group.booth_number.length){
                                length--;
                            }
                            booth_number = booth_number_full.substring(0, length)
                        }
                        var newTextBooth = booth_number?booth_number:""
                        if(newTextBooth != f_text_booth.text){
                            f_text_booth.set({      
                                text: newTextBooth
                            })
                        }
                        var newTextCompany = companyString?companyString:""
                        if(f_text_company && newTextBooth != f_text_company.text){
                            f_text_company?.set({      
                                text: newTextCompany
                            })
                        }
                        
                        var textPadding = 0.25*newScaleBooth
                        var textWidthBooth = f_text_booth.width*newScaleBooth+textPadding;
                        var textHeightBooth = f_text_booth.height*newScaleBooth+textPadding;
                        var textWidthCompany = 0
                        var textHeightCompany = 0
                        if(f_text_company){
                            textWidthCompany = f_text_company.width*newScaleCompany+textPadding;
                            textHeightCompany = f_text_company.height*newScaleCompany+textPadding;
                        }
                        
                        
                        var newLeft = 0 - group.width/2 + marginX
                        var newTop = 0 - group.height/2 + marginX

                        //booth number scale
                        if(group.labelPosition == 'center'){
                            newLeft = 0 - textWidthBooth/2
                            newTop = 0 + group.height/2 - textHeightBooth
                        }
                        else if(group.labelPosition == 'top-center'){
                            newLeft = 0 - textWidthBooth/2
                            newTop = marginX*1 - group.height/2
                        }
                        else if(group.labelPosition == 'bottom-right'){
                            newLeft = 0 + group.width/2 - marginX*3 - textWidthBooth
                            newTop = 0 + group.height/2 - marginX*1 - textHeightBooth
                        }
                        else if(group.labelPosition == 'top-right'){
                            newLeft = 0 + group.width/2 - marginX*3 - textWidthBooth
                            newTop = marginX*1 - group.height/2
                        }
                        else if(group.labelPosition == 'top-left'){
                            newLeft = 0 - group.width/2 + marginX
                            newTop = marginX*1 - group.height/2
                        }
                        else{
                            newLeft = 0 - group.width/2 + marginX
                            newTop = 0 + group.height/2 - marginX*1 - textHeightBooth
                        }
                             
                        f_text_booth.set({             
                            left: newLeft, 
                            top:newTop,
                            scaleX: newScaleBooth,
                            scaleY: newScaleBooth,
                        })
                        
                        
                        //company scale
                        var newLeftCompany =  0 - textWidthCompany/2
                        var newTopCompany =  - group.height/2 + (group.height - f_text_booth.height*newScaleBooth)/2 +marginX - textHeightCompany/2 //0 - textHeightCompany/2 - textHeightBooth/2
                        if(group.labelPosition?.includes('top')){
                            newTopCompany =   (f_text_booth.height*newScaleBooth)/2 +marginX - textHeightCompany/2 
                        }
                        var limitTop = newTop - textHeightCompany
                        // newTopCompany = Math.min(newTopCompany, limitTop)
                        f_text_company?.set({
                            textAlign:'center'
                        })        
                                   
                        
                                      
                        if(booth_number_full*1 == 503){
                            // console.log("booth_number_full", booth_number_full)
                        }
                        // if(polygonPoints?.length && !isApproxRectangle(polygonPoints)){
                        // if((polygonPoints?.length && !isAlmostRectangle(polyObject) && false) || (booth_number_full == 1111)){
                        if((polygonPoints?.length && !isAlmostRectangle(polyObject))){
                            // console.log("not rectangle", booth_number_full)
                            polygonPoints = translatePolygonToOrigin(polygonPoints)
                            var limitTopBottom = f_text_booth.height*newScaleBooth + marginX
                            const largestRect = findLargestRectangle(polygonPoints, limitTopBottom, limitTopBottom);        
                            newScaleCompany =  Math.min((largestRect.width - 2* marginX)/f_text_company.width,(largestRect.height - 2 * marginX)/f_text_company.height)              
                            f_text_company?.set({             
                                // left: largestRect.x + largestRect.width / 2 - f_text_company.width / 2,
                                // top:largestRect.y + largestRect.height / 2 - f_text_company.height / 2,
                                // left: largestRect.x  + (largestRect.width / 2  - f_text_company.width*newScaleCompany / 2) - group.width/2,
                                // top:largestRect.y + ( largestRect.height / 2  - f_text_company.height*newScaleCompany / 2) - group.height/2,
                                left: largestRect.x - group.width/2 + marginX,
                                top:largestRect.y  - group.height/2 + marginX,
                                scaleX: newScaleCompany*0.95,
                                scaleY: newScaleCompany*0.95,
                            })
                        }
                        else{
                            f_text_company?.set({             
                                left: newLeftCompany, 
                                top:newTopCompany,
                                scaleX: newScaleCompany,
                                scaleY: newScaleCompany,
                            })
                        }
                
                    }
                    
                }
                else if(objects[i].get('class')=="table")
                {                                
                    var object = objects[i];
                    var textobjects = objects[i].getObjects('text');
                    for(var j = 0; j<  textobjects.length; j++){
                        var table_number = textobjects[j].get('text');
                        if(textobjects[j].fontSize*zoom > 30){         
                            var scaleX = 30/(textobjects[j].fontSize*zoom)     
                            var scaleY = 30/(textobjects[j].fontSize*zoom)                               
                            textobjects[j].set({scaleX: scaleX})
                            textobjects[j].set({scaleY: scaleY})
                            var newLeft = -(table_number.length*textobjects[j].fontSize/1.7*scaleX)/2
                            var newTop = -textobjects[j].fontSize*scaleY
                            // var newLeft = 0
                            // var newTop = 0
                            textobjects[j].set({left: newLeft, top:newTop})
                        }
                        else{
                            var newLeft = -(table_number.length*textobjects[j].fontSize/1.7)/2
                            var newTop = -(textobjects[j].fontSize/1.5)
                            textobjects[j].set({left: newLeft, top:newTop})
                        }
                    }
                }
            }
            catch(e){
                // console.log("scaleTextsCenterFocus", e)
            }
        }
    }
}

export const scaleTexts=(mainCanvas, zoom)=>{
    scaleTextsCenterFocus(mainCanvas, zoom)
    return;
}
export const setActiveObject=(o, main, greenBg = true, openBoothList)=>{
    if(!o) return;
    const main_widow_offset_x = 0;
    const main_widow_offset_y = 51;
    var paddingBottom = 0;
    if(openBoothList){
        paddingBottom = window.innerHeight/2-32
    }
    var { mainCanvas, bgCanvas, g_grid, topRuler,leftRuler, bgImgCanvas } = main
    mainCanvas.viewportTransform[0] = 1;
    mainCanvas.viewportTransform[3] = 1;
    mainCanvas.viewportTransform[4] = window.innerWidth/2-o.left-o.width/2-main_widow_offset_x;
    mainCanvas.viewportTransform[5] = (window.innerHeight-paddingBottom)/2-o.top-o.height/2-main_widow_offset_y;

    bgImgCanvas.viewportTransform[0] = 1;
    bgImgCanvas.viewportTransform[3] = 1;
    bgImgCanvas.viewportTransform[4] = window.innerWidth/2-o.left-o.width/2-main_widow_offset_x;
    bgImgCanvas.viewportTransform[5] = (window.innerHeight-paddingBottom)/2-o.top-o.height/2-main_widow_offset_y;

    if(bgCanvas){
      bgCanvas.viewportTransform[0] = 1;
      bgCanvas.viewportTransform[3] = 1;
      bgCanvas.viewportTransform[4] = window.innerWidth/2-o.left-o.width/2-main_widow_offset_x;
      bgCanvas.viewportTransform[5] = (window.innerHeight-paddingBottom)/2-o.top-o.height/2-main_widow_offset_y;
    }
    window.scrollTo(0, 0)
    mainCanvas.setZoom(mainCanvas.getZoom()*1.00000001)
    bgImgCanvas.setZoom(mainCanvas.getZoom()*1.00000001)
    bgCanvas.setZoom(mainCanvas.getZoom()*1.00000001)
    mainCanvas.renderAll();
    bgImgCanvas.renderAll();
    o.greenBg = greenBg
    mainCanvas.bringToFront(o);
    mainCanvas.setActiveObject(o).renderAll();
    if(topRuler && leftRuler)
        redrawRulers(mainCanvas, topRuler, leftRuler)
    if(g_grid)
        redrawGrid(g_grid, mainCanvas, (mainCanvas.showGrid &&mainCanvas.gridSize),mainCanvas.gridSize, mainCanvas.gridAngle, mainCanvas.gridOffsetX, mainCanvas.gridOffsetY);
    
    scaleTexts(mainCanvas, mainCanvas.getZoom())
    mainCanvas.renderAll();
}

export const setActiveObjectsbyGroup=(groupId, main, greenBg = true)=>{
    if(!groupId) return;
    const main_widow_offset_x = 0;
    const main_widow_offset_y = 51;
    var { mainCanvas, bgCanvas, g_grid, topRuler,leftRuler, bgImgCanvas } = main
    var booths = [];
    var objects = mainCanvas.getObjects('group')
    for(var i = 0; i < objects.length; i++){
      if(checkIfSelectable(objects[i])){
        if(objects[i].class=='booth' && objects[i].boothGroup){
          if(objects[i].boothGroup == groupId){
            booths.push(objects[i])
            highlightObject(objects[i], main)
          }
        }
      }
    }
    if(!booths.length){
        return;
    }
    
    centerFocus(mainCanvas, bgCanvas, g_grid, topRuler, leftRuler, bgImgCanvas, true)
}

export const setDeactiveObjectsbyGroup=(groupId, main, greenBg = true)=>{
    if(!groupId) return;
    const main_widow_offset_x = 0;
    const main_widow_offset_y = 51;
    var { mainCanvas, bgCanvas, g_grid, topRuler,leftRuler, bgImgCanvas } = main
    var booths = [];
    var objects = mainCanvas.getObjects('group')
    for(var i = 0; i < objects.length; i++){
      if(checkIfSelectable(objects[i])){
        if(objects[i].class=='booth' && objects[i].boothGroup){
          if(objects[i].boothGroup == groupId){
            booths.push(objects[i])
            deselectHighlightObject(objects[i], main)
          }
        }
      }
    }
    mainCanvas.renderAll();
}

export const highlightObject=(target, main, render = true)=>{
    if(!target) return
    if(target.hightlighted) return;
    var { mainCanvas, bgCanvas, g_grid, topRuler,leftRuler, bgImgCanvas } = main
    var convertRateX = mainCanvas.convertRateX
    target.deselectedColor = getBGColor(target)   
    target.hightlighted = 1    
    mainCanvas.bringToFront(target);
    changeBorderColor(target, 'red')
    changeBorderWidth(target, getStrokeWidth(mainCanvas)*2)
    
    addLocationIcon(mainCanvas,target,render)
    changeSingleBG(target, '#96FC3C')                    
    target.orgBg = ('#96FC3C');
}

export const deselectHighlightObject=(target, main, greenBg = true)=>{
    if(!target) return;
    var { mainCanvas, bgCanvas, g_grid, topRuler,leftRuler, bgImgCanvas } = main
    var convertRateX = mainCanvas.convertRateX
    changeBorderWidth(target, getStrokeWidth(mainCanvas))   
    changeBorderColor(target, 'black') 
    target.hightlighted = 0   
    var bgColor = target.deselectedColor?target.deselectedColor:"#dadada"
    changeSingleBG(target, bgColor)     
    target.orgBg = bgColor;
    // mainCanvas.sendToBack(target);
    // target.sendBackwards();
    target.dirty = true
    // if(render)
    removeLocationIcon(mainCanvas, target, false)
    mainCanvas.setZoom(mainCanvas.getZoom()*1.00000001)
}

export const changeBorderColor = (object, color)=>{
    if(object.type == 'group'){
        if(object.class=="booth"){
            var polygons = object.getObjects('polygon')
            for(var i = 0; i < polygons.length; i++){
                if(polygons[i].class!="color"){
                    polygons[i].set({stroke: color})
                }
            }                
        }
        else{
            var polygons = object.getObjects('polygon')
            for(var i = 0; i < polygons.length; i++){
                polygons[i].set({stroke: color})
            }
            
            polygons = object.getObjects('rect')
            for(var i = 0; i < polygons.length; i++){
                polygons[i].set({stroke: color})
            }
            
            polygons = object.getObjects('line')
            for(var i = 0; i < polygons.length; i++){
                polygons[i].set({stroke: color})
            }

            polygons = object.getObjects('polyline')
            for(var i = 0; i < polygons.length; i++){
                polygons[i].set({stroke: color})
            }
        }
        
    }
    else{
        object.set({stroke: color})
    }
}


export const changeBorderWidth = (object, strokWidth)=>{
    // return;
    if(object.type == 'group'){
        if(object.class=="booth"){
            var polygons = object.getObjects('polygon')
            for(var i = 0; i < polygons.length; i++){
                if(polygons[i].class!="color"){
                    polygons[i].set({strokeWidth: strokWidth, dirty: true, left: 0-polygons[i].width/2-strokWidth/2, top: 0-polygons[i].height/2-strokWidth/2})
                }
            }                
        }
        
    }
    else{
        object.set({strokeWidth: strokWidth, dirty: true})
    }
 }


export const getGeometry = (mainCanvas, object, convertRateX, mapOffsetX, mapOffsetY)=>{
    var active_booth_file
    if (store){
        const state = store.getState();
        var active_booth_file = state.main.active_booth_file
    }
	var width = 0;
	var height = 0
	var left = 0;
	var top = 0
	var feetUnitSize = 20.305;	
    mapOffsetX = mapOffsetX?mapOffsetX:0
    mapOffsetY = mapOffsetY?mapOffsetY:0
	if(convertRateX && object){
		var decimal = 0;
		decimal = 1
		width = px2unit((object.width*(object.class=='booth'?1:object.scaleX)), convertRateX, mainCanvas.mapUnit).toFixed(decimal)
		height = px2unit((object.height*(object.class=='booth'?1:object.scaleY)), convertRateX, mainCanvas.mapUnit).toFixed(decimal)
		left = px2unit((object.left-50), convertRateX, mainCanvas.mapUnit).toFixed(decimal)
		top = px2unit((object.top-50), convertRateX, mainCanvas.mapUnit).toFixed(decimal)
        if(active_booth_file?.version == 2){
            left = (px2unit(object.left-50, convertRateX, mainCanvas.mapUnit) + mapOffsetX*1)
            top = (px2unit(object.top-50, convertRateX, mainCanvas.mapUnit) + mapOffsetY*1)
        }
        else{
            left = (px2unit(object.left-50, convertRateX, mainCanvas.mapUnit) + mapOffsetX*1).toFixed(6)
            top = (px2unit(object.top-50, convertRateX, mainCanvas.mapUnit) + mapOffsetY*1).toFixed(6)
        }
		// left = (px2unit(object.left-50, convertRateX, mainCanvas.mapUnit) + mapOffsetX*1).toFixed(6)
		// top = (px2unit(object.top-50, convertRateX, mainCanvas.mapUnit) + mapOffsetY*1).toFixed(6)
	}
	return {width, height, left, top}
}

export const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
};

export const composeLoadObjects = (data, mapRemoveObjects, mapJS, bgImgCanvas, setFirstBgContent)=>{ 
    var objects = []   
    var convertRateX= Math.abs(mapJS.convertRateX)
    var mapUnit= (mapJS.mapUnit)
    var booths= data.BOOTHS
    var columns= data.COLUMNS
    var tables= data.TABLES
    var annotations= data.ANNOTATIONS
    var sponsorships = data.SPONSORSHIPS
    var backgrounds= data.BACKGROUND
    var deletes = data.DELETED
    if(annotations && annotations.length > 0){
        for(var i = 0; i < annotations.length; i++){
            var INSERT_POINT = annotations[i].INSERT_POINT
            var left = 0;
            var top = 0;
            if(INSERT_POINT){
                var tmp = INSERT_POINT.split(",")
                if(tmp.length >=2){
                    left = unit2Px(tmp[0], convertRateX, mapUnit) + 50
                    top = unit2Px(tmp[1], convertRateX, mapUnit) + 50
                }
            }
            var fabricJS = annotations[i].FABRICJS
            var objectJS = annotations[i].OBJECTJS
            if(fabricJS && fabricJS != ""){
                try{
                    var object = JSON.parse(fabricJS)
                    object.KEY_ID = annotations[i].KEY_ID
                    if(object.img_url){
                        object.img_url  = assetUrl(object.img_url)
                    }
                    // object.left = left
                    // object.top = top
                    if(objectJS && objectJS != ""){
                        objectJS = JSON.parse(objectJS)
                        object = {...object, ...objectJS}
                    }
                    // object.zIndex = object.zIndex + 100000
                    // object.zIndex1 = (object.zIndex?object.zIndex:0)*1
                    object.zIndex1 = (object.zIndex?object.zIndex:0)*1 + (object.forward?200000:0)
                    objects.push(object)
                }
                catch(e){
                    // console.log("annotations error", e)
                    continue;
                }
            }
        }
    }
    if(sponsorships && sponsorships.length > 0){
        for(var i = 0; i < sponsorships.length; i++){
            var INSERT_POINT = sponsorships[i].INSERT_POINT
            var left = 0;
            var top = 0;
            if(INSERT_POINT){
                var tmp = INSERT_POINT.split(",")
                if(tmp.length >=2){
                    left = unit2Px(tmp[0], convertRateX, mapUnit) + 50
                    top = unit2Px(tmp[1], convertRateX, mapUnit) + 50
                }
            }
            var fabricJS = sponsorships[i].FABRICJS
            var objectJS = sponsorships[i].OBJECTJS
            if(fabricJS && fabricJS != ""){
                try{
                    var object = JSON.parse(fabricJS)
                    object.KEY_ID = sponsorships[i].KEY_ID
                    if(object.img_url){
                        object.img_url  = assetUrl(object.img_url)
                    }
                    // object.left = left
                    // object.top = top
                    if(objectJS && objectJS != ""){
                        objectJS = JSON.parse(objectJS)
                        object = {...object, ...objectJS}
                    }
                    // object.zIndex = object.zIndex + 100000
                    object.zIndex1 = (object.zIndex?object.zIndex:0)*1 + 100000
                    objects.push(object)
                }
                catch(e){
                    // console.log("sponsorships error", e)
                    continue;
                }
            }
        }
    }
    if(booths && booths.length > 0){
        for(var i = 0; i < booths.length; i++){
            var INSERT_POINT = booths[i].INSERT_POINT
            var left = 0;
            var top = 0;
            if(INSERT_POINT){
                var tmp = INSERT_POINT.split(",")
                if(tmp.length >=2){
                    left = unit2Px(tmp[0], convertRateX, mapUnit) + 50
                    top = unit2Px(tmp[1], convertRateX, mapUnit) + 50
                }
            }
            var fabricJS = booths[i].FABRICJS
            var objectJS = booths[i].OBJECTJS
            var exhibitors = booths[i].EXHIBITORS
            var priceCheck = booths[i].PRICE?booths[i].PRICE:[]
            var holdBooth = booths[i].STATUS*1 == 2
            var invite_code = booths[i].INVITE_CODE
            if(fabricJS && fabricJS != ""){
                try{
                    var object = JSON.parse(fabricJS)
                    object.KEY_ID = booths[i].KEY_ID
                    if(object.booth_number*1 == 359){
                        // console.log("F31D42BB-F4E9-4247-AE3C-D7AC890744D1")
                    }
                    // object.left = left
                    // object.top = top
                    object.invite_code = invite_code
                    object.exhibitors = exhibitors
                    object.priceCheck = priceCheck
                    object.sold = exhibitors && exhibitors.length>0?1:0
                    object.company = exhibitors?.[0].COMPANY_NAME
                    object.holdBooth = holdBooth
                    object.zIndex1 = (object.zIndex?object.zIndex:0)*1 + 100000
                    object.class = 'booth'
                    if(objectJS && objectJS != ""){
                        objectJS = JSON.parse(objectJS)
                        object = {...object, ...objectJS}
                    }
                    object.clipPath = null;
		            if(checkIfSelectable(object)){
                        object.opacity= 1;
                    }
                    var subObjects = object.objects;
                    for(var j = 0; j<  subObjects.length; j++){
                        
                        if(subObjects[j].type == "text"){
                            subObjects[j].width =  object.width;
                            subObjects[j].height =  object.height;
                        }
                    }
                    object.objects = subObjects;
                    objects.push(object)
                }
                catch(e){
                    console.log("booth error", e)
                    continue
                }
            }
        }
    }
    if(columns && columns.length > 0){
        for(var i = 0; i < columns.length; i++){
            var INSERT_POINT = columns[i].INSERT_POINT
            var left = 0;
            var top = 0;
            if(INSERT_POINT){
                var tmp = INSERT_POINT.split(",")
                if(tmp.length >=2){
                    left = unit2Px(tmp[0], convertRateX, mapUnit) + 50
                    top = unit2Px(tmp[1], convertRateX, mapUnit) + 50
                }
            }
            var fabricJS = columns[i].FABRICJS
            var objectJS = columns[i].OBJECTJS
            var exhibitors = columns[i].EXHIBITORS
            var priceCheck = columns[i].PRICE?columns[i].PRICE:[]
            var holdBooth = columns[i].STATUS*1 == 2
            var invite_code = columns[i].INVITE_CODE
            let bgFlag = false
            var columnUrl = false
            if(objectJS && objectJS != ""){
                let tmp = JSON.parse(objectJS)
                bgFlag = tmp['bg']
                columnUrl = tmp['URL']
            }
            if(bgFlag && false){//draw venue image
                var offsetX = 0;
                var offsetY = 0;
                var sizeX = columns[i].SIZE_X;
                var sizeY = columns[i].SIZE_Y;
                var KEY_ID = columns[i].KEY_ID
                var offset = columns[i].INSERT_POINT;
                if(offset && offset != ""){
                    var tmp = offset.split(",")
                    if(tmp && tmp.length > 1){
                        offsetX = unit2Px(tmp[0], convertRateX, mapUnit)
                        offsetY = unit2Px(tmp[1], convertRateX, mapUnit)
                    }
                }
                var objectJS = columns[i].OBJECTJS
                var bgFileName = false
                if(columnUrl && columnUrl != ""){
                    getMeta(assetUrl(columnUrl), (err, img) => {
                        console.log("columnUrl", assetUrl(columnUrl))
                        // console.log(img.naturalWidth, img.naturalHeight);
                        var convertRateX= Math.abs(mapJS.convertRateX)
                        var mapUnit= (mapJS.mapUnit)
                        var mapOffsetX = unit2Px(mapJS.mapOffsetX?mapJS.mapOffsetX:0, convertRateX, mapUnit)
                        var mapOffsetY = unit2Px(mapJS.mapOffsetY?mapJS.mapOffsetY:0, convertRateX, mapUnit)
                        
                        let bgCanvas
                        if (store){
                            const state = store.getState();
                            bgCanvas = state.main.bgCanvas
                        }
                        bgCanvas.setBackgroundImage(assetUrl(columnUrl), ()=>{
                            bgCanvas.bgFileName = bgFileName
                            // bgCanvas.setZoom(bgCanvas.getZoom()*1.0000000001)
                            bgCanvas.renderAll(); 
                        }, {
                            // opacity: 0,
                            angle: 0,
                            left: offsetX*1 + 50 + mapOffsetX,
                            top: offsetY*1 + 50 + mapOffsetY,
                            originX: 'left',
                            originY: 'top',
                            width: img.naturalWidth,
                            height: img.naturalHeight,
                            scaleX:sizeX/img.naturalWidth,
                            scaleY: sizeY/img.naturalHeight,
                            crossOrigin: 'anonymous',
                            KEY_ID: KEY_ID,
                            bgFileName: bgFileName
                        })
                    });
    
                }
            }
            else { //draw individual venue
                if(fabricJS && fabricJS != ""){
                    try{
                        var object = JSON.parse(fabricJS)
                        object.KEY_ID = columns[i].KEY_ID
                        object.lockRotation = true
                        object.lockScalingX = true
                        object.lockScalingY = true
                        object.selectable = false
                        object.selection = false
                        object.hasControls = false
                        object.hasBorders = false
                        object.lockMovementX = true
                        object.lockMovementY = true
                        object.invite_code = invite_code
                        object.exhibitors = exhibitors
                        object.priceCheck = priceCheck
                        object.sold = exhibitors && exhibitors.length>0?1:0
                        object.company = exhibitors?.[0].COMPANY_NAME
                        object.holdBooth = holdBooth
                        object.zIndex1 = (object.zIndex?object.zIndex:0)*1 + 150000
                        object.class = 'venue'
                        if(objectJS && objectJS != ""){
                            objectJS = JSON.parse(objectJS)
                            object = {...object, ...objectJS}
                        }
                        object.clipPath = null;
                if(checkIfSelectable(object)){
                            object.opacity= 1;
                        }
                        var subObjects = object.objects;
                        for(var j = 0; j<  subObjects.length; j++){
                            
                            if(subObjects[j].type == "text"){
                                subObjects[j].width =  object.width;
                                subObjects[j].height =  object.height;
                            }
                        }
                        object.objects = subObjects;
                        objects.push(object)
                    }
                    catch(e){
                        console.log("booth error", e)
                        continue
                    }
                }
            }
        }
    }
    if(1){
    if(deletes && deletes.length > 0){
        for(var i = 0; i < deletes.length; i++){
            var INSERT_POINT = deletes[i].INSERT_POINT
            var left = 0;
            var top = 0;
            if(INSERT_POINT){
                var tmp = INSERT_POINT.split(",")
                if(tmp.length >=2){
                    left = unit2Px(tmp[0], convertRateX, mapUnit) + 50
                    top = unit2Px(tmp[1], convertRateX, mapUnit) + 50
                }
            }
            var fabricJS = deletes[i].FABRICJS
            var objectJS = deletes[i].OBJECTJS
            if(fabricJS && fabricJS != ""){
                var object = JSON.parse(fabricJS)
                object.KEY_ID = deletes[i].KEY_ID
                object.old_deleted = true;
                // object.left = left
                // object.top = top
                if(objectJS && objectJS != ""){
                    objectJS = JSON.parse(objectJS)
                    object = {...object, ...objectJS}
                }
                object.clipPath = null;
                mapRemoveObjects.push(object)
            }
        }
    }
    if(tables && tables.length > 0){
        for(var i = 0; i < tables.length; i++){
            var INSERT_POINT = tables[i].INSERT_POINT
            var left = 0;
            var top = 0;
            if(INSERT_POINT){
                var tmp = INSERT_POINT.split(",")
                if(tmp.length >=2){
                    left = unit2Px(tmp[0], convertRateX, mapUnit) + 50
                    top = unit2Px(tmp[1], convertRateX, mapUnit) + 50
                }
            }
            var fabricJS = tables[i].FABRICJS
            var objectJS = tables[i].OBJECTJS
            if(fabricJS && fabricJS != ""){
                try{
                    var object = JSON.parse(fabricJS)
                    object.KEY_ID = tables[i].KEY_ID
                    // object.left = left
                    // object.top = top
                    if(objectJS && objectJS != ""){
                        objectJS = JSON.parse(objectJS)
                        object = {...object, ...objectJS}
                    }
                    objects.push(object)
                }
                catch(e){
                    console.log("table error", e)
                    continue;
                }
            }
        }
    }
    if(backgrounds && backgrounds.length > 0){
        for(var i = 0; i < backgrounds.length; i++){
            var url = backgrounds[i].URL
            var offsetX = 0;
            var offsetY = 0;
            var sizeX = backgrounds[i].SIZE_X;
            var sizeY = backgrounds[i].SIZE_Y;
            var KEY_ID = backgrounds[i].KEY_ID
            var offset = backgrounds[i].INSERT_POINT;
            if(offset && offset != ""){
                var tmp = offset.split(",")
                if(tmp && tmp.length > 1){
                    offsetX = unit2Px(tmp[0], convertRateX, mapUnit)
                    offsetY = unit2Px(tmp[1], convertRateX, mapUnit)
                }
            }
            var objectJS = backgrounds[i].OBJECTJS
            var bgFileName = false
            if(objectJS && objectJS != ""){
                objectJS = JSON.parse(objectJS)
                bgFileName = objectJS.fileName
            }
            if(url && url != ""){
                // getMeta(assetUrl(url), (err, img) => {
                fabric.Image.fromURL(assetUrl(url), (img) => {
                    // console.log("url", assetUrl(url))
                    // console.log(img.naturalWidth, img.naturalHeight);
                    var convertRateX= Math.abs(mapJS.convertRateX)
                    var mapUnit= (mapJS.mapUnit)
                    var mapOffsetX = unit2Px(mapJS.mapOffsetX?mapJS.mapOffsetX:0, convertRateX, mapUnit)
                    var mapOffsetY = unit2Px(mapJS.mapOffsetY?mapJS.mapOffsetY:0, convertRateX, mapUnit)
                    // if(img && img.naturalWidth && img.naturalHeight){
                    if(img && img.width && img.height){
                        bgImgCanvas.setBackgroundImage(img, ()=>{
                            bgImgCanvas.bgFileName = bgFileName
                            bgImgCanvas.renderAll(); 
                            // console.log("bgImgCanvas", bgImgCanvas)
                            // console.log("bgImgCanvas toDatalessObject", bgImgCanvas.toDatalessObject())
                            setFirstBgContent?.(bgImgCanvas.toDatalessObject())}, {
                            // opacity: 0,
                            angle: 0,
                            left: offsetX*1 + 50 + mapOffsetX,
                            top: offsetY*1 + 50 + mapOffsetY,
                            originX: 'left',
                            originY: 'top',
                            // width: img.naturalWidth,
                            // height: img.naturalHeight,
                            // scaleX:sizeX/img.naturalWidth,
                            // scaleY: sizeY/img.naturalHeight,
                            width: img.width,
                            height: img.height,
                            scaleX:sizeX/img.width,
                            scaleY: sizeY/img.height,
                            crossOrigin: 'anonymous',
                            KEY_ID: KEY_ID,
                            bgFileName: bgFileName
                        })
                    }
                });

            }
        }
    }
    }
    var outputObjects = [...objects]
    outputObjects.sort((a, b) => {
            let fa = (a.zIndex1?a.zIndex1:0)*1,
            fb = (b.zIndex1?b.zIndex1:0)*1
        
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        }
    );
    
    return outputObjects;
}

export const initCanvas = (canvas, mapJS, bgCanvas, bgImgCanvas)=>{
    canvas.set({
        convertRateX:mapJS['convertRateX'], 
        convertOffsetX:mapJS['convertOffsetX'], 
        convertOffsetY:mapJS['convertOffsetY'], 
        startX:mapJS['startX'], 
        startY:mapJS['startY'], 
        endX:mapJS['endX'], 
        endY:mapJS['endY'], 
        mapWidth:mapJS['mapWidth'], 
        mapHeight:mapJS['mapHeight'],
        mapUnit:mapJS['mapUnit']?mapJS['mapUnit']:"feet", 
        convertUnit:mapJS['convertUnit'], 
        mapLock:mapJS['mapLock']?mapJS['mapLock']:false,
        gridSize:mapJS['gridSize'], 
        showGrid:mapJS['showGrid'], 
        snapToGrid:mapJS['snapToGrid'],
        gridAngle:mapJS['gridAngle'], 
        gridOffsetX:mapJS['gridOffsetX'], 
        gridOffsetY:mapJS['gridOffsetY'], 
        gridOffsetXDefault:mapJS['gridOffsetXDefault'], 
        gridOffsetYDefault:mapJS['gridOffsetYDefault'],
        strokeWidth:mapJS['strokeWidth'], 
        mapOffsetX:mapJS['mapOffsetX'], 
        mapOffsetY:mapJS['mapOffsetY'],
        strokeWidthUnscaled:mapJS['strokeWidthUnscaled'],
        boothfile_brand:mapJS['boothfile_brand'],
        event_home:mapJS['event_home'],
        sales_form:mapJS['sales_form'],
        header_banner:mapJS['header_banner'],
        header_color:mapJS['header_color'],
        role:mapJS['role'],
    })              
    canvas.clipPath = new fabric.Rect({
        width: Math.abs(mapJS.endX - mapJS.startX)+5,
        height: Math.abs(mapJS.endY - mapJS.startY)+5,
        top: 45,
        left: 45
      });      
      
    // canvas.renderAll();
    if(bgCanvas){
        bgCanvas.clipPath = new fabric.Rect({
            width: Math.abs(mapJS.endX - mapJS.startX)+5,
            height: Math.abs(mapJS.endY - mapJS.startY)+5,
            top: 45,
            left: 45
        });   
        bgCanvas.renderAll(); 
    }
    if(bgImgCanvas){
        bgImgCanvas.clipPath = new fabric.Rect({
            width: Math.abs(mapJS.endX - mapJS.startX)+5,
            height: Math.abs(mapJS.endY - mapJS.startY)+5,
            top: 45,
            left: 45
        });    
        bgImgCanvas.renderAll();
    }
    
}
export const afterCanvasLoad=(canvas, g_groupOptions, render = true)=>{
    var convertRateX= Math.abs(canvas.convertRateX)
    var groups = canvas.getObjects();    
    var mapOffsetX = unit2Px(canvas.mapOffsetX?canvas.mapOffsetX:0, convertRateX, canvas.mapUnit)
    var mapOffsetY = unit2Px(canvas.mapOffsetY?canvas.mapOffsetY:0, convertRateX, canvas.mapUnit)
    var defaultGroup = getDefaultGroup(g_groupOptions)
    for(var i = 0; i< groups.length; i++){
        groups[i].set({
            borderColor: '#ff7777',
            cornerColor: 'red',
            cornerSize: 6,
            transparentCorners: false,
            lockScalingX: true,
            lockScalingY: true,
            objectCaching: false, //true
            noScaleCache: true,
            left: groups[i].left+mapOffsetX,
            top: groups[i].top+mapOffsetY
        });
        var layer = groups[i].get('layer'); 
        if(layer == "original"){
            groups[i].set({
                visible:false,
                lockMovementX: true,
                lockMovementY: true,
                lockRotation: true,
                lockScalingX: true,
                lockScalingY: true,
                selection:false,
                hasControls:false,
                hasBorders:false,
                evented:false,
                selectable: false ,
            })
        }
        if(groups[i].class == 'table'){
            var sub_objects = groups[i].getObjects('group');
            if(sub_objects && sub_objects.length > 0){
                // if(sub_objects[0].class == "path-group"){
                    sub_objects[0].set({
                        left:NaN,
                        top: NaN,
                    })
                    .setCoords();
                // }
            }
        }
        if(groups[i].class == "booth"){
            changeBorderColor(groups[i], 'black')
            changeBorderWidth(groups[i], getStrokeWidth(canvas)*1)
            if(!checkifrectbooth(groups[i])){
                sendBackIntersects(groups[i], canvas)
            }
            var groupOptions = g_groupOptions
            var boothGroup = groups[i].boothGroup;
            if(!boothGroup || boothGroup == '' || (boothGroup && !isUUID(boothGroup))){
                // console.log("uuidRegexExp.test(boothGroup)",uuidRegexExp.test(boothGroup))
                boothGroup = groups[i].boothGroup = defaultGroup
            }
            var exhibitors = groups[i].exhibitors
            if(groups[i].holdBooth){
                var bgColor = "#dadada"
                for(var k = 0; k < groupOptions.length; k++){
                    if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                        var groupOption = groupOptions[k]
                            bgColor = convertRgbHex(groupOption['RESERVED']);
                    }
                }
                setObjectBG(canvas, groups[i], bgColor)
            }
            else if(groups[i].sold){
                var bgColor = "#dadada"
                for(var k = 0; k < groupOptions.length; k++){
                    if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                        var groupOption = groupOptions[k]
                        var status = exhibitors?.[0].STATUS == '2'?'PENDING':'SOLD';
                        bgColor = convertRgbHex(groupOption[status]);                        
                        // if(status == 'SOLD'){
                        //     bgColor = bgColor+"c0"
                        // }
                    }
                }
                setObjectBG(canvas, groups[i], bgColor)
            }
            else{
                var bgColor = "#dadada"
                if(groups[i].priceCheck && groups[i].priceCheck.length > 0){
                    for(var k = 0; k < groupOptions.length; k++){
                        if(groupOptions[k].KEY_ID&& groupOptions[k].KEY_ID.trim() == boothGroup){
                            var groupOption = groupOptions[k]
                                bgColor = convertRgbHex(groupOption['AVAILABLE']);
                        }
                    }
                }
                setObjectBG(canvas, groups[i], bgColor)
            }
            var textObjects = groups[i].getObjects('text');
            for(var k = 0; k< textObjects.length; k++){
                groups[i].remove(textObjects[k])
            }
            addText(canvas, groups[i], (groups[i].company?((groups[i].company+"").trim()+"\n"):"")+""+groups[i].booth_number)

        }
        if(groups[i].layer == 'sponsorships'){
            var bgColor = groups[i].bgColorCustom
            setObjectBG(canvas, groups[i], bgColor)
        }
        if(groups[i].layer == 'venue'){
            var bgColor = "transparent"
            setObjectBG(canvas, groups[i], bgColor)
        }
    }
    if(render)
        canvas.renderAll();
}

export const addLocationIcon = (mainCanvas,target,render = true)=>{
    var convertRateX= 1
    if(mainCanvas.convertRateX) {
        convertRateX= Math.abs(mainCanvas.convertRateX)
    }
    fabric.Image.fromURL("/asset/select-booth.png", function(img) {
        // var scale = unit2Px(Math.min(6, target.width), convertRateX, mainCanvas.mapUnit)/img.width
        var zoom = mainCanvas.getZoom();
        var scale = LOCATION_WIDTH/img.width/Math.max(zoom,2)
        var left = target.left + target.width/2
        var top = target.top + target.height*2/3

        var angle = target.angle?target.angle:0
        var canvasCenter = new fabric.Point(target.left, target.top);
        var objectOrigin = new fabric.Point(left, top);
        var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(1))
        
        img.set('KEY_ID', target.KEY_ID).set('left', new_loc.x - img.width/2*scale).set('top', new_loc.y - (img.height/1.3)*scale).set({scaleX: scale, scaleY:  scale}).set({class:'icon', dirty:true, objectCaching: false});
        img.targetLeft = target.left
        img.targetTop = target.top
        img.targetWidth = target.width
        img.targetHeight = target.height
        mainCanvas.add(img);  
        
        target.objectCaching= false; //true
        target.dirty = true; 
        // mainCanvas.setZoom(mainCanvas.getZoom()*1.000000001)
        var objs = mainCanvas.getObjects('image');
        objs.forEach(function(obj){
            var objClass = obj.get('class');
            if(objClass == "icon"){
                mainCanvas.bringToFront(obj, true);
            }
        })
        if(render)
            mainCanvas.renderAll()
    },{
        crossOrigin: 'anonymous'
    });
}

export const removeLocationIcon = (mainCanvas,target,render = true)=>{
    var objs = mainCanvas.getObjects('image');
    objs.forEach(function(obj){
        var objClass = obj.get('class');
        if(objClass == "icon"){
            if(obj.KEY_ID == target.KEY_ID)
                mainCanvas.remove(obj)
        }
        else{            
            mainCanvas.bringToFront(obj, true);
        }
    })
    
    if(render)
        mainCanvas.renderAll();
}

export const locationScaling=(mainCanvas,render = true)=>{
    var zoom = mainCanvas.getZoom();
    var objs = mainCanvas.getObjects('image');
    objs.forEach(function(obj){
        var objClass = obj.get('class');
        if(objClass == "icon"){
            var scale = LOCATION_WIDTH/obj.width/Math.max(zoom,2)
            var left = obj.targetLeft + obj.targetWidth/2
            var top  = obj.targetTop + obj.targetHeight*2/3
            var target = getBoothByIcon(mainCanvas, obj)
            var angle = target.angle?target.angle:0
            var canvasCenter = new fabric.Point(obj.targetLeft, obj.targetTop);
            var objectOrigin = new fabric.Point(left, top);
            var new_loc = fabric.util.rotatePoint(objectOrigin, canvasCenter, angle*2*Math.PI/360*(1))

            obj.set('left', new_loc.x - obj.width/2*scale).set('top', new_loc.y - (obj.height/1.3)*scale).set({scaleX: scale, scaleY:  scale});
            mainCanvas.bringToFront(obj, true);
            // obj.set({scaleX: scale, scaleY:  scale});
        }
    })
    if(render)
        mainCanvas.renderAll();
}

export const getBoothByIcon = (mainCanvas, iconObj)=>{
    var objs = mainCanvas.getObjects('group');
    for(var i = 0; i <= objs?.length; i++){
        var obj = objs[i]
        var objClass = obj.get('class');
        if(objClass == "booth"){
            if(obj.KEY_ID == iconObj.KEY_ID)
                return obj;
        }
    }
    return false
}

export const markActiveBooth = (mainCanvas,target,render = true)=>{
    if(mainCanvas.mapLock != 1){
        target.hasControls=true
        target.hasBoder=true
    }
    else{
        target.hasControls=false
        target.hasBoder = false
    }
    var convertRateX= 1
    if(mainCanvas.convertRateX) {
        convertRateX= Math.abs(mainCanvas.convertRateX)
    }
    mainCanvas.bringToFront(target);
    if(target.get('groupped')){
        var groups = target.getObjects('group');
        for(var groupInd = 0; groupInd < groups.length; groupInd++){
           var group = groups[groupInd]
           if(mainCanvas.mapLock != 1){
            group.hasControls=true
           }
           else{
                group.hasControls=false
                group.hasBoder = false
           }
           if(group.class == "booth"){
            
                if(mainCanvas.mapLock != 1){
                    group.hasControls=true
                    group.hasBoder = true
                }
                else{
                    group.hasControls=false
                    group.hasBoder = false
                }
                target.setControlVisible('tl',false)
                target.setControlVisible('bl',false)
                target.setControlVisible('tr',false)
                target.setControlVisible('br',false)
                target.setControlVisible('ml',false)
                target.setControlVisible('mb',false)
                target.setControlVisible('mr',false)
                target.setControlVisible('mt',false)
                if(group.hovered){
                    group.deselectedColor = group.orgBg
                }
                else
                    group.deselectedColor = getBGColor(group)
                changeBorderColor(group, 'red')
                changeBorderWidth(group, getStrokeWidth(mainCanvas)*2)
                changeSingleBG(group, '#96FC3C')           
                group.orgBg = ('#96FC3C');
                group.dirty = true
                if(render)
                    mainCanvas.renderAll()            
            }
       }
    }
    else{
        if(target.class == "booth"){
            if(mainCanvas.mapLock != 1){
                target.hasControls=true
                target.hasBoder = true
            }
            else{
                target.hasControls=false
                target.hasBoder = false
            }
            target.setControlVisible('tl',false)
            target.setControlVisible('bl',false)
            target.setControlVisible('tr',false)
            target.setControlVisible('br',false)
            target.setControlVisible('ml',false)
            target.setControlVisible('mb',false)
            target.setControlVisible('mr',false)
            target.setControlVisible('mt',false)
            if(target.hovered){
                target.deselectedColor = target.orgBg
            }
            else
                target.deselectedColor = getBGColor(target)
            changeBorderColor(target, 'red')
            changeBorderWidth(target, getStrokeWidth(mainCanvas)*2)
            if(target.greenBg){
                changeSingleBG(target, '#96FC3C')                    
                target.orgBg = ('#96FC3C');
            }
            target.dirty = true
            if(render)
                mainCanvas.renderAll()
        }
    }
}

export const markDeactiveBooth = (mainCanvas, target, render = true)=>{
    var convertRateX= 1
    if(mainCanvas.convertRateX) {
        convertRateX= Math.abs(mainCanvas.convertRateX)
    }
   if(target.get('groupped')){
       var groups = target.getObjects('group');
       for(var groupInd = 0; groupInd < groups.length; groupInd++){
            var group = groups[groupInd]
            if(group.class == "booth"){
                changeBorderColor(group, 'black')
                var bgColor = group.deselectedColor?group.deselectedColor:"#dadada"
                changeSingleBG(group, bgColor)       
                changeBorderWidth(group, getStrokeWidth(mainCanvas))
                group.orgBg = bgColor;

                group.sendBackwards();
                group.dirty = true
                if(render)
                    mainCanvas.renderAll()
            }
       }
   }
   else{
        if(target.class == "booth"){
            changeBorderWidth(target, getStrokeWidth(mainCanvas))   
            changeBorderColor(target, 'black')
            var bgColor = target.deselectedColor?target.deselectedColor:"#dadada"
            changeSingleBG(target, bgColor)      
            target.orgBg = bgColor;
            // mainCanvas.sendToBack(target);
            // target.sendBackwards();
            target.dirty = true
            if(render){
                mainCanvas.renderAll()
            }
        }
   }

}

export function LightenDarkenColor(col, amt) {
  
    var usePound = false;
  
    if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
    }
 
    var num = parseInt(col,16);
 
    var r = (num >> 16) + amt;
 
    if (r > 255) r = 255;
    else if  (r < 0) r = 0;
 
    var b = ((num >> 8) & 0x00FF) + amt;
 
    if (b > 255) b = 255;
    else if  (b < 0) b = 0;
 
    var g = (num & 0x0000FF) + amt;
 
    if (g > 255) g = 255;
    else if (g < 0) g = 0;
 
    return (usePound?"#":"") + (g | (b << 8) | (r << 16)).toString(16);
  
}


export const getDuplicatedBooths=(canvas)=>{
    var objs = canvas.getObjects('group');

    var boothObjs = objs.filter((obj)=>{
        return (obj.class == "booth"&&obj.layer !== "original")
      })
    let groupping = boothObjs.reduce((r, a) => {
        r[a.booth_number] = [...r[a.booth_number] || [], a];
        return r;
       }, {});

    var duplicates = [];
    for(var i = 0; i < groupping.length; i++){
        if(groupping[i].length > 1){
            duplicates.push(groupping[i][0])
        }
    }
    for(var i in groupping){
        if(groupping[i].length > 1 && groupping[i][0].booth_number && groupping[i][0].booth_number!="" ){
            duplicates.push(groupping[i][0])
        }
    }
    return duplicates

}

export const transformRotateVertex = (vertexes, angle, entityType)=>{
    let {point,segment,Matrix, Polygon} = Flatten;
    var points = [];
    for(var i = 0; i < vertexes?.length; i++){
        if(entityType == "2dPolyline"){
            points.push(
                point(vertexes[i].x*1, vertexes[i].y*1)
            )
        }
        else{
            points.push(
                point(vertexes[i].Location.x*1, vertexes[i].Location.y*1)
            )
        }
    }
    if(angle == 0){
        var outputPoints = []
        for(var i = 0; i < vertexes?.length; i++){
            if(entityType == "2dPolyline"){
                outputPoints.push(
                    {x:vertexes[i].x*1, y:vertexes[i].y*1}
                )
            }
            else{
                outputPoints.push(
                    {x:vertexes[i].Location.x*1, y:vertexes[i].Location.y*1}
                )
            }
        }
        return outputPoints;
    }

        let s = new Polygon(points);
        let center = s.box.center;
        // let angle = 270*Math.PI/180.;
        let rotated_segment = s.rotate(angle, center)
        // console.log("rotated_segment", rotated_segment.svg())
        // console.log("rotated_segment", rotated_segment.toArray())
        // console.log("rotated_segment", rotated_segment.toJSON())
        var rotatedPoints = rotated_segment.toJSON();
        // var startpoint = 
        var basePoint = rotatedPoints[0][0];
        for(var pointInd = 0; pointInd<rotatedPoints[0].length; pointInd++ ){
            
            var tmp = rotatedPoints[0][pointInd];
            if((tmp.ps.y)< (basePoint.ps.y)){
                basePoint = tmp
            }
        }

        var matrix = new Matrix(1,0,0,1,-basePoint.ps.x,-basePoint.ps.y)
        var transformed_segment = rotated_segment.transform(matrix)
        var transformedPoints = transformed_segment.toJSON()
        var outputPoints = [];
        for(var pointInd = 0; pointInd<transformedPoints[0].length; pointInd++ ){
            var tmp = transformedPoints[0][pointInd];
            outputPoints.push({
                x:tmp.ps.x.toFixed(12)*1,
                y:tmp.ps.y.toFixed(12)*1
            })
            if(pointInd == transformedPoints[0].length-1){
                outputPoints.push({
                    x:tmp.pe.x.toFixed(12)*1,
                    y:tmp.pe.y.toFixed(12)*1
                })
            }
        }
        // console.log("outputPoints",  outputPoints)

        basePoint = outputPoints[0];
        for(var pointInd = 0; pointInd<outputPoints.length; pointInd++ ){
            
            var tmp = outputPoints[pointInd];
            if((tmp.x+tmp.y)< (basePoint.x + basePoint.y)){
                basePoint = tmp
            }
        }

        return  outputPoints
}

export const transformVertex = (vertexes, basePoint, entityType)=>{
    let {point,segment,Matrix, Polygon} = Flatten;
    var points = [];
    for(var i = 0; i < vertexes?.length; i++){
        if(entityType == "2dPolyline"){
            points.push(
                point(vertexes[i].x*1, vertexes[i].y*1)
            )
        }
        else{
            points.push(
                point(vertexes[i].Location.x*1, vertexes[i].Location.y*1)
            )
        }
    }

        let s = new Polygon(points);
       
        var matrix = new Matrix(1,0,0,1,-basePoint.x,-basePoint.y)
        var transformed_segment = s.transform(matrix)
        var transformedPoints = transformed_segment.toJSON()
        var outputPoints = [];
        for(var pointInd = 0; pointInd<transformedPoints[0].length; pointInd++ ){
            var tmp = transformedPoints[0][pointInd];
            outputPoints.push({
                x:tmp.ps.x.toFixed(12)*1,
                y:tmp.ps.y.toFixed(12)*1
            })
            if(pointInd == transformedPoints[0].length-1){
                outputPoints.push({
                    x:tmp.pe.x.toFixed(12)*1,
                    y:tmp.pe.y.toFixed(12)*1
                })
            }
        }
        // console.log("outputPoints",  outputPoints)

        basePoint = outputPoints[0];
        for(var pointInd = 0; pointInd<outputPoints.length; pointInd++ ){
            
            var tmp = outputPoints[pointInd];
            if((tmp.x+tmp.y)< (basePoint.x + basePoint.y)){
                basePoint = tmp
            }
        }

        return  outputPoints
}

export const getStrokeWidth = (canvas)=>{
    var strokWidth = canvas.strokeWidth //inch
    if(!strokWidth){
        if(canvas.mapUnit == 'feet')
          strokWidth = 3 //3 inch
        else{
          strokWidth = 3/40
        }
    }
    return strokWidth/canvas.convertRateX
}
export const findCombinedBooth = (booth_number, mainCanvas)=>{
    var objs = mainCanvas.getObjects('group');
    for(var i = 0; i < objs.length; i++){
        var obj = objs[i]
        var objClass = obj.class;
        if(objClass == "booth"){
            if(obj.child_booths){
                var combined = findCombined(booth_number, obj)
                if(combined){
                    return combined
                }
            }
        }
    }
    return false
}

export const findCombined=(booth_number, parent)=>{
    var booths = parent.child_booths
    if(booths){
        for(var i = 0; i < booths.length; i++){
            if(booths[i].booth_number == booth_number){
                return parent;
            }
            if(booths[i].child_booths){
                var combined = findCombined(booth_number, booths[i])
                if(combined){
                    return combined
                }
            }
        }
    }
    return false
}

export const searchObjectByKey=(KEY_ID, mainCanvas)=>{
    var objs = mainCanvas.getObjects('group');
    for(var i = 0; i < objs.length; i++){
        var obj = objs[i]
        if(obj.KEY_ID == KEY_ID){
            return obj;
        }
    }
    return false
}

export const swichFullOverView=(overviewObject)=> {
    // camera.zoom = Math.max( minZoom, 0.15 );
    // camera.updateProjectionMatrix();
    const { controls } = overviewObject
    controls.reset();
    controls.setZoom(1);
}

export const getSqrt= (booth, props)=>{
    if(!booth){
      return 0
    }
    var convertRateX= 1
    var convertRateY= 1
    var mainCanvas = props.main.mainCanvas
    if(mainCanvas.convertRateX) {
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        var convertRateY= Math.abs(mainCanvas.convertRateY)
    }
    var booth_width = 0
    var booth_height = 0
    if(px2unit(booth.width, convertRateX, mainCanvas.mapUnit) >= 5){
      booth_width = px2unit(booth.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
      booth_height = px2unit(booth.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
    }
    else{
      booth_width = px2unit(booth.width, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
      booth_height = px2unit(booth.height, convertRateX, mainCanvas.mapUnit).toFixed(1).replace(/\.0$/, "")
    }
    var sqrt = 0;
    if(booth.class1 == "custom-booth"){
        var objects = booth.getObjects('polyline');
        if(objects && objects.length > 0 ){
            var points = objects[0].points;
            var polyline = [];
            for(var i = 0; i < points.length; i++){
                polyline.push([px2unit(points[i].x, convertRateX, mainCanvas.mapUnit), px2unit(points[i].y, convertRateX, mainCanvas.mapUnit)])
            }
            sqrt = area(polyline)
        }
    }
    else{
        sqrt = (booth_width*booth_height)
    }
    return sqrt
  }

  export const sendBackIntersects = (targetObject, canvas)=>{
    const intersectingObjects = canvas.getObjects().filter(obj => obj !== targetObject && targetObject.intersectsWithObject(obj) && obj.class=="booth");

    intersectingObjects.sort((a, b) => canvas.getObjects().indexOf(a) - canvas.getObjects().indexOf(b));
    
    const targetIndex = canvas.getObjects().indexOf(targetObject);
    let lowestIntersectingIndex = targetIndex;
    
    if (intersectingObjects.length > 0) {
      lowestIntersectingIndex = canvas.getObjects().indexOf(intersectingObjects[0]);
    }
    
    if (lowestIntersectingIndex < targetIndex) {
      for (let i = targetIndex; i > lowestIntersectingIndex; i--) {
        canvas.sendBackwards(targetObject);
      }
    }
  }

export const setBackgroundImage = (canvas, imageUrl, params) =>{
    return new Promise((resolve, reject) => {
        fabric.Image.fromURL(imageUrl, (img) => {
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), params);
            resolve();
        }, { crossOrigin: 'anonymous' });
    });
}
export const getBoothByNumber = (number, mainCanvas)=>{
    var objects = mainCanvas.getObjects('group')
    for(var i = 0; i < objects.length; i++){
      if(checkIfSelectable(objects[i])){
        if(objects[i].class=='booth'){
          if(objects[i].booth_number == number){
            return objects[i]
          }
        }
      }
    }
    return null
  }
export const groupApartObjects=(groupobject, mainCanvas, groupOptions, newBoothNumber, org_id)=>{
    var orgObject;
    var objectsInGroup = groupobject.getObjects();
    var booth_numbers = [];
    for(var i = 0; i < objectsInGroup.length; i++){
      booth_numbers.push(objectsInGroup[i].booth_number);
    }
    booth_numbers.sort(function(a, b) {
      if (a < b) {
          return -1;
      }
      if (a > b) {
          return 1;
      }
      
      // names must be equal
      return 0;
    })

    objectsInGroup.forEach(function(object) {
      if(object.booth_number == newBoothNumber){
        orgObject = object; return;
      }
    });
    var boothId = "G_"+parseInt(Math.random()*(10000))

    var bgColor = "#dadada"
    var element = new fabric.Polygon(
      [
        {x: 0, y:0},
        {x: groupobject.width, y:0},
        {x: groupobject.width, y:groupobject.height},
        {x: 0, y:groupobject.height},
        {x: 0, y:0}
      ]
      , {
        class:"outline",
        left: groupobject.left,
        top: groupobject.top,
        fill: bgColor, 
        stroke: 'black',
        strokeWidth: getStrokeWidth(mainCanvas),
        borderColor: 'black',
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
    });
    
    element.set({
        borderColor: '#ff7777',
        cornerColor: 'red',
        cornerSize: 6,
        transparentCorners: false
    });

    var group = new fabric.Group([element]).set({
        width: groupobject.width,
        height: groupobject.height,
        id: org_id?org_id:'booth-'+boothId,
        class: 'booth',
        boothGroup: getDefaultGroup(groupOptions),
        class1 : 'booth-group',
        lockRotation: true,
        lockScalingX: true,
        lockScalingY: true,
    });	
    group.set({
        borderColor: '#ff7777',
        cornerColor: 'red',
        cornerSize: 6,
        transparentCorners: false,
        layer:'booth',
        booth_number:newBoothNumber
    }); 
    
    group.set(buildObjectProperty(orgObject));  
    
    var newText = (orgObject.company?(orgObject.company+"\n"):"")+(newBoothNumber)
    addText(mainCanvas, group, newText)   
    return group;
}

export const breakSentenceByWidth=(sentence, width)=>{
    if (width < 50) {
        // Break by word
        return sentence.split(' ').join('\n');
    } else if (width >= 50 && width < 500) {
        return breakByLength(sentence, 10 + (width - 50) * 0.8);        
    } 
    // else if (width >= 100 && width < 200) {
    //     return breakByLength(sentence, 20);
    // } else if (width >= 200 && width < 300) {
    //     return breakByLength(sentence, 40);
    // }
     else {
        // No breaking (return sentence as is)
        return sentence;
    }
}

export const breakByLength=(sentence, length)=>{
    let result = [];
    for (let i = 0; i < sentence.length; i += length) {
        result.push(sentence.slice(i, i + length));
    }
    return result.join('\n');
}
export const isPointInPolygon=(point, vertices)=>{
    let intersects = 0;
    for (let i = 0; i < vertices.length; i++) {
      const v1 = vertices[i];
      const v2 = vertices[(i + 1) % vertices.length];
      if (
        (point.y > Math.min(v1.y, v2.y)) &&
        (point.y <= Math.max(v1.y, v2.y)) &&
        (point.x <= Math.max(v1.x, v2.x)) &&
        v1.y !== v2.y
      ) {
        const xinters = ((point.y - v1.y) * (v2.x - v1.x)) / (v2.y - v1.y) + v1.x;
        if (xinters > point.x) intersects++;
      }
    }
    return intersects % 2 !== 0;
  }

export const findLargestRectangle=(vertices, topMargin = 0, bottomMargin = 0)=>{
    const bounds = {
      minX: Math.min(...vertices.map((v) => v.x)),
      maxX: Math.max(...vertices.map((v) => v.x)),
      minY: Math.min(...vertices.map((v) => v.y + topMargin)),
      maxY: Math.max(...vertices.map((v) => v.y - bottomMargin)),
    };

    let maxArea = 0;
    let bestRect = null;

    // Grid sampling (adjust step for precision)
    const step = Math.min(bounds.maxX, bounds.maxY)/15;
    for (let x = bounds.minX; x <= bounds.maxX; x += step) {
      for (let y = bounds.minY; y <= bounds.maxY; y += step) {
        for (let width = step; x + width <= bounds.maxX; width += step) {
          for (let height = step; y + height <= bounds.maxY; height += step) {
            const rect = [
              { x, y },
              { x: x + width, y },
              { x: x + width, y: y + height },
              { x, y: y + height },
            ];
            if (rect.every((point) => isPointInPolygon(point, vertices))) {
              const area = width * height;
              if (area > maxArea) {
                maxArea = area;
                bestRect = { x, y, width, height };
              }
            }
          }
        }
      }
    }
    bestRect.x = bestRect.x + step/2
    return bestRect;
  }

function findLargestRectangleFast(vertices) {
    // Calculate the polygon bounding box
    const bounds = {
      minX: Math.min(...vertices.map((v) => v.x)),
      maxX: Math.max(...vertices.map((v) => v.x)),
      minY: Math.min(...vertices.map((v) => v.y)),
      maxY: Math.max(...vertices.map((v) => v.y)),
    };
  
    // Use binary search to maximize rectangle size
    let maxArea = 0;
    let bestRect = null;
  
    const step = Math.min((bounds.maxX - bounds.minX), (bounds.maxY - bounds.minY)) / 10; // Reduce step dynamically
    for (let x = bounds.minX; x <= bounds.maxX; x += step) {
      for (let y = bounds.minY; y <= bounds.maxY; y += step) {
        const width = bounds.maxX - x;
        const height = bounds.maxY - y;
  
        const rect = [
          { x, y },
          { x: x + width, y },
          { x: x + width, y: y + height },
          { x, y: y + height },
        ];
  
        if (rect.every((point) => isPointInPolygon(point, vertices))) {
          const area = width * height;
          if (area > maxArea) {
            maxArea = area;
            bestRect = { x, y, width, height };
          }
        }
      }
    }
  
    return bestRect;
}

export const translatePolygonToOrigin=(points)=>{
    const minPoint = points.reduce((min, point) => ({
        x: Math.min(min.x, point.x),
        y: Math.min(min.y, point.y)
    }), { x: Infinity, y: Infinity });

    return points.map(point => ({
        x: point.x - minPoint.x,
        y: point.y - minPoint.y
    }));
}

export const isRectanglePolygon = (polygon)=>{
    if (polygon.length !== 4 && polygon.length !== 5) {
        return false; // Must have 4 or 5 points
    }

    // If the polygon has 5 points, check if the first and last points are the same
    if (polygon.length === 5) {
        const first = polygon[0];
        const last = polygon[4];
        if (first.x !== last.x || first.y !== last.y) {
            return false; // Invalid rectangle with 5 points
        }
        // Remove the redundant last point for further checks
        polygon = polygon.slice(0, 4);
    }

    // Function to calculate the squared distance between two points
    function squaredDistance(p1, p2) {
        return (p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2;
    }

    // Function to calculate the dot product of two vectors
    function dotProduct(p1, p2, p3) {
        const v1 = { x: p2.x - p1.x, y: p2.y - p1.y };
        const v2 = { x: p3.x - p2.x, y: p3.y - p2.y };
        return v1.x * v2.x + v1.y * v2.y;
    }

    // Calculate squared distances for all sides and diagonals
    const d1 = squaredDistance(polygon[0], polygon[1]); // side 1
    const d2 = squaredDistance(polygon[1], polygon[2]); // side 2
    const d3 = squaredDistance(polygon[2], polygon[3]); // side 3
    const d4 = squaredDistance(polygon[3], polygon[0]); // side 4
    const diag1 = squaredDistance(polygon[0], polygon[2]); // diagonal 1
    const diag2 = squaredDistance(polygon[1], polygon[3]); // diagonal 2

    // Check if opposite sides are equal
    const sidesEqual = d1 === d3 && d2 === d4;

    // Check if adjacent sides are perpendicular (dot product == 0)
    const anglesRight =
        dotProduct(polygon[0], polygon[1], polygon[2]) === 0 &&
        dotProduct(polygon[1], polygon[2], polygon[3]) === 0 &&
        dotProduct(polygon[2], polygon[3], polygon[0]) === 0;

    // Check if diagonals are equal
    const diagonalsEqual = diag1 === diag2;

    // A rectangle satisfies all three conditions
    return sidesEqual && anglesRight && diagonalsEqual;
}
function isAlmostRectangle(object, tolerance = 0.05) {    
    var polygon = object.points;
    if (polygon.length < 4 || polygon.length > 5) {
        return false; // Not a quadrilateral
    }

    
    const bounds = {
        minX: Math.min(...polygon.map((v) => v.x)),
        maxX: Math.max(...polygon.map((v) => v.x)),
        minY: Math.min(...polygon.map((v) => v.y)),
        maxY: Math.max(...polygon.map((v) => v.y)),
    };

    // Use binary search to maximize rectangle size
    tolerance = Math.min((bounds.maxX - bounds.minX), (bounds.maxY - bounds.minY)) / 10; 


    const distanceSquared = (p1, p2) =>
        (p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2;

    const dotProduct = (v1, v2) => v1.x * v2.x + v1.y * v2.y;

    const vectors = [];
    for (let i = 0; i < 4; i++) {
        const next = (i + 1) % 4;
        const vector = {
            x: polygon[next].x - polygon[i].x,
            y: polygon[next].y - polygon[i].y,
        };
        vectors.push(vector);
    }

    // Check if adjacent sides form approximately right angles (dot product ≈ 0)
    for (let i = 0; i < 4; i++) {
        const next = (i + 1) % 4;
        if (Math.abs(dotProduct(vectors[i], vectors[next])) > tolerance) {
            return false; // Not a rectangle
        }
    }

    // Check if opposite sides are approximately equal in length
    const sideLengthsSquared = vectors.map(
        (v) => v.x ** 2 + v.y ** 2
    );
    const isCloseEnough = (a, b) =>
        Math.abs(a - b) / Math.max(a, b) <= tolerance;

    if (
        !isCloseEnough(sideLengthsSquared[0], sideLengthsSquared[2]) ||
        !isCloseEnough(sideLengthsSquared[1], sideLengthsSquared[3])
    ) {
        return false; // Opposite sides are not approximately equal
    }

    return true;
}
import React ,{ useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux'
import './App.css';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ToolBox from './viewer/ToolBox';
import { setAuthInfo, setLoading, setBookmarkList, setExhibitorList} from './actions/main'
import { setSearchKeySelect, setSearchPulldownValue, setSearchInputValue, setSearchOptions } from './actions/searchbox'
import { setOpenCompanyProfile } from './actions/ui'
import { setCustomStyles } from './actions/ui'
import { setOpenLogin } from './actions/ui'
import {  getTokenCookie, getTokenPlanner, setCookie, getUrlToken } from './utils/Common'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import LeftProfile from  './viewer/component/LeftProfile';
import ExhibitorCard from  './viewer/component/ExhibitorCard';

import { useParams } from 'react-router-dom'
import qs from 'qs'
import { makeStyles } from '@mui/styles';
import { ESHOW_URL } from './config.js'
import axiosInstance from './utils/axiosInstance.js'
import SearchBox from './viewer/component/SearchBox.js';
import Footer from './viewer/component/Footer.js';


function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function Index(props) {
  const { id1, id2, id3, id4, id5 } = useParams();
  const [data, setData] = useState([]);
  const [page, setPage] = React.useState(1);
  const [filteredItems, setFilteredItems] = useState([])
  const [dataFetched, setDataFetched] = useState(false);
  const [boothFileList, setBoothFileList] = useState([]);
  const page_size = 16;
  
  let standardSearchOptions = props.searchbox.searchOptions?.STANDARD??[]
  let keySelect = props.searchbox?.searchKeySelect
  let pulldownValue = props.searchbox?.searchPulldownValue
  let searchInputValue = props.searchbox?.searchInputValue
  var selectedAdvancedFilterOptions = props.searchbox.selectedAdvancedFilterOptions

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const useStyles = makeStyles((theme) => ({
    searchBtn:{
      backgroundColor: props.ui?.customStyles?.['search-button-bg-color']?(props.ui?.customStyles['search-button-bg-color']+"!important"):'#1565c0',
      color: props.ui?.customStyles?.['search-button-label-color']?(props.ui?.customStyles['search-button-label-color']+"!important"):'#1565c0',
    },
    bubble:{
      color:props.ui?.customStyles?.['bubble-label-color']?(props.ui?.customStyles['bubble-label-color']+"!important"):'white',
      backgroundColor:props.ui?.customStyles?.['bubble-bg-color']?(props.ui?.customStyles['bubble-bg-color']+"!important"):'#1565c0',
    }
  }));
  const classes = useStyles();

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };
  
  const fetchData = ()=>{
    props.setLoading(true)
    var token = getTokenCookie();   
    try{     
        var _token = getTokenPlanner();
        if(_token){
          token = _token
        }
    }
    catch(e){
    }
    var url = `${ESHOW_URL()}?method=getExhibitorList&token=${token}`
    
    axiosInstance.get(url).then(res=>{
        const result = res.data;
        if(result.SUCCESS){
          props.setExhibitorList(result)  
          props.setSearchOptions(result.SEARCH)            
          if(result.EXHIBITORS){
            var boothList = [];
            for(var i = 0; i< result.EXHIBITORS.length; i++){
              var item = result.EXHIBITORS[i]
              if(!item?.SETUP?.LIST_PENDING_EXHIBITORS && item.STATUS == 2)
                continue;
              boothList.push(item)
            }        
            setData(boothList)
            setFilteredItems(boothList)
          }
        }
        else{
          setData([])
          setFilteredItems([])
        }
        setDataFetched(true)
        props.setLoading(false)
    }).catch(error => {
      props.setLoading(false)
    })  
  }

  useEffect(() => {
    props.setOpenCompanyProfile(false)
  }, [])

  const queryString = window.location.search;
  var urlToken = getUrlToken()
  useEffect(() => {
    
    if(props.urlToken){
      var public_token = `/${id1}`
        if(id2){
          public_token = `${public_token}/${id2}`
        }
        if(id3){
          public_token = `${public_token}/${id3}`
        }
        if(id4){
          public_token = `${public_token}/${id4}`
        }
        if(id5){
          public_token = `${public_token}/${id5}`
        }
        setCookie('V3PUBLICMAP', public_token)
    }
    // fetchSearchOptions()
    document.getElementsByTagName("body")[0].style.overflowY='scroll'
    fetchBookmarkList()
    fetchData()
    fetchboothlist()
  }, [props.main[`planner-${urlToken}`], id5, id4, id3, id2, id1])

  
  useEffect(() => {
    if(data.length)
      doSearch()
  }, [data])

  
  useEffect(() => {
    if(data.length)
      debouncedSearch()
  }, [keySelect, pulldownValue, searchInputValue, selectedAdvancedFilterOptions])  

  const doSearch=(value)=>{    
    setPage(1);
    var keyword = null
    let searchOption = getSearchOption(keySelect)
    if(value){
      keyword = value
    }
    else{
      if(searchOption && searchOption.TYPE == 'pulldown'){
        keyword = pulldownValue
      }
      else{
        keyword = searchInputValue
      }
    }
    if(!keyword) keyword = ''
    keyword = `${keyword}`
    var filteredItems = data;
    if((keyword == "" || keyword == '""' || !keyword) && selectedAdvancedFilterOptions?.length === 0){
      setFilteredItems(filteredItems)
      return;
    }
    if(keyword && keyword.trim() !== '') {
      filteredItems = data.filter((item)=>{
        var str = ''
        if(keySelect == "company_description") {
          str = `${item.DESCRIPTION?item.DESCRIPTION:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "exhibitor_name"){
          str = `${item.COMPANY_NAME?item.COMPANY_NAME:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "Product Categories"){
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }          
        else if(keySelect == "booth_number"){
          str = `${item.BOOTHS?item.BOOTHS:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "map_name"){
          str = `${item.MAP_NAME?item.MAP_NAME:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "state"){
          str = `${item.STATE?item.STATE:''}`
          return (str.toLowerCase()==(keyword.toLowerCase()))
        }
        else if(keySelect == "country"){
          str = `${item.COUNTRY?item.COUNTRY:'us'}`
          return (str.toLowerCase()==(keyword.toLowerCase()))
        }
        else if(keySelect == 'Featured Exhibitors'){
          str = `${item.FEATURED?item.FEATURED:''}`
          return (str.toLowerCase().includes(keyword.toLowerCase()))
        }
        else if(keySelect == "Region of Services"){
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }     
        else if(keySelect == "Service Specialty"){
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }             
        else{
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          return (keys?.includes(keyword))
        }
      })      
    }

    if(selectedAdvancedFilterOptions?.length){
      let selectedFilter = selectedAdvancedFilterOptions[0]['key']

      if(selectedFilter?.includes("Product Categories")){
        filteredItems = filteredItems.filter((item)=>{
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          for(var i = 0; i < selectedAdvancedFilterOptions?.length; i++){
            if(keys?.includes(selectedAdvancedFilterOptions[i]['value'])){
              return true;
            }
          }
          return false
        })
      }           
      if(selectedFilter?.includes("Region of Services")){
        filteredItems = filteredItems.filter((item)=>{
            var categories = item.CATEGORY;
            var keys = [];
            for(var i = 0; i< categories?.length; i++){
              keys.push(categories[i].ANSWER_KEY)
            }
            for(var i = 0; i < selectedAdvancedFilterOptions?.length; i++){
              if(keys?.includes(selectedAdvancedFilterOptions[i]['value'])){
                return true;
              }
            }
            return false
        })
      }     
      if(selectedFilter?.includes("Service Specialty")){
        filteredItems = filteredItems.filter((item)=>{
          var categories = item.CATEGORY;
          var keys = [];
          for(var i = 0; i< categories?.length; i++){
            keys.push(categories[i].ANSWER_KEY)
          }
          for(var i = 0; i < selectedAdvancedFilterOptions?.length; i++){
            if(keys?.includes(selectedAdvancedFilterOptions[i]['value'])){
              return true;
            }
          }
          return false
        })
      }  
    }
    
    setFilteredItems(filteredItems)    
    // let newUrl = `${props.main.authInfo.tokenInfo?.ESHOW_PATH}/${searchOption.ID}/${keyword}`;
    // window.history.pushState(null, '', newUrl);
  }
  
  const debouncedSearch = debounce(doSearch, 300);

  const getSearchOption = (value)=>{
    if (!value) return false;
    for(var i = 0; i < standardSearchOptions?.length; i++){
      if(standardSearchOptions[i].ID == value){
        return standardSearchOptions[i]
      }
    }
    return false
  }

  const fetchBookmarkList = ()=>{
  { 
      try{
        var token =  getTokenPlanner();
        if(token){
          var url  = `${ESHOW_URL()}?method=getPlanner&token=${token}`
          var formdata = 
          {  
              query:`${ESHOW_URL()}?method=getPlanner&token=${token}`                
          }
          var data = qs.stringify({
              request: JSON.stringify(formdata)
          });
          const options = {
              headers: {"content-type": 'application/x-www-form-urlencoded'}
          }
          axiosInstance.get(url).then(res=>{
              const result = res.data;
              if(result.SUCCESS){
                props.setBookmarkList(result.BOOKMARKS)
              }
              else{

              }
          }).catch(error => {
          })  
        }
      }
      catch(e){
      }
    }
  }

  const callBookmarkApi= async (exhibitor, value)=>{
    const _token = getTokenPlanner();
    var BOOKMARKS = props.main.bookmarkList?props.main.bookmarkList:[]
    if(value){
      BOOKMARKS = [
        ...BOOKMARKS,
        {
          COMPANY_NAME: exhibitor?.COMPANY_NAME?exhibitor?.COMPANY_NAME:"",
          NOTES:"",
          STATUS:'',
          BOOTHS:exhibitor.BOOTHS,
          EXHIBITOR_KEY:exhibitor?.EXHIBITOR_KEY?exhibitor?.EXHIBITOR_KEY:"" 
        }
      ]
    }
    else{
      BOOKMARKS = BOOKMARKS.filter((item, index) => `${item.EXHIBITOR_KEY}` !== `${exhibitor.EXHIBITOR_KEY}`)
    }
    props.setBookmarkList(BOOKMARKS)
    var postData =
    {
      SUCCESS:true,
      BOOKMARKS:BOOKMARKS
    };

    var formdata = {
      query:`${ESHOW_URL()}?method=putPlanner&token=${_token}`,
      postData: postData,
      returnResponse: true
    }
    var data = qs.stringify({
        request: JSON.stringify(formdata)
    });
    const options = {
      headers: {
        'body': '[{"company:","test"}]', 
        'Content-Type': 'application/json'
      }
    }
    var url = `${ESHOW_URL()}?method=putPlanner&token=${_token}`
    axiosInstance.post(url, JSON.stringify(postData), options).then(async res=>{
      const result = res.data;
      if(result.SUCCESS == true){
      }
      else{
        props.setOpenSnack(true);
        props.setSnackMsg(result.MESSAGE?result.MESSAGE:"Failed to save bookmark");
      }
      // props.setViewFile(filename);
    }).catch(error => {
    })
  }

  const fetchboothlist=async (save_id=false)=>{   
    var url = `${ESHOW_URL()}?method=getMaps&token=${getTokenCookie()}`;
    axiosInstance.get(url).then(res=>{
      setBoothFileList(res.data)
    }).catch(error => {
    })      
  }

  const doBookmark = (exhibitor)=>{
    if(exhibitor){
      try{
        const _token = getTokenPlanner();
        if((props.role !='admin') && (!_token || !_token)){
          props.setOpenLogin(true)
          return false;
        }
        var tmp = [...data];
        for(var i = 0; i < tmp.length; i++){
          if(tmp[i].EXHIBITOR_KEY == exhibitor.EXHIBITOR_KEY){
            tmp[i].BOOKMARKED = !tmp[i].BOOKMARKED
            callBookmarkApi(exhibitor, tmp[i].BOOKMARKED)
          }
        }
        this.setData(tmp);
        // var tmp1 = [...filteredItems];
        // for(var i = 0; i < tmp1.length; i++){
        //   if(tmp1[i].EXHIBITOR_KEY == exhibitor.EXHIBITOR_KEY){
        //     tmp1[i].BOOKMARKED = !tmp1[i].BOOKMARKED
        //     callBookmarkApi(exhibitor, tmp1[i].BOOKMARKED)
        //   }
        // }
        // this.setFilteredItems(tmp1)
      }
      catch(e){

      }
    }
  }

  var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
  if(banner == "undefined") banner = null
  var sales_bookmarks_show = true
  if(props.main.showSetup?.PUBLIC?.BOOKMARKS == 'hide'){
    sales_bookmarks_show = false
  }

  var outputItems = [];
  outputItems = filteredItems.sort(function (a, b){
    if(a.FEATURED === true && (!b.FEATURED || b.FEATURED == "")){
      return -1
    }
    else if(b.FEATURED === true && (!a.FEATURED || a.FEATURED == "")){
      return 1
    }
    else {
      if(keySelect == 'Company Description')
        return `${a.DESCRIPTION}`.localeCompare(`${b.DESCRIPTION}`);
      else if(keySelect == 'Exhibitor Name')
        return `${a.COMPANY_NAME}`.localeCompare(`${b.COMPANY_NAME}`);
      else if(keySelect == 'Product Categories'||keySelect == 'Region of Services'||keySelect == 'Service Specialty'){
          var str_a = ""
          var str_b = ""
          var categories_a = a.CATEGORY;
          for(var i = 0; i <categories_a?.length; i++){
            str_a+=`${categories_a[i].ANSWER_TITLE}`
          }
          var categories_b = b.CATEGORY;
          for(var i = 0; i <categories_b?.length; i++){
            str_b+=`${categories_b[i].ANSWER_TITLE}`
          }
          return `${str_a}`.localeCompare(`${str_b}`)
      }          
      else if(keySelect == 'Booth Number')
        return a.BOOTHS*1 - b.BOOTHS*1;
      else if(keySelect == 'Map Name')
        return  `${a.MAP_NAME}`.localeCompare(`${b.MAP_NAME}`);
      else if(keySelect == 'States')
        return `${a.STATE}`.localeCompare(`${b.STATE}`);
      else if(keySelect == 'Countries')
        return `${a.COUNTRY}`.localeCompare(`${b.COUNTRY}`);
      else if(keySelect == 'Featured Exhibitors')
        return `${a.FEATURED}`.localeCompare(`${b.FEATURED}`);
    }
   
  })

  var pageData = paginate(outputItems, page_size, page);

  return (
    <Box id="xhbt-main-container" className="App Client">
      <ToolBox {...props}/>
      <Box className="xhbt-data-box" style={{maxWidth: 1240, margin:'64px auto', paddingLeft: 10, paddingRight:10, marginTop:(banner && banner != ''?(150+64):64)}}>        
        <Box textAlign={'center'}>
          <SearchBox/>
        </Box>
        <Box mt={2}>
          <Grid container spacing={2}>
            {
              pageData.map((item, index)=>{
                return(
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    <ExhibitorCard item={item} boothFileList={boothFileList} setKeySelect={props.setSearchKeySelect} setPulldownValue={props.setSearchPulldownValue} doBookmark={doBookmark}/>
                  </Grid>
                )
              })
            }
            {
              (!outputItems || outputItems.length == 0) && dataFetched && 
              <Box style={{textAlign:'center', width:'100%'}}>
                <p>No Exhibitor Found.</p>
              </Box>
            }
          </Grid>
        </Box>
      </Box>
      <Box mt={3} mb={8}>
        <Stack spacing={2}>
          <Pagination variant="outlined" shape="rounded" style={{justifyContent:'center'}} count={Math.ceil(outputItems.length/page_size)} color="primary"  page={page} onChange={handleChangePage}/>
        </Stack>
      </Box>
      <Footer/>
      
      <LeftProfile groupOptions = {[]}/>
      {props.ui?.customStyles?.['loaded'] &&
      <LoadingOverlay
            active={props.main.loadingFlag}
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
        }
    </Box>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
    searchbox: state.searchbox,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
      setCustomStyles: data=>dispatch(setCustomStyles(data)),
      setAuthInfo:  data=>dispatch(setAuthInfo(data)), 
      setLoading:  data=>dispatch(setLoading(data)),
      setBookmarkList:  data=>dispatch(setBookmarkList(data)),
      setOpenLogin: value=>dispatch(setOpenLogin(value)) ,
      setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
      setExhibitorList:  data=>dispatch(setExhibitorList(data)),
      setSearchKeySelect:  data=>dispatch(setSearchKeySelect(data)),
      setSearchPulldownValue:  data=>dispatch(setSearchPulldownValue(data)),
      setSearchInputValue:  data=>dispatch(setSearchInputValue(data)),
      setSearchOptions:  data=>dispatch(setSearchOptions(data))
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);

import React ,{ useEffect, useState } from 'react';
import { connect } from 'react-redux'
import './App.css';
import Box from '@mui/material/Box';
import ToolBox from './viewer/ToolBox';
import { setAuthInfo, setLoading} from './actions/main'
import { setOpenCompanyProfile, setCustomStyles } from './actions/ui'
import { getTokenCookie, setCookie,assetUrl } from './utils/Common'
import axios from 'axios';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import LeftProfile from  './viewer/component/LeftProfile';
import { useParams } from 'react-router-dom'
import { ESHOW_URL } from './config.js'
import Footer from './viewer/component/Footer.js';

function Index(props) {
  const { id1, id2, id3, id4, id5 } = useParams();
  const [data, setData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  const fetchData = ()=>{    
    if(props.urlToken){
      var public_token = `/${id1}`
        if(id2){
          public_token = `${public_token}/${id2}`
        }
        if(id3){
          public_token = `${public_token}/${id3}`
        }
        if(id4){
          public_token = `${public_token}/${id4}`
        }
        if(id5){
          public_token = `${public_token}/${id5}`
        }
        setCookie('V3PUBLICMAP', public_token)
    }
    props.setLoading(true)
    let page_key = ''
    for(var i = 0; i < props.ui.menuList?.length; i++){
      var menu = props.ui.menuList[i];      
        if((menu.URL == 'V3_CUSTOM1' || menu.URL == 'V3_CUSTOM2') && !menu.EXTERNAL_URL){
          if(public_token.includes(menu.PAGE_NAME)){
            page_key = menu.KEY_ID
          }
        }
    }

    var url = `${ESHOW_URL()}?method=getPageContent&page_key=${page_key}` 
    
    const options = {
      headers: {
        'Authorization': `Bearer ${getTokenCookie()}`
      }
    } 
    axios.get(url, options).then(res=>{
        const result = res.data;
        if(result.SUCCESS){  
          if(result.PAGE){
            setData(result.PAGE)
          }
        }
        else{
          setData([])
        }
        setDataFetched(true)
        props.setLoading(false)
    }).catch(error => {
      props.setLoading(false)
    })  
  }

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflowY='scroll'
    props.setOpenCompanyProfile(false)
    fetchData()
  }, [id5, id4, id3, id2, id1])


  var banner = props.main.showSetup?.PUBLIC?.HEADER_BANNER;
  if(banner == "undefined") banner = null

  return (
    <Box id="xhbt-main-container" className="App Client">
      <ToolBox {...props}/>
      <Box className="xhbt-data-box" style={{maxWidth: 1200, margin:'64px auto 128px', paddingLeft: 10, paddingRight:10, marginTop:(banner && banner != ''?(150+64):64)}}>
        <Box textAlign={'left'}>
          {
            <div dangerouslySetInnerHTML={{__html: data.HEADER_TEXT}}></div>
          }
        </Box>
      </Box>
      <Footer/>
      <LeftProfile groupOptions = {[]}/>
      {props.ui?.customStyles?.['loaded'] &&
      <LoadingOverlay
            active={props.main.loadingFlag}
            fadeSpeed = {100}
            styles={{
                overlay: (base) => ({
                ...base,
                fontSize:'18px',
                color: 'rgb(5, 37, 51)',
                // background: 'rgb(229 229 229 / 92%)',
                background: 'transparent',
                position:'fixed',
                top: '49px',
                zIndex:1000000000
                })
            }}
            spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
            // spinner
            // text='Loading ...'
            >
        </LoadingOverlay> 
        }
    </Box>
  );
}


const mapStateToProps = (state) => {
  return {
    main: state.main,
    booth_history: state.booth_history.present,
    ui: state.ui,
    cart: state.cart,
  };
};

const mapDispatchProps = (dispatch) => {
  return {    
      setCustomStyles: data=>dispatch(setCustomStyles(data)),
      setAuthInfo:  data=>dispatch(setAuthInfo(data)), 
      setLoading:  data=>dispatch(setLoading(data)),
      setOpenCompanyProfile:  data=>dispatch(setOpenCompanyProfile(data)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(Index);

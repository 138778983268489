import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import { ESHOW_URL } from '../../config.js'


function Footer(props) {
  var showSetup = props.main.showSetup;
  var publicSetup = showSetup?.PUBLIC
  var footerSetup = publicSetup?.FOOTER

  useEffect(() => {
  }, [])  

  const renderSocialLink=(social)=>{
    if(social.SOCIAL_MEDIA == 'Twitter'){
      return(
        <a className="social-icon" target="_blank" href={`https://twitter.com/${social.ACCOUNT_ID}`}>
          <i class="fa-brands fa-x-twitter"></i>
        </a>
      )
    }
    else if(social.SOCIAL_MEDIA == 'Facebook'){
      return(
        <a className="social-icon" target="_blank" href={`https://www.facebook.com/${social.ACCOUNT_ID}`}>
          <i class="fa-brands fa-facebook-f"></i>
        </a>
      )
    }
    else if(social.SOCIAL_MEDIA == 'LinkedIn'){
      return(
        <a className="social-icon" target="_blank" href={`https://www.linkedin.com/company/${social.ACCOUNT_ID}`}>
          <i class="fa-brands fa-linkedin-in"></i>
        </a>
      )
    }
    else if(social.SOCIAL_MEDIA == 'Instagram'){
      return(
        <a className="social-icon" target="_blank" href={`https://www.instagram.com/${social.ACCOUNT_ID}`}>
          <i class="fa-brands fa-instagram"></i>
        </a>
      )
    }
    else if(social.SOCIAL_MEDIA == 'TikTok'){
      return(
        <a className="social-icon" target="_blank" href={`https://www.tiktok.com/@${social.ACCOUNT_ID}`}>
          <i class="fa-brands fa-tiktok"></i>
        </a>
      )
    }
  }

  return (
    <Box className="bottom-footer">
      {
        footerSetup?.FOOTER_TEXT &&
        <Box class="footer-text">
          <div dangerouslySetInnerHTML={{__html: footerSetup?.FOOTER_TEXT?.replace(/\n/g, "<br>")}}></div>
        </Box>
      }
      <Box class="footer-items">
        <Box class="footer-logo">
          <a target="_blink" href={`'javascript:void(0)'}`}>
              <img width="auto" height="28px" src={publicSetup?.LOGO}/>                                       
          </a>  
        </Box>
        <Box class="footer-copyright">
          {footerSetup?.COPYRIGHT_TEXT}
        </Box>
        <Box class="footer-social">
          {
            footerSetup?.SOCIAL_MEDIA?.map((item, index)=>{
              return(
                <div key={index}>
                  {renderSocialLink(item)}
                </div>
              )
            })
          }
        </Box>
        <Box class="footer-poweredby">
          <a target="_blink" href={`${footerSetup?.POWERED_BY??'javascript:void(0)'}`}>
              <img width="auto" height="28px" src={`${ESHOW_URL()}?method=getPoweredBy`}/>                                       
          </a>  
        </Box>
      </Box>
    </Box>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(Footer);
import * as React from 'react';
import { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FileUpload from '@mui/icons-material/CloudUpload';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { connect } from 'react-redux'
import Box from '@mui/material/Box';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { API_URL, S3_URL } from '../../config.js'
import axios from 'axios';
import qs from 'qs'
import { SVG } from '@svgdotjs/svg.js'
import { addLog } from '../../actions/log.js'
import { setLoading } from '../../actions/main.js'
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader'
import { setBoothHistory } from '../../actions/booth_history.js'
import { fabric } from "fabric";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { v4 as uuidv4 } from 'uuid';
import { cloneCanvas } from '../../utils/CanvasTool.js'
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {PaperComponent, getDefaultGroup} from '../../utils/Common.js'
import {renderGrid, rotateCanvasAndElements, removeGrid, redrawGrid, editMode, viewMode, renderBorder, redrawobjectborder, adjustobjectborder, checkifrotate, checkifrotatepoints, checkiftable, rotatePathObject, rotateObject, rotatePoints, rotatePoints2 , checkifpathrotate, checkifbooth, compressJson, toggleDrawMode, unlockMap, lockMap, addText, centerFocus, redrawRulers, changeBorderColor, getGeometry, setObjectBG, getBGColor, checkIfSelectable, composeLoadObjects, initCanvas, afterCanvasLoad, px2unit, unit2Px, renderCoordinate, removeCoordinate, moveCoordinate, setObjectBorder, sendOriginalToTopGrid,getDuplicatedBooths, scaleTextsCenterFocus, transformVertex, transformRotateVertex, getStrokeWidth, setBackgroundImage} from '../../utils/CanvasTool'
var moveTimer = -1
var step2Canvas
var columnCanvas
var g_offsetX_Ft
var g_offsetY_Ft
var g_isDragging = false
var dwgFilePath = false
var linklistBlocks = [];
function ColumnModal(props) {
  const { onClose, onOk, onChange, open } = props;
  const [step, setStep] = React.useState(1);
  const [imgUrl, setImageUrl] = React.useState(null);
  const [dwgSvg, setDwgSvg] = React.useState(null);
  const [dwgFilteredSvg, setDwgFilteredSvg] = React.useState(null);
  const [mainLayers, setMainLayers] = React.useState([])
  const [imageWidth, setImageWidth] = React.useState(0)
  const [imageHeight, setImageHeight] = React.useState(0)
  const [imageWidth_Ft, setImageWidth_Ft] = React.useState(0)
  const [imageHeight_Ft, setImageHeight_Ft] = React.useState(0)
  const [factor, setFactor] = React.useState(1)
  const [prepareDwgimg, setPrepareDwgimg] = React.useState(false)
  const [preparePreviewDwgimg, setPreparePreviewDwgimg] = React.useState(false)  
  const [offsetX_Ft, setOffsetX_Ft] = React.useState(0)
  const [offsetY_Ft, setOffsetY_Ft] = React.useState(0)
  const [loadingFlag, setLoadingFlag] = React.useState(false)
  const [fileName, setFileName] = React.useState(false)
  var mainCanvas = props.main.mainCanvas
  const active_booth_file = props.main.active_booth_file
  
  const openRef = useRef();
  var bgImgCanvas = props.main.bgImgCanvas

  const onSet = async ()=>{
    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    var mainCanvas = props.main.mainCanvas;
    var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
    var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
    if(imgUrl){
      console.log("mainLayers", mainLayers)
      var parseLayers = [];
      for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
          if(mainLayers[layerInd].selected){
              parseLayers.push(mainLayers[layerInd].name);
          }
      }
      props.setLoading(true) ;
      
      var formdata = 
      {
          // "action":"svg_creation",
          "action":"data_extraction",
          "converter":"AutoXchange AX2020",
          "version":"V1.00",
          "contentType":"file",
          "contentLocation":`${S3_URL()}s3/${dwgFilePath}`,
          "contentFormat":"DWG",
          "contentUsername":"",
          "contentPassword":"",
          "userLabel":"fromCADViewerJS",
          "contentResponse":"stream",
          "leaveStreamOnServer":0,
          "parameters":[
              {"paramName":"json",
              "paramValue":""},
              {"paramName":"entities",
              "paramValue":""},
              {"paramName":"prec",
              "paramValue":"15"},
              // {"paramName":"lon",
              // "paramValue":parseLayers.join(";")},
              // {"paramName":"explodeMText",
              // "paramValue":""},
              // {"paramName":"prec",
              // "paramValue":"15"},
          ],
          parseLayers:parseLayers
      }
      var data = qs.stringify({
          request: JSON.stringify(formdata)
        });
      const options = {
          headers: {"content-type": 'application/x-www-form-urlencoded'}
      }
      var result = false
      var response = false;
      try{
          response = await axios.post('/callapiconversion', data, options);
          var parseEntities = [];
          if(response.status == 200){
              var convertedSvgInfo = response.data
              var formdata = 
              {
                  parseLayers:parseLayers
              }
              var data = qs.stringify({
                  request: JSON.stringify(formdata)
              });
              var response = await axios.post(convertedSvgInfo.contentStreamData, data, { headers: {  } })
              if(response.status == 200){
                  parseEntities = response.data.parseEntities
                  linklistBlocks = response.data.linklistBlocks
              }

          }
          else{
              props.setLoading(false) ;
              props.setOpenSnack(true);
              props.setSnackMsg("Failed to convert the dwg.");
              return;
          }
      }
      catch(e){                
          props.setLoading(false) ;
          props.setOpenSnack(true);
          props.setSnackMsg("Failed to convert the dwg.");
          return;
      }
      
      if(1){                
          await renderEntities(parseEntities);   
          props.setLoading(false) ; 
      }

    }
    else{
    }
    onOk();
  }

  const findBlock = (linklistBlocks, block_name)=>{
    var result;
    if(!block_name || block_name == ''){
        return false;
    }
    for(var i = 0; i < linklistBlocks?.length; i++){
        if(linklistBlocks[i].Block_Name == block_name){
            result = linklistBlocks[i];
            break;
        }
    }
    return result;
}

const getSvgInfo=(svgStr)=>{
  var drawermain = SVG(svgStr);
  var dimension_str = drawermain.find("#cadviewer_drawingCoordinates").text();
  var units_str = drawermain.find("#cadviewer_worldUnits").text();
  ////console.log("dimension_str", dimension_str)
  var dimension = null;
  var convertRateX = 1;
  var convertRateY = 1;
  var svgToWorldUnits = 1;
  var convertOffsetXOrg = 0;
  var convertOffsetYOrg = 0;
  var convertOffsetX = 0;
  var convertOffsetY = 0;
  var startX = 0;
  var startY = 0;
  var endX = 0;
  var endY = 0;
  var mapWidth = 0;
  var mapHeight = 0;
  var mapUnit = mainCanvas.mapUnit;
  let convertUnit = ''
  if(units_str && units_str.length>0){
      units_str[0] = units_str[0].replaceAll(". ", "");
      var units = JSON.parse(units_str[0]);
      var globalOrigin = units['globalOrigin']
      if(globalOrigin['units']){
          convertUnit = globalOrigin['units']
      }
      else{
          convertUnit = units['units']
      }
      
      if(globalOrigin['svgToWorldUnits']){
          convertRateX = convertRateY = globalOrigin['svgToWorldUnits']
          svgToWorldUnits = globalOrigin['svgToWorldUnits']
      }
      else{
          convertRateX = convertRateY = units['svgToWorldUnits']
          svgToWorldUnits = units['svgToWorldUnits']
      }
  }
  if(dimension_str && dimension_str.length>0){
      dimension_str[0] = dimension_str[0].replaceAll(". ", "");
      dimension = JSON.parse(dimension_str[0])
      ////console.log("dimension", dimension)
      var dwg_info = dimension.DWG;
      var svg_info = dimension.SVG;
      var width_svg = Math.abs(svg_info.upperRight.x - svg_info.lowerLeft.x)
      var height_svg = Math.abs(svg_info.upperRight.y - svg_info.lowerLeft.y)
      var width_dwg = Math.abs(dwg_info.upperRight.x - dwg_info.lowerLeft.x)
      var height_dwg = Math.abs(dwg_info.upperRight.y - dwg_info.lowerLeft.y)
      var real_width_svg = width_dwg/svgToWorldUnits
      var real_height_svg = height_dwg/svgToWorldUnits
      var offset_svg_x =  svg_info.lowerLeft.x
      var offset_svg_y =  real_height_svg - svg_info.lowerLeft.y

      let cropOffsetY = height_dwg - svg_info.upperRight.y*svgToWorldUnits

      convertOffsetXOrg = dwg_info.lowerLeft.x + svg_info.lowerLeft.x*svgToWorldUnits
      convertOffsetYOrg = dwg_info.upperRight.y - svg_info.lowerLeft.y*svgToWorldUnits - cropOffsetY

      startX = 0;
      startY = 0;
      endX = width_svg;
      endY = height_svg;
      if(convertUnit == 'mm'){
          convertRateX/=1000
          convertOffsetX=convertOffsetXOrg/1000
          convertOffsetY=convertOffsetYOrg/1000
      }
      else{
          convertOffsetX=convertOffsetXOrg
          convertOffsetY=convertOffsetYOrg
      }
      if(mapUnit){
          mapWidth = px2unit(width_svg, convertRateX, mapUnit)
          mapHeight = px2unit(height_svg, convertRateX, mapUnit)
      }
  }

  return {
    convertRateX, convertRateY, svgToWorldUnits, convertOffsetX, convertOffsetY, mapWidth, mapHeight, convertUnit
  };
}

const renderEntities = async (entities)=>{
  var mapUnit = mainCanvas.mapUnit;
            var bgColor = "#dadada"
  if(true){
    var {convertRateX, svgToWorldUnits, convertRateY, svgToWorldUnits, convertOffsetX, convertOffsetY} = getSvgInfo(dwgSvg);
    // fabric.Object.prototype.objectCaching = false
    // mainCanvas.renderOnAddRemove = false
    let venueHandles = [];
    for(var entInd = 0; entInd < entities?.length; entInd++)  
    {
        var entity = entities[entInd];
        {
            var newPoints = [];
            // var angle = entity.Rotation*180/Math.PI;
            var angle = 0;
            var position = {}
            var dwg_left = 0
            var dwg_top = 0
            var dwg_width = 0
            var dwg_height = 0
            venueHandles.push([entity.Handle,entity.Type])
            if(entity.Type == 'BlockReference'){
                var basicPoint = {}
                basicPoint.x = entity.Position.x
                basicPoint.y = entity.Position.y
                var blockDefinition = findBlock(linklistBlocks, entity.Block_Name)
                if(blockDefinition){
                    var blockDefinitionEntity
                    for(var defInd = 0; defInd< blockDefinition?.Entities?.length; defInd++){
                        if(blockDefinition?.Entities?.[defInd].Type == "Polyline" || blockDefinition?.Entities?.[defInd].Type == "2dPolyline"){
                            blockDefinitionEntity = blockDefinition?.Entities?.[defInd]
                            break;
                        }
                    }
                    if(blockDefinitionEntity){
                        if(blockDefinitionEntity.Type == "Polyline" || blockDefinitionEntity.Type == "2dPolyline"){
                            var position = {};
                            var startPointX = entity.Entity_Extents.Minimum_Point.x*1
                            var startPointY = entity.Entity_Extents.Maximum_Point.y*1
                            position.x = (startPointX - convertOffsetX*1)/svgToWorldUnits+50;
                            position.y = (startPointY - convertOffsetY*1)/(-svgToWorldUnits)+50;

                            dwg_left = startPointX/12
                            dwg_top = startPointY/12
                            var transformedVertexes = transformRotateVertex(blockDefinitionEntity.Vertices, entity.Rotation, blockDefinitionEntity.Type)
                            for(var vertices_index = 0; vertices_index < transformedVertexes?.length; vertices_index++){
                                var position_x
                                var position_y
                                position_x = (startPointX + transformedVertexes[vertices_index].x*1 - convertOffsetX*1)/svgToWorldUnits+50;
                                position_y = (startPointY + transformedVertexes[vertices_index].y*1- convertOffsetY*1)/(-svgToWorldUnits)+50;
                                newPoints.push({x: position_x, y:position_y})
                            }
                            if(newPoints.length == 4){
                                newPoints.push(newPoints[0])
                            }
                            
                            dwg_width = Math.abs(blockDefinitionEntity.Entity_Extents.Maximum_Point.x-blockDefinitionEntity.Entity_Extents.Minimum_Point.x)
                            dwg_height = Math.abs(blockDefinitionEntity.Entity_Extents.Maximum_Point.y-blockDefinitionEntity.Entity_Extents.Minimum_Point.y)
                            // console.log("Polyline size", dwg_width, dwg_height)
                            if(mapUnit == 'feet'){
                                dwg_width = dwg_width/12
                                dwg_height = dwg_height/12
                            } 
                        }
                    }
                }

            }
            if(entity.Type == 'Polyline' || entity.Type == '2dPolyline'){
                var position = {};

                
                var startPointX = entity.Entity_Extents.Minimum_Point.x*1
                var startPointY = entity.Entity_Extents.Maximum_Point.y*1
                
                position.x = (startPointX - convertOffsetX*1)/svgToWorldUnits+50;
                position.y = (startPointY - convertOffsetY*1)/(-svgToWorldUnits)+50;

                dwg_left = startPointX/12
                dwg_top = startPointY/12

                var transformedVertexes = transformVertex(entity.Vertices, {x:startPointX, y:startPointY}, entity.Type)
                for(var vertices_index = 0; vertices_index < transformedVertexes?.length; vertices_index++){
                    var position_x
                    var position_y
                    position_x = (startPointX + transformedVertexes[vertices_index].x*1 - convertOffsetX*1)/svgToWorldUnits+50;
                    position_y = (startPointY + transformedVertexes[vertices_index].y*1- convertOffsetY*1)/(-svgToWorldUnits)+50;
                    newPoints.push({x: position_x, y:position_y})
                }
                if(newPoints.length == 4){
                    newPoints.push(newPoints[0])
                }                                        
                dwg_width = Math.abs(entity.Entity_Extents.Maximum_Point.x-entity.Entity_Extents.Minimum_Point.x)
                dwg_height = Math.abs(entity.Entity_Extents.Maximum_Point.y-entity.Entity_Extents.Minimum_Point.y)
                // console.log("Polyline size", dwg_width, dwg_height)
                if(mapUnit == 'feet'){
                    dwg_width = dwg_width/12
                    dwg_height = dwg_height/12
                }

            }
            ////console.log(paths);
            if(newPoints?.length >= 4 ){
                var rotateFlag = checkifrotatepoints(newPoints)
                if(rotateFlag){
                    var ratateResult = rotatePoints2(mainCanvas, newPoints)
                    newPoints = ratateResult.newPoints
                    angle = ratateResult.angle
                    var new_centerPoint = ratateResult.new_centerPoint
                    position.x+= ratateResult.offsetX
                }
                
                fabric.Object.prototype.transparentCorners = false;
                var obj = new fabric.Polygon(
                    newPoints, {
                    
                    layer: 'venue',
                    class:'venue',
                    left : position.x,
                    top : position.y,
                    visible:1,
                    fill: (entInd==412?'red':bgColor), 
                    // fill: (entity.Type == 'BlockReference'?'red':bgColor),
                    strokeWidth:getStrokeWidth(mainCanvas),
                    stroke:'black',
                    // stroke:(entity.Type == 'BlockReference'?'red':'black'),
                    objectCaching: false //true
                });
                var f_group = new fabric.Group([obj]).set({
                        width : obj.width,
                        height : obj.height,
                        booth_number: 'venue-'+entInd,
                        id: 'venue-'+entInd,
                        Handle:  entity.Handle,
                        boothGroup: getDefaultGroup(props.main.groupOptions),
                        labelPosition: 'bottom-left',
                        class: 'venue',
                        layer:'venue',
                        preserveObjectStacking: true,
                        objectCaching: false, //true
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        selectable: false,
                        selection: false,
                        hasControls: false,
                        hasBorders: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        uniq_id: Date.now() + parseInt(Math.random()*10000000000),
                        KEY_ID: uuidv4(),
                        dwg_left,
                        dwg_top,
                        dwg_width,
                        dwg_height
                });	
                f_group.set({
                    borderColor: '#ff7777',
                    cornerColor: 'red',
                    cornerSize: 6,
                    transparentCorners: false,
                });
                
                
                
                if(angle > 0){
                    f_group.set({
                        angle:angle
                    })
                }
                mainCanvas.add(f_group)
            }
            if(entity.Type == 'Line'){
                var position = {};

                
                var startPointX = entity.Start_Point.x*1
                var startPointY = entity.Start_Point.y*1

                var endPointX = entity.End_Point.x*1
                var endPointY = entity.End_Point.y*1
                
                position.x = (startPointX - convertOffsetX*1)/svgToWorldUnits+50;
                position.y = (startPointY - convertOffsetY*1)/(-svgToWorldUnits)+50;
                var radius = (entity.Radius)/svgToWorldUnits;

                dwg_left = startPointX/12
                dwg_top = startPointY/12

                dwg_width = Math.abs(entity.Entity_Extents.Maximum_Point.x-entity.Entity_Extents.Minimum_Point.x)
                dwg_height = Math.abs(entity.Entity_Extents.Maximum_Point.y-entity.Entity_Extents.Minimum_Point.y)
                // console.log("Polyline size", dwg_width, dwg_height)
                if(mapUnit == 'feet'){
                    dwg_width = dwg_width/12
                    dwg_height = dwg_height/12
                }
                let points = [
                    {x:(startPointX - convertOffsetX*1)/svgToWorldUnits+50,y:(startPointY - convertOffsetY*1)/(-svgToWorldUnits)+50},
                    {x:(endPointX - convertOffsetX*1)/(svgToWorldUnits)+50, y:(endPointY - convertOffsetY*1)/(-svgToWorldUnits)+50}
                ]
                fabric.Object.prototype.transparentCorners = false;
                var obj = new fabric.Polyline(points,
                    {
                        fill: bgColor, 
                        // left: position.x,  // X position
                        // top: position.y,    // Y position
                        strokeWidth:getStrokeWidth(mainCanvas),
                        objectCaching: false, //true
                        stroke:'black',
                        visible:1,
                        layer: 'venue',
                        class:'venue',
                        strokeLineCap: 'round'
                    }
                );
                var f_group = new fabric.Group([obj]).set({
                        width : obj.width,
                        height : obj.height,
                        booth_number: 'venue-'+entInd,
                        id: 'venue-'+entInd,
                        Handle:  entity.Handle,
                        boothGroup: getDefaultGroup(props.main.groupOptions),
                        labelPosition: 'bottom-left',
                        class: 'venue',
                        layer:'venue',
                        preserveObjectStacking: true,
                        objectCaching: false, //true
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        selectable: false,
                        selection: false,
                        hasControls: false,
                        hasBorders: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        uniq_id: Date.now() + parseInt(Math.random()*10000000000),
                        KEY_ID: uuidv4(),
                        dwg_left,
                        dwg_top,
                        dwg_width,
                        dwg_height
                });	
                f_group.set({
                    borderColor: '#ff7777',
                    cornerColor: 'red',
                    cornerSize: 6,
                    transparentCorners: false,
                });
                
                
                
                if(angle > 0){
                    f_group.set({
                        angle:angle
                    })
                }
                mainCanvas.add(f_group)
            }
            if(entity.Type == 'Circle'){
                var position = {};

                
                var startPointX = entity.Center.x*1
                var startPointY = entity.Center.y*1
                
                position.x = (startPointX - convertOffsetX*1)/svgToWorldUnits+50;
                position.y = (startPointY - convertOffsetY*1)/(-svgToWorldUnits)+50;
                var radius = (entity.Radius)/svgToWorldUnits;

                dwg_left = startPointX/12
                dwg_top = startPointY/12

                dwg_width = Math.abs(entity.Entity_Extents.Maximum_Point.x-entity.Entity_Extents.Minimum_Point.x)
                dwg_height = Math.abs(entity.Entity_Extents.Maximum_Point.y-entity.Entity_Extents.Minimum_Point.y)
                // console.log("Polyline size", dwg_width, dwg_height)
                if(mapUnit == 'feet'){
                    dwg_width = dwg_width/12
                    dwg_height = dwg_height/12
                }
                fabric.Object.prototype.transparentCorners = false;
                var obj = new fabric.Circle(
                    {
                        radius: radius,  // Radius of the circle
                        fill: bgColor, 
                        left: position.x - radius,  // X position
                        top: position.y - radius,    // Y position
                        strokeWidth:getStrokeWidth(mainCanvas),
                        objectCaching: false, //true
                        stroke:'black',
                        visible:1,
                        layer: 'venue',
                        class:'venue',
                    }
                );
                var f_group = new fabric.Group([obj]).set({
                        width : obj.width,
                        height : obj.height,
                        booth_number: 'venue-'+entInd,
                        id: 'venue-'+entInd,
                        Handle:  entity.Handle,
                        boothGroup: getDefaultGroup(props.main.groupOptions),
                        labelPosition: 'bottom-left',
                        class: 'venue',
                        layer:'venue',
                        preserveObjectStacking: true,
                        objectCaching: false, //true
                        lockRotation: true,
                        lockScalingX: true,
                        lockScalingY: true,
                        selectable: false,
                        selection: false,
                        hasControls: false,
                        hasBorders: false,
                        lockMovementX: true,
                        lockMovementY: true,
                        uniq_id: Date.now() + parseInt(Math.random()*10000000000),
                        KEY_ID: uuidv4(),
                        dwg_left,
                        dwg_top,
                        dwg_width,
                        dwg_height
                });	
                f_group.set({
                    borderColor: '#ff7777',
                    cornerColor: 'red',
                    cornerSize: 6,
                    transparentCorners: false,
                });
                
                
                
                if(angle > 0){
                    f_group.set({
                        angle:angle
                    })
                }
                mainCanvas.add(f_group)
            }
        }
    }
    mainCanvas.renderAll();
  }
}

  const pushBoothHistory = ()=>{    
    var bgImgCanvas = props.main.bgImgCanvas;
    bgImgCanvas.renderAll();
    bgImgCanvas.requestRenderAll()
    // props.setBoothHistory({content: (mainCanvas.toDatalessObject())})
  }

  const previewCallback = ()=>{    
    step2Canvas.renderAll();
    step2Canvas.requestRenderAll()
    columnCanvas.renderAll();
    columnCanvas.requestRenderAll()

  }

  useEffect( async () => {
    if(prepareDwgimg && dwgSvg){
      {
        // document.getElementById("render-svg").innerHTML = ""
        var drawermain = SVG(dwgSvg);
        var bbox = drawermain.bbox();
        var width = bbox.width.toFixed(1)
        var height = bbox.height.toFixed(1)
        setImageWidth(width)
        setImageHeight(height)
        var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
        setImageWidth_Ft(px2unit(width,convertRateX,props.main.mainCanvas.mapUnit))
        setImageHeight_Ft(px2unit(height,convertRateX,props.main.mainCanvas.mapUnit))
        drawermain.width(642)
        drawermain.height(500)
        drawermain.viewbox(0,0,width,height)
        drawermain.transform({
          a:1,b:0,c:0,d:1,e:0,f:0
        })

        // drawermain.addTo('.render-svg')
        for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
          var layer = mainLayers[layerInd];
          var topgroup = drawermain.find("#"+layer['id']);
          if(layer && !layer.active){
              if(topgroup && topgroup.length > 0){
                topgroup.remove();
              }
          }
          if(layer && layer.active){
            var subGroups = topgroup.find('g');
            if(subGroups && subGroups.length > 0){
              for(var i = 0; i< subGroups[0].length; i++){
                if(subGroups[0][i].node.style.fill!= ''){
                  subGroups[0][i].node.style.fill="transparent"
                }
              }
            }
          }
          var textelements = topgroup.find('text');
          if(textelements && textelements.length > 0){
            for(var i = 0; i< textelements[0].length; i++){
              if(textelements[0][i].node.innerHTML == "For Demonstration Purposes Only"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.cadviewer.com"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.cadviewer.com"){
                textelements[0][i].remove();
              }
            }
          }
        }

        var tmp_main = SVG(dwgSvg);
        for(var layerInd = 0; layerInd< mainLayers.length; layerInd++){
          var layer = mainLayers[layerInd];
          var topgroup = tmp_main.find("#"+layer['id']);
          if(layer && !layer.active){
              if(topgroup && topgroup.length > 0){
                topgroup.remove();
              }
          }
          if(layer && layer.active){
            var subGroups = topgroup.find('g');
            if(subGroups && subGroups.length > 0){
              for(var i = 0; i< subGroups[0].length; i++){
                subGroups[0][i].node.style.fill="transparent"
                const computedStyle = window.getComputedStyle(subGroups[0][i].node);
                const strokeWidth = computedStyle.getPropertyValue('stroke-width');

                console.log("strokeWidth", strokeWidth)
                subGroups[0][i].node.style.stroke="black"
                let idealWidth = 2; //2feet
                if(props.main.mainCanvas.mapUnit=='meter'){
                  idealWidth = 2/20 //10cm
                  if(props.main.mainCanvas.convertUnit == 'mm'){
                    idealWidth = 20 //20cm
                  }
                }
                subGroups[0][i].node.style.strokeWidth=(strokeWidth?strokeWidth:1)*Math.min(idealWidth/(convertRateX?convertRateX:1),10)
              }
            }
          }
          var textelements = topgroup.find('text');
          if(textelements && textelements.length > 0){
            for(var i = 0; i< textelements[0].length; i++){
              if(textelements[0][i].node.innerHTML == "For Demonstration Purposes Only"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "Tailor Made Software, Ltd"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.cadviewer.com"){
                textelements[0][i].remove();
              }
              if(textelements[0][i].node.innerHTML == "www.tailormade.com"){
                textelements[0][i].remove();
              }
            }
          }
        }
        var svgstring = tmp_main.svg();
        svgstring = svgstring.replace(/&quot;/g,"'");
        svgstring = svgstring.replace(/&nbsp;/g,"'");
        svgstring = svgstring.replace(/svgjs:/g,"");
        setDwgFilteredSvg(svgstring);        
        // console.log("svg", svgstring)
        // let blob = new Blob([svgstring], {type: 'image/svg+xml;charset=utf-8'});
        // // var blob = new Blob(drawermain.svg(), {type: 'image/svg+xml;charset=utf-8'});
        // var canvas = document.createElement("canvas");
        // canvas.width=width;
        // canvas.height=height;
        // const ctx = canvas.getContext('2d');
        // var v = await Canvg.from(ctx, svgstring);
  
        // // // Start SVG rendering with animations and mouse handling.
        // v.start();
        // var img_url = canvas.toDataURL("image/png")
        // v.stop()

        var filename = "tmp";
        // var data = blob.slice(0, blob.size);
        var data = svgstring
        var filePath = `map_bg/${uuidv4()}.png`
        var url = API_URL()+`uploadbgimg?ax-allow-ext=all&listtype=serverfolder&filePath=${filePath}&&start=0`

        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);

        xhr.setRequestHeader("Accept", "image/apng");
        // xhr.setRequestHeader("Content-Type", "text/plain");

        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
              console.log(xhr.responseText);
              
              // var objecturl = URL.createObjectURL(file);
              var objecturl = xhr.responseText
              setImageUrl(objecturl)
              setLoadingFlag(false);
          }
        };

        xhr.send((data));
        setLoadingFlag(true);
      }
      setPrepareDwgimg(false)
      setPreparePreviewDwgimg(true)
    }
  }, [prepareDwgimg, dwgSvg])


  useEffect(() => {
    if(open){
      setTimeout(()=>{
        var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)  
        var bg_url = null;
        var bg_width = 0;
        var bg_height = 0;
        var bg_offset_x = 0;
        var bg_offset_y = 0;
        var bg_scale = 1;
        var bg_width_feet = 0;
        var bg_height_feet = 0;
        var bg_offset_x_feet = 0;
        var bg_offset_y_feet = 0;
        var backgroundImage = bgImgCanvas.backgroundImage
        if(backgroundImage && backgroundImage.src){
          bg_url = backgroundImage.src
        }
        else if(backgroundImage && backgroundImage._element){
          bg_url = backgroundImage._element.currentSrc
        }
        if(backgroundImage){
          
          var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
          var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
          bg_width = backgroundImage.width?backgroundImage.width:3000
          bg_height = backgroundImage.height?backgroundImage.height:3000
          bg_scale = backgroundImage.scaleX
          bg_offset_x = backgroundImage.left?backgroundImage.left-50-mapOffsetX:0
          bg_offset_y = backgroundImage.top?backgroundImage.top-50-mapOffsetY:0
        }
        setImageUrl(bg_url);
        var factor = bg_scale?bg_scale:1
        setFactor(factor)
        setImageWidth(bg_width)
        setImageHeight(bg_height)
        var offsetX_Ft = px2unit(bg_offset_x,convertRateX,props.main.mainCanvas.mapUnit)
        var offsetY_Ft = px2unit(bg_offset_y,convertRateX,props.main.mainCanvas.mapUnit)
        setOffsetX_Ft(offsetX_Ft)
        setOffsetY_Ft(offsetY_Ft)
        setImageWidth_Ft(px2unit(bg_width,convertRateX,props.main.mainCanvas.mapUnit))
        setImageHeight_Ft(px2unit(bg_height,convertRateX,props.main.mainCanvas.mapUnit))

        setPrepareDwgimg(true)  
        setFileName(bgImgCanvas.bgFileName)    
      
      // setPreparePreviewDwgimg(true)
      // setTimeout(()=>{
        initCanvas(bg_url, offsetX_Ft, offsetY_Ft, factor);
      // }, 500)
      }, 500)
    }
  }, [open])

  const dollyIn = ()=>{
    var zoom = step2Canvas.getZoom()
    zoom = zoom * 1.3;	
    step2Canvas.zoomToPoint({ x: step2Canvas.width/2, y: step2Canvas.height/2}, zoom);
    step2Canvas.requestRenderAll();
    
    columnCanvas.zoomToPoint({ x: columnCanvas.width/2, y: columnCanvas.height/2}, zoom);
    columnCanvas.requestRenderAll();
  }
  const dollyOut = ()=>{
    var zoom = step2Canvas.getZoom()
    zoom = zoom * 0.8;	
    step2Canvas.zoomToPoint({ x: step2Canvas.width/2, y: step2Canvas.height/2}, zoom);
    step2Canvas.requestRenderAll();
    	
    columnCanvas.zoomToPoint({ x: columnCanvas.width/2, y: columnCanvas.height/2}, zoom);
    columnCanvas.requestRenderAll();
  }
  const swichFullView = ()=>{    
    var mapWidth = window.innerWidth;
    var mapHeight = window.innerHeight;
    var mainCanvas = props.main.mainCanvas
    if(mainCanvas.endX){
        var convertRateX= Math.abs(mainCanvas.convertRateX)
        if(mainCanvas.mapWidth && mainCanvas.mapWidth*1 && mainCanvas.mapHeight && mainCanvas.mapHeight*1){
          mapWidth = unit2Px(mainCanvas.mapWidth?mainCanvas.mapWidth:0, convertRateX, mainCanvas.mapUnit)
          mapHeight = unit2Px(mainCanvas.mapHeight?mainCanvas.mapHeight:0, convertRateX, mainCanvas.mapUnit)
        }
    }
    if(mapWidth && mapHeight){
        var zoomX = (642)/(mapWidth)
        var zoomY = (500)/(mapHeight)
        var zoom = Math.min(zoomX, zoomY)*0.95
        step2Canvas.viewportTransform[0] = zoom;
        step2Canvas.viewportTransform[3] = zoom;
        step2Canvas.viewportTransform[4] = 0;
        step2Canvas.viewportTransform[5] = 0;   
        step2Canvas.requestRenderAll();
        
        columnCanvas.viewportTransform[0] = zoom;
        columnCanvas.viewportTransform[3] = zoom;
        columnCanvas.viewportTransform[4] = 0;
        columnCanvas.viewportTransform[5] = 0;   
        columnCanvas.requestRenderAll();

        if(Math.min(zoomX, zoomY) > 0){
            var deltaX = (642 - mapWidth*zoom)/2;
            var deltaY = (500 - mapHeight*zoom)/2;
            var delta = new fabric.Point(deltaX, 35);
            step2Canvas.relativePan(delta);
            columnCanvas.relativePan(delta);
        }


    }
  }
  const onMouseDown = (opt, that)=>{           
    var evt = opt.e;
    g_isDragging = true; 
    that.selection = false;
    that.lastPosX = evt.clientX;
    that.lastPosY = evt.clientY;
  }

  const initCanvas = (bg_url, offsetX_Ft, offsetY_Ft, factor)=>{
    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    step2Canvas = new fabric.Canvas("step-2-canvas");
    step2Canvas.set({preserveObjectStacking: true, altActionKey: 'ctrlKey', altSelectionKey: 'ctrlKey'})
    step2Canvas.defaultCursor = 'pointer';
    step2Canvas.off('mouse:down');
    step2Canvas.on('mouse:down', function(opt) {
        onMouseDown(opt, this)            
    });
    
    step2Canvas.off('mouse:up');
    step2Canvas.on('mouse:up', function(opt) { 
      g_isDragging = false; 
    });
    step2Canvas.off('mouse:move')    
    step2Canvas.on('mouse:move', function(opt) {
        var evt = opt.e;
        if (g_isDragging) {
          var e = opt.e;
          var vpt = this.viewportTransform;
          var deltaX = e.clientX - this.lastPosX;
          var deltaY = e.clientY - this.lastPosY;
          vpt[4] += deltaX;
          vpt[5] += deltaY;
          this.requestRenderAll();

          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
        }
    });
    
    var columnImgCanvasEle = cloneCanvas(step2Canvas);
    columnImgCanvasEle.setAttribute('id', 'column-img-canvas');
    columnCanvas = new fabric.StaticCanvas("column-img-canvas");

    var content = props.main.mainCanvas.toDatalessObject()
    step2Canvas.loadFromJSON(content, ()=>{
      step2Canvas.discardActiveObject();
        step2Canvas.forEachObject(function(object) {
            object.evented = false;
            object.selectable = false; 
            object.selection=false;
            object.hasControls=false;
            object.hasBorders=false;
            object.lockMovementX= true;
            object.lockMovementY= true;
            object.lockRotation= true;
            object.lockScalingX= true;
            object.lockScalingY= true;
        });

      var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
      var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)


      var offsetX_PX = unit2Px(offsetX_Ft,convertRateX, mainCanvas.mapUnit);
      var offsetY_PX = unit2Px(offsetY_Ft,convertRateX, mainCanvas.mapUnit);
      if(bg_url){
        {
          fabric.Image.fromURL(bg_url, (img) => {
            step2Canvas.setBackgroundImage(img, previewCallback.bind(step2Canvas), {
              opacity: 0.5,
              angle: 0,
              left: offsetX_PX+50+mapOffsetX,
              top: offsetY_PX+50+mapOffsetY,
              originX: 'left',
              originY: 'top',
              scaleX: factor,
              scaleY: factor,
              crossOrigin: 'anonymous'
            });
          });
        }
      }
      else{
        step2Canvas.setBackgroundImage(null);
      }
      swichFullView();
      setLoadingFlag(false)

    })
  }

  const previewRender = ()=>{
    if(!step2Canvas) return;
    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    
    var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
    var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)


    var offsetX_PX = unit2Px(offsetX_Ft,convertRateX, mainCanvas.mapUnit);
    var offsetY_PX = unit2Px(offsetY_Ft,convertRateX, mainCanvas.mapUnit);
    if(imgUrl){
      {
        if(dwgFilteredSvg){
          fabric.Image.fromURL(imgUrl, (img) => {
            columnCanvas.setBackgroundImage(img, previewCallback.bind(columnCanvas), {
              // opacity: 0.5,
              angle: 0,
              left: offsetX_PX+50+mapOffsetX,
              top: offsetY_PX+50+mapOffsetY,
              originX: 'left',
              originY: 'top',
              scaleX: factor,
              scaleY: factor,
              crossOrigin: 'anonymous'
            });
          })
          // v.stop()
        }
      }
    }
    else{
      columnCanvas.setBackgroundImage(null).renderAll();
    }
    setLoadingFlag(false)
  }

  useEffect(() => {
    if(preparePreviewDwgimg){
      setLoadingFlag(true)
      setTimeout(function(){
        previewRender()
      },100)
    }
    
    setPreparePreviewDwgimg(false)
  }, [preparePreviewDwgimg])

  useEffect(() => {
      setLoadingFlag(true)
      setTimeout(function(){
        previewRender()
      },100)
  }, [imgUrl])
  

  useEffect( async () => {
    if(columnCanvas){      
      var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
      var mapOffsetX = unit2Px(mainCanvas.mapOffsetX?mainCanvas.mapOffsetX:0, convertRateX, mainCanvas.mapUnit)
      var mapOffsetY = unit2Px(mainCanvas.mapOffsetY?mainCanvas.mapOffsetY:0, convertRateX, mainCanvas.mapUnit)
      var offsetX_PX = unit2Px(offsetX_Ft,convertRateX, mainCanvas.mapUnit);
      var offsetY_PX = unit2Px(offsetY_Ft,convertRateX, mainCanvas.mapUnit);
      if(1){
        if(imgUrl){
          fabric.Image.fromURL(imgUrl, (img) => {
            columnCanvas.setBackgroundImage(img, columnCanvas.renderAll.bind(columnCanvas), {
              opacity: 0.5,
              angle: 0,
              left: offsetX_PX+50+mapOffsetX,
              top: offsetY_PX+50+mapOffsetY,
              originX: 'left',
              originY: 'top',
              scaleX: factor,
              scaleY: factor,
              crossOrigin: 'anonymous',
            });
          })
        }
      }
    }
    g_offsetX_Ft = offsetX_Ft
    g_offsetY_Ft = offsetY_Ft
  }, [factor, offsetX_Ft, offsetY_Ft])
  
  const parseLayer = (svgStr)=>{
    if(svgStr){
        var drawermain = SVG(svgStr);
        var layerGroups = drawermain.find("[id*='layer_']");
        var layers = [];
        if(layerGroups && layerGroups.length>0){
            for(var loop = 0; loop < layerGroups.length; loop++){
                var layerGroup = layerGroups[loop];
                if(layerGroup){
                    var layerid = layerGroup.attr('id');
                    var layername = layerGroup.attr('cvjs:layername')
                    console.log("layername", layername)
                    console.log("layerid", layerid)
                    layers.push({id:layerid, name:layername, active:false})
                }
            }
        }
        return layers;
    }
}

  const getConvertedSvg=(convertedSvgInfo) => {
    if(convertedSvgInfo){
        axios.get(convertedSvgInfo.contentStreamData, { headers: {  } }).then(res=>{
            const result = res.data;
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(result, "application/xml");
            // var svgDoc = doc.contentDocument;
            var svgStr = svgDoc.getElementsByTagName('svg')[0].outerHTML;
            if(svgStr){
                var layers = parseLayer(svgStr)                        
                setMainLayers(layers)                
            }
            setDwgSvg(svgStr); 
        }).catch(error => {
            console.log("error", error);
        })
            
    }
  }

  const handleListItemClick = (value) => {
    // onClose(value);
    console.log("handleListItemClick", value)
    var tmp = [...mainLayers];
    for(var i = 0; i <tmp.length; i++){
        if(tmp[i]['id'] == value){
            tmp[i] = {...tmp[i], active:!tmp[i]['active'], selected: !tmp[i]['active']};
        }
        else{
          tmp[i] = {...tmp[i], selected: false};
        }
    }
    setMainLayers(tmp)
    setPrepareDwgimg(true)
  };

  const onChangeFile = async (e)=>{
    var convertRateX= Math.abs(props.main.mainCanvas.convertRateX)
    var file = openRef.current.files[0]
    var filename = file.name
    if(file){
      setFileName(filename)
      {
        var data = file.slice(0, file.size);
        let last_dot = filename.lastIndexOf('.')
        let ext = filename.slice(last_dot + 1)
        dwgFilePath = `dwg/${uuidv4()}.${ext}`
        const formData = new FormData();
        const fileType = file.type || "application/octet-stream"; 
        const newFile = new File([file], file.name, {
            type: fileType,
            lastModified: file.lastModified
        });
        formData.append('file', newFile); 
        formData.append('filePath', dwgFilePath);
        var url = `${S3_URL()}uploadfile` 
        const headers = {headers: {'Content-Type': 'multipart/form-data'}};
        setLoadingFlag(true)
        try{
        const response = await axios.post(url, formData, headers);
        if(response.status == 200){
          var formdata = 
          {
              "action":"svg_creation",
              "converter":"AutoXchange AX2020",
              "version":"V1.00",
              "contentType":"file",
              "contentLocation":`${S3_URL()}s3/${dwgFilePath}`,
              "contentFormat":"DWG",
              "contentUsername":"",
              "contentPassword":"",
              "userLabel":"fromCADViewerJS",
              "contentResponse":"stream",
              "leaveStreamOnServer":0,
              "parameters":[
                {"paramName":"f",
                "paramValue":"svg"},
                // {"paramName":"RL",
                // "paramValue":"RM_"},
                // {"paramName":"TL","paramValue":"RM_TXT"},
                // {"paramName":"LA","paramValue":""},
                {"paramName":"model","paramValue":""},
                {"paramName":"fpath","paramValue":"/converters/ax2020/windows/fonts/"},
                {"paramName":"WIDTH","paramValue":"10000"},
                {"paramName":"HEIGHT","paramValue":"10000"},
             ]
          }
          var data = qs.stringify({
              request: JSON.stringify(formdata)
            });
          // var bodyFormData = new FormData();
          // bodyFormData.append('request', JSON.stringify(formdata));
          const options = {
              headers: {"content-type": 'application/x-www-form-urlencoded'}
          }
          axios.post('/callapiconversion', data, options).then(res=>{
              const result = res.data;
              getConvertedSvg(result);
              setLoadingFlag(false)
          }).catch(error => {
              console.log("error", error);
              setLoadingFlag(false)
          })
        }
        }
        catch(e){
          setLoadingFlag(false)
        }
        
      }
      openRef.current.value = null;
    }
  }

  const moveX = (direction)=>{
    setOffsetX_Ft(g_offsetX_Ft*1+direction)
  }

  const moveY = (direction)=>{
    setOffsetY_Ft(g_offsetY_Ft*1+direction)
  }

  return (
    <Dialog
      id="column-modal"
      fullWidth={true}
      maxWidth={"lg"}      
      PaperComponent={PaperComponent}
      PaperProps={{drag_id:'column-dialog-title'}}
      aria-labelledby="column-dialog-title"
      // onClose={onClose} 
      open={open}>
      <DialogTitle id='column-dialog-title' style={{display:'flex', alignItems:'center'}}><SplitscreenIcon style={{marginRight: 10}}/>Column</DialogTitle>
      <DialogContent>
        <Box>
            <Box mt={4}>
              <Grid
                container
                spacing={2}
                rowSpacing = {2}
              >
                <Grid item md={12}>
                  <div>
                    <FormControl>
                      <div>
                        <Button variant="contained" onClick={()=>{openRef.current.click()}} color="primary" endIcon = {<FileUpload/>}>
                          Upload
                        </Button>
                          <input type="file" ref={openRef} style={{display:"none"}} onChange={(e)=>onChangeFile(e)}  accept='.dwg,.dxf'/>
                      </div>
                    </FormControl>
                    <FormControl sx={{marginLeft:'10px'}}>
                      <Button variant="contained" onClick={()=>{setImageUrl(null); }} color="primary" endIcon = {<DeleteForeverOutlinedIcon/>}>
                        Delete
                      </Button>
                      
                    </FormControl>
                  </div>                  
                  {
                    fileName&& 
                      <Box mb={1} style={{fontWeight:'15px'}}>
                        {fileName}
                      </Box>
                  }
                </Grid>
                
              </Grid>
            </Box>
            <Box mt={4}>
              <Grid
                container
                spacing={1}
                rowSpacing = {1}
              >
                <Grid item md={3}>
                    <Box mt={0} style={{borderRight:'1px solid rgb(237 237 237)', height:'100%'}}>
                      <List sx={{ pt: 0 }}>
                        {mainLayers.map((layer, index) => (
                          <ListItem key={index} style={{fontSize:12}} button onClick={() => handleListItemClick(layer['id'])}>
                            <ListItemAvatar className="layeravada">
                                {layer['active']?<span style={{color:'green', fontWeight:'bold'}}>On</span>:<span>Off</span>}
                            </ListItemAvatar>
                            <ListItemText className = "layertext" primary={layer['name']} />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                </Grid>
                <Grid item md={9}>
                  <Grid
                      container
                      spacing={2}
                      rowSpacing = {4}
                  >
                    <Grid item md={9}>
                      <canvas id="step-2-canvas" width="642" height="500"/>
                    </Grid>              
                    <Grid item md={3}>
                      <Box pl={2} style={{borderLeft:'1px solid rgb(237 237 237)', height:'100%'}}>
                        <Box mb={3}>
                          <TextField
                            id="outlined-required"
                            label="Width"
                            defaultValue="0"
                            value={imageWidth_Ft}
                            onChange={(e)=>setImageWidth(e.target.value)}
                            disabled={true}
                          />
                        </Box>
                        <Box mb={3}>
                          <TextField
                            id="outlined-required"
                            label="Height"
                            defaultValue="0"
                            value={imageHeight_Ft}
                            onChange={(e)=>setImageHeight(e.target.value)}
                            disabled={true}
                          />
                        </Box>
                        <Box mb={3}>
                          <TextField
                            id="outlined-required"
                            label="Scale"
                            defaultValue="0"
                            value={factor}
                            onChange={(e)=>setFactor(e.target.value)}
                          />
                        </Box>
                        <Box mb={3}>
                          <TextField
                            id="outlined-required"
                            label="Offset X"
                            defaultValue="0"
                            value={offsetX_Ft}
                            onChange={(e)=>setOffsetX_Ft(e.target.value)}
                          />
                        </Box>
                        <Box>
                          <TextField
                            id="outlined-required"
                            label="Offset Y"
                            defaultValue="0"
                            value={offsetY_Ft}
                            onChange={(e)=>setOffsetY_Ft(e.target.value)}
                          />
                        </Box>
                        
                        <div className="set-bg-controls" style={{display:'flex', marginTop:'15px', justifyContent:'center'}}>
                            <Button title="Zoom In" onClick={()=>{dollyIn()}}><ZoomInIcon/></Button>
                            <Button style={{ marginLeft: '5px' }}  title="Zoom Out" onClick={()=>{dollyOut()}}><ZoomOutIcon/></Button>
                            <Button style={{ marginLeft: '5px' }}  title="Auto Fit on Screen" onClick={()=>{swichFullView()}}><FullscreenIcon/></Button>
                        </div>
                        <Box className="pan" sx={{mt:'15px'}}>
                          <Box sx={{textAlign:'center'}}>
                            <IconButton sx={{p:0, mb: '5px'}} size="small" type="button" 
                              onMouseDown={()=>{
                                moveTimer = setInterval(()=>{
                                  moveY(-1)
                                }, 80)
                              }}
                              onMouseUp={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                              onMouseLeave={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                            >
                                <KeyboardArrowUpOutlinedIcon />
                            </IconButton>
                          </Box>
                          <Box sx={{textAlign:'center'}}>
                            <IconButton sx={{p:0, marginRight:'14px'}} size="small" type="button"
                              onMouseDown={()=>{
                                moveTimer = setInterval(()=>{
                                  console.log("moveX")
                                  moveX(-1)
                                }, 80)
                              }}
                              onMouseUp={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                              onMouseLeave={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                            >
                                <ChevronLeftOutlinedIcon />
                            </IconButton>
                            <IconButton sx={{p:0, marginLeft:'14px'}} size="small" type="button"
                              onMouseDown={()=>{
                                moveTimer = setInterval(()=>{
                                  moveX(1)
                                }, 80)
                              }}
                              onMouseUp={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                              onMouseLeave={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                            >
                                <ChevronRightOutlinedIcon />
                            </IconButton>
                          </Box>
                          <Box sx={{textAlign:'center'}}>
                            <IconButton sx={{p:0, mt:'5px'}} size="small" type="button"
                              onMouseDown={()=>{
                                moveTimer = setInterval(()=>{
                                  console.log("moveX")
                                  moveY(1)
                                }, 80)
                              }}
                              onMouseUp={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                              onMouseLeave={()=>{
                                if(moveTimer)
                                  clearInterval(moveTimer)
                              }}
                            >
                                <KeyboardArrowDownOutlinedIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                
              </Grid>
            </Box>           
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={()=>onSet()}>
          <b>Set</b>
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
      <LoadingOverlay
        active={loadingFlag}
        styles={{
          // spinner: (base) => ({
          // ...base,
          // width: '50px',
          // '& svg circle': {
          //     stroke: '#1976d2'
          // }
          // }),
          overlay: (base) => ({
          ...base,
          fontSize:'18px',
          color: 'rgb(5, 37, 51)',
          // background: 'rgb(229 229 229 / 92%)',
          background: 'transparent',
          position:'fixed',
          zIndex:1000000000
          })
        }}
        spinner = {<ScaleLoader color={props.ui?.customStyles?.['spinner-color']?props.ui?.customStyles['spinner-color']:'#1976d2'} radius={2.5} margin={2.5} height={40} width={5}/>}
        >
      </LoadingOverlay> 
    </Dialog>
  );
}
const mapStateToProps = (state) => {
    return {
      main: state.main,
    };
  };
  
  const mapDispatchProps = (dispatch) => {
    return {   
      setLoading:  data=>dispatch(setLoading(data)),
      setBoothHistory: data=>dispatch(setBoothHistory(data)),
      addLog:  data=>dispatch(addLog(data)),
    };
  };
  
export default connect(mapStateToProps, mapDispatchProps)(ColumnModal);